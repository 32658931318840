import { observer } from 'mobx-react';
import { ComponentProps, ReactNode } from 'react';
import { useModal } from 'react-modal-hook';

import { Event, EventInvite, User } from '../../models';
import { usePotluck, useUser } from '../../providers';
import { Button } from '../Button';
import { Loader } from '../Loader';
import { Modal } from '../Modal';
import { Tabs } from '../Tabs';
import { AddGuest, ConnectionListItem } from './';

interface GuestListCreatorProps {
  event: Event;
  editInvite?: EventInvite;
  onAddInvite?: (invite: EventInvite) => void;
  onUpdateInvite?: (invite: EventInvite) => void;
  openCreatorOption?: ReactNode;
  initialTab: string;
  onTabChange: (tab: string) => void;
}

export const GuestListCreator = ({
  event,
  editInvite,
  onAddInvite,
  openCreatorOption,
  onUpdateInvite,
  initialTab,
  onTabChange
}: Omit<ComponentProps<'div'>, ''> & GuestListCreatorProps) => {
  const { user } = useUser();
  const [openCreator, closeCreator] = useModal(
    () => (
      <Modal
        onClose={closeCreator}
        header={<div className="text-2xl font-semibold">Guests</div>}
        fullHeight
      >
        {({ close }) => (
          <GuestListCreatorDetails
            initialTab={initialTab}
            event={event}
            host={user}
            editInvite={editInvite}
            onTabChange={(tab) => {
              onTabChange(tab);
            }}
            onClose={() => close()}
            onAddInvite={(invite) => {
              onAddInvite?.(invite);
            }}
            onUpdateInvite={(invite) => onUpdateInvite?.(invite)}
          />
        )}
      </Modal>
    ),
    [event, initialTab, user]
  );

  return <div onClick={() => openCreator()}>{openCreatorOption}</div>;
};

interface GuestListCreatorDetailsProps extends GuestListCreatorProps {
  onClose: () => void;
  initialTab: string;
  onTabChange: (tab: string) => void;
  host: User | null | undefined;
}

export const GuestListCreatorDetails = observer(
  ({
    initialTab,
    event,
    editInvite,
    onAddInvite,
    onClose,
    onUpdateInvite,
    onTabChange,
    host
  }: GuestListCreatorDetailsProps) => {
    const { user } = usePotluck();

    if (!!editInvite && initialTab === 'connection') {
      initialTab = 'new';
      onTabChange(initialTab);
    }

    return (
      <Tabs
        onTabChange={(tab) => onTabChange(tab)}
        initialTab={initialTab}
        tabs={[
          {
            name: 'Connections',
            path: 'connection',
            disabled: !!editInvite, 
            content: (
              <>
                <div ref={user.connections.register}>
                  {!user.connections.loading && user.connections.data?.length === 0 ? (
                    <>
                      <div className="pb-4">
                        When a guest RSVP's “Yes” to any of your events, they will automatically
                        become a Connection and appear here. So you can invite them to future events
                        with just the click of a button!
                      </div>
                    </>
                  ) : null}
                  {!editInvite &&
                    user.connections.data
                      ?.filter((c) => c.id !== event.organizer.id)
                      .map(({ id, ...connection }, index) => (
                        <ConnectionListItem
                          key={id}
                          connection={{ id, ...connection }}
                          event={event}
                          isEdit={!!editInvite}
                          onAddInvite={(invite) => onAddInvite?.(invite)}
                          displayRoleDescriptions={index === 0}
                        />
                      ))}
                  {!!editInvite &&
                    user.connections.data
                      ?.filter((u) => u.id === editInvite?.user?.id)
                      .map(({ id, ...connection }, index) => (
                        <ConnectionListItem
                          key={id}
                          connection={{ id, ...connection }}
                          event={event}
                          isEdit={!!editInvite}
                          editInvite={event.invites?.find((i) => i.user?.id === id)}
                          onAddInvite={(invite) => onAddInvite?.(invite)}
                          onUpdateInvite={(invite) => onUpdateInvite?.(invite)}
                          displayRoleDescriptions={index === 0}
                        />
                      ))}
                  {user.connections.loading && <Loader />}
                  <div className="text-center">
                    <Button outline onClick={onClose}>
                      Save
                    </Button>
                  </div>
                </div>
              </>
            )
          },
          {
            name: !!editInvite ? 'Edit Invite': 'Add New',
            path: 'new',
            disabled: false,
            content: (
              <AddGuest
                host={host}
                onAdd={(guest, role) => {
                  onAddInvite?.({ ...guest, role });
                  onClose();
                }}
                editInvite={editInvite}
              />
            )
          }
        ]}
      />
    );
  }
);
