import { useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useHistory } from 'react-router-dom';

import { Button } from '../components';
import Loader from '../components/Loader/Loader';
import Modal from '../components/Modal/Modal';
import { Event } from '../models';
import useEvent from './useEvent';

export function useDuplicateEventConfirmationModal(event: Event | undefined) {
  const { copy } = useEvent();
  const router = useHistory();

  const [openDuplicateEventConfirmation, closeDuplicateEventConfirmation] = useModal(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isCopying, setIsCopying] = useState(false);
    if (!event) {
      return null;
    }

    return (
      <Modal onClose={closeDuplicateEventConfirmation}>
        {({ close }) => (
          <div className="text-center space-y-8 flex flex-col">
            <div className="font-bold text-lg">Are you sure?</div>
            <div>
              This will create a new event with the same details, needs, and guests. It can be fully
              edited before invitations are sent.
            </div>
            <div className="mt-2">
              At minimum, event title, date, and time will need to be updated.
            </div>
            <div className="flex flex-col justify-evenly sm:flex-row">
              <Button outline onClick={close}>
                Cancel
              </Button>
              <Button
                disabled={isCopying}
                onClick={async () => {
                  setIsCopying(true);
                  const copied = await copy(event);
                  setIsCopying(false);
                  close();
                  router.push(`/edit/${copied.id}/details`);
                }}
                className="mt-2"
              >
                Duplicate
              </Button>
            </div>
            {isCopying && <Loader />}
          </div>
        )}
      </Modal>
    );
  }, [event]);

  return { openDuplicateEventConfirmation, closeDuplicateEventConfirmation };
}
