import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { Loader } from '../../components';
import { useEvent } from '../../hooks';
import EventPageBody from './EventPageBody';

const EventPage = () => {
  const router = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { path } = useRouteMatch();
  const { event } = useEvent(Number(id));

  if (!event) {
    return <Loader />;
  }

  return <EventPageBody event={event} router={router} path={path} />;
};

export default EventPage;
