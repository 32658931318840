import styled from '@emotion/styled';
import { ComponentProps, FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';

import { BrandLogo, FadeIn, fadeInAnimation } from '../components';

// lg:p-20 pb-32 lg:pb-8 px-6
export const Page = styled.div`
  ${tw`w-full h-full px-6 pb-32 lg:px-12 bg-content-spring-wood lg:p-20 lg:pb-8 overflow-auto`}

  ${fadeInAnimation()}
`;

const Container = styled(FadeIn)`
  ${tw`flex flex-col items-center justify-between w-full bg-neutral-white`}
`;

const MegaLogo = styled(BrandLogo)`
  width: 12rem;
  height: 5rem;
`;

const Content = styled.div`
  ${tw`w-full`}
`;

const Body = styled.div`
  ${tw`w-full px-8 mx-auto bg-neutral-white`}
`;

const Footer = styled.div`
  ${tw`w-full p-8 pt-16 text-center space-y-5 bg-neutral-white`}
`;

export const PublicPage: FC<ComponentProps<'div'> & { footer?: ReactNode }> = ({
  children,
  footer,
  ...props
}) => {
  return (
    <Container {...props}>
      <Content>
        <div className="flex justify-center my-8">
          <Link to="/sign-in">
            <MegaLogo />
          </Link>
        </div>
        <Body>{children}</Body>
      </Content>
      <Footer>{footer}</Footer>
    </Container>
  );
};

export default Page;
