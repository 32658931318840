import styled from '@emotion/styled';
import { ComponentProps, ReactNode } from 'react';
import tw from 'twin.macro';

import { FadeIn, Loader } from '../../components';
import { Event as EventModel } from '../../models';

const Container = styled(FadeIn)`
  ${tw`w-full mx-auto bg-neutral-white pb-12 flex-1`}

  ${(p) => tw`rounded-br-3xl`}
`;

const HeaderContainer = styled.div`
  ${tw`overflow-hidden bg-neutral-stone bg-opacity-50 rounded-br-3xl`}
  height: 56.25vw;
  width: 100%;

  @media (min-width: 896px) {
    height: 31.156rem;
  }
`;

const EventBanner = styled.div<{ background?: string }>`
  ${tw`flex flex-col items-end justify-center bg-center bg-no-repeat bg-cover bg-secondary-600`}
  height: 56.25vw;
  width: 100%;

  @media (min-width: 896px) {
    height: 31.156rem;
  }

  background-image: ${(p) => p.background && `url(${p.background})`};
  transition: background 0.1s ease-in;
`;

type EventProps = ComponentProps<'div'> & {
  event: EventModel;
  header?: ReactNode;
};

export function Event({ event, header, children }: EventProps) {
  if (!event) {
    return <Loader>Fetching event details...</Loader>;
  }

  return (
    <Container>
      <div>
        <HeaderContainer>
          <EventBanner background={event.coverImageUrl}>
            {header}
            <svg
              className="absolute bottom-0 right-0 w-6 h-6 corner-mask"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 8C4.41846 8 8 4.41833 8 0V8H0Z" fill="white" />
            </svg>
          </EventBanner>
        </HeaderContainer>
        <div className="relative flex flex-col bg-neutral-white">
          <div className="flex flex-1 w-full overflow-auto">{children}</div>
        </div>
      </div>
    </Container>
  );
}

export default Event;
