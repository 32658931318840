import styled from '@emotion/styled';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useModal } from 'react-modal-hook';
import { Link, Redirect, useHistory } from 'react-router-dom';
import tw from 'twin.macro';

import { Modal } from '../../components';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { fetcher, useUser } from '../../providers';
import { PublicPage } from '../Page';

const Form = styled.form`
  ${tw`max-w-sm mx-auto space-y-14`}
`;

const FormBody = styled.div`
  ${tw`space-y-10`}
`;

export function SignIn() {
  const { user, setUser, signIn, signInPasswordless, isPasswordRequiredForUser } = useUser();
  const { register, handleSubmit, formState, resetField, setError } = useForm({
    defaultValues: { identity: '', password: '' },
    mode: 'onTouched'
  });
  const router = useHistory();

  const { errors } = formState;
  const [requirePassword, setRequirePassword] = useState(false);
  const eventId = new URLSearchParams(window.location.search).get('event-id');
  const rsvpResponse = new URLSearchParams(window.location.search).get('response');

  const [openSignInWasInvalid, closeSignInWasInvalid] = useModal(
    () => (
      <Modal onClose={closeSignInWasInvalid}>
        {({ close }) => (
          <div className="flex flex-col items-center space-y-10">
            <div>
              Oops! That information is not associated with an account, please try again or register
              for a new account.
            </div>
            <Button onClick={close}>Try again</Button>
            <a href="/send-reset-password-code">Forgot Password?</a>
          </div>
        )}
      </Modal>
    ),
    []
  );

  const [openPasswordWasInvalid, closePasswordWasInvalid] = useModal(
    () => (
      <Modal onClose={closePasswordWasInvalid}>
        {({ close }) => (
          <div className="flex flex-col items-center space-y-10">
            <div>Password incorrect</div>
            <Button onClick={close}>Try again</Button>
            <a href="/send-reset-password-code">Forgot Password?</a>
          </div>
        )}
      </Modal>
    ),
    []
  );

  const handleRsvp = async () => {
    if (eventId) {
      if (rsvpResponse && rsvpResponse === 'yes') {
        await fetcher(`/api/event/${eventId}/rsvp`, {
          method: 'POST',
          body: JSON.stringify({
            response: rsvpResponse
          })
        });
      } else {
        router.push(`/event/${eventId}`);
      }
    }
  };

  const [openPasswordRequired, closePasswordRequired] = useModal(
    () => (
      <Modal fullWidth={false} onClose={closePasswordRequired}>
        {({ close }) => (
          <Form
            onSubmit={handleSubmit(async ({ identity, password }) => {
              try {
                await signIn(identity, password);
                handleRsvp();
              } catch {
                setUser(null);
                resetField('password');
                setError(
                  'password',
                  { type: 'focus', message: 'Incorrect password' },
                  { shouldFocus: true }
                );
              }
            })}
          >
            <div className="flex flex-col items-center space-y-10">
              <div>Welcome back! Please enter your password.</div>
              <Input
                autoFocus
                type="password"
                placeholder="Password (required)"
                {...register('password', { required: 'A password is required.' })}
                errors={errors.password}
              />
              <Button type="submit">Sign In</Button>
              <a href="/send-reset-password-code">Forgot Password?</a>
            </div>
          </Form>
        )}
      </Modal>
    ),
    [errors, formState]
  );

  if (user && user.id) {
    const redirectUrl = new URLSearchParams(window.location.search).get('redirect-url') || '/';
    return <Redirect to={{ pathname: redirectUrl }} />;
  }

  return (
    <PublicPage
      footer={
        <>
          <Link to="/send-reset-password-code">Forgot Password?</Link>
          <p>
            By signing in you agree to our <a href="https://potluck.us/privacy">Privacy Policy</a>{' '}
            and <a href="https://potluck.us/terms">Terms of Service</a>
          </p>
        </>
      }
    >
      <Form
        onSubmit={handleSubmit(async ({ identity, password }) => {
          try {
            if (!password && (await isPasswordRequiredForUser(identity))) {
              setRequirePassword(true);
              openPasswordRequired();
              return;
            }

            if (requirePassword) await signIn(identity, password);
            else await signInPasswordless(identity);
            handleRsvp();
          } catch {
            setUser(null);
            resetField('password');
            if (requirePassword) {
              openPasswordWasInvalid();
            } else {
              openSignInWasInvalid();
            }
          }
        })}
      >
        <FormBody>
          <Input
            placeholder="Email or Phone (required)"
            {...register('identity', {
              required: 'An email or phone number is required.',
              validate: {
                pattern: (value) =>
                  !!(
                    value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) ||
                    value.match(/^(\d{10}|\d{12})$/)
                  ) || 'Not a valid email or phone number.'
              }
            })}
            errors={errors.identity}
          />
          <div className="flex flex-col items-center mt-10 space-y-4">
            <Button>Sign In</Button>
            <Link to="/sign-up">Register</Link>
          </div>
        </FormBody>
      </Form>
    </PublicPage>
  );
}

export default SignIn;
