import * as React from 'react';

function SvgTransportation(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        transform="translate(2 5)"
        stroke="#070401"
        strokeWidth={1.24}
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M4.32 0h5.666a2.4 2.4 0 012.106 1.25l1.867 3.417h0H1.214l.792-2.9A2.4 2.4 0 014.32 0z"
          fill="#F8F6F1"
        />
        <path
          d="M17.628 4.667a2.4 2.4 0 012.4 2.4v3.983a1.2 1.2 0 01-1.2 1.2h-.739 0c0-1.861-1.157-3.37-2.584-3.37s-2.584 1.509-2.584 3.37H7.107h0c0-1.861-1.157-3.37-2.585-3.37-1.427 0-2.584 1.509-2.584 3.37H1.2a1.2 1.2 0 01-1.2-1.2V5.867a1.2 1.2 0 011.2-1.2h16.428z"
          fill="#F0EBE0"
        />
        <ellipse fill="#F8F6F1" cx={15.172} cy={11.083} rx={3.034} ry={2.917} />
        <ellipse fill="#F8F6F1" cx={4.855} cy={11.083} rx={3.034} ry={2.917} />
        <path d="M6.979 0v4.667M9.407 6.125H7.586" />
        <path
          d="M12.441 5.25c.67 0 1.214-.392 1.214-.875s-.543-.875-1.214-.875v1.75zM19.946 9.262c.639-.536.987-1.035 1.045-1.498.087-.694-.496-1.372-1.166-1.372l.12 2.87z"
          fill="#D8D8D8"
        />
        <circle fill="#1C1F21" cx={15.172} cy={11.083} r={1} />
        <circle fill="#1C1F21" cx={4.855} cy={11.083} r={1} />
      </g>
    </svg>
  );
}

export default SvgTransportation;
