import { Link } from 'react-router-dom';

import { Button, Card, PlanSomething } from '../../components';
import { PublicPage } from '../Page';

export function NotFound() {
  return (
    <PublicPage>
      <Card className="flex flex-col items-center max-w-xl mx-auto space-y-8">
        <PlanSomething />
        <div className="text-4xl font-semibold">NOT FOUND</div>
        <p>
          You may have ended up here by mistake! If you tried to respond to an invite, the event may
          have been canceled. Check with the host to make sure.
        </p>
        <Link to="/">
          <Button>Home</Button>
        </Link>
      </Card>
    </PublicPage>
  );
}

export default NotFound;
