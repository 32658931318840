import styled from '@emotion/styled';
import { FC, useEffect, useRef, useState } from 'react';
import { Transition, TransitionStatus } from 'react-transition-group';
import tw from 'twin.macro';

import { slideInVerticalAnimation } from '../animations';
import { CloseWhite } from '../icons';

const Card = styled.div<{ transition?: TransitionStatus }>`
  ${tw`bg-neutral-black text-neutral-white rounded-3xl flex p-3 items-center opacity-0 max-w-4xl m-auto`}
  transition: all 300ms ease-in;

  ${slideInVerticalAnimation(0.3)}

  opacity: ${(p) => (p.transition === 'entered' ? 1 : 0)};
  transform: ${(p) => (p.transition === 'entered' ? 'translateY(0)' : 'translateY(100%)')};
`;

export const Notification: FC = ({ children }) => {
  const [isShowing, setIsShowing] = useState(true);
  const notificationCard = useRef<HTMLDivElement>(null);

  useEffect(() => void setTimeout(() => setIsShowing(false), 5900), []);

  return (
    <Transition in={isShowing} timeout={800} nodeRef={notificationCard} unmountOnExit>
      {(status) => (
        <div className="w-full fixed p-4 z-30">
          <Card ref={notificationCard} transition={status}>
            <div className="flex-1 text-center text-base">{children}</div>
            <CloseWhite
              className="cursor-pointer stroke-white"
              onClick={() => setIsShowing(false)}
            />
          </Card>
        </div>
      )}
    </Transition>
  );
};

export default Notification;
