import * as React from 'react';

function SvgPollCreateScene(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 179 112" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(89.523 5.424)">
          <path
            d="M82.246.55c3.39 0 6.14 2.75 6.14 6.143v58.265a6.142 6.142 0 01-6.14 6.144H6.69a6.142 6.142 0 01-6.14-6.144V6.693A6.142 6.142 0 016.69.55h75.556m0-.55H6.69A6.699 6.699 0 000 6.693v58.265a6.699 6.699 0 006.69 6.694h75.556a6.699 6.699 0 006.69-6.694V6.693A6.699 6.699 0 0082.246 0z"
            fill="#000"
            fillRule="nonzero"
          />
          <rect
            fill="#FFF"
            fillRule="nonzero"
            x={0.55}
            y={0.55}
            width={87.836}
            height={70.551}
            rx={7.594}
          />
          <path stroke="#000" strokeWidth={0.68} d="M.55 13.753h88.111" />
          <ellipse fill="#000" fillRule="nonzero" cx={7.01} cy={7.014} rx={2.337} ry={2.338} />
          <ellipse fill="#000" fillRule="nonzero" cx={14.092} cy={7.014} rx={2.337} ry={2.338} />
          <ellipse fill="#000" fillRule="nonzero" cx={21.173} cy={7.014} rx={2.337} ry={2.338} />
        </g>
        <g transform="translate(12.134 22.692)">
          <rect
            fill="#FFF"
            fillRule="nonzero"
            x={0.939}
            y={0.282}
            width={87.836}
            height={70.551}
            rx={7.594}
          />
          <path
            d="M82.246.55c3.39 0 6.14 2.75 6.14 6.143v58.265a6.142 6.142 0 01-6.14 6.144H6.69a6.142 6.142 0 01-6.14-6.144V6.693A6.142 6.142 0 016.69.55h75.556m0-.55H6.69A6.699 6.699 0 000 6.693v58.265a6.699 6.699 0 006.69 6.694h75.556a6.699 6.699 0 006.69-6.694V6.693A6.699 6.699 0 0082.246 0z"
            fill="#000"
            fillRule="nonzero"
          />
          <path stroke="#000" strokeWidth={0.68} d="M.55 13.753h88.111" />
          <ellipse fill="#000" fillRule="nonzero" cx={7.01} cy={7.014} rx={2.337} ry={2.338} />
          <ellipse fill="#000" fillRule="nonzero" cx={14.092} cy={7.014} rx={2.337} ry={2.338} />
          <ellipse fill="#000" fillRule="nonzero" cx={21.173} cy={7.014} rx={2.337} ry={2.338} />
        </g>
        <g transform="translate(.687 19.768)">
          <g stroke="#000" strokeWidth={0.68}>
            <path
              d="M72.942 13.148v-.179l.053-.04-.052-.011c0-.955-.08-3.792-1.008-5.954-.824-1.92-1.96-2.808-2.75-2.469-.79.339-.93 1.777-.11 3.694.457 1.069 1.177 2.1 1.871 2.952-1.398-1.325-3.306-2.968-5.03-3.872-2.33-1.222-4.144-1.186-4.647-.228-.503.957.5 2.474 2.83 3.696 1.515.797 3.584 1.387 5.343 1.788a11.897 11.897 0 00-2.81.237c-2.039.458-3.118 1.414-2.93 2.252.187.837 1.572 1.24 3.61.783 2.27-.51 4.591-2.077 5.39-2.654l.14.022.092.084v-.071h.034l-.026-.03z"
              fill="#CDD2E3"
              fillRule="nonzero"
            />
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M64.173 20.968L79.17 5.811l4.074 4.034-14.997 15.158z"
            />
            <path
              fill="#FFF"
              d="M72.484 27.69l-3.369-3.229 13.55-13.71 12.434 12.525-13.2 13.438-6.616-6.34"
            />
          </g>
          <path
            d="M75.49 30.417s1.786-5.914.686-6.876c-1.327-1.163-3.574 2.613-5.384 7.151-1.904 4.768-7.423 18.291-7.698 19.254 0 0-9.403-5.144-13.608-7.014-3.711-1.65-8.232-2.25-8.232-2.25l-.575-3.453s2.685.962 4.986-.384c2.3-1.346 2.877-3.838 2.11-7.675-.455-2.274-.439-5.089-.825-7.599-1.531.259-3.775-.268-3.775-.268-3.07 3.453-6.323 4.22-6.323 4.22-2.11-2.302-5.946-2.302-6.52.963-.637 3.61 3.834 4.605 3.834 4.605s.91 5.24-1.014 10.466c-2.674 7.261-13.452 9.188-17.87 6.05-7.972 5.64-.92 22.771 5.774 30.807 5.498 6.601 25.017 3.163 25.017 3.163s2.2 1.65 4.811 2.2c4.674 9.215 6.598 6.602 6.598 6.602s1.65 1.788 2.887-.138c2.474.138 2.336-1.375.137-6.601 2.474 3.026 4.896 3.035 4.949 2.2.137-2.2-7.148-9.902-11.684-11.827-3.408-1.447-5.086-1.925-5.086-1.925l-.688-11.14s14.588 8.015 21.444 4.263c8.797-4.813 12.371-28.468 12.371-28.468l-6.323-6.326z"
            stroke="#000"
            strokeWidth={0.68}
            fill="#FDFDFD"
            fillRule="nonzero"
          />
          <path
            d="M33.152 41.557c1.924-5.226 1.006-10.469 1.006-10.469s-4.471-.994-3.835-4.604c.575-3.263 4.411-3.263 6.521-.963 0 0 3.26-.767 6.323-4.22 0 0 2.243.526 3.775.267-.268-1.728-.727-3.317-1.666-4.49-2.301-2.877-8.63-4.797-13.616-1.151-4.985 3.646-4.603 9.594-4.41 11.896 0 0-8.623-2.112-13.21 4.379-5.636 7.977 1.237 15.403 1.237 15.403 4.423 3.14 15.201 1.213 17.875-6.048z"
            stroke="#000"
            strokeWidth={0.68}
            fill="#000"
            fillRule="nonzero"
          />
          <path
            d="M48.238 64.85l-.24-3.902h.012l-1.15-19a37.41 37.41 0 00-3.863-.973l-.635 13.097H31.64l-4.073-6.622a15.546 15.546 0 01-4.615 1.44l6.126 15.188 2.287 5.672s5.223.275 9.897.962c4.674.688 7.423 1.376 7.423 1.376l-.447-7.238z"
            stroke="#000"
            strokeWidth={0.68}
            fill="#FFF"
            fillRule="nonzero"
          />
          <path
            d="M75.474 30.658c-.113.359-.228.708-.342 1.034 0 0 3.505 4.057 1.306 9.283M63.152 49.972L61.71 53.67M60.865 84.362c-.582-1.319-1.3-2.862-1.936-3.988M56.54 81.664c1.65 2.906 2.943 6.16 3.754 8.445M53.975 83.292c1.238 2.272 2.442 4.92 3.335 7"
            stroke="#000"
            strokeWidth={0.68}
            strokeLinecap="round"
          />
          <path
            d="M22.951 48.89c-2.96.441-5.853.008-7.669-1.282-5.794 4.098-3.652 14.264.52 22.6l13.276-6.13L22.95 48.89zM33.152 41.557c-.993 2.694-3.101 4.654-5.585 5.893l4.073 6.622h10.722l.635-13.09c-1.056-.208-1.743-.3-1.743-.3l-.267-1.6h-7.118c-.182.84-.421 1.667-.717 2.475zM49.485 42.932a24.902 24.902 0 00-2.626-.978l1.15 19a56.996 56.996 0 005.624 2.599l5.636-15.67c-3.09-1.65-7.325-3.85-9.784-4.951z"
            stroke="#000"
            strokeWidth={0.68}
            fill="#FFF"
            fillRule="nonzero"
          />
          <ellipse
            fill="#000"
            fillRule="nonzero"
            transform="rotate(-30 10.011 48.836)"
            cx={10.011}
            cy={48.836}
            rx={7.699}
            ry={6.688}
          />
          <path
            d="M11.915 41.35s.962 5.776 5.635 7.564"
            stroke="#000"
            strokeWidth={0.68}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            stroke="#000"
            strokeWidth={0.68}
            strokeLinecap="round"
            d="M31.296 69.543l-3.161-.137"
          />
          <path
            d="M27.04 27.786c.105 1.05.247 2.356.384 3.387"
            stroke="#000"
            strokeWidth={0.68}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            stroke="#000"
            strokeWidth={0.68}
            strokeLinecap="round"
            d="M34.724 28.003l-1.711-.951"
          />
          <path
            d="M42.894 31.221c.097 1.805 1.95 1.955 2.404.688l-2.404-.688z"
            stroke="#000"
            strokeWidth={0.68}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle fill="#000" fillRule="nonzero" cx={41.787} cy={27.481} r={1} />
          <circle fill="#000" fillRule="nonzero" cx={46.029} cy={26.449} r={1} />
          <path
            d="M44.57 28.178c.137.364.266.765.225.915a.833.833 0 01-.334.438"
            stroke="#000"
            strokeWidth={0.68}
            strokeLinecap="round"
          />
          <path
            d="M39.526 12.912l.727.8.481.53h0c.307 1.073-1.924 2.782-5.025 3.841-3.101 1.06-5.911 1.069-6.323.032h0l.057-.715.088-1.077c1.165.275 3.185.054 5.347-.688 2.163-.74 3.897-1.787 4.648-2.723z"
            stroke="#000"
            fill="#FFF"
            fillRule="nonzero"
          />
          <path
            d="M34.04 13.19c1.622-.55 3.005-1.284 3.912-2.01l1.574 1.732c-.75.936-2.488 1.99-4.652 2.728-2.163.738-4.183.962-5.347.687l.188-2.338c1.162.02 2.703-.246 4.325-.799z"
            stroke="#000"
            fill="#FFF"
            fillRule="nonzero"
          />
          <path
            d="M36.267 9.326l1.685 1.854c-.907.726-2.29 1.457-3.912 2.01-1.622.553-3.162.825-4.325.8l.201-2.498a16.215 16.215 0 006.35-2.166h0z"
            stroke="#000"
            fill="#FFF"
            fillRule="nonzero"
          />
          <path
            d="M32.37 8.292a16.37 16.37 0 002.14-.899l1.757 1.933a16.215 16.215 0 01-6.35 2.166l.21-2.602c.761-.147 1.51-.347 2.243-.598h0z"
            stroke="#000"
            fill="#FFF"
            fillRule="nonzero"
          />
          <path
            d="M30.128 8.89l.469-5.804 3.914 4.307c-.69.35-1.406.65-2.14.899-.733.251-1.482.451-2.243.598h0z"
            stroke="#000"
            fill="#FFF"
            fillRule="nonzero"
          />
          <ellipse
            stroke="#000"
            fill="#FFF"
            fillRule="nonzero"
            cx={30.265}
            cy={2.118}
            rx={2.117}
            ry={2.118}
          />
        </g>
        <g transform="translate(108.449 1.003)">
          <path fill="#3EC1F3" fillRule="nonzero" d="M2.79 40.453l-.042-.041v.085z" />
          <path
            stroke="#F5EBDC"
            strokeWidth={0.68}
            strokeLinecap="round"
            d="M18.072 48.911l.55 4.126"
          />
          <path
            d="M63.777 44.029s6.185-7.29-.688-13.478c-4.2-3.782-9.246-2.728-9.246-2.728.19-2.303.574-8.252-4.411-11.896-4.986-3.645-11.316-1.726-13.617 1.15-2.301 2.878-1.725 8.252-2.492 12.09-.767 3.836-.193 6.326 2.108 7.675 2.301 1.349 4.987.383 4.987.383l-.576 3.454s-4.52.6-8.23 2.25c-4.207 1.87-13.609 7.014-13.609 7.014-.275-.963-1.787-16.641-2.474-21.73-.524-3.877-2.337-8.664-3.712-7.701-1.333.933-.824 3.988-.824 3.988l-7.01 7.289S.516 56.782 8.792 63.558c6.048 4.95 21.856-3.026 21.856-3.026l-2.062 14.99H47.45s-3.536.941-10.89 4.264c-8.522 3.85-13.47 14.578-12.92 15.403.55.825 2.2.138 4.398-2.613-1.924 7.151 0 6.601 1.238 6.464 1.257 1.81 2.886-.413 2.886-.413s2.2 2.063 5.636-7.564a16.688 16.688 0 004.261-2.888s21.856-1.375 26.117-9.077c4.262-7.701 1.375-27.78-4.398-35.07z"
            stroke="#000"
            strokeWidth={0.68}
            fill="#FDFDFD"
            fillRule="nonzero"
          />
          <circle fill="#000" fillRule="nonzero" cx={35.206} cy={26.859} r={1} />
          <path
            d="M43.588 82.24l.353.792 2.117 4.746c6.516-.806 19.007-3.056 22.117-8.68 4.262-7.701 1.375-27.78-4.398-35.07h0c-4.811 5.294-12.25 3.852-15.233-.55-.55.137-1.11.23-1.675.276-.88.081-1.768.046-2.639-.105a6.767 6.767 0 01-2.887-1.217c-1.087-.818-1.484-1.704-1.484-1.704l-.184-.026c-.844.125-4.771.762-8.064 2.227-4.206 1.87-13.608 7.014-13.608 7.014-.124-.432-.496-3.833-.932-7.908l-5.339 2.063-.579.224-2.761 1.07-5.021 1.944c.36 6.475 1.71 13.182 5.422 16.222 6.048 4.95 21.856-3.026 21.856-3.026l-2.062 14.99H47.45s-1.934.516-5.91 2.125l.915 2.05 1.134 2.544z"
            stroke="#000"
            strokeWidth={0.68}
            fill="#FFF"
            fillRule="nonzero"
          />
          <path
            d="M58.622 71.603c-3.695 1.26-8.678 2.792-11.753 3.782M54.706 58.235l1.304 14.193"
            stroke="#000"
            strokeWidth={0.68}
            strokeLinecap="round"
          />
          <path
            d="M27.668 92.936c.45-1.393 1.411-4.255 2.255-6.085M32.608 88.002c-1.916 4.126-3.04 8.83-3.457 10.78M35.293 89.344c-1.238 2.855-2.32 6.753-2.928 9.151"
            stroke="#30242A"
            strokeWidth={0.68}
            strokeLinecap="round"
          />
          <path
            stroke="#000"
            strokeWidth={0.68}
            strokeLinecap="round"
            d="M31.093 55.643l-.748 4.992"
          />
          <path
            d="M63.09 30.551c-4.201-3.782-9.247-2.728-9.247-2.728.19-2.303.574-8.252-4.411-11.896-4.986-3.645-11.316-1.726-13.617 1.15-.938 1.174-1.398 2.762-1.666 4.49 1.531.26 3.776-.267 3.776-.267 3.068 3.453 6.323 4.22 6.323 4.22 2.11-2.302 5.945-2.302 6.521.963.636 3.61-3.836 4.605-3.836 4.605s-.949 5.144.618 10.33a7.6 7.6 0 00.987 2.063c2.983 4.401 10.424 5.845 15.233.55.238-.293.459-.6.661-.918 1.393-2.152 4.06-7.696-1.343-12.562z"
            stroke="#000"
            strokeWidth={0.5}
            fill="#000"
            fillRule="nonzero"
          />
          <path
            d="M40.398 37.469c.889-.26 1.745-.62 2.554-1.073"
            stroke="#30242A"
            strokeWidth={0.68}
            strokeLinecap="round"
          />
          <path
            stroke="#000"
            strokeWidth={0.68}
            strokeLinecap="round"
            d="M46.372 28l1.711-.952M36.645 32.464c.315.197 1.493.35 1.971-.557"
          />
          <circle fill="#000" fillRule="nonzero" cx={39.743} cy={27.615} r={1} />
          <path
            d="M36.575 29.005c-.088.38-.16.794-.1.936.082.17.22.308.39.39"
            stroke="#000"
            strokeWidth={0.68}
            strokeLinecap="round"
          />
          <ellipse
            stroke="#000"
            strokeWidth={0.68}
            strokeLinecap="round"
            transform="rotate(-76.95 33.938 26.833)"
            cx={33.938}
            cy={26.833}
            rx={2.75}
            ry={2.474}
          />
          <ellipse
            stroke="#000"
            strokeWidth={0.68}
            strokeLinecap="round"
            transform="rotate(-76.95 39.987 27.795)"
            cx={39.987}
            cy={27.795}
            rx={2.75}
            ry={2.474}
          />
          <path
            stroke="#000"
            strokeWidth={0.68}
            strokeLinecap="round"
            d="M36.491 27.182l.962.137M9.274 25.256s2.612 2.2 2.887 2.75c0 0-4.261 4.402-.962 9.765"
          />
          <path
            d="M22.908 9.502c.517.316.68.991.363 1.507L2.45 45.062a1.095 1.095 0 11-1.87-1.144L21.403 9.865a1.095 1.095 0 011.506-.363z"
            fill="#000"
            fillRule="nonzero"
          />
          <path
            d="M25.163 2.161L27.53.266a1.21 1.21 0 011.945 1.172l-.567 2.983c-.092.48.115.969.523 1.238l2.532 1.667a1.21 1.21 0 01-.512 2.212l-3.001.384c-.486.062-.887.41-1.016.882l-.801 2.923a1.211 1.211 0 01-2.263.196l-1.29-2.75a1.21 1.21 0 00-1.153-.688l-3.024.137a1.21 1.21 0 01-.9-2.093l2.209-2.077c.357-.335.476-.852.304-1.31l-1.07-2.838A1.212 1.212 0 0121.165.815l2.657 1.462c.429.236.958.19 1.341-.116z"
            stroke="#000"
            strokeWidth={0.68}
            fill="#EEAF7B"
            fillRule="nonzero"
          />
          <path
            d="M10.993 24.5c-.963-.825-2.811-1.36-3.712-.825-1.52.903-1.146 2.658-1.146 2.658s-2.474-.385-2.444 2.151c0 0-1.992.872-1.12 2.865 0 0-1.724.436-.905 2.56.667 1.73 2.205 2.637 3.141 3.106.825.413 2.09-.183 1.65-1.788 0 0 .962.275 1.512-.275.758-.76.412-2.2.412-2.2s.962.275 1.65-.275c.838-.672.412-1.926.412-1.926a1.71 1.71 0 001.374-1.1c.55-1.375-1.512-3.026-1.512-3.026l.688-1.925z"
            stroke="#000"
            strokeWidth={0.68}
            fill="#FDFDFD"
            fillRule="nonzero"
          />
          <path
            d="M2.47 31.427a33.344 33.344 0 003.986 4.075M3.707 28.472a28.363 28.363 0 004.82 4.647M5.907 26.449a20.135 20.135 0 004.63 4.41M9.274 25.256s1.288 1.085 2.15 1.926"
            stroke="#000"
            strokeWidth={0.68}
            strokeLinecap="round"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgPollCreateScene;
