import styled from '@emotion/styled';
import tw from 'twin.macro';

interface ProTipBannerProps {
  title: string;
  details: string;
  icon?: React.ReactNode;
}

const Container = styled.div`
  ${tw`flex bg-content-pearl rounded-2xl p-3`}
`;

const Title = styled.div`
  ${tw`font-semibold`}
`;

const Info = styled.div`
  ${tw`text-sm`}
`;

const GraphicContainer = styled.div`
  ${tw`inline-flex h-16 self-center`}
`;

export const ProTipBanner = ({ title, details, icon }: ProTipBannerProps) => {
  return (
    <Container>
      {icon && <GraphicContainer>{icon}</GraphicContainer>}
      <div className="flex flex-col pl-4">
        <Title>{title}</Title>
        <Info>{details}</Info>
      </div>
    </Container>
  );
};

export default ProTipBanner;
