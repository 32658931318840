import * as React from 'react';

function SvgPlanSomething(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 581 357" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M444.028 335.602c8.074 1.87 13.094-5.598 23.945-4.566 9.928.944 26.272 10.34 22.537 12.887-41.477 28.294-149.005 1.816-178.582 2.123-47.42.493-93.464 9.506-140.84 10.693-43.996 1.102-149.465-16.982-86.38-21.137 87.907-5.79 221.892-8.427 309.907-13.74-19.299-2.94-2.828 1.64 49.413 13.74z"
          fill="#FBE0E2"
        />
        <g transform="matrix(-1 0 0 1 581 0)">
          <path
            d="M494.496 193.818a17.207 17.207 0 00-2.654-1.794c-14.205-7.822-138.6-64.728-139.891-65.302l-.215-.072 12.195 117.256c16.644 18.586 40.461 40.186 63.56 57.624l.288.071.143-.143c1.363-1.364 13.918-14.28 21.02-31.718 3.73-9.257 6.6-19.304 9.11-28.13 3.3-11.625 6.17-21.672 10.116-27.7 9.541-15.715 20.66-20.092 26.328-20.092z"
            stroke="#000"
            strokeWidth={2.87}
            fill="#F8F6F1"
            fillRule="nonzero"
          />
          <path
            d="M361.707 232.999l.072-.144c5.596-3.731 11.694-6.817 17.935-9.257 5.524-2.153 10.545-3.229 14.85-3.229 7.389 0 10.33 3.086 10.402 3.23 1.435 3.946 1.148 7.965-.861 11.911-7.82 15.5-39.815 25.547-40.102 25.69l-.143.072s-2.01-8.54-2.153-28.273zM490.909 238.166c-.072-.072-7.39-6.674-22.956-24.399l-.072-.072.072-.071c.071-.072 5.882-9.33 12.195-14.352 6.528-5.239 12.77-5.454 13.99-5.454h.358c14.993 12.27-3.157 44.06-3.3 44.348l-.072.143-.215-.143z"
            fill="#000"
            fillRule="nonzero"
          />
          <path
            d="M246.238 101.529l10.635-64.582a3.588 3.588 0 013.108-2.98l28.323-3.432a3.588 3.588 0 014 3.943l-7.257 68.014a3.588 3.588 0 01-3.567 3.208h-31.702a3.588 3.588 0 01-3.54-4.171z"
            stroke="#000"
            strokeWidth={2.87}
            fill="#FBE0E2"
          />
          <g fillRule="nonzero">
            <path
              d="M283.44 106.342c-1.721 0-3.085-.645-3.73-1.937-.718-1.364-.43-3.158.717-4.736l.861-1.148-1.22-.862s-2.439-1.722-2.439-4.018c0-1.435 1.005-2.942 2.942-4.45l2.08-1.506-2.367-.933s-2.655-1.076-3.013-3.23c-.359-1.793.932-4.09 3.658-6.601l1.22-1.148-1.291-1.077s-2.655-2.153-2.655-4.88c0-1.506.861-3.013 2.655-4.377 1.578-1.291 7.317-4.592 9.397-5.74l.574-.36.861-4.664c2.152 1.077 3.515 2.799 4.09 5.095.86 3.516-.503 7.32-1.722 8.611l-1.363 1.364 1.65.933c.071 0 1.65 1.004 2.152 3.085.574 2.153-.144 4.737-2.009 7.822l-.717 1.148 1.076.79s1.65 1.291 1.937 3.516c.215 1.794-.503 3.803-2.224 5.884l-.718.861.718.862c.072.071 1.291 1.722.717 3.946-.645 2.512-3.443 4.808-8.035 6.746-1.363.717-2.654 1.004-3.802 1.004z"
              fill="#F8F6F1"
            />
            <path
              d="M292.695 57.259a6.296 6.296 0 011.65 2.942c.717 3.085-.503 6.315-1.363 7.247l-2.655 2.656 3.3 1.865s1.076.718 1.507 2.153c.43 1.722-.144 4.09-1.794 6.746l-1.506 2.224 2.224 1.507c.143.072.932.79 1.22 1.938.501 1.578-.073 3.444-1.723 5.453l-1.578 1.794 1.578 1.794c.144.216.861 1.292.503 2.727-.574 2.01-3.085 4.019-7.246 5.741-1.22.502-2.367.79-3.3.79-.717 0-1.937-.144-2.44-1.149-.43-.86-.214-2.08.575-3.229l1.721-2.368-2.367-1.65c-.502-.36-1.865-1.58-1.865-2.87 0-1.22 1.291-2.44 2.367-3.302l4.09-3.086-4.807-1.865c-.287-.144-1.937-1.005-2.152-2.153-.215-1.22.932-3.158 3.228-5.31l2.44-2.225-2.583-2.08c-.574-.503-2.153-2.154-2.153-3.732 0-1.077.718-2.153 2.08-3.301 1.364-1.077 6.386-4.019 9.183-5.598l1.22-.646.215-1.363.43-1.65m-2.152-4.45l-1.004 5.741s-7.604 4.234-9.613 5.885c-7.174 5.74 0 11.481 0 11.481-9.326 8.611-.215 12.2-.215 12.2-7.676 5.74-.502 10.763-.502 10.763-2.87 4.019-.933 8.899 4.16 8.899 1.292 0 2.798-.287 4.448-1.005 13.63-5.74 7.892-12.917 7.892-12.917 5.739-7.176 0-11.481 0-11.481 5.739-9.33-.718-12.917-.718-12.917 2.87-2.87 5.022-13.635-4.448-16.649z"
              fill="#000"
            />
          </g>
          <path
            d="M282.149 77.351a1.44 1.44 0 01-1.363-1.004c-.215-.718.143-1.579.933-1.794.071 0 6.958-2.297 10.545-6.315.502-.574 1.435-.646 2.009-.072.574.502.645 1.435.072 2.01-4.161 4.52-11.479 6.96-11.837 7.032-.072.143-.216.143-.36.143zM281.36 89.55c-.646 0-1.22-.43-1.363-1.076-.215-.79.215-1.579 1.004-1.794 2.654-.79 10.044-3.3 12.196-5.956.502-.646 1.435-.718 2.008-.215.646.502.718 1.363.216 2.01-3.085 3.802-12.555 6.601-13.63 6.96-.144.072-.288.072-.431.072zM281.36 100.53c-.646 0-1.22-.43-1.363-1.077-.215-.789.215-1.578 1.004-1.794.072 0 8.394-2.296 12.34-5.669.573-.502 1.506-.43 2.008.216s.43 1.507-.215 2.009c-4.52 3.731-13.057 6.171-13.487 6.243 0 .072-.144.072-.287.072z"
            fill="#000"
            fillRule="nonzero"
          />
          <path
            d="M453.605 319.828c-8.609-5.525-17.361-11.696-26.113-18.227l-.144-.071.216-.216c1.434-1.435 13.917-14.28 20.947-31.646 3.73-9.185 6.6-19.232 9.111-28.058 3.372-11.697 6.241-21.887 10.259-27.915l.072-.143.143.143c15.567 17.725 22.885 24.327 22.957 24.399.215.215 19.01 17.868 52.297 17.868 15.209 0 23.817 3.157 28.337 5.74 4.95 2.871 6.17 5.67 6.241 5.813l.072.072-56.243 47.003-.072-.072c-8.752-7.032-19.8-10.907-31.063-10.907h-.143c-13.918 0-27.333 5.884-36.659 16.217l-.072.072-.143-.072z"
            stroke="#000"
            strokeWidth={2.87}
            fill="#F8F6F1"
            fillRule="nonzero"
          />
          <path
            d="M490.694 340.352c-12.77-5.956-25.252-12.845-37.09-20.524l-.143-.071.144-.144c9.47-10.405 22.956-16.361 36.945-16.361 11.335 0 22.455 3.875 31.278 10.98l.144.143-31.135 26.049-.143-.072zM335.523 340.352h0c-5.883-21.672-25.611-36.741-47.994-36.813-1.434 0-2.94.072-4.52.215h-.07l-.073-.143c-1.865-7.535-3.443-15.357-4.806-23.179v-.072l.072-.071c13.917-7.894 83.145-47.434 83.719-47.434h.143V233c0 17.079 2.152 27.914 2.152 28.058 4.735 22.748 12.626 33.368 20.446 41.477 2.152 2.297 4.376 4.378 6.743 6.53 2.583 2.44 5.31 5.024 7.963 7.894 12.124 12.845 11.55 23.178 11.55 23.322v.144h-75.325v-.072h0z"
            stroke="#000"
            strokeWidth={2.87}
            fill="#F8F6F1"
            fillRule="nonzero"
          />
          <path
            d="M294.416 340.352h0c-4.663-11.984-8.608-24.255-11.55-36.67l-.071-.143h.143a50.318 50.318 0 014.591-.215c22.526 0 42.326 15.213 48.28 36.884l.072.216h-41.465v-.072h0z"
            stroke="#000"
            strokeWidth={2.87}
            fill="#F8F6F1"
            fillRule="nonzero"
          />
          <path d="M427.495 301.39l.22-.221.221.22-.22.221z" />
          <g fillRule="nonzero">
            <path
              d="M191.686 213.695c-14.778 0-27.045-8.97-37.376-27.484-10.043-17.94-16.787-42.482-21.521-61.857-1.292-5.382-1.005-9.4 1.004-11.912 1.865-2.368 5.38-3.588 10.402-3.588 18.007 0 48.71 15.5 48.998 15.715l1.22.575.717-1.149c9.684-16.217 23.243-34.803 36.228-49.873 14.993-17.294 23.53-23.465 26.328-23.465.143 0 .215 0 .287.071.79.287 1.291.79 1.578 1.507.79 2.225-.287 6.602-3.013 12.271-2.152 4.593-4.663 8.396-4.663 8.468l-.287.502.072.574c0 .144 2.296 14.568-5.81 24.686l-.36.502.933 7.607h29.341c-5.595 14.495-42.326 106.85-84.078 106.85z"
              fill="#F8F6F1"
            />
            <path
              d="M257.614 52.092c.287.143.43.287.574.574 1.22 3.444-3.802 13.634-7.532 19.447l-.574.933.143 1.076c0 .144 2.152 13.993-5.524 23.537l-.789.933.144 1.22.717 5.741.287 2.512H273.54c-3.3 8.467-11.908 29.708-23.96 50.662-13.918 24.327-35.296 53.39-58.037 53.39-14.276 0-26.041-8.755-36.085-26.767-9.972-17.796-16.643-42.195-21.378-61.498-1.22-4.952-1.004-8.54.717-10.692 1.579-2.01 4.735-3.014 9.255-3.014 17.647 0 48.065 15.356 48.352 15.5l2.367 1.22 1.363-2.297c9.685-16.146 23.172-34.731 36.085-49.657 14.634-16.505 23.171-22.677 25.395-22.82m.072-2.87c-8.896 0-42.972 39.18-63.848 74.056 0 0-31.063-15.86-49.643-15.86-9.685 0-15.926 4.306-12.77 17.295 11.12 45.28 26.544 90.418 60.261 90.418 46.63 0 86.087-109.793 86.087-109.793h-30.13l-.718-5.741c8.609-10.764 6.098-25.834 6.098-25.834s13.989-21.528 5.38-24.398c-.215-.072-.43-.144-.717-.144z"
              fill="#000"
            />
          </g>
          <g fillRule="nonzero">
            <path
              d="M166.936 87.11c-10.258 0-17.934-7.75-20.947-21.312-8.179-36.382-.216-48.223 15.208-55.542.072 0 9.326-4.162 20.518-4.162 8.68 0 16.069 2.44 22.023 7.176 9.9 7.893 7.82 29.708-4.878 50.662-8.609 14.28-20.876 23.179-31.924 23.179z"
              fill="#F8F6F1"
            />
            <path
              d="M181.715 7.6c8.321 0 15.423 2.297 21.162 6.89 4.305 3.444 6.242 10.046 5.524 18.657-.789 9.257-4.59 19.95-10.689 30.14-8.321 13.85-20.158 22.389-30.776 22.389-9.684 0-16.643-7.176-19.513-20.165-7.963-35.45-.43-46.931 14.42-53.892.359-.215 9.182-4.018 19.872-4.018m0-2.87c-11.622 0-21.092 4.305-21.092 4.305-17.289 8.109-23.889 22.03-15.997 57.12 3.443 15.357 12.195 22.39 22.382 22.39 11.191 0 23.96-8.54 33.215-23.753 13.272-21.958 14.85-44.204 4.52-52.528-7.318-5.813-15.64-7.535-23.028-7.535z"
              fill="#000"
            />
          </g>
          <path
            d="M185.947 59.268c-.646 0-1.22-.43-1.363-1.077a1.375 1.375 0 011.076-1.722c.933-.215 1.794-.79 2.44-1.579 1.075-1.291 1.793-4.736 2.438-11.553.072-.79.79-1.363 1.579-1.292.789.072 1.363.79 1.291 1.58-.79 7.75-1.578 11.265-3.085 13.131a7.53 7.53 0 01-4.017 2.512h-.359zM179.562 46.71h-.287a1.375 1.375 0 01-1.076-1.722l.359-1.723c.144-.789.933-1.291 1.722-1.148.789.144 1.291.933 1.076 1.722l-.359 1.723c-.143.717-.789 1.148-1.435 1.148zM199.864 46.71c-.143 0-.215 0-.358-.072-.79-.215-1.22-.933-1.076-1.722l.43-1.794c.215-.79.933-1.22 1.722-1.077.789.216 1.22.933 1.076 1.723l-.43 1.794c-.144.717-.718 1.148-1.364 1.148zM173.49 57.378c.431 0 .862 0 1.364.071 3.946.718 6.6 4.521 5.883 8.468a1.4 1.4 0 01-1.65 1.148 1.4 1.4 0 01-1.148-1.65c.215-1.148-.072-2.297-.718-3.301-.717-1.005-1.721-1.65-2.87-1.866-.214-.072-.501-.072-.788-.072a1.44 1.44 0 01-1.435-1.435c0-.79.574-1.363 1.363-1.363z"
            fill="#000"
            fillRule="nonzero"
          />
          <path
            d="M228.99 7.027c-5.739-8.899-24.104-9.903-33.071.215-16.859-6.53-35.367 1.722-35.367 1.722-16.213 7.678-23.1 20.38-17.29 51.021 24.822-10.62 26.974-44.347 26.974-44.347s15.065 19.16 39.17 18.8h.143c.502 0 1.005 0 1.507-.07v-.073c17.074-1.65 25.61-15.428 17.934-27.268z"
            fill="#000"
            fillRule="nonzero"
          />
          <path
            d="M153.09 340.136c-7.89 0-35.08-1.865-52.297-25.69-8.967-12.342-13.846-28.704-14.563-48.653-.86-23.537 4.233-52.17 15.065-85.179v-.072h87.522c1.004-1.291 18.58-24.685 42.756-47.648C254.386 111.079 287.96 85.1 318.018 85.1c15.926 0 30.992 9.257 37.448 23.035 27.978 59.776 44.622 101.756 49.5 115.534 0 0-13.846-9.544-43.33 9.616-10.69 6.817-17.576 12.2-24.248 17.294-6.744 5.238-13.057 10.118-22.885 16.361-19.154 12.056-36.228 13.635-36.371 13.635h-.144v-.144c-3.802-22.245-4.448-39.18-4.591-42.482-22.096 44.133-44.622 73.124-68.654 88.552-19.657 12.558-36.3 13.634-51.652 13.634z"
            stroke="#000"
            strokeWidth={2.87}
            fill="#F8F6F1"
            fillRule="nonzero"
          />
          <path
            d="M273.755 238.811c-.789 0-1.363-.574-1.434-1.363-1.435-32.794 4.232-70.038 4.304-70.397.144-.789.86-1.291 1.65-1.22.79.144 1.291.862 1.22 1.65-.072.36-5.668 37.316-4.305 69.823 0 .79-.574 1.507-1.435 1.507.072 0 0 0 0 0zM167.295 215.848c-.215 0-.502-.072-.717-.215-.646-.43-.933-1.292-.503-1.938a558.64 558.64 0 0121.88-33.799c.431-.645 1.364-.789 2.01-.358.645.43.789 1.363.358 2.009a565.948 565.948 0 00-21.737 33.655c-.358.43-.789.646-1.291.646zM404.679 224.962a1.471 1.471 0 01-1.363-.933c-5.452-15.285-19.154-48.869-37.52-89.485-.358-.718 0-1.579.718-1.866.717-.358 1.578 0 1.865.718 18.437 40.76 32.21 74.415 37.663 89.772.287.717-.144 1.578-.86 1.865-.216-.071-.36-.071-.503-.071z"
            fill="#000"
            fillRule="nonzero"
          />
          <path
            d="M174.469 203.649h-79.63a1.44 1.44 0 01-1.435-1.435c0-.79.646-1.435 1.435-1.435h79.63c.789 0 1.435.645 1.435 1.435a1.44 1.44 0 01-1.435 1.435z"
            fill="#000"
            fillRule="nonzero"
          />
          <path
            d="M164.856 283.518c-1.004 0-1.937-.072-2.87-.287-20.087-3.803-16.284-43.056-13.487-71.688.36-3.373.646-6.53.861-9.401.072-.79.718-1.363 1.579-1.292.789.072 1.363.79 1.29 1.58-.214 2.87-.573 6.099-.86 9.471-2.51 25.977-6.313 65.302 11.191 68.603.79.143 1.579.215 2.368.215 21.02 0 51.436-48.51 71.81-81.017 4.592-7.32 8.896-14.208 12.626-19.734 15.64-23.465 35.726-50.16 52.298-48.294 7.102.79 12.77 6.745 16.93 17.724 5.668 14.926 29.198 92.57 29.413 93.36.215.79-.215 1.579-.932 1.794-.79.215-1.579-.215-1.794-.933-.215-.79-23.745-78.362-29.413-93.216-3.73-9.831-8.68-15.213-14.563-15.859-15.208-1.722-35.582 25.977-49.571 47.003-3.659 5.525-7.963 12.414-12.555 19.662-21.952 34.947-51.652 82.309-74.321 82.309zM457.12 244.552c-.287 0-.574-.072-.86-.287L395.28 201.21c-.646-.43-.789-1.363-.359-2.01.43-.645 1.363-.789 2.01-.358l60.977 43.056c.646.43.79 1.363.359 2.01-.287.43-.718.645-1.148.645z"
            fill="#000"
            fillRule="nonzero"
          />
          <g fillRule="nonzero">
            <path
              d="M232.075 218.719c-18.795 0-34.076-10.55-43.545-19.376-10.33-9.615-16.285-19.303-16.285-19.375a148.968 148.968 0 01-12.913-24.9l-7.317-18.012 52.226-20.595 9.182 11.122c8.537 15.86 13.63 26.408 13.702 26.552l.574 1.291 1.291-.574c.574-.287 59.83-26.623 90.176-35.09 11.478-3.23 22.813-4.88 32.641-4.88 14.635 0 23.674 3.803 23.674 9.974v.933l.861.36s3.013 1.434 3.802 4.018c.43 1.435.144 3.014-.86 4.664l-.646 1.077 1.004.789c.574.43 5.165 4.305 1.291 9.4l-.574.79.503.86s1.793 3.087.574 5.67c-.861 1.866-3.229 3.157-6.887 3.731-2.87.503-5.38.646-7.748.79-8.394.502-16.357 1.004-39.385 14.495-8.178 4.737-15.998 10.334-23.674 15.788-20.015 14.208-40.747 28.919-68.654 30.57-1.004-.144-2.008-.072-3.013-.072z"
              fill="#F8F6F1"
            />
            <path
              d="M351.807 116.174c13.487 0 22.24 3.372 22.24 8.539v1.866l1.65.79c.717.358 2.582 1.506 3.084 3.157.287 1.004.072 2.152-.717 3.516l-1.292 2.153 2.01 1.578s1.219 1.005 1.864 2.44c.79 1.65.503 3.23-.789 4.952l-1.22 1.507 1.005 1.65s1.363 2.512.502 4.306c-.86 1.794-3.658 2.583-5.81 2.942-2.798.502-5.238.646-7.605.79-8.609.501-16.715 1.004-39.958 14.638-8.179 4.808-16.142 10.405-23.746 15.86-19.872 14.136-40.389 28.703-67.865 30.282-.933.072-1.937.072-2.941.072-18.294 0-33.144-10.262-42.47-18.873-10.258-9.473-16.141-19.088-16.141-19.232v-.143a145.587 145.587 0 01-12.77-24.614l-6.815-16.72 49.859-19.734 8.465 10.19c8.465 15.715 13.559 26.264 13.559 26.336l1.22 2.511 2.582-1.148c.574-.287 59.687-26.551 89.96-35.019 11.263-2.942 22.383-4.592 32.14-4.592m0-2.87c-9.542 0-21.02 1.578-33 4.95-30.848 8.612-90.391 35.163-90.391 35.163s-5.094-10.62-13.774-26.695l-9.972-12.055-54.522 21.528 7.892 19.375c3.515 8.754 7.891 17.15 12.984 25.116 0 0 23.818 39.468 61.122 39.468 1.004 0 2.009 0 3.085-.072 39.026-2.296 63.56-29.278 92.973-46.5 29.413-17.223 33.718-12.917 46.63-15.07 12.914-2.153 7.318-11.553 7.318-11.553 4.878-6.387-1.578-11.41-1.578-11.41 4.304-7.176-3.587-10.764-3.587-10.764-.072-7.535-10.259-11.482-25.18-11.482z"
              fill="#000"
            />
          </g>
          <path
            d="M101.439 180.83c-.215-.072-16.644-5.31-15.209-25.26.861-11.625 13.917-32.364 35.941-56.762 20.876-23.25 30.991-25.188 39.528-25.188 17.218 0 28.122 8.898 43.187 35.234a862.597 862.597 0 019.9 17.796l.072.144-56.602 28.848c3.515 8.682 7.748 17.078 12.841 24.972l.144.215h-69.802 0z"
            stroke="#000"
            strokeWidth={2.87}
            fill="#F8F6F1"
            fillRule="nonzero"
          />
          <path
            d="M331.003 128.301c-.646 0-1.22-.43-1.363-1.076-.215-.79.287-1.58 1.004-1.723 1.005-.287 25.54-6.458 45.842-.717.789.215 1.22 1.004 1.004 1.794-.215.79-1.004 1.22-1.794 1.004-19.513-5.597-44.047.646-44.334.718h-.359zM334.447 139.065c-.646 0-1.22-.43-1.363-1.076-.216-.79.286-1.58 1.004-1.723.86-.215 22.095-5.74 45.124-1.435a1.4 1.4 0 011.147 1.65c-.143.79-.932 1.292-1.65 1.149-22.382-4.162-43.617 1.363-43.832 1.435h-.43zM338.034 149.829c-.718 0-1.292-.502-1.435-1.22-.144-.79.43-1.507 1.148-1.65 22.813-3.804 34.865-2.727 42.828-1.436a1.4 1.4 0 011.147 1.65 1.4 1.4 0 01-1.65 1.149c-7.747-1.292-19.512-2.296-41.823 1.435-.072.072-.144.072-.215.072zM308.405 187.934a1.417 1.417 0 01-1.29-.862l-23.531-56.546c-.287-.718.071-1.58.789-1.866.717-.287 1.578.072 1.865.79l23.53 56.546c.287.718-.071 1.579-.789 1.866-.215.072-.358.072-.574.072zM160.121 157.794c-.574 0-1.076-.287-1.291-.86-3.085-6.89-7.246-16.793-9.326-22.39a1.433 1.433 0 01.86-1.866 1.432 1.432 0 011.866.861c2.08 5.526 6.241 15.357 9.326 22.246.287.718 0 1.579-.717 1.866-.36.072-.503.143-.718.143z"
            fill="#000"
            fillRule="nonzero"
          />
          <g transform="translate(279.925 141.218)" fillRule="nonzero">
            <ellipse fill="#F8F6F1" cx={16.5} cy={16.505} rx={15.065} ry={15.07} />
            <path
              d="M16.5 33.01C7.39 33.01 0 25.618 0 16.505 0 7.39 7.39 0 16.5 0S33 7.391 33 16.505c0 9.113-7.39 16.505-16.5 16.505zm0-30.14c-7.533 0-13.63 6.1-13.63 13.635S8.967 30.139 16.5 30.139s13.63-6.1 13.63-13.634C30.13 8.97 24.033 2.87 16.5 2.87z"
              fill="#000"
            />
          </g>
          <path
            d="M20.087 25.9c25.108 17.94 46.774 12.27 60.978 7.175 20.087-7.176 41.608-11.481 68.152 11.482l-5.022 9.329c-36.587-3.588-44.98 31.144-81.065 51.667C31.565 123.493 0 107.706 0 107.706l20.087-81.807z"
            fill="#000"
            fillRule="nonzero"
          />
          <path
            d="M143.228 39.618c1.039.477-1.651-7.947-4.913-13.719-2.327-4.118-4.549-6.753-7.715-4.449-2.987 2.165-6.075 3.41-4.27 6.23 2.54 3.965 11.628 9.53 16.898 11.938z"
            fill="#EEAF7B"
            fillRule="nonzero"
          />
          <path
            d="M143.312 39.223c-.303-.637-10.185-4.998-16.239-5.213-6.583-.233-9.334 3.805-8.414 6.396.766 2.108 3.897 7.135 9.18 6.68 6.739-.582 15.823-7.11 15.473-7.863z"
            fill="#EEAF7B"
            fillRule="nonzero"
          />
          <ellipse
            stroke="#000"
            strokeWidth={2.87}
            fill="#000"
            cx={263.845}
            cy={96.573}
            rx={1.782}
            ry={1.783}
          />
          <path
            stroke="#000"
            strokeWidth={2.87}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M263.845 44.364l19.146-1.549M263.733 51.905l18.717-1.251M262.467 59.837l18.717-1.252M261.224 69.363l12.193-.609"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgPlanSomething;
