import styled from '@emotion/styled';
import { ComponentProps } from 'react';
import tw from 'twin.macro';

import { User } from '../../models';
import { ProfilePicture as _ProfilePicture } from '../ProfilePicture';

type UserSummaryProps = ComponentProps<'div'> & {
  user: User;
};

const Container = styled.div`
  ${tw`flex space-x-4`}

  & + & {
    ${tw`border-t-2 border-neutral-stone`}
  }
`;

const Content = styled.div`
  ${tw`flex flex-col flex-1`}

  max-width: 12.3rem;

  @media (max-width: 640px) {
    max-width: 9.5rem;
  }

  &,
  & > * {
    ${tw`overflow-hidden whitespace-nowrap overflow-ellipsis`}
  }
`;

const Children = styled.div`
  ${tw`mt-2`}
`;

const ProfilePicture = styled(_ProfilePicture)`
  ${tw`w-12 h-12 text-xl`}
`;

export function UserSummary({ user, children, ...props }: UserSummaryProps) {
  return (
    <Container {...props}>
      <ProfilePicture user={user} />
      <Content>
        <b>
          {user.firstName} {!user.privacyRules.some((r) => r === 'last_name') && user.lastName}
        </b>
        <div>{!user.privacyRules.some((r) => r === 'email') ? user.email : '\u00a0'}</div>
        <Children>{children}</Children>
      </Content>
    </Container>
  );
}

export default UserSummary;
