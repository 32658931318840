import styled from '@emotion/styled';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { ReactNode, useState } from 'react';
import ReactQuill from 'react-quill';
import { useHistory, useRouteMatch } from 'react-router-dom';
import tw from 'twin.macro';

import { Button, CoverImagePicker, Divider as _Divider, Planning } from '../../components';
import { Accordion } from '../../components/Accordion';
import { Item as _Item } from '../../components/Item';
import { ItemsListItem } from '../../components/Item/ItemsListItem';
import { ItemCreator } from '../../components/ItemCreator';
import { ItemsHelpLink } from '../../components/ItemsHelpLink';
import { PreviewEventLink } from '../../components/PreviewEventLink/PreviewEventLink';
import { Event, EventCategory, EventItem } from '../../models';
import { usePotluck, useUser } from '../../providers';

interface ItemsProps {
  event: Event;
  saveButtonName?: string;
  showPreviewEventButton?: boolean;
  onChange: (event: Event) => void;
  onSubmit: (event: Event) => void;
  onCancelEvent?: () => void;
}

const Container = styled.div`
  ${tw`flex flex-col flex-1 justify-between`}
`;

const Item = styled(_Item)`
  ${tw`py-4`}
  & + & {
    ${tw`border-t-2 border-neutral-stone border-opacity-50`}
  }
`;

const Divider = styled(_Divider)`
  ${tw`mb-5 mt-6`}
`;

const Cancel = styled.div`
  ${tw`cursor-pointer select-none text-indicating-error text-center`}
`;

const NextButton = styled(Button)`
  ${tw`min-w-0 px-14`}
`;

const AddItemButton = styled.div`
  ${tw`cursor-pointer select-none text-interaction mt-5`}
`;

const StyledReactQuill = styled(ReactQuill)`
  ${tw`mb-12`}
  .ql-toolbar {
    ${tw`rounded-t-lg border border-neutral-ash`}
  }
  .ql-container {
    ${tw`rounded-b-lg border border-neutral-ash`}
  }
  .ql-editor:before {
    ${tw`text-[#a1a1ab] not-italic text-base`}
    font-family: Source Sans Pro
  }
  .ql-editor {
    ${tw`text-base h-60 p-4`}
    font-family: Source Sans Pro
  }
`;

const editorToolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['clean']
];

interface ItemsListProps {
  event: Event;
  items?: EventItem[];
  onUpdate: (item: EventItem, itemIndex: number) => void;
  onDelete: (itemIndex: number) => void;
  onUpdateExcursionItem: (item: EventItem) => void;
}

function ItemsList({
  items: _items,
  event,
  onUpdate,
  onDelete,
  onUpdateExcursionItem
}: ItemsListProps) {
  const isExcursion = event.category === EventCategory.Excursion;

  let excursionSpecificItems: ReactNode = null;

  if (isExcursion) {
    const lodging = _items?.find((i) => i.name === 'Lodging');
    const transportation = _items?.find((i) => i.name === 'Transportation');

    excursionSpecificItems = (
      <>
        <div className="flex my-4 space-x-6">
          <Item
            event={event}
            item={lodging || ({ name: 'Lodging', quantity: 0 } as EventItem)}
            onChange={(item) => onUpdateExcursionItem(item)}
          />

          <Item
            event={event}
            item={transportation || ({ name: 'Transportation', quantity: 0 } as EventItem)}
            onChange={(item) => onUpdateExcursionItem(item)}
          />
        </div>
        <Divider />
      </>
    );
  }

  return (
    <>
      {excursionSpecificItems}
      <div className="flex flex-col">
        {_items?.map((item, index) => (
          <ItemsListItem
            key={index}
            item={item}
            event={event}
            onUpdate={(i) => onUpdate(i, index)}
            onDelete={() => onDelete(index)}
          />
        ))}
      </div>
    </>
  );
}

export const Items = observer(
  ({
    event,
    onSubmit,
    onCancelEvent,
    onChange,
    saveButtonName,
    showPreviewEventButton = true
  }: ItemsProps) => {
    const { organizing, attending } = usePotluck();
    const { user } = useUser();
    const router = useHistory();
    const isOrganizer = event?.organizer.id === user?.id;
    const observableItemsList = isOrganizer
      ? organizing.items(event.id)
      : attending.items(event.id);
    const _items = observableItemsList.data;
    const submit = (items: any) => onSubmit({ ...event, items });
    const [eventPageMessage, setEventPageMessage] = useState(event?.eventPageMessage);
    
    const { path } = useRouteMatch();
    const isEditing = path.includes('edit') || path.includes('event');

    const onEditorStateChange = (editorState: string) => {
      setEventPageMessage(editorState);
      event.eventPageMessage = editorState;
    };

    if (!event.id) {
      router.push('/');
    }

    return (
      <Container>
        <div>
          <CoverImagePicker
            className="mt-8"
            imageUrl={event.coverImageUrl}
            onPick={async (image, url) => {
              event.coverImage = image;
              if (url) {
                event.coverImageUrl = url;
              }
            }}
          />
          <div className="border-t border-neutral-stone mt-8" />
          <div className="w-full">
            <Accordion
              isOpen={!isEditing}
              header={
                <>
                  <Planning />
                  <div>Event Page Message</div>
                </>
              }
            >
              <StyledReactQuill
                placeholder="This will appear at the top of your event page, visible to all. Make sure to provide your guests will all relevant details, including instructing them to sign up for items in your list, if necessary."
                modules={{ toolbar: editorToolbarOptions }}
                theme="snow"
                value={eventPageMessage}
                onChange={onEditorStateChange}
              />
            </Accordion>
          </div>
          <div className="border-t border-neutral-stone mb-2" />
          <div ref={observableItemsList.register} className="mt-6 py-4 px-3">
            {
              <ItemsList
                event={event}
                items={_items}
                onUpdate={(_item, i) => {
                  runInAction(() => {
                    if (_items) {
                      _items[i] = _item;
                    }
                    observableItemsList.data = _items;

                    onChange({ ...event, items: _items } as Event);
                  });
                }}
                onDelete={(i) => {
                  runInAction(() => {
                    if (_items) {
                      _items.splice(i, 1);
                    }

                    observableItemsList.data = _items;

                    onChange({ ...event, items: _items } as Event);
                  });
                }}
                onUpdateExcursionItem={(_item) => {
                  runInAction(() => {
                    if (_item.name === 'Lodging') {
                      if (_item.quantity === 0) {
                        observableItemsList.data = [
                          //@ts-ignore
                          ..._items.filter((i) => i.name !== 'Lodging')
                        ];
                        return;
                      }

                      if (_items ? _items[0]?.name === 'Lodging' : false) {
                        observableItemsList.data = [
                          _item,
                          //@ts-ignore
                          ..._items.filter((i) => i.name !== 'Lodging')
                        ];
                      } else {
                        //@ts-ignore
                        observableItemsList.data = [_item, ..._items];
                      }
                    } else if (_item.name === 'Transportation') {
                      if (_item.quantity === 0) {
                        observableItemsList.data = [
                          //@ts-ignore
                          ..._items.filter((i) => i.name !== 'Transportation')
                        ];
                        return;
                      }

                      if (_items ? _items[0]?.name === 'Transportation' : false) {
                        observableItemsList.data = [
                          _item,
                          //@ts-ignore
                          ..._items.filter((i) => i.name !== 'Transportation')
                        ];
                      } else {
                        //@ts-ignore
                        observableItemsList.data = [_item, ..._items];
                      }
                    }
                  });
                }}
              />
            }
            <ItemCreator
              event={event}
              onSave={(item) => {
                runInAction(() => {
                  observableItemsList.data?.push(item);
                });
                const updatedItems = observableItemsList.data;
                onChange({ ...event, items: updatedItems } as Event);
              }}
              onTemplateSave={(event) => {
                onChange(event);
              }}
              openCreatorOption={
                <Button className={`bg-neutral-white ${_items && _items.length > 0 ? 'mt-4' : ''}`} outline inline>
                  {_items &&
                   _items.length > 0 &&
                   _items?.find((i) => i.name !== 'Lodging' && i.name !== 'Transportation')
                     ? '+ Add another Sign Up Item'
                     : '+ Add a Sign Up Item'
                  }
                </Button>
              }
            />
          </div>
        </div>
        <div>
          <div className="flex flex-row flex-wrap mt-10">
            {showPreviewEventButton && (
              <div className="flex w-full justify-center pt-10">
                <PreviewEventLink event={event} />
              </div>
            )}
            <div
              className={`flex w-full mt-10 ${onCancelEvent ? 'justify-between' : 'justify-between'}`}>
              <div className="flex">
                {onCancelEvent && (
                  <Cancel className="self-center" onClick={onCancelEvent}>
                    Cancel
                  </Cancel>
                  )}
                <ItemsHelpLink howToButton/>
              </div>
              <NextButton onClick={() => submit(_items)}>
                {saveButtonName ? saveButtonName : 'Next'}
              </NextButton>
              </div>
            </div>
          </div>
      </Container>
    );
  }
);

export default Items;
