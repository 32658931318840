import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { useRef, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useHistory, useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import tw from 'twin.macro';

import {
  Button,
  CircleShare,
  CloseWhite,
  Divider,
  Event,
  EventLinkCopy,
  Loader,
  Modal, Notification,
  Popup, Portal
} from '../../components';
import ImageUpload from '../../components/CoverImagePicker/CoverImageUpload';
import { MomentFeed } from '../../components/MomentFeed';
import { useDuplicateEventConfirmationModal, useEvent } from '../../hooks';
import { useSendGuestMessageModal } from '../../hooks/useSendGuestMessageModal';
import { EventRole } from '../../models';
import AddRecap from './AddRecap';

const Page = styled.div`
  ${tw`flex flex-col flex-grow w-full h-full max-w-4xl mx-auto`}
`;

const FileUpload = styled(ImageUpload)<{ visible?: boolean }>`
  ${(p) => !p.visible && tw`hidden`}
`;

const Header = styled.div`
  ${tw`absolute flex items-center self-end justify-center w-8 h-8 rounded-full cursor-pointer bg-neutral-black bg-opacity-50 top-4 right-4`}
`;

const MenuItem = styled.div`
  ${tw`text-interaction cursor-pointer select-none text-left self-center px-2 pb-2`}
`;

export const Moment = observer(() => {
  const router = useHistory();
  const { id } = useParams<{ id?: string }>();
  const uploadRef = useRef<HTMLInputElement>(null);
  const { event, save, addMoment, role } = useEvent(Number(id));
  const [userRole, setRole] = useState<EventRole>();
  const { openDuplicateEventConfirmation } = useDuplicateEventConfirmationModal(event);
  const [showMessageSent, setShowMessageSent] = useState(false);

  const handleMessageSentDisplay = () => {
    setShowMessageSent(true);
    setTimeout(() => setShowMessageSent(false), 3000);
  };

  const { openGuestMessageModal } = useSendGuestMessageModal(Number(id), handleMessageSentDisplay);

  useAsync(async () => {
    if (event) {
      setRole(await role(event.id));
    }
  }, [event]);

  const [openAddRecap, closeAddRecap] = useModal(() => {
    const [isSaving, setIsSaving] = useState(false);

    return (
      <Modal onClose={closeAddRecap}>
        <div className="flex flex-col space-y-4">
          <div className="font-semibold text-center">Create your Moment Page</div>
          <Divider />
          <div className="text-3xl font-semibold">{event?.name}</div>
          {event?.startTime && (
            <div>
              {new Date(event.startTime).toLocaleString('en', {
                hour12: true,
                dateStyle: 'long',
                timeStyle: 'short'
              })}
            </div>
          )}
        </div>
        {isSaving ? (
          <Loader>Adding your recap...</Loader>
        ) : (
          <AddRecap
            recap={event?.recap}
            onSubmit={async (recap) => {
              setIsSaving(true);
              await save({ ...event!, recap });
              setIsSaving(false);
              closeAddRecap();
            }}
          />
        )}
      </Modal>
    );
  }, [event]);

  const [openAddingMoment, closeAddingMoment] = useModal(() => (
    <Modal forceOpen onClose={closeAddingMoment} header={<>Uploading your moment...</>}>
      <div className="p-4 text-center space-y-8">
        <Loader />
        <div>Thank you for sharing your awesome moments!</div>
      </div>
    </Modal>
  ));

  const [openMomentUploadError, closeMomentUploadError] = useModal(() => (
    <Modal forceOpen onClose={closeMomentUploadError} header={<>Error uploading your moment.</>}>
      <div className="p-4 text-center space-y-8">
        <div>Please try again or reach out to support.</div>
        <Button
          onClick={() => {
            closeMomentUploadError();
          }}
        >
          Close
        </Button>
      </div>
    </Modal>
  ));

  const [showShareMenu, setShowShareMenu] = useState(false);

  const toggleShareMenu = () => {
    setShowShareMenu(!showShareMenu);
  };

  if (!event) {
    return <Loader>Fetching this moment...</Loader>;
  }

  return (
    <Page>
      <Event
        event={event}
        header={
          <Header onClick={() => router.push('/moments')}>
            <CloseWhite className="text-2xl" />
          </Header>
        }
      >
        <Portal container="notifications">
          {showMessageSent && <Notification>Message sent!</Notification>}
        </Portal>
        <div className="flex flex-col w-full mx-8 my-8 space-y-4">
          <div className="flex justify-between">
            <div className="text-3xl font-semibold">{event.name}</div>
            <div className="flex flex-col justify-start">
              <div className="flex pl-4">
                <>
                  <CircleShare
                    className="flex-shrink-0 text-3xl cursor-pointer select-none text-interaction"
                    onClick={toggleShareMenu}
                  />
               </>
              </div>
            {showShareMenu && (
                <Popup className="-left-32" onClose={() => setShowShareMenu(false)}>
                  <EventLinkCopy
                    customLabel="Copy Link"
                    copyButtonOnly
                    defaultValue={`${window.location.origin}/moments/${event.id}`}
                  />
                  {(userRole === EventRole.Organizer) &&
                      <MenuItem onClick={openDuplicateEventConfirmation}>Re-create Event</MenuItem>
                  }
                  {event.guests.length > 0 && (
                    <MenuItem onClick={openGuestMessageModal}>Send Guest Message</MenuItem>
                  )}
                </Popup>
              )}
            </div>
          </div>
          <div>
            {new Date(event.startTime!).toLocaleDateString('en', {
              dateStyle: 'long'
            })}
          </div>
          {!event.recap ? (
            <>
              <div className="cursor-pointer select-none text-interaction" onClick={openAddRecap}>
                Add a recap
              </div>
            </>
          ) : (
            <>
              <div>{event.recap}</div>
              {userRole === EventRole.Organizer && (
                <div className="cursor-pointer text-interaction" onClick={openAddRecap}>
                  Edit
                </div>
              )}
              <Divider />
            </>
          )}
          <div className="flex flex-col mb-4">
            <FileUpload
              useCropper={false}
              ref={uploadRef}
              onUpload={async (image) => {
                openAddingMoment();
                let isUploadError  = await addMoment(event, {}, image);
                if (uploadRef.current?.value) {
                  uploadRef.current.value = '';
                }
                closeAddingMoment();
                if (isUploadError === true) {
                  openMomentUploadError()
                }
              }}
              visible={userRole !== EventRole.Guest && !event?.recap}
              clearOnUpload
            />

            <div className="flex items-center justify-between w-full px-1 py-4 text-2xl font-semibold">
              <div>Photos</div>
              <div className="flex flex-col items-end">
                <div
                  className="text-base font-normal cursor-pointer select-none text-interaction text-right"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    uploadRef.current?.click();
                  }}
                >
                  + Add yours
                </div>
                <div className="text-sm italic font-normal text-right">Video not supported</div>
              </div>
            </div>

            <MomentFeed event={event} />
          </div>
        </div>
      </Event>
    </Page>
  );
});

export default Moment;
