import * as React from 'react';

function SvgSubtract(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        stroke="#070401"
        strokeWidth={1.54}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M19.643 11.643H3.336" />
      </g> 
    </svg>
  );
}

export default SvgSubtract;