import * as React from 'react';

function SvgPlanning(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#070401" strokeWidth={1.54} fill="none" fillRule="evenodd">
        <path
          d="M3 6.79h18v12.77A1.44 1.44 0 0119.56 21H4.44A1.44 1.44 0 013 19.56V6.79h0z"
          fill="#F8F6F1"
        />
        <path
          d="M3 7.235V4.777C3 3.796 3.811 3 4.812 3h14.376c1 0 1.812.796 1.812 1.777v2.58L3 7.234z"
          fill="#F0EBE0"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6.619 11.529H9.39M11.761 11.529h5.62M6.619 14.74H9.39M11.761 14.74h5.62"
        />
      </g>
    </svg>
  );
}

export default SvgPlanning;
