import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Route, useRouteMatch } from 'react-router-dom';
import tw from 'twin.macro';

import { Event, EventInvite, EventRole, User } from '../../models';
import { EditEventGuests } from '../EditEventGuests';
import { EditEventItems } from '../EditEventItems';
import { ProfilePicture } from '../ProfilePicture';

interface EventRSVPsProps {
  event: Event;
  role?: EventRole;
  initialGuestCount?: number;
  onEventUpdate?: (event: Event) => void;
}

const RSVPList = styled.div`
  ${tw`flex-1 bg-content-spring-wood p-4 pt-2`}
`;

const GuestPictureContainer = styled.div`
  ${tw`w-4 h-4`}
  font-size: 0.6rem;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 50%;
  }
`;

export const FadedLoader = styled.div`
  ${tw`fixed bottom-0 right-0 z-40 flex items-center justify-center w-full h-full bg-opacity-50 bg-neutral-soil opacity-50`};
  max-width: 30rem;

  animation: ${fadeIn} 0.5s ease-in;
`;

interface RSVPItemProps {
  invite: EventInvite;
  event: Event;
}

const calculatePlusOnesText = (user: User, event: Event) => {
  const guest = event.guests.find((g) => g.guest.id === user.id);
  return guest?.guestCount && guest.guestCount > 1 ? ` +${guest.guestCount - 1}` : '';
};

const RSVPItem = ({ invite, event }: RSVPItemProps) => (
  <div className="flex items-center space-x-2">
    <GuestPictureContainer>
      <ProfilePicture
        user={invite.user || { firstName: invite.firstName, lastName: invite.lastName }}
      />
    </GuestPictureContainer>
    <div>
      {invite.user?.firstName || invite.firstName}{' '}
      {invite.user ? invite.user.lastName : invite.lastName}
      {invite.user && event.allowPlusOnes && calculatePlusOnesText(invite.user, event)}
    </div>
  </div>
);

export function EventRSVPs({ event, role }: EventRSVPsProps) {
  const { url } = useRouteMatch();
  const canEditGuests = () => role === EventRole.Organizer || role === EventRole.Cohost;
  const guestCountTotal = useMemo(
    () =>
      event.guests.reduce((sum, _guest) => {
        if (event.allowPlusOnes) {
          return sum + (!!_guest.guestCount ? _guest.guestCount : 1);
        } else {
          return sum + 1;
        }
      }, 0),
    [event.guests, event.allowPlusOnes]
  );

  const prefilteredInvites = useMemo(() => {
    const invitesMap = new Map();

    event.invites.forEach(invite => {
        const key = invite.phoneNumber || invite.email;
        const existingEntry = invitesMap.get(key);
        if (!existingEntry) {
            invitesMap.set(key, invite);
        } else {
            if (invite.accepted != null && (!existingEntry.respondedAt || invite.respondedAt! > existingEntry.respondedAt)) {
                invitesMap.set(key, invite)
            }
        }
    });

    return Array.from(invitesMap.values());
  }, [event.invites]);

  const accepted = useMemo(() => prefilteredInvites.filter((i) => i.accepted === true), [prefilteredInvites]);
  const declined = useMemo(
    () => prefilteredInvites.filter((i) => i.accepted === false),
    [prefilteredInvites]
  );
  const notResponded = useMemo(
    () => prefilteredInvites.filter((i) => i.accepted === null && i.sentAt !== null),
    [prefilteredInvites]
  );

  return (
    <div className="space-y-4 mt-4">
      <div className="text-center">
        <div className="font-semibold text-lg">RSVP Details</div>
        <div>Total in Attendance: {guestCountTotal}</div>
        {canEditGuests() && (
          <div>
            <Link to={`${url}/guests`}>Edit Guest Lists</Link>
          </div>
        )}
        <Route path={`/event/:id/insights/guests`}>
          <EditEventGuests />
        </Route>
      </div>

      <Route path={`/event/:id/insights/items`}>
        <EditEventItems />
      </Route>

      <div className="flex flex-col gap-y-2">
        <RSVPList className="rounded-2xl">
          <div className="text-center mb-2">Yes</div>
          <div className="space-y-2">
            {accepted.map((invite) => (
              <RSVPItem key={invite.id} invite={invite} event={event} />
            ))}
          </div>
        </RSVPList>
        <RSVPList className="rounded-2xl">
          <div className="text-center mb-2">No</div>
          <div className="space-y-2">
            {declined.map((invite) => (
              <RSVPItem key={invite.id} invite={invite} event={event} />
            ))}
          </div>
        </RSVPList>
        <RSVPList className="rounded-2xl">
          <div className="text-center mb-2">Not Responded</div>
          <div className="space-y-2">
            {notResponded.map((invite) => (
              <RSVPItem key={invite.id} invite={invite} event={event} />
            ))}
          </div>
        </RSVPList>
      </div>
    </div>
  );
}

export default EventRSVPs;
