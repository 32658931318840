import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { FadeIn, Loader, MessageInput } from '../../components';
import { useMomentComments } from '../../hooks';
import { Event, EventMoment, Message, UserReaction } from '../../models';
import { usePotluck, useUser } from '../../providers';

interface CommentsProps {
  event: Event;
  moment: EventMoment;
  onComment: (comment: string) => void;
}

export const Comments = observer(({ event, moment, onComment }: CommentsProps) => {
  const { send } = useMomentComments(event.id, moment.id);
  const { user } = useUser();
  const { moments } = usePotluck();
  const comments = moments.comments(event.id, moment.id);
  const { data, load, loading, next, hasMore } = comments;

  useEffect(() => void next(3), [next]);

  const commentInput = useRef<HTMLFormElement>(null);

  const {
    reset,
    register,
    handleSubmit,
    formState: { isSubmitting, isDirty }
  } = useForm({ defaultValues: { comment: '' } });

  if (!user) {
    return <Loader />;
  }

  return (
    <>
      <div className="flex flex-col space-y-4">
        {data?.map((c) => (
          <FadeIn key={c.id}>
            <b>
              {c.author.firstName} {c.author.lastName ? `${c.author.lastName[0]}.` : ''}
            </b>
            : {c.message}
          </FadeIn>
        ))}
      </div>
      {!loading && hasMore && (
        <div onClick={() => next(8)} className="mt-4 cursor-pointer select-none text-interaction">
          More Comments...
        </div>
      )}
      <form
        className="mt-4"
        onSubmit={handleSubmit(async ({ comment }) => {
          await send(comment);
          if (hasMore) {
            await load();
          } else {
            runInAction(() =>
              comments.data?.push({
                message: comment,
                author: user,
                reactions: [] as UserReaction[]
              } as Message)
            );
          }

          onComment(comment);
          reset();
        })}
        ref={commentInput}
      >
        <MessageInput
          {...register('comment', { required: true })}
          disabled={isSubmitting || !isDirty}
        />
      </form>
    </>
  );
});

export default Comments;
