import EventStore from './Event';
import LazyStore from './Lazy';
import MomentStore from './Moment';
import UserStore from './User';

export class PotluckStore {
  user = new UserStore();

  organizing = new EventStore();
  attending = new EventStore('attending');
  ended = new EventStore('ended');

  moments = new MomentStore();

  coverImages = new LazyStore<{ url: string }>('event/cover-images', 20);
}

export default PotluckStore;
