import { css } from '@emotion/react';
import styled from '@emotion/styled';
import tw from 'twin.macro';

export const Card = styled.div<{ inline?: boolean }>`
  ${tw`p-8 overflow-auto bg-neutral-white`}
  border-radius: 1.5rem;

  ${(p) =>
    p.inline &&
    css`
      @media (min-width: 1024px) {
        max-width: 21.375rem;
      }
    `}
`;

export default Card;
