import { useState } from 'react';
import { Link } from 'react-router-dom';

import { useGuestCount } from '../../hooks/useGuestCount';
import { Event, EventGuest, EventRole } from '../../models';
import useUser from '../../providers/UserProvider';

interface EventSettingsProps {
  initialGuestCount?: number;
  event: Event;
  role?: EventRole;
  onCancel: () => void;
  onLeave: () => void;
}

export function EventSettings({
  event,
  role,
  onCancel,
  onLeave,
  initialGuestCount = 1
}: EventSettingsProps) {
  const { user } = useUser();
  const guest = event?.guests.find((g: EventGuest) => g.guest.id === user?.id);
  const [guestCount, setGuestCount] = useState(initialGuestCount);

  const handleGuestCountUpdate = (count: number) => {
    setGuestCount(count);
    if (guest) {
      guest.guestCount = count;
    }
  };

  const { openGuestCount } = useGuestCount(event.id, user?.id, handleGuestCountUpdate, guestCount);

  return (
    <div className="space-y-4">
      <div className="text-lg text-neutral-soil font-semibold">Event Settings</div>
      <div className="space-y-4 inline-flex flex-col">
        <Link to={`/event/${event.id}/insights`} className="font-bold">
          RSVP Details
        </Link>
        {event.allowPlusOnes && guest && (
          <div>
            <div className="text-interaction cursor-pointer" onClick={openGuestCount}>
              Edit Your Party Size
            </div>
          </div>
        )}
        {role === EventRole.Organizer ? (
          <div className="text-interaction cursor-pointer" onClick={onCancel}>
            CANCEL EVENT
          </div>
        ) : (
          <div className="text-interaction cursor-pointer" onClick={onLeave}>
            I can no longer attend this event 😢
          </div>
        )}
      </div>
    </div>
  );
}

export default EventSettings;
