import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useMount } from 'react-use';
import tw from 'twin.macro';

import { ActionBar, Add, CircleButton, Loader, MomentScene } from '../../components';
import { MomentSummary } from '../../components/MomentSummary';
import { usePotluck } from '../../providers';
import { PlanSomethingCallToAction } from '../Events';

const Container = styled.div`
  ${tw`flex flex-col flex-grow pb-32 space-y-8`}
`;

const Header = styled.div`
  ${tw`font-semibold`}
  font-size: 3rem;
`;

const Summaries = styled.div`
  ${tw`flex flex-wrap`}
`;

export const Moments = observer(() => {
  const { organizing, attending, ended } = usePotluck();

  useMount(() => {
    !attending.events && attending.next();
    !organizing.events && organizing.next();

    ended.next();
  });

  if (!organizing.events || !attending.events || !ended.events) {
    return <Loader>Loading your moments...</Loader>;
  }

  if (
    organizing.events.length === 0 &&
    attending.events.length === 0 &&
    ended.events.length === 0
  ) {
    return (
      <>
        <PlanSomethingCallToAction />
        <ActionBar className="fixed bottom-0 z-10 lg:hidden" />
      </>
    );
  }

  if (ended.events.length === 0) {
    return (
      <>
        <MomentScene width="20rem" height="20rem" />
        <b>No moments yet 🙁</b>
        <p>After events you’ve organized or attended, check back here to share photos, and chat.</p>
        <ActionBar className="fixed bottom-0 z-10 lg:hidden" />
      </>
    );
  }

  return (
    <>
      <Container>
        <Header className="hidden text-6xl font-semibold lg:block">Moments</Header>
        <Summaries ref={ended.register}>
          {ended.events.map((e) => (
            <MomentSummary key={e.id} event={e} />
          ))}
        </Summaries>
      </Container>
      <Link to="/create/details" className="fixed hidden lg:block bottom-12 right-12">
        <CircleButton>
          <Add />
        </CircleButton>
      </Link>
      <ActionBar className="fixed bottom-0 z-10 lg:hidden" />
    </>
  );
});

export default Moments;
