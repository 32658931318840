import '../../react-toggle.css';

import styled from '@emotion/styled';
import { ComponentProps, ReactNode } from 'react';
import tw from 'twin.macro';

import { Divider, EditIcon, FadeIn, GuestListCreator, Trash } from '../../components';
import { Event, EventInvite } from '../../models';
import { useUser } from '../../providers';
import { InviteUserSummary } from '../InviteUserSummary';

const Container = styled.div`
  ${tw`flex flex-col`}

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const Guest = styled(FadeIn)`
  ${tw`flex items-center transition-all flex-shrink-0`}
`;

const HeaderAction = styled.div`
  ${tw`text-lg p-1 bg-content-pearl rounded-xl self-start`}
`;

type GuestListProps = Omit<ComponentProps<'div'>, 'onClick'> & {
  event: Event;
  initialTab: string;
  onTabChange: (tab: string) => void;
  onDelete?: (inviteIndex: number) => void;
  onUpdateInvite?: (invite: EventInvite, index: number) => void;
};

type GuestSummaryProps = Omit<ComponentProps<'div'>, 'onClick'> & {
  invite: EventInvite;
  inviteEdit?: ReactNode;
  onDelete: () => void;
};

const GuestSummary = ({ invite, inviteEdit, onDelete, ...props }: GuestSummaryProps) => {
  const { user } = useUser();
  const isCurrentUser = user?.id === invite.user?.id;

  return (
    <Guest {...props}>
      <InviteUserSummary
        invite={invite}
        userOptions={
          <div className="flex">
            {!isCurrentUser && (
              <>
                <HeaderAction className="cursor-pointer">{inviteEdit}</HeaderAction>
                <HeaderAction className="ml-4 cursor-pointer" onClick={() => onDelete()}>
                  <Trash />
                </HeaderAction>
              </>
            )}
          </div>
        }
      />
    </Guest>
  );
};

export function GuestList({
  event,
  onUpdateInvite,
  onDelete,
  initialTab,
  onTabChange,
  ...props
}: GuestListProps) {
  let emptyGuestsMessage: ReactNode = null;
  if (event?.invites?.length === 0) {
    emptyGuestsMessage = <div className="text-2xl">Send invitations via text or email:</div>;
  }

  return (
    <>
      <div>{emptyGuestsMessage}</div>
      <Container {...props}>
        {event.invites.map((c, i) => (
          <div key={c.email}>
            <GuestSummary
              key={c.email}
              invite={c}
              onDelete={() => onDelete?.(i)}
              inviteEdit={
                <GuestListCreator
                  onTabChange={(tab) => onTabChange(tab)}
                  initialTab={initialTab}
                  event={event}
                  editInvite={c}
                  onAddInvite={(invite) => onUpdateInvite?.(invite, i)}
                  openCreatorOption={<EditIcon />}
                  onUpdateInvite={(invite) => onUpdateInvite?.(invite, i)}
                />
              }
            />
            <Divider className={i !== event.invites?.length - 1 ? 'mb-8' : ''} />
          </div>
        ))}
      </Container>
    </>
  );
}

export default GuestList;
