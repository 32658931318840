import styled from '@emotion/styled';
import { FC, ReactNode, useState } from 'react';
import tw from 'twin.macro';

import { Divider } from '../Divider';

interface Tab {
  name: string;
  path: string;
  disabled?: boolean;
  content: ReactNode | FC;
}

interface TabsProps {
  tabs: Tab[];
  initialTab?: string;
  onTabChange: (tab: string) => void;
}

const Container = styled.div``;

const Link = styled.button`
  ${tw`border-b-4 border-secondary border-opacity-0 text-neutral-black font-semibold py-2 px-6`}

  &.active {
    ${tw`border-opacity-100`}
  }

  :disabled {
    ${tw`text-neutral-ash`}
  }
`;

const TabDivider = styled(Divider)`
  ${tw`mt-0`}
`;

export const Tabs: FC<TabsProps> = ({ tabs, initialTab, onTabChange }) => {
  const [activeTab, setActiveTab] = useState(
    initialTab === 'connection' ? tabs[0].path : tabs[1].path
  );

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    onTabChange(tab);
  };

  return (
    <Container>
      <div className="flex items-center justify-around space-x-4">
        {tabs.map((t) => (
          <Link
            key={t.name}
            className={`${activeTab === t.path ? 'active' : ''}`}
            disabled={t.disabled}
            onClick={() => handleTabChange(t.path)}
          >
            {t.name}
          </Link>
        ))}
      </div>
      <TabDivider />
      <div className="mt-8">
        {tabs.map((t) => (
          <div key={t.name}>{activeTab === t.path && <>{t.content}</>}</div>
        ))}
      </div>
    </Container>
  );
};

export default Tabs;
