import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ComponentProps, FC } from 'react';
import tw from 'twin.macro';

export type ProgressBarProps = ComponentProps<'div'> & {
  value?: number;
  total?: number;
  animated?: boolean;
};

const Bar = styled.div`
  ${tw`w-full overflow-hidden rounded-xl bg-content-mist`}
`;

const Progress = styled.div<{ animated?: boolean }>`
  ${tw`h-full bg-secondary`}
  width: 0;

  ${(p) =>
    p.animated &&
    css`
      transition: width 0.5s;
    `}
`;

export const ProgressBar: FC<ProgressBarProps> = ({ value, total, animated, ...props }) => (
  <Bar className="h-3" {...props}>
    <Progress style={{ width: `${((value ?? 0) / (total || 1)) * 100}%` }} animated={animated} />
  </Bar>
);

export default ProgressBar;
