import * as React from 'react';

function SvgPhoto(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        transform="translate(3 3)"
        stroke="#070401"
        strokeWidth={1.54}
        fill="none"
        fillRule="evenodd"
      >
        <rect fill="#ECECEC" width={18} height={17.963} rx={3.283} />
        <path
          d="M.9 15.813l7.043-8.65 7.369 9.485a.82.82 0 01-.644 1.324c-7.344.04-11.357.037-12.038-.01 0 0 0 0 0 0a1.855 1.855 0 01-1.73-1.85v-.299h0z"
          fill="#DDDDDC"
        />
        <path
          d="M15.908 17.488h0l-5.191-6.736 2.811-2.85 4.472 5.1v2.394a2.092 2.092 0 01-2.092 2.092z"
          fill="#DDDDDC"
        />
      </g>
    </svg>
  );
}

export default SvgPhoto;
