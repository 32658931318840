import styled from '@emotion/styled';
import { ComponentProps } from 'react';
import tw from 'twin.macro';

import { Address as AddressModel } from '../../models';
import { Input } from '../Input';

type AddressProps = ComponentProps<'div'> & {
  value?: AddressModel;
  onChange?: (address: AddressModel) => void;
};

const Container = styled.div`
  ${tw`flex flex-col space-y-6`}
`;

export function Address({ value, onChange }: AddressProps) {
  const { address, address2, city, state, zipCode } = value || {
    address: '',
    address2: '',
    city: '',
    state: '',
    zipCode: ''
  };
  const _onChange = (change: Partial<AddressModel>) =>
    onChange?.({ ...(value || ({} as AddressModel)), ...change });

  return (
    <Container>
      <div>
        <Input
          value={address}
          onChange={(e) => _onChange({ address: e.target.value })}
          placeholder="Address"
        />
      </div>
      <div>
        <Input
          value={address2}
          onChange={(e) => _onChange({ address2: e.target.value })}
          placeholder="Address 2"
        />
      </div>
      <div className="flex space-x-4 pr-16">
        <div className="w-2/3">
          <Input
            value={city}
            onChange={(e) => _onChange({ city: e.target.value })}
            placeholder="City"
          />
        </div>
        <div>
          <Input
            value={state}
            onChange={(e) => _onChange({ state: e.target.value })}
            placeholder="State"
          />
        </div>
      </div>
      <div className="w-1/3">
        <Input
          value={zipCode}
          onChange={(e) => _onChange({ zipCode: e.target.value })}
          placeholder="Zip"
        />
      </div>
    </Container>
  );
}

export default Address;
