import { useCallback } from 'react';

import { User } from '../models';
import { fetcher } from '../providers';
import usePaged from './usePaged';

export function useConnections() {
  const { data: connections, ...pagination } = usePaged<User>(10, '/api/user/connections');

  const remove = useCallback(
    async (connection: User) => {
      await fetcher(`/api/user/connections/${connection.id}`, { method: 'DELETE' });
      await pagination.mutate();
    },
    [pagination]
  );

  return {
    connections,
    remove,
    ...pagination
  };
}

export default useConnections;
