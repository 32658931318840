import * as React from 'react';

function SvgGear(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        transform="translate(5 3)"
        stroke="#070401"
        strokeWidth={1.54}
        fill="none"
        fillRule="evenodd"
      >
        <rect
          fill="#F0EBE0"
          strokeLinejoin="round"
          x={9.35}
          y={0.03}
          width={4.52}
          height={14.158}
          rx={1.12}
        />
        <path
          d="M1.523 3.842h8.97v8.294c0 .571.29 1.103.77 1.412l.994.64h0-9.054a1.68 1.68 0 01-1.68-1.68V3.842h0z"
          fill="#F8F6F1"
          strokeLinejoin="round"
        />
        <path
          d="M13.135 18l-11.27-.071A1.876 1.876 0 010 16.053a1.863 1.863 0 011.864-1.863l11.695.009h0L13.135 18z"
          fill="#F8F6F1"
        />
        <ellipse fill="#F0EBE0" cx={13.588} cy={16.094} rx={1.412} ry={1.906} />
        <path
          d="M3.267.225c1.049-.36 9.75-.18 10.038-.18.19 0 .656-.028.047.261-.314.15-.71.485-.885.714-.533.697-.8.99-1.126 2.61-.093.459-.146.9-.16 1.325a1.68 1.68 0 01-1.68 1.625H2.57A1.68 1.68 0 01.89 4.97c-.041-1.009 0-1.861.121-2.556C1.286.853 2.217.585 3.267.225z"
          fill="#F8F6F1"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.814 9.434v2.729H7.77V9.434M5.792 4.901V8.59"
        />
      </g>
    </svg>
  );
}

export default SvgGear;
