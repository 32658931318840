import styled from '@emotion/styled';
import { useState } from 'react';
import { FieldError } from 'react-hook-form';
import tw from 'twin.macro';

import { useAllItemCategoriesIncludingCustom } from '../../hooks';
import { Event, ItemCategory } from '../../models';
import { CheckmarkWhite } from '../icons';
import { Input } from '../Input';
import { Loader } from '../Loader';
import itemCategoryIconMap from './iconMap';

interface ItemCategoriesProps {
  event: Event;
  selected?: ItemCategory;
  customInputError?: FieldError;
  onSelect: (category: ItemCategory) => void;
  onCustomCategory: (categoryName: string) => void;
  onCustomCategoryClick: (categoryName: string) => void;
}

const Container = styled.div`
  ${tw`flex flex-wrap items-center`}
`;

export const CategoryTag = styled.div<{ selected?: boolean }>`
  ${tw`relative flex items-center p-2 m-2 border cursor-pointer rounded-lg`}
  ${(p) => p.selected && tw`bg-content-mist`}

  svg {
    ${tw`text-2xl`}
  }
`;

export function ItemCategories({
  event,
  selected,
  onSelect,
  onCustomCategory,
  onCustomCategoryClick,
  customInputError
}: ItemCategoriesProps) {
  const [categoryName, setCategoryName] = useState('');
  const categories = useAllItemCategoriesIncludingCustom(event);

  if (!categories) {
    return <Loader>Fetching categories...</Loader>;
  }

  return (
    <Container>
      {categories.map((category) => (
        <CategoryTag
          key={category.id}
          onClick={() => {
            setCategoryName('');
            onSelect(category);
          }}
          selected={selected?.id === category.id}
        >
          {itemCategoryIconMap[category.name]}
          <div>{category.name}</div>
          {selected?.id === category.id && (
            <div className="absolute flex items-center justify-center w-4 h-4 rounded-full -top-1 -right-1 bg-neutral-black">
              <CheckmarkWhite />
            </div>
          )}
        </CategoryTag>
      ))}
      <div className="flex w-44 mx-2">
        <Input
          errors={customInputError}
          maxLength={65}
          value={categoryName}
          onChange={(e) => {
            const value = e.target.value;
            setCategoryName(value);
            onCustomCategory(value);
          }}
          onClick={() => onCustomCategoryClick(categoryName)}
          placeholder="Enter Custom Category"
        ></Input>
      </div>
    </Container>
  );
}

export default ItemCategories;
