import * as React from 'react';

function SvgMenu(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#001E29" fillRule="evenodd">
        <path d="M3.176 8h18.648a1.176 1.176 0 010 2.352H3.176a1.176 1.176 0 010-2.352zM2 15c0-.634.514-1.148 1.148-1.148h12.704a1.148 1.148 0 010 2.296H3.148A1.148 1.148 0 012 15z" />
      </g>
    </svg>
  );
}

export default SvgMenu;
