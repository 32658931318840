import { fetcher } from '../providers';

export async function verifyPhoneWithTwilio(phoneNumber: string) {
  return await fetcher('/api/notification/verify-phone-number', {
    method: 'POST',
    body: JSON.stringify({ phone_number: phoneNumber })
  });
}

export default verifyPhoneWithTwilio;
