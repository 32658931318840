import styled from '@emotion/styled';
import { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import tw from 'twin.macro';

import { fadeInAnimation } from '../animations';
import { Button } from '../Button';

const Container = styled.div`
  ${tw`flex-shrink-0 rounded-full bg-secondary w-full h-full`}

  ${fadeInAnimation()}
`;

const CropContainer = styled.div`
  position: inherit;
  width: 50%;
  height: 50%;
  background: #333;
`;

const Controls = styled.div`
  bottom: 10%;
  left: 25%;
  position: absolute;
  width: 50%;
  accent-color: #4c69bf;
  display: flex;
  flex-direction: column;
`;

export const createImage = (url: string): Promise<File> =>
  new Promise(async (resolve, reject) => {
    let response = await fetch(url);
    let data = await response.blob();
    let metadata = {
      type: 'image/jpeg'
    };
    resolve(new File([data], 'profile-picture.jpg', metadata));
  });

export function getRadianAngle(degreeValue: any) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width: any, height: any, rotation: any) {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height)
  };
}

export default async function getCroppedImg(
  imageSrc: string,
  pixelCrop: any,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
): Promise<string> {
  const image = new Image();
  image.src = imageSrc;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return '';
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      resolve(URL.createObjectURL(file!));
    }, 'image/jpeg');
  });
}

type ImageDialogProps = {
  image: string;
  onSave: (imageFile: File) => void;
  cropShape?: 'round' | 'rect';
};

export const ImageDialog = ({ image, onSave, cropShape = 'rect' }: ImageDialogProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaInPixels, setCroppedAreaInPixels] = useState({
    width: 0,
    height: 0,
    x: 0,
    y: 0
  });

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaInPixels(croppedAreaPixels);
  }, []);

  const handleSave = async () => {
    const croppedImage = await getCroppedImg(image, croppedAreaInPixels);
    const resolvedImage = await createImage(croppedImage);
    onSave(resolvedImage);
  };

  const isRoundCrop = cropShape === 'round';
  const aspect = isRoundCrop ? 1 : 16/9;

  return (
    <Container>
      <CropContainer className="crop-container">
        <Cropper
          image={image}
          crop={crop}
          cropShape={cropShape}
          zoom={zoom}
          aspect={aspect}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </CropContainer>
      <Controls>
        <input
          type="range"
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e) => {
            setZoom(+e.target.value);
          }}
        />
        <Button className="mt-4" onClick={handleSave}>
          Save
        </Button>
      </Controls>
    </Container>
  );
};
