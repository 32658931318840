import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { truncate } from 'lodash';
import { useEffect, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import tw from 'twin.macro';

import useEvent from '../../hooks/useEvent';
import FlowConfirmation from '../../pages/Events/FlowConfirmation';
import Guests from '../../pages/Events/Guests';
import Invites from '../../pages/Events/Invites';
import { Button } from '../Button';
import { Card } from '../Card';
import { Loader } from '../Loader';
import { Menu } from '../Menu';
import { Modal } from '../Modal';

export function EditEventGuests() {
  const [isSaving, setIsSaving] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { event: _event } = useEvent(Number(id));
  const [event, setEvent] = useState(_event);
  const { save } = useEvent(Number(id));
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentPage, setCurrentPage] = useState('guestList');
  const { path } = useRouteMatch();
  const isOnEventPage = path.includes('/event');
  const [failedNotificationMessage, setFailedNotificationMessage] = useState('');

  const Header = styled.div`
    ${tw`text-base font-semibold text-center flex-1 self-center`}
  `;

  const fadeIn = keyframes`
    0% {
      opacity: 0;
    }

    100% {
      opacity: 50%;
    }
  `;

  const Pill = styled.div`
    ${tw`flex flex-1 text-sm bg-content-pearl px-2 rounded-2xl w-16 justify-center self-center`}
  `;

  const FadedLoader = styled.div`
    ${tw`fixed bottom-0 right-0 z-40 flex items-center justify-center w-full h-full bg-opacity-50 bg-neutral-soil opacity-50`};
    max-width: 30rem;

    animation: ${fadeIn} 0.5s ease-in;
  `;

  const maxTitleLength = 57;
  const guestsTitle = truncate(`Guests for ${event?.name}`, {
    length: maxTitleLength,
    omission: '...'
  });

  useEffect(() => {
    setEvent(_event);
  }, [_event]);

  const [openFailedNotificationModal, closeFailedNotificationModal] = useModal(
    () => (
      <Modal onClose={closeFailedNotificationModal} fullHeight>
        {() => {
          const lines = failedNotificationMessage.split('\n');
          return (
            <div className="space-y-8 flex flex-col">
              <div className="text-center mb-2">
                The following invites were not sent due to invalid or missing phone numbers.
              </div>
              <div className="text-center">
                {lines.map((text) => (
                  <div>{text}</div>
                ))}
              </div>
              <div className="flex flex-col justify-evenly sm:flex-row">
                <Button
                  onClick={() => {
                    closeFailedNotificationModal();
                    setCurrentPage('guestList');
                  }}
                >
                  Edit Event Guests
                </Button>
              </div>
            </div>
          );
        }}
      </Modal>
    ),
    [failedNotificationMessage, event]
  );

  if (!event) {
    return (
      <FadedLoader>
        <Loader />
      </FadedLoader>
    );
  }

  return (
    <>
      <Menu
        displayBackToDashboard={showConfirmation && !isOnEventPage}
        displayClose={!showConfirmation || isOnEventPage}
        displayBack={!showConfirmation && currentPage !== 'guestList'}
        onGoBack={async () => {
          await save(event, false);
          setCurrentPage('guestList');
        }}
        header={
          <>
            {!showConfirmation && (
              <div className="flex flex-1 flex-col">
                <Pill>Editing</Pill>
                <Header>{guestsTitle}</Header>
              </div>
            )}
          </>
        }
        goBackOnClose
      >
        <Card className="flex flex-col flex-1 w-full space-y-6">
          {!showConfirmation ? (
            currentPage === 'guestList' ? (
              <Guests
                event={event}
                onChange={async (e) => {
                  setIsSaving(true);
                  await save(e, false);
                  setIsSaving(false);
                }}
                onSubmit={async () => {
                  setIsSaving(true);
                  await save(event, false);
                  setIsSaving(false);
                  setCurrentPage('guestMessage');
                }}
                saveButtonName="Next"
              />
            ) : (
              <Invites
                showPreviewEventButton={false}
                onChange={(e) => {
                  event.guestMessage = e.guestMessage;
                  event.cohostMessage = e.cohostMessage;
                }}
                event={event}
                onSubmit={async () => {
                  try {
                    setIsSaving(true);
                    await save(event, true);
                    setShowConfirmation(true);
                  } catch (e) {
                    //@ts-ignore
                    const result = await e.json();
                    setFailedNotificationMessage(result.error);
                    openFailedNotificationModal();
                    return;
                  } finally {
                    setIsSaving(false);
                  }
                }}
              />
            )
          ) : (
            <FlowConfirmation />
          )}
        </Card>
      </Menu>
      {isSaving && (
        <FadedLoader>
          <Loader />
        </FadedLoader>
      )}
    </>
  );
}

export default EditEventGuests;
