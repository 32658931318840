import { ReactNode } from 'react';

import {
  Alcohol,
  Appetizers,
  Breads,
  Clothing,
  Desserts,
  Drinks,
  Entrees,
  Essentials,
  Gear,
  Reservations,
  Sides,
  Snacks,
  Toiletries
} from '../icons';

export const itemCategoryIconMap: Record<string, ReactNode> = {
  Appetizers: <Appetizers />,
  Entrees: <Entrees />,
  Sides: <Sides />,
  Breads: <Breads />,
  Desserts: <Desserts />,
  Alcohol: <Alcohol />,
  Drinks: <Drinks />,
  Essentials: <Essentials />,
  Snacks: <Snacks />,
  Gear: <Gear />,
  Clothing: <Clothing />,
  Reservations: <Reservations />,
  Toiletries: <Toiletries />
};

export default itemCategoryIconMap;
