import * as React from 'react';

function SvgCircleEdit(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#f4f2ee" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7728 8.77344L14.2879 8.25833C14.6806 7.86566 15.3173 7.86566 15.7099 8.25833V8.25833C16.1026 8.65101 16.1026 9.28766 15.7099 9.68033L15.1948 10.1954L10.0911 15.2148L8.18502 15.6366L8.78203 13.86L13.7728 8.77344Z"
        stroke="#4C69BF"
      />
      <path d="M13.5601 9.06531L14.8101 10.3153" stroke="#4C69BF" />
    </svg>
  );
}

export default SvgCircleEdit;
