import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import tw from 'twin.macro';

import { ProgressBar } from '../../components';

const Header = styled.div`
  ${tw`float-left`}
`;

const orderedSteps = ['details', 'items', 'guests', 'invites'] as const;
export function Step() {
  const { pathname } = useLocation();
  const step = orderedSteps.findIndex((step) => pathname.includes(step)) + 1;

  if (!step) {
    return null;
  }

  return (
    <Header>
      <div className="w-10">
        <div>
          <b>{step}</b> of 4
        </div>
        <ProgressBar value={step} total={4} className="h-1" />
      </div>
    </Header>
  );
}

export default Step;
