import { useForm } from 'react-hook-form';

import { Button, Input } from '../../components';
import { fetcher } from '../../providers';
import { Page } from '../Page';

export function Admin() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isDirty, errors }
  } = useForm({ defaultValues: { email: '' }, mode: 'all' });

  return (
    <Page>
      <form
        onSubmit={handleSubmit(async ({ email }) => {
          await fetcher(`/api/admin/invite/lifetime/${email}`, { method: 'POST' });
          reset();
        })}
        className="max-w-md space-y-4"
      >
        <div>Send Lifetime Membership Invitation</div>
        <div className="space-y-8">
          <Input
            placeholder="Email"
            {...register('email', {
              required: 'An email address is required.',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Not a valid email.'
              }
            })}
            errors={errors.email}
          />
          <Button disabled={!isValid || !isDirty}>Send</Button>
        </div>
      </form>
    </Page>
  );
}

export default Admin;
