import { EventItem } from '../../models';

const TAILGATE: Partial<EventItem>[] = [
  {
    name: 'Finger Sandwiches',
    quantity: 1,
    customMeasurement: 'Platter'
  },
  {
    name: 'Buffalo Wings',
    quantity: 1,
    customMeasurement: 'Platter'
  },
  {
    name: 'Chips & Dip',
    quantity: 3,
    customMeasurement: 'Bags w/ bowls'
  },
  {
    name: 'Desserts',
    quantity: 3,
    customMeasurement: 'Plates (e.g., cookies, bars, etc)'
  },
  {
    name: 'Paper Towels',
    quantity: 3,
    customMeasurement: 'Rolls'
  },
  {
    name: 'BYO Chairs',
    note: 'If you want to be able to sit, bring chairs! :)'
  },
  {
    name: 'Table Cloths',
    quantity: 2
  }
];

const BAKE_SALE: Partial<EventItem>[] = [
  {
    name: 'Baked Goods',
    note:
      'Please bring baked goods individually wrapped or wrapped as a package. Cookies will be sold for $2, ' +
      'pies will be sold for $5, and cakes will be sold for $10. Please leave a note below specifying what you will ' +
      'bring so we can know what to expect.'
  },
  {
    name: 'Volunteers',
    note:
      'Please arrive an hour early to help with setup, and plan to stay at least ' +
      'an hour later for clean up. Specific assignments will be given upon arrival.',
    quantity: 5,
    customMeasurement: 'People'
  }
];

const THANKSGIVING: Partial<EventItem>[] = [
  {
    name: 'Appetizers',
    quantity: 4,
    customMeasurement: 'Dishes (i.e., stuffed mushrooms, pita & hummus, cheese balls, etc)',
    note: "Please leave a note with what specific dish you'll be bringing"
  },
  {
    name: 'Sides',
    quantity: 6,
    customMeasurement: 'Dishes (i.e., corn, mashed potatoes, green beans, etc)'
  },
  {
    name: 'Desserts',
    quantity: 6,
    customMeasurement: 'Dishes (i.e., pies, cookies, bars, etc)'
  }
];

const GENERAL_POTLUCK: Partial<EventItem>[] = [
  {
    name: 'Appetizers',
    quantity: 4,
    customMeasurement: 'Dishes (i.e., stuffed mushrooms, pita & hummus, cheese balls, etc)',
    note: "Please leave a note with what specific dish you'll be bringing"
  },
  {
    name: 'Main Dishes',
    quantity: 2,
    customMeasurement: 'Dishes (Turkey or Ham)'
  },
  {
    name: 'Sides',
    quantity: 6,
    customMeasurement: 'Dishes (i.e., corn, mashed potatoes, green beans, etc)'
  },
  {
    name: 'Desserts',
    quantity: 6,
    customMeasurement: 'Dishes (i.e., pies, cookies, bars, etc)'
  },
  {
    name: 'Essentials',
    note: 'We need paper plates, plastic cups, and napkins. Please leave a note below with which of these you can bring. Thank you!'
  }
];

const BLANK_SIGNUP: Partial<EventItem>[] = [
  {
    name: 'Bring a Dish',
    note: 'Please add a note below with what specific dish you will bring.'
  }
];

export { BAKE_SALE, BLANK_SIGNUP, GENERAL_POTLUCK, TAILGATE, THANKSGIVING };
