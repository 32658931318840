import styled from '@emotion/styled';
import { ComponentProps, forwardRef, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useIntersection } from 'react-use';
import tw from 'twin.macro';

import { useEventEnagementMetrics } from '../../hooks';
import { Event } from '../../models';
import { useUser } from '../../providers';
import { fadeInAnimation } from '../animations';
import { Card } from '../Card';
import { ChatIcon, Heart } from '../icons';

const MomentCard = styled(Card)`
  ${tw`flex flex-col w-full p-6 my-4 md:mr-8 space-y-8`}
  max-width: calc(100vw - 3rem);

  @media (min-width: 768px) {
    max-width: 21.25rem;
  }

  ${fadeInAnimation(0.3)}
`;

const CoverImage = styled.div<{ background?: string }>`
  ${tw`flex flex-col items-end justify-center p-4 bg-secondary-600 rounded-2xl bg-center bg-no-repeat bg-cover`}
  height: 7.5rem;

  background-image: ${(p) => p.background && `url(${p.background})`};
  transition: background 0.3 ease-in;

  ${(p) => p.background && fadeInAnimation(0.3)}
`;

type MomentSummaryProps = ComponentProps<'div'> & {
  event: Event;
};

const MomentLoader = forwardRef<HTMLDivElement, {}>((props, ref) => (
  <MomentCard {...props} ref={ref}>
    <div className="flex pt-1 space-x-4">
      <div className="flex-1 space-y-2">
        <div className="w-2/5 h-5 bg-neutral-stone" />
        <div className="w-full h-6 bg-neutral-stone" />
        <div className="w-2/5 h-4 bg-neutral-stone" />
      </div>
    </div>
    <div className="mt-4 rounded-2xl bg-neutral-stone" style={{ height: '7.5rem' }} />
  </MomentCard>
));

export function MomentSummary({ event, ...props }: MomentSummaryProps) {
  const { user } = useUser();

  const element = useRef<HTMLDivElement>(null);
  const intersection = useIntersection(element, { threshold: 0.1 });

  const { metrics } = useEventEnagementMetrics(event.id, !!intersection?.isIntersecting);

  if (!metrics || !user) {
    return <MomentLoader ref={element} />;
  }

  return (
    <MomentCard {...props}>
      <Link to={`/moments/${event.id}`} className="flex flex-col flex-1 text-neutral-soil">
        <div className="flex flex-col space-y-2">
          <div className="flex items-center justify-between space-x-4" ref={element}>
            <div>{event.organizer.id === user.id ? 'You organized' : 'You went to'}</div>
            <div className="flex space-x-4">
              <div className="flex items-center space-x-2">
                <Heart className="font-lg" />
                <div>{metrics?.reactions?.like || 0}</div>
              </div>
              <div className="flex items-center space-x-2">
                <ChatIcon className="font-xl" />
                <div>{metrics?.comments || 0}</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col overflow-hidden overflow-ellipsis whitespace-nowrap space-y-2">
            <div className="text-xl font-semibold">{event.name}</div>
            <div>
              {new Date(event.startTime!).toLocaleDateString('en', {
                dateStyle: 'long'
              })}
            </div>
          </div>
        </div>
        <div className="mt-4 rounded-2xl" style={{ height: '7.5rem' }}>
          <CoverImage background={event.coverImageUrl} />
        </div>
      </Link>
    </MomentCard>
  );
}
