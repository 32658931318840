import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ComponentProps, FC, useEffect, useRef, useState } from 'react';
import { useModal } from 'react-modal-hook';
import tw from 'twin.macro';

import { User } from '../../models';
import { fetcher } from '../../providers';
import { fadeInAnimation } from '../animations';
import { ImageDialog } from '../ImageEditor/ImageDialog';
import { Modal } from '../Modal';

type ProfilePictureProps = ComponentProps<'img'> & {
  user?: Pick<User, 'firstName' | 'lastName' | 'pictureUrl'> | null;
  editable?: boolean;
};

const Img = styled.img`
  ${tw`flex-shrink-0 rounded-full bg-secondary w-full h-full`}

  ${fadeInAnimation()}
`;

const Container = styled.div<{ uploading?: boolean; uploaded?: boolean }>`
  ${tw`relative flex w-10 h-10 overflow-hidden border rounded-full cursor-pointer transition-all bg-secondary-100 border-neutral-stone max-w-full max-h-full`}

  ${(p) => p.uploading && tw`animate-pulse`}
  ${(p) =>
    p.uploaded &&
    css`
      animation: 0.2s ping ease-out;
    `}

  :hover {
    div {
      ${tw`opacity-100`}
    }

    img {
      filter: brightness(0.75);
    }
  }
`;

const EditLabel = styled.div`
  ${tw`absolute bottom-0 z-10 flex items-center justify-center w-full text-base text-center md:text-xs opacity-40 bg-neutral-black text-neutral-white`}

  height: 30%;
`;

const Placeholder = styled.div`
  ${tw`flex items-center justify-center flex-shrink-0 w-full h-full capitalize rounded-full bg-secondary-100`}
`;

export const ProfilePicture: FC<ProfilePictureProps> = ({ user, editable, ...props }) => {
  const [src, setSrc] = useState(props.src || user?.pictureUrl);
  const [isUploading, setIsUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const fileInput = useRef<HTMLInputElement>(null);

  useEffect(() => setSrc(props.src || user?.pictureUrl), [props.src, user?.pictureUrl]);

  const upload = async (file?: File) => {
    if (!file) {
      return;
    }

    const src = URL.createObjectURL(file);

    setUploaded(false);
    setIsUploading(true);
    setSrc(src);

    const envelope = new FormData();
    envelope.set('profile-picture', file);

    fileInput.current!.value = '';
    openImageEditorModal();
  };

  // TODO: profile pic at top left of page needs to refresh upon upload

  const doTheUpload = async (image: File) => {
    closeImageEditorModal();
    setUploaded(false);
    setIsUploading(true);
    setSrc(URL.createObjectURL(image));

    const envelope = new FormData();
    envelope.set('profile-picture', image);

    await fetcher('/api/user/profile-picture', { method: 'POST', body: envelope }, true);

    setIsUploading(false);
    setUploaded(true);

    fileInput.current!.value = '';
  };

  const handleImageEditorModalDismiss = () => {
    closeImageEditorModal();
    setIsUploading(false);
    setUploaded(false);
    setSrc(user?.pictureUrl);
  };

  const [openImageEditorModal, closeImageEditorModal] = useModal(
    () => (
      <Modal onClose={() => handleImageEditorModalDismiss()} fullHeight>
        {() => <ImageDialog cropShape="round" onSave={doTheUpload} image={src!} />}
      </Modal>
    ),
    [src]
  );

  const Image = src ? (
    <Img alt="profile" {...(props as any)} src={src} />
  ) : (
    <Placeholder {...props}>
      {user?.firstName?.[0]}
      {user?.lastName?.[0]}
    </Placeholder>
  );

  if (!editable) {
    return <Container {...props}>{Image}</Container>;
  }

  return (
    <Container
      onClick={() => fileInput.current?.click()}
      {...props}
      uploading={isUploading}
      uploaded={uploaded}
    >
      {Image}
      {!isUploading && <EditLabel>Edit</EditLabel>}
      <input
        hidden
        value=""
        ref={fileInput}
        type="file"
        accept=".jpg,.png"
        onChange={(e) => upload(e.target.files?.[0])}
      />
    </Container>
  );
};

export default ProfilePicture;
