import styled from '@emotion/styled';
import { useModal } from 'react-modal-hook';
import tw from 'twin.macro';

import { FadeIn } from '../components/animations';
import { Button } from '../components/Button';
import { Input } from '../components/Input';
import { Modal } from '../components/Modal';
import { fetcher } from '../providers';

const Container = styled(FadeIn)<{ stretch?: boolean }>`
  ${tw`w-full mx-auto bg-neutral-white pb-12 flex-1`}

  ${(p) => !p.stretch && tw`rounded-br-3xl`}
`;

const QuantityInput = styled.div`
  ${tw`text-right pl-4`}

  max-width: 5rem;
`;

export function useGuestCount(
  eventId: number | undefined,
  userId: number | undefined,
  onChange: (value: number) => void,
  guestCount: number | undefined
) {
  const handleGuestCountUpdate = async () => {
    if (eventId && userId) {
      await fetcher(`/api/event/${eventId}/guests/${userId}/guest-count`, {
        method: 'POST',
        body: JSON.stringify({ guestCount: guestCount })
      });
    }

    closeGuestCount();
  };

  const [openGuestCount, closeGuestCount] = useModal(() => {
    return (
      <Modal fullWidth={false} onClose={closeGuestCount}>
        {() => (
          <div className="flex flex-col space-y-4">
            <Container className="flex justify-center">
              <div>
                <div className="text-lg text-left font-semibold">How many in your party?</div>
                <div className="text-neutral-ash italic text-left">Including you</div>
              </div>
              <QuantityInput>
                <Input
                  value={!!guestCount ? guestCount : 1}
                  onChange={(e) => {
                    onChange(Number(e.target.value));
                  }}
                  type="number"
                  min="1"
                  onFocus={(e) => e.target.select()}
                />
              </QuantityInput>
            </Container>
            <Button onClick={handleGuestCountUpdate}>Save</Button>
          </div>
        )}
      </Modal>
    );
  }, [guestCount, eventId]);

  return { closeGuestCount, openGuestCount };
}
