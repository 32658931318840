import styled from '@emotion/styled';
import { ComponentProps, FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Transition, TransitionStatus } from 'react-transition-group';
import tw from 'twin.macro';

import { ChevronLeft, Close } from '../icons';

const Container = styled.div``;

const Content = styled.div<{ transition?: TransitionStatus }>`
  ${tw`fixed top-0 right-0 z-40 flex flex-col h-full overflow-auto bg-neutral-white`}

  max-width: 30rem;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  transform: translateX(${(p) => (p.transition === 'entered' ? '0' : '100%')});
  transition: all 300ms ease-in-out;
`;

const MenuBackdrop = styled.div<{ transition?: TransitionStatus }>`
  ${tw`fixed top-0 left-0 z-40 w-full h-full bg-neutral-soil bg-opacity-60`}
  transition: all 300ms ease-in;

  opacity: ${(p) => (p.transition === 'entered' ? 1 : 0)};
`;

export type MenuProps = ComponentProps<'div'> & {
  open?: boolean;
  header?: ReactNode;
  onClose?: () => void;
  onGoBack?: () => void;
  displayBack?: boolean;
  displayBackToDashboard?: boolean;
  displayClose?: boolean;
  goBackOnClose?: boolean;
};

export const Menu: FC<MenuProps> = ({
  header,
  children,
  open,
  displayBack,
  displayBackToDashboard = false,
  displayClose = true,
  onClose,
  onGoBack,
  goBackOnClose,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(open ?? false);
  const container = useRef<HTMLDivElement>(null);
  const router = useHistory();

  useEffect(() => setIsOpen(open ?? true), [open]);
  const close = useCallback(() => {
    setIsOpen(false);
    goBackOnClose ? router.goBack() : router.push('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Transition
      in={isOpen}
      timeout={300}
      nodeRef={container}
      unmountOnExit
      onExit={() => setTimeout(() => onClose?.(), 300)}
    >
      {(transitionStatus) => (
        <Container ref={container} {...props}>
          <MenuBackdrop onClick={close} transition={transitionStatus} />
          <Content className={`menu ${props.id}-menu`} transition={transitionStatus}>
            <div className="z-10 flex px-8 pt-8 pb-4 justify-center">
              {displayBack && (
                <ChevronLeft className="text-2xl cursor-pointer mt-1" onClick={onGoBack} />
              )}
              <div className="flex flex-1">{header}</div>
              {displayClose && <Close className="text-2xl cursor-pointer" onClick={close} />}
              {displayBackToDashboard && (
                <div
                  onClick={() => router.push('/')}
                  className="text-sm text-interaction cursor-pointer mt-1"
                >
                  Back to Dashboard
                </div>
              )}
            </div>
            {typeof children === 'function' ? children({ close }) : children}
          </Content>
        </Container>
      )}
    </Transition>
  );
};

export default Menu;
