import * as React from 'react';

function CircleChatWithNotification(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#F8F6F1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 9C8 8.44772 8.44772 8 9 8H15C15.5523 8 16 8.44772 16 9V13.1348C16 13.687 15.5523 14.1348 15 14.1348H9.9095L8 16V9Z"
        stroke="#4C69BF"
      />
      <path d="M9.78467 11.7668H11.918" stroke="#4C69BF" />
      <path d="M9.78467 9.90155H14.0513" stroke="#4C69BF" />
      <circle cx="16" cy="7" r="3" fill="#4C69BF" />
    </svg>
  );
}

export default CircleChatWithNotification;
