import * as Sentry from '@sentry/browser';
import {
  createContext,
  Dispatch,
  ProviderProps,
  SetStateAction,
  useContext,
  useState
} from 'react';
import { useHistory } from 'react-router-dom';
import { useAsync, useUpdateEffect } from 'react-use';

import { Loader } from '../components';
import { SubscriptionType, User } from '../models';
import { fetcher } from '../providers';
import { usePotluck } from './PotluckProvider';

const noOp = async (): Promise<any> => void {};

export interface UserContextProps {
  user?: User | null;
  getUser: () => Promise<User | null>;
  setUser: Dispatch<SetStateAction<User | null | undefined>>;
  signUp: (
    user: User,
    code?: string,
    redirectUrl?: string,
    eventId?: string,
    rsvpResponse?: string,
    guestCount?: number
  ) => Promise<void>;
  signIn: (email: string, password: string) => Promise<void>;
  signInPasswordless: (email: string) => Promise<void>;
  signOut: () => Promise<void>;
  update: (user: User) => Promise<void>;
  checkout: (
    subscription: SubscriptionType,
    redirectUrl?: string,
    eventId?: number
  ) => Promise<void>;
  deactivate: () => Promise<void>;
  sendResetPasswordCode: (identifier: string) => Promise<void>;
  resetPassword: (password: string, code: string) => Promise<void>;
  hasPaidSingleEvent?: Boolean;
  setHasPaidSingleEvent: Dispatch<SetStateAction<Boolean>>;
  isPasswordRequiredForUser: (identity: string) => Promise<Boolean>;
}

const UserContext = createContext<UserContextProps>({
  user: undefined,
  getUser: noOp,
  setUser: noOp,
  signUp: noOp,
  signIn: noOp,
  signInPasswordless: noOp,
  signOut: noOp,
  update: noOp,
  checkout: noOp,
  deactivate: noOp,
  sendResetPasswordCode: noOp,
  resetPassword: noOp,
  hasPaidSingleEvent: undefined,
  setHasPaidSingleEvent: noOp,
  isPasswordRequiredForUser: noOp
});

const API = `/api/user`;

export function UserProvider({ children }: Omit<ProviderProps<UserContextProps>, 'value'>) {
  const [user, setUser] = useState<undefined | null | User>(undefined);
  const [hasPaidSingleEvent, setHasPaidSingleEvent] = useState<Boolean>(false);
  const { clear } = usePotluck();
  const router = useHistory();

  async function getUser(): Promise<User | null> {
    try {
      return await fetcher(API);
    } catch {
      return null;
    }
  }

  async function getUserHasPaidSingleEvent() {
    const result = await fetcher(`/api/user/has-paid-single-event`);
    setHasPaidSingleEvent(result === 'True');
  }

  useAsync(async () => {
    const user = await getUser();

    try {
      Sentry.setUser({ id: user?.id, email: user?.email });
    } catch {
      // try/catch, don't wanna risk breaking a high traffic function for sentry
    }

    setUser(user);
    await getUserHasPaidSingleEvent();

    // bring back when we decide to use verification on email/phone
    // if (user?.id && !user?.verifiedAt) {
    //   router.push(`/verify-email${window.location.search}`);
    // }
  }, []);

  useUpdateEffect(() => {
    if (
      !user &&
      !router.location.pathname.includes('/sign-') &&
      !router.location.pathname.includes('reset-password') &&
      !router.location.pathname.includes('/event/')
    ) {
      router.push('/sign-in');
    }
  }, [user, router]);

  async function signUp(
    user: User,
    code?: string,
    redirectUrl?: string,
    eventId?: string,
    rsvpResponse?: string,
    guestCount?: number
  ) {
    await fetcher(`${API}/sign-out`);

    if (!user.phoneNumber) {
      delete user.phoneNumber;
    }

    if (!user.email) {
      delete user.email;
    }

    const queryPrams = new URLSearchParams();
    if (code) {
      queryPrams.set('code', code);
    }
    if (redirectUrl) {
      queryPrams.set('redirect-url', redirectUrl);
    }
    if (eventId && rsvpResponse) {
      queryPrams.set('auto-verify', 'true');
    }
    if (guestCount) {
      queryPrams.set('guest-count', guestCount.toString());
    }

    await fetcher(`${API}/sign-up?${queryPrams}`, {
      method: 'POST',
      body: JSON.stringify(user)
    });

    const identity = user.phoneNumber ? user.phoneNumber : user.email;
    if (!identity) return;

    await signInPasswordless(identity);

    if (eventId && rsvpResponse) {
      await fetcher(`/api/event/${eventId}/rsvp`, {
        method: 'POST',
        body: JSON.stringify({ guestCount: guestCount, response: rsvpResponse })
      });
    }
  }

  async function signIn(identity: string, password: string) {
    const user = await fetcher(`${API}/sign-in`, {
      method: 'POST',
      body: JSON.stringify({ identity, password })
    });

    setUser(user);

    // bring back when we decide to use verification on email/phone
    // if (user?.id && !user?.verifiedAt) {
    //   router.push(`/verify-email${window.location.search}`);
    // }
  }

  async function signInPasswordless(identity: string) {
    if (!identity) {
      return;
    }

    const user = await fetcher(`${API}/sign-in`, {
      method: 'POST',
      body: JSON.stringify({ identity })
    });

    setUser(user);

    // bring back when we decide to use verification on email/phone
    // if (user?.id && !user?.verifiedAt) {
    //   router.push(`/verify-email${window.location.search}`);
    // }
  }

  async function signOut() {
    await fetcher(`${API}/sign-out`, { method: 'POST' });
    setUser(null);
    clear();

    router.push('/sign-in');
  }

  async function update(updates: Partial<User>) {
    delete updates.id;

    setUser(
      await fetcher(API, {
        method: 'PUT',
        body: JSON.stringify(updates)
      })
    );
  }

  async function uploadProfilePicture(file: File) {
    const envelope = new FormData();
    envelope.set('profile-picture', file);

    const user = await fetcher(`${API}/profile-picture`, { method: 'POST', body: envelope }, true);

    setUser(user);
  }

  async function deactivate() {
    await fetcher(API, { method: 'DELETE' });
    await fetcher(`${API}/sign-out`);
  }

  async function checkout(subscription: SubscriptionType, redirectUrl?: string, eventId?: number) {
    let url = eventId
      ? `${API}/checkout/${subscription.toLowerCase()}/${eventId}`
      : `${API}/checkout/${subscription.toLowerCase()}`;

    url = `${url}?redirect_url=${redirectUrl}`;

    window.location.href = await fetcher(url);
  }

  async function sendResetPasswordCode(identifier: string) {
    await fetcher(`${API}/reset-password/send-code/${identifier}`);
  }

  async function resetPassword(password: string, code: string) {
    const user = await fetcher(`${API}/reset-password?code=${code}`, {
      method: 'POST',
      body: JSON.stringify({ password })
    });

    setUser(user);
  }

  async function isPasswordRequiredForUser(identity: string) {
    const result = await fetcher(`${API}/password-requirement-check`, {
      method: 'POST',
      body: JSON.stringify({ identity: identity })
    });
    return JSON.parse(result);
  }

  if (user === undefined) {
    return <Loader>Loading...</Loader>;
  }

  if (
    user &&
    !user.id &&
    !window.location.pathname.startsWith('/event') &&
    !window.location.pathname.startsWith('/sign')
  ) {
    router.push('/sign-in');

    return <></>;
  }

  const context = {
    user,
    getUser,
    setUser,
    signUp,
    signIn,
    signInPasswordless,
    signOut,
    update,
    checkout,
    uploadProfilePicture,
    deactivate,
    sendResetPasswordCode,
    resetPassword,
    hasPaidSingleEvent,
    setHasPaidSingleEvent,
    isPasswordRequiredForUser
  };

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>;
}

export const useUser = () => useContext(UserContext);

export default useUser;
