import styled from '@emotion/styled';
import { ComponentProps, FC } from 'react';
import { NavLink } from 'react-router-dom';
import tw from 'twin.macro';

import { CircleButton } from '../Button';
import { Card } from '../Card';
import { Add, Moments, Planning } from '../icons';

const Container = styled.div`
  ${tw`w-full left-0`}
`;

const Bar = styled(Card)`
  ${tw`relative flex items-center justify-between w-full px-16 py-0`}
  font-size: 2rem;
  box-shadow: 1px -3px 51px -18px rgba(0, 0, 0, 0.65);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

const Action = styled.div`
  ${tw`relative flex items-center justify-center h-full py-8`}
`;

const Link = styled(NavLink)`
  &.active::after {
    ${tw`absolute bottom-0 flex bg-secondary`}

    content: ' ';
    width: 150%;
    left: -25%;
    height: 10%;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
`;

export const ActionBar: FC<ComponentProps<'div'>> = (props) => (
  <Container {...props}>
    <Bar>
      <Action>
        <Link exact to="/">
          <Planning />
        </Link>
      </Action>
      <NavLink to="/create/details">
        <CircleButton>
          <Add />
        </CircleButton>
      </NavLink>
      <Action>
        <Link to="/moments">
          <Moments />
        </Link>
      </Action>
    </Bar>
  </Container>
);

export default ActionBar;
