import { makeAutoObservable, runInAction } from 'mobx';

import { SubscriptionType, User } from '../models';
import { fetcher } from '../providers';
import LazyStore from './Lazy';

export class UserStore {
  user?: User | null;
  connections = new LazyStore<User>('user/connections', 10);

  constructor() {
    makeAutoObservable(this);
  }

  async signUp(user: User) {
    await fetcher('/api/user/sign-out');

    await fetcher('/api/user/sign-up', {
      method: 'POST',
      body: JSON.stringify(user)
    });

    await this.signIn(user);
  }

  async signIn(user: User) {
    runInAction(() => (this.user = null));

    const signIn = await fetcher('/api/user/sign-in', {
      method: 'POST',
      body: JSON.stringify({
        identity: user.phoneNumber ? user.phoneNumber : user.email,
        password: user.password
      })
    });

    runInAction(() => (this.user = signIn));
  }

  async signOut() {
    await fetcher('/api/user/sign-out', { method: 'POST' });
    runInAction(() => (this.user = undefined));
  }

  async update(updates: Partial<User>) {
    delete updates.id;

    runInAction(() => (this.user = { ...(this.user || {}), ...(updates as User) }));

    const user = await fetcher('/api/user', {
      method: 'PUT',
      body: JSON.stringify(updates)
    });

    runInAction(() => (this.user = user));
  }


  async uploadProfilePicture(file: File) {
    const envelope = new FormData();
    envelope.set('profile-picture', file);

    const user = await fetcher(
      '/api/user/profile-picture',
      {
        method: 'POST',
        body: envelope
      },
      true
    );

    runInAction(() => (this.user = user));
  }

  async deactivate() {
    await fetcher('/api/user', { method: 'DELETE' });
    await fetcher('/api/user/sign-out');

    runInAction(() => (this.user = undefined));
  }

  async checkout(subscription: SubscriptionType) {
    const url = await fetcher(`/api/user/checkout/${subscription}`);
    window.location.href = url;
  }

  async removeConnection(connection: User) {
    await fetcher(`/api/user/connections/${connection.id}`, { method: 'DELETE' });
    this.connections.data = this.connections.data?.filter((c) => c.id !== connection.id);
  }
}

export default UserStore;
