import { FC, ReactNode } from 'react';
import { SWRConfig } from 'swr';

export function getCookie(name: string) {
  const nameLength = name.length + 1;
  return (
    document.cookie
      .split(';')
      .map((c) => c.trim())
      .filter((cookie) => {
        return cookie.substring(0, nameLength) === `${name}=`;
      })
      .map((cookie) => {
        return decodeURIComponent(cookie.substring(nameLength));
      })[0] || null
  );
}

async function request(
  resource: RequestInfo,
  init?: RequestInit,
  suppressContentTypeHeader?: boolean
) {
  const headers: any = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(init?.headers || {}),
    'X-CSRF-TOKEN': getCookie('csrf_access_token') || ''
  };

  if (suppressContentTypeHeader) {
    delete headers['Content-Type'];
  }

  return await fetch(resource, {
    ...(init || {}),
    headers
  });
}

async function deserialize(response: Response) {
  const contentType = response.headers.get('Content-Type');

  if (contentType && contentType !== 'application/json') {
    return await response.text();
  }

  return await response.json();
}

export async function fetcher(
  resource: RequestInfo,
  init?: RequestInit,
  suppressContentTypeHeader?: boolean
) {
  let response = await request(resource, init, suppressContentTypeHeader);

  if (response.redirected) {
    window.location.href = response.url;
    return;
  }

  if (response.status === 401) {
    // Refresh token and retry
    const refresh = await fetch('/api/user/refresh');
    if (refresh.status !== 401) {
      response = await request(resource, init);
      return await deserialize(response);
    }

    // Redirect to sign in after attempting to refresh
    if (
      !window.location.href.includes('/sign-') &&
      !window.location.href.includes('reset-password') &&
      !window.location.href.includes('event')
    ) {
      window.location.href = `/sign-in?redirect-url=${encodeURIComponent(
        window.location.pathname
      )}`;

      return;
    }

    throw await deserialize(response);
  }

  if (response.status === 403) {
    window.location.href = '/forbidden';
  }

  if (response.status === 404) {
    window.location.href = '/not-found';
  }

  if (response.status >= 400) {
    throw response;
  }

  return await deserialize(response);
}

export const ApiConnectionProvider: FC<ReactNode> = ({ children }) => {
  return <SWRConfig value={{ fetcher }}>{children}</SWRConfig>;
};
