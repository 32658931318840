import { css } from '@emotion/react';
import styled from '@emotion/styled';
import tw from 'twin.macro';

import SvgLogo from './Logo';

export default styled(SvgLogo)<{ large?: string }>`
  ${tw`self-center`}
  height: 2rem;

  ${(p) =>
    p.large
      ? css`
          width: 12rem;
        `
      : css`
          width: 7rem;
        `}
`;
