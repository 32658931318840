import * as React from 'react';

function SvgDrinks(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        stroke="#070401"
        strokeWidth={1.54}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M12.075 20H9.934C6.657 20 4 17.378 4 14.144V5h14.01v9.144c0 3.234-2.657 5.856-5.935 5.856z"
          fill="#DDDDDC"
        />
        <path d="M20.084 6.319h-2.043l.011 9.273c1.711-.55 2.948-2.137 2.948-4.01v-4.36a.91.91 0 00-.916-.903z" />
      </g>
    </svg>
  );
}

export default SvgDrinks;
