import { Link } from 'react-router-dom';

import { BrandLogo, Button, Card, PollCreateScene, SignupScene } from '../../components';
import { Page } from '../Page';

interface InviteDeclinedProps {
  message: string;
}

export function InviteDeclined({ message }: InviteDeclinedProps) {
  return (
    <Page className="flex flex-col items-center self-center h-full max-w-xl pt-8 mx-auto space-y-20">
      <Card className="z-20 max-w-xl space-y-8">
        <PollCreateScene />
        <div className="text-xl font-semibold">{message}</div>
        <div>Have any planning needs of your own coming up?</div>
        <div className="flex justify-center pb-4">
          <Link to="/">
            <Button>Try Potluck</Button>
          </Link>
        </div>
      </Card>
      <BrandLogo className="z-10" />
      <SignupScene className="absolute bottom-0" />
    </Page>
  );
}

export default InviteDeclined;
