import * as React from 'react';

function SvgChat(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd" stroke="#070401" strokeWidth={1.54}>
        <path d="M5.654 3.422h13a1 1 0 011 1v11.156a1 1 0 01-1 1H8.234h0l-3.58 4V4.422a1 1 0 011-1zM8 11.5h4M8 7.5h8" />
      </g>
    </svg>
  );
}

export default SvgChat;
