import * as React from 'react';

function SvgMemberCreateScene(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 130 138" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g transform="translate(1)" fill="none" fillRule="evenodd">
        <circle stroke="#000" strokeWidth={1.6} fill="#F5F5F6" cx={74} cy={54.8} r={54} />
        <g transform="translate(0 5.6)">
          <circle fill="#000" fillRule="nonzero" cx={20.489} cy={19.437} r={2.88} />
          <path fill="#3EC1F3" fillRule="nonzero" d="M4.679 41.76l-.067-.066v.135z" />
          <path
            stroke="#F5EBDC"
            strokeWidth={1.024}
            strokeLinecap="round"
            d="M28.749 55.075l.866 6.496"
          />
          <path
            d="M97.473 45.657s13.01-9.743 2.184-19.486c-6.617-5.954-14.565-4.296-14.565-4.296.301-3.624.905-12.99-6.948-18.728C70.291-2.591 60.321.43 56.696 4.959c-3.624 4.53-2.717 12.99-3.925 19.031-1.208 6.041-.303 9.96 3.321 12.084 3.625 2.124 7.855.604 7.855.604l-.907 5.437s-7.119.944-12.965 3.542C43.45 48.602 28.64 56.699 28.64 56.699c-.223-.78-1.124-6.028-2.383-12.841-1.185-6.412-2.72-14.297-3.293-18.442-.844-6.1 1.405-10.87-1.075-11.933-2.48-1.062-3.822 2.433-3.822 2.433s-1.185 2.559-4.353 8.074c-.726 1.265-3.112 2.642-7.158 4.13 0 0-5.458 39.346 7.578 50.014 9.527 7.794 34.426-4.763 34.426-4.763 0 5.037-.722 10.462-2.165 16.273-1.444 5.812 8.099 8.254 28.627 7.327 0 0-5.57-2.52-17.152 2.712-13.424 6.062-21.219 22.95-20.352 24.25.866 1.298 3.464.216 6.928-4.115-3.031 11.26 0 10.393 1.949 10.176 1.98 2.85 4.546-.65 4.546-.65s3.465 3.249 8.877-11.907a26.287 26.287 0 006.712-4.547s34.426-2.165 41.138-14.29c6.712-12.125-1.102-41.468-10.195-52.943z"
            stroke="#000"
            strokeWidth={1.024}
            fill="#FDFDFD"
            fillRule="nonzero"
          />
          <circle fill="#000" fillRule="nonzero" cx={55.737} cy={20.357} r={1} />
          <path
            d="M68.94 107.547l.557 1.245 3.334 3.472c10.263-1.269 29.937-4.81 34.837-13.664 6.712-12.125 2.165-39.736-6.929-51.21-7.577 8.333-19.295 6.061-23.994-.867a16.55 16.55 0 01-2.637.433 15.84 15.84 0 01-4.157-.164 10.661 10.661 0 01-4.547-1.917c-1.712-1.288-2.338-2.682-2.338-2.682l-.29-.041c-1.33.197-7.515 1.2-12.7 3.505C43.45 48.602 28.64 56.699 28.64 56.699c-.194-.68-.781-6.034-1.467-12.45l-8.41 3.248-.911.353-4.35 1.685-7.91 3.061C6.16 62.79 8.289 73.35 14.135 78.134c9.527 7.794 34.426-4.763 34.426-4.763l-2.92 22.262c3.748 1.978 7.16 3.529 10.234 4.653.695.254 9.222-3.786 10.052-3.97 1.792-.398 11.33-2.903 28.614-7.516 0 0-22.564 4.983-28.828 7.516l1.442 7.228 1.786 4.003z"
            stroke="#000"
            strokeWidth={1.024}
            fill="#EBEBED"
            fillRule="nonzero"
          />
          <path
            stroke="#000"
            strokeWidth={1.024}
            strokeLinecap="round"
            d="M86.452 69.755L88.5 89.9M44.334 120.587c.861-1.837 2.685-5.609 4.212-8M51.2 115.587c-2.772 5.359-4.398 11.468-5 14"
          />
          <path
            d="M55.874 115.587c-1.95 4.056-3.655 9.593-4.612 13"
            stroke="#30242A"
            strokeWidth={1.024}
            strokeLinecap="round"
          />
          <path
            stroke="#000"
            strokeWidth={1.024}
            strokeLinecap="round"
            d="M50.059 65.674L48.4 74"
          />
          <path
            d="M99.657 26.17c-6.617-5.953-14.565-4.295-14.565-4.295.301-3.624.905-12.99-6.948-18.728C70.291-2.591 60.321.43 56.696 4.959c-1.476 1.847-2.202 4.348-2.624 7.07 2.412.406 5.948-.423 5.948-.423 4.832 5.437 9.96 6.645 9.96 6.645 3.323-3.625 9.364-3.625 10.27 1.515 1.003 5.684-6.042 7.25-6.042 7.25s-1.494 8.099.974 16.264c.352 1.154.876 2.25 1.555 3.247 4.698 6.929 16.418 9.202 23.994.866.375-.461.722-.944 1.041-1.446 2.194-3.388 6.396-12.116-2.115-19.776z"
            stroke="#000"
            strokeWidth={0.5}
            fill="#000"
            fillRule="nonzero"
          />
          <path
            d="M63.915 37.061c1.4-.41 2.75-.976 4.023-1.688"
            stroke="#30242A"
            strokeWidth={1.024}
            strokeLinecap="round"
          />
          <path
            stroke="#000"
            strokeWidth={1.024}
            strokeLinecap="round"
            d="M73.325 22.154l2.695-1.498M58.004 29.182c.496.31 2.351.55 3.105-.876"
          />
          <circle fill="#000" fillRule="nonzero" cx={62.884} cy={21.548} r={1} />
          <path
            d="M57.894 23.737c-.139.598-.254 1.25-.158 1.472.129.27.346.486.614.615"
            stroke="#000"
            strokeWidth={1.024}
            strokeLinecap="round"
          />
          <ellipse
            stroke="#000"
            strokeWidth={1.024}
            strokeLinecap="round"
            transform="rotate(-76.95 53.74 20.317)"
            cx={53.74}
            cy={20.317}
            rx={4.33}
            ry={3.897}
          />
          <ellipse
            stroke="#000"
            strokeWidth={1.024}
            strokeLinecap="round"
            transform="rotate(-76.95 63.268 21.832)"
            cx={63.268}
            cy={21.832}
            rx={4.33}
            ry={3.897}
          />
          <path
            stroke="#000"
            strokeWidth={1.024}
            strokeLinecap="round"
            d="M57.762 20.866l1.515.217M13.679 20.258s2.705 3.006 3.253 3.794c0 0-5.996 6.446.587 14.126"
          />
          <g stroke="#000" strokeWidth={1.024}>
            <path
              d="M16.29 19.755c-1.681-1.075-5.366-2.9-6.653-1.869-2.174 1.742-1.206 4.396-1.206 4.396s-3.944-.058-3.34 3.89c0 0-2.916 1.795-1.12 4.71 0 0-2.594 1.058-.85 4.19C4.54 37.623 7.138 38.7 8.7 39.225c1.376.463 3.218-.743 2.18-3.149 0 0 1.562.218 2.299-.76 1.017-1.35.16-3.521.16-3.521s1.562.218 2.513-.79c1.161-1.23.222-3.092.222-3.092a2.693 2.693 0 001.903-2.017c.556-2.265-3.022-4.386-3.022-4.386l1.334-1.756z"
              fill="#FDFDFD"
              fillRule="nonzero"
            />
            <path
              d="M3.831 31.027a52.507 52.507 0 007.11 5.479M5.113 26.148a44.665 44.665 0 008.536 6.189M8.1 22.512a31.708 31.708 0 008.19 5.861"
              strokeLinecap="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgMemberCreateScene;
