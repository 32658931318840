import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './DatePicker.css';

import { forwardRef } from 'react';
import { DayPickerRangeController, DayPickerRangeControllerShape } from 'react-dates';

export const DatePicker = forwardRef((props: DayPickerRangeControllerShape, ref) => (
  <DayPickerRangeController {...props} ref={ref as any} />
));
