import styled from '@emotion/styled';
import { useCallback, useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import tw from 'twin.macro';

import { BrandLogo } from '../icons';
import Banner from './Banner';
import Items from './Items';
import Menu from './Menu';

const DesktopMenu = styled.div`
  ${tw`z-10 flex-col justify-between hidden h-full py-6 overflow-hidden lg:flex bg-neutral-white lg:py-16 lg:w-72 lg:fixed`}

  @media (min-width: 1024px) {
    min-height: 50rem;
  }
`;

const Anchor = styled.div`
  ${tw`hidden lg:block lg:mr-72`}
`;

export const MobileMenu = styled(Menu)`
  ${tw`lg:hidden`}

  *:last-of-type {
    ${tw`max-w-xs`}
  }
`;

export function MainMenu() {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(window.innerWidth >= 1024);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  // Dismiss menu on navigation.
  useEffect(() => {
    if (window.innerWidth >= 1024) {
      return;
    }

    close();
  }, [location, close]);

  useEffect(() => {
    const syncMenuWithScreenSize = () => {
      const canOpen = window.innerWidth < 1024;

      // always open on larger screens
      if (!canOpen) {
        setIsOpen(true);
      }

      if (canOpen && isOpen) {
        setIsOpen(false);
      }
    };

    window.addEventListener('resize', syncMenuWithScreenSize);

    return () => window.removeEventListener('resize', syncMenuWithScreenSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Switch>
        <Route>
          <Anchor />
          <Banner onClick={open} mobile logo />
          <DesktopMenu>
            <Items />
            <BrandLogo className="hidden lg:block" />
          </DesktopMenu>
        </Route>
        <Route>
          <Banner onClick={open} className="fixed top-0 m-auto" logo />
        </Route>
      </Switch>

      <MobileMenu open={isOpen} onClose={close}>
        <Items />
      </MobileMenu>
    </>
  );
}

export default MainMenu;
