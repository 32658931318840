import styled from '@emotion/styled';
import { ComponentProps } from 'react';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';

import { BrandLogo, MenuIcon } from '../icons';

const BannerContainer = styled.div<{ mobile?: boolean }>`
  ${tw`sticky z-20 flex items-center self-stretch justify-between w-full p-6`}

  ${(p) => p.mobile && tw`lg:hidden`}
`;

export type BannerProps = { mobile?: boolean; disabled?: boolean; logo?: boolean };

export const Banner = ({
  onClick,
  disabled,
  children,
  ...props
}: ComponentProps<'div'> & BannerProps) => (
  <BannerContainer
    {...props}
    className={`${props.className} ${disabled ? 'pointer-events-none' : ''}`}
  >
    {props.logo && (
      <Link to="/" className="logo">
        <BrandLogo />
      </Link>
    )}
    {children}
    <div onClick={onClick} className={disabled ? 'invisible menu-icon' : 'ml-auto menu-icon'}>
      <MenuIcon className="text-2xl cursor-pointer" />
    </div>
  </BannerContainer>
);

export default Banner;
