import { css, keyframes } from '@emotion/react';

export const slideOut = keyframes`
  0% {
    transform: translateX(0);
  }

  50% {
    opacity: 1;
  }
  
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
`;

export const slideOutAnimation = (time?: number) => css`
  animation: ${slideOut} ${time || 1.5}s cubic-bezier(0.4, 0, 0.2, 1);
`;
