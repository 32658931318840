import * as React from 'react';

function SvgLocation(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        transform="translate(6 3)"
        stroke="#070401"
        strokeWidth={1.54}
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M6 0C2.692 0 0 2.595 0 5.784c0 5.499 5.175 11.676 5.398 11.937a.788.788 0 001.204.002C6.822 17.464 12 11.337 12 5.784 12 2.595 9.308 0 6 0"
          fill="#DDDDDC"
        />
        <circle fill="#ECECEC" cx={6} cy={6.75} r={3} />
      </g>
    </svg>
  );
}

export default SvgLocation;
