import * as React from 'react';

function SvgSnacks(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd" stroke="#070401">
        <g transform="rotate(28 .484 9.5)">
          <path
            d="M0 3.337C1.978 2.065 3.998 1.159 6.058.619c2.061-.54 4.21-.727 6.45-.56.544 2.098.637 4.555.279 7.372A77.345 77.345 0 0111.19 16c-4.241-3.21-7.145-5.572-8.712-7.088C.913 7.396.086 5.538 0 3.337z"
            strokeWidth={1.54}
            fill="#F0EBE0"
            strokeLinejoin="round"
          />
          <circle cx={4.65} cy={5.586} r={1} />
          <circle cx={9.026} cy={10.621} r={1} />
        </g>
        <path
          d="M7.435 11.52c2.146-1.355 4.336-2.32 6.57-2.896 2.236-.575 4.567-.774 6.995-.596-.006 1.778-.28 3.888-.82 6.33a50.285 50.285 0 01-2.174 7.208c-2.394-1.387-4.44-2.888-6.14-4.503a26.435 26.435 0 01-4.43-5.543z"
          strokeWidth={1.54}
          fill="#F8F6F1"
          strokeLinejoin="round"
        />
        <circle cx={14} cy={13} r={1} />
        <circle cx={16.5} cy={16.5} r={1} />
      </g>
    </svg>
  );
}

export default SvgSnacks;
