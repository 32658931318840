import styled from '@emotion/styled';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, Redirect } from 'react-router-dom';
import tw from 'twin.macro';

import { Button, FadeIn, Input } from '../../components';
import { useUser } from '../../providers';
import { PublicPage } from '../Page';

const Form = styled.form`
  ${tw`flex flex-col items-center max-w-sm mx-auto space-y-14`}
`;

const FormBody = styled.div`
  ${tw`flex flex-col space-y-10`}
`;

export function SendResetPasswordCode() {
  const { sendResetPasswordCode, user } = useUser();
  const {
    handleSubmit,
    register,
    setError,
    formState: { isValid, isDirty, errors }
  } = useForm({ defaultValues: { identifier: '' }, mode: 'all' });

  const [hasSubmitted, setHasSubmitted] = useState(false);

  if (user) {
    const redirectUrl = new URLSearchParams(window.location.search).get('redirect-url') || '/';
    return <Redirect to={{ pathname: redirectUrl }} />;
  }

  let body = (
    <>
      <p>
        Enter the email or phone number associated with your account to receive an email with
        further instructions for resetting your password.
      </p>
      <Input
        {...register('identifier', {
          required: 'An email or phone number is required.',
          validate: {
            pattern: (value) =>
              !!(
                value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) ||
                value.match(/^(\d{10}|\d{12})$/)
              ) || 'Not a valid email or phone number.'
          }
        })}
        errors={errors.identifier}
      />
      <Button disabled={!isDirty || !isValid}>Submit</Button>
    </>
  );

  if (hasSubmitted) {
    body = (
      <FadeIn className="space-y-4">
        <p>Thank you for requesting to reset your password.</p>
        <p>
          If an account is associated with that email address you should receive an email shortly
          with instructions to reset your password.
        </p>
      </FadeIn>
    );
  }

  return (
    <PublicPage footer={<Link to="/sign-in">Sign in</Link>}>
      <Form
        onSubmit={handleSubmit(async (f) => {
          try {
            await sendResetPasswordCode(f.identifier);
            setHasSubmitted(true);
          } catch (e: any) {
            const result = await e.json();
            setError('identifier', { message: result.error });
          }
        })}
      >
        <FormBody>{body}</FormBody>
      </Form>
    </PublicPage>
  );
}

export default SendResetPasswordCode;
