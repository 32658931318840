import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useModal } from 'react-modal-hook';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';

import { Button, Card, fadeInAnimation, Loader, Modal, UserSummary } from '../../components';
import { User } from '../../models';
import { usePotluck } from '../../providers';

const Container = styled.div`
  ${tw`flex flex-col mb-20 space-y-8 mb-8`}

  @media (min-width: 1024px) {
    max-width: 21.375rem;
  }

  ${fadeInAnimation()}
`;

const Header = styled.div`
  ${tw`font-semibold`}

  font-size: 1.375rem;
`;

export const Connections = observer(() => {
  const [target, setTarget] = useState<User>();

  const { user } = usePotluck();

  const [openConfirmRemoveConnection, closeConfirmRemoveConnection] = useModal(
    () => (
      <Modal onClose={closeConfirmRemoveConnection}>
        {({ close }) =>
          target && (
            <div className="flex flex-col space-y-4">
              <b>
                Are you sure you want to remove {target.firstName} {target.lastName} as a
                connection?
              </b>
              <div className="flex flex-col justify-evenly sm:flex-row">
                <Button
                  onClick={() => {
                    user.removeConnection(target);
                    close();
                  }}
                >
                  Remove Connection
                </Button>
                <Button className="mt-2" outline onClick={close}>
                  I'll keep them around
                </Button>
              </div>
            </div>
          )
        }
      </Modal>
    ),
    [target, user.removeConnection]
  );

  if (!user.connections.loading && user.connections.data?.length === 0) {
    return (
      <Container>
        <Header className="hidden text-6xl font-semibold text-center lg:block">Connections</Header>
        <p>
          You don't have any connections yet! Plan an event and invite some friends. They
          automatically become a connection when they accept your invitation.
        </p>
        <Link to="/create/details">
          <Button outline>Plan Something</Button>
        </Link>
      </Container>
    );
  }

  return (
    <Container>
      <Card ref={user.connections.register}>
        <Header>Connections</Header>
        {user.connections.data?.map((connection) => (
          <UserSummary key={connection.id} user={connection} className="py-6">
            <div
              className="cursor-pointer select-none text-interaction"
              onClick={() => {
                setTarget(connection);
                openConfirmRemoveConnection();
              }}
            >
              Remove
            </div>
          </UserSummary>
        ))}
        {user.connections.loading && <Loader />}
      </Card>
    </Container>
  );
});

export default Connections;
