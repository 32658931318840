import styled from '@emotion/styled';
import tw from 'twin.macro';

export const Divider = styled.div`
  ${tw`w-full mt-8 bg-neutral-stone`}

  height: 0.0625rem;
`;

export default Divider;
