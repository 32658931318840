import * as React from 'react';

function SvgThumbsUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="129"
      height="138"
      viewBox="0 0 129 138"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="74" cy="54.8" r="54" fill="white" stroke="black" strokeWidth="1.6" />
      <circle cx="20.4892" cy="25.0367" r="2.88" fill="black" />
      <path d="M4.67899 47.3592L4.61187 47.2943V47.4285L4.67899 47.3592Z" fill="#3EC1F3" />
      <path
        d="M28.7488 60.6754L29.6148 67.1708"
        stroke="#F5EBDC"
        strokeWidth="1.024"
        strokeLinecap="round"
      />
      <path
        d="M97.4727 51.257C97.4727 51.257 110.483 41.5139 99.657 31.7708C93.0403 25.8167 85.0921 27.4752 85.0921 27.4752C85.3931 23.8507 85.9971 14.4844 78.1442 8.74675C70.2912 3.00914 60.3208 6.02951 56.6964 10.559C53.0719 15.0884 53.9791 23.5498 52.771 29.5905C51.5628 35.6312 52.4679 39.5501 56.0923 41.6741C59.7167 43.7981 63.9474 42.2782 63.9474 42.2782L63.0402 47.7148C63.0402 47.7148 55.9212 48.6588 50.0754 51.257C43.4501 54.2016 28.6405 62.2992 28.6405 62.2992C28.4175 61.5184 27.5158 56.2707 26.2568 49.4579C25.0717 43.046 23.5377 35.1609 22.9639 31.0156C22.1196 24.9164 24.3688 20.1463 21.8886 19.0835C19.4083 18.0206 18.0671 21.5161 18.0671 21.5161C18.0671 21.5161 16.8817 24.075 13.7141 29.5905C12.6244 31.488 6.55618 33.7194 6.55618 33.7194C6.55618 33.7194 1.09787 73.0664 14.1341 83.734C23.6607 91.5285 48.5598 78.9707 48.5598 78.9707C48.5598 78.9707 48.5598 86.527 46.3946 95.2445C44.2295 103.962 75.0221 102.571 75.0221 102.571C75.0221 102.571 69.4512 100.052 57.8699 105.283C44.446 111.345 36.6515 128.233 37.5176 129.532C38.3837 130.831 40.9818 129.749 44.446 125.418C41.4148 136.677 44.446 135.811 46.3946 135.594C48.3757 138.444 50.9414 134.945 50.9414 134.945C50.9414 134.945 54.4056 138.193 59.8185 123.037C62.2774 121.877 64.5417 120.343 66.5304 118.49C66.5304 118.49 100.956 116.325 107.668 104.2C114.38 92.0753 106.566 62.7322 97.4727 51.257Z"
        fill="#FDFDFD"
        stroke="black"
        strokeWidth="1.024"
      />
      <circle cx="55.7372" cy="25.9574" r="0.898531" fill="black" />
      <path
        d="M68.9402 113.147L69.4966 114.392L72.8309 117.864C83.0936 116.595 102.768 113.053 107.668 104.2C114.38 92.0753 109.833 64.4643 100.739 52.9891C93.1615 61.3227 81.4438 59.0515 76.7455 52.1231C75.8795 52.3382 74.9976 52.4831 74.1083 52.5561C72.7214 52.6842 71.3237 52.6289 69.9513 52.3915C68.3067 52.1171 66.7494 51.4609 65.4045 50.4754C63.6919 49.1871 63.0661 47.7928 63.0661 47.7928L62.776 47.7516C61.4466 47.9487 55.2608 48.9511 50.0753 51.257C43.45 54.2016 28.6405 62.2992 28.6405 62.2992C28.4456 61.6193 27.8589 56.265 27.1725 49.8497L18.7632 53.0974L17.8516 53.4503L13.5019 55.1348L5.59265 58.1963C6.15992 68.3897 8.28824 78.9491 14.1341 83.734C23.6607 91.5285 48.5597 78.9707 48.5597 78.9707L45.6401 101.233C45.6401 101.233 51.2618 104.2 55.8736 105.886C56.5688 106.14 65.0961 102.1 65.9263 101.916C68.614 101.319 94.5402 94.4001 94.5402 94.4001C94.5402 94.4001 71.9757 99.3826 65.7119 101.916L67.1539 109.144L68.9402 113.147Z"
        fill="white"
        stroke="black"
        strokeWidth="1.024"
      />
      <path
        d="M86.4518 75.3549L88.5 95.5"
        stroke="black"
        strokeWidth="1.024"
        strokeLinecap="round"
      />
      <path
        d="M44.3337 126.187C45.1952 124.35 47.0192 120.578 48.5464 118.187"
        stroke="black"
        strokeWidth="1.024"
        strokeLinecap="round"
      />
      <path
        d="M51.1998 121.187C48.4284 126.546 46.8022 132.655 46.1998 135.187"
        stroke="black"
        strokeWidth="1.024"
        strokeLinecap="round"
      />
      <path
        d="M55.8736 121.187C53.925 125.243 52.2188 130.78 51.2618 134.187"
        stroke="#30242A"
        strokeWidth="1.024"
        strokeLinecap="round"
      />
      <path
        d="M50.0591 71.2737L48.4 79.6"
        stroke="black"
        strokeWidth="1.024"
        strokeLinecap="round"
      />
      <path
        d="M99.6569 31.7708C93.0403 25.8167 85.0921 27.4752 85.0921 27.4752C85.393 23.8507 85.9971 14.4844 78.1442 8.74675C70.2912 3.00914 60.3208 6.02951 56.6963 10.559C55.2197 12.4058 54.4944 14.9066 54.0722 17.6281C56.4842 18.0352 60.0198 17.2059 60.0198 17.2059C64.8524 22.6426 69.9794 23.8507 69.9794 23.8507C73.3029 20.2263 79.3437 20.2263 80.2508 25.3663C81.2533 31.0498 74.208 32.6152 74.208 32.6152C74.208 32.6152 72.714 40.715 75.1823 48.8797C75.5336 50.0345 76.0578 51.1295 76.7368 52.1274C81.4352 59.0558 93.155 61.3292 100.731 52.9934C101.106 52.532 101.454 52.049 101.772 51.5471C103.966 48.1587 108.168 39.431 99.6569 31.7708Z"
        fill="black"
        stroke="black"
        strokeWidth="0.5"
      />
      <path
        d="M63.9149 42.6614C65.315 42.2514 66.6645 41.6849 67.9377 40.9726"
        stroke="#30242A"
        strokeWidth="1.024"
        strokeLinecap="round"
      />
      <path
        d="M73.3246 27.7545L76.0202 26.2562"
        stroke="black"
        strokeWidth="1.024"
        strokeLinecap="round"
      />
      <path
        d="M58.0041 34.7825C58.4999 35.0921 60.3554 35.3324 61.1089 33.9056"
        stroke="black"
        strokeWidth="1.024"
        strokeLinecap="round"
      />
      <circle cx="62.8843" cy="27.1482" r="0.898531" fill="black" />
      <path
        d="M57.8937 29.3372C57.7551 29.9348 57.6404 30.5865 57.7356 30.8095C57.8649 31.0783 58.0817 31.2951 58.3505 31.4244"
        stroke="black"
        strokeWidth="1.024"
        strokeLinecap="round"
      />
      <ellipse
        cx="53.7401"
        cy="25.9172"
        rx="4.33027"
        ry="3.89724"
        transform="rotate(-76.95 53.7401 25.9172)"
        stroke="black"
        strokeWidth="1.024"
        strokeLinecap="round"
      />
      <ellipse
        cx="63.2677"
        cy="27.4321"
        rx="4.33027"
        ry="3.89724"
        transform="rotate(-76.95 63.2677 27.4321)"
        stroke="black"
        strokeWidth="1.024"
        strokeLinecap="round"
      />
      <path
        d="M57.7616 26.4662L59.2772 26.6827"
        stroke="black"
        strokeWidth="1.024"
        strokeLinecap="round"
      />
      <path
        d="M13.6787 25.8578C13.6787 25.8578 16.3839 28.8642 16.9325 29.6519C16.9325 29.6519 10.9359 36.0978 17.5187 43.7778"
        stroke="black"
        strokeWidth="1.024"
        strokeLinecap="round"
      />
      <path
        d="M16.2903 25.355C14.6086 24.2795 10.924 22.4549 9.63685 23.4863C7.46349 25.2282 8.43117 27.8821 8.43117 27.8821C8.43117 27.8821 4.48748 27.8241 5.0903 31.7712C5.0903 31.7712 2.1746 33.5671 3.97056 36.4828C3.97056 36.4828 1.37742 37.5404 3.12054 40.6712C4.53978 43.2244 7.13756 44.3002 8.70042 44.8261C10.0773 45.2885 11.9193 44.0829 10.8816 41.6772C10.8816 41.6772 12.4427 41.8951 13.1798 40.917C14.197 39.5671 13.3409 37.396 13.3409 37.396C13.3409 37.396 14.902 37.6139 15.8535 36.6056C17.0143 35.3755 16.0748 33.5136 16.0748 33.5136C17.033 33.2446 17.7648 32.4691 17.9778 31.497C18.5341 29.2324 14.9564 27.1115 14.9564 27.1115L16.2903 25.355Z"
        fill="#FDFDFD"
        stroke="black"
        strokeWidth="1.024"
      />
      <path
        d="M3.83124 36.627C6.0407 38.653 8.41959 40.486 10.9419 42.1061"
        stroke="black"
        strokeWidth="1.024"
        strokeLinecap="round"
      />
      <path
        d="M5.11334 31.7482C7.70416 34.1407 10.5692 36.218 13.6486 37.9366"
        stroke="black"
        strokeWidth="1.024"
        strokeLinecap="round"
      />
      <path
        d="M8.10059 28.1122C10.5008 30.4896 13.2656 32.4682 16.2903 33.973"
        stroke="black"
        strokeWidth="1.024"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SvgThumbsUp;
