import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { ComponentProps, useMemo } from 'react';
import tw from 'twin.macro';

import { Event, EventCategory, EventItem } from '../../models';
import { Claims } from '../Claims';
import { Lodging, Transportation } from '../icons';

const Container = styled.div<{ outlined?: boolean }>`
  ${tw`flex flex-col space-y-8`}

  ${(p) => p.outlined && tw`relative px-8 py-4 mt-4 border border-neutral-stone`}
  ${(p) =>
    p.outlined &&
    css`
      border-radius: 1.5rem;
    `}
`;

type ClaimItemProps = ComponentProps<'div'> & {
  event: Event;
  item: EventItem;
  onClaim: (quantity: number) => void;
  isPreviewMode?: boolean;
};

export const ClaimItem = observer(
  ({ event, item, onClaim, isPreviewMode = false }: ClaimItemProps) => {
    const totalClaims = useMemo(
      () => item.claims.reduce((total, c) => total + c.quantity, 0),
      [item.claims]
    );

    const isExcursion = event.category === EventCategory.Excursion;

    return (
      <Container
        outlined={isExcursion && (item.name === 'Lodging' || item.name === 'Transportation')}
      >
        {isExcursion ? (
          <div className="absolute self-center overflow-hidden text-5xl rounded-full -top-8">
            {item.name === 'Lodging' ? (
              <div className="p-2 bg-content-shell">
                <Lodging />
              </div>
            ) : item.name === 'Transportation' ? (
              <div className="p-2 bg-content-mist">
                <Transportation />
              </div>
            ) : null}
          </div>
        ) : null}
        <div className="flex flex-col">
          <div className="flex items-center space-x-4">
            <div className="font-semibold">
              {item.name}{' '}
              {item.quantity && item.quantity > 0 && (
                <span className="text-neutral-ash font-normal">
                  ({totalClaims}/{item.quantity} claimed)
                </span>
              )}
            </div>
          </div>
          {item.note && <div className="text-neutral-ash mt-2">Note: {item.note}</div>}
          <div className="space-y-2 w-full my-6">
            {item.id && (
              <Claims event={event} item={item} onClaim={onClaim} isPreviewMode={isPreviewMode} />
            )}
          </div>
        </div>
      </Container>
    );
  }
);

export default ClaimItem;
