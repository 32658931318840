import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  ComponentProps,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Transition, TransitionStatus } from 'react-transition-group';
import { useKey } from 'react-use';
import tw from 'twin.macro';

import { Card } from '../Card';
import { Close } from '../icons';
import { Portal } from '../Portal';
import { PortalProps } from '../Portal';

export type ModalProps = ComponentProps<'div'> & {
  open?: boolean;
  header?: ReactNode;
  forceOpen?: boolean;
  fullHeight?: boolean;
  fullWidth?: boolean;
  showCloseButton?: boolean;
  excludePadding?: boolean;
  padContentAtSmallWidths?: boolean;
  onClose?: () => void;
  children?: FC<{ close: () => void }> | ReactNode;
  noBackdrop?: boolean;
  drawerDialog?: boolean;
} & PortalProps;

const Container = styled.div<{ fullHeight?: boolean; fullWidth?: boolean }>`
  ${tw`absolute top-0 left-0 z-50 flex flex-col items-center h-full w-full overflow-hidden`}

  ${(p) => !p.fullHeight && tw`px-8 pt-8`}

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const Backdrop = styled.div<{ transition?: TransitionStatus }>`
  ${tw`fixed top-0 left-0 w-full h-full bg-neutral-soil bg-opacity-60`}
  transition: all 300ms ease-out;

  opacity: ${(p) => (p.transition === 'entered' ? 1 : 0)};
`;

export const Dialog = styled.div<{
  transition?: TransitionStatus;
  fullHeight?: boolean;
  fullWidth?: boolean;
  drawer?: boolean;
}>`
  ${tw`max-w-2xl pt-12`}

  @media (max-width: 1000px) {
    width: 100%;
  }

  transition: all 300ms ease-out;

  ${(p) => p.fullHeight && tw`h-full`}
  ${(p) => p.fullWidth && tw`w-full`}
  ${(p) => !p.fullWidth && tw`w-3/5`}
  ${(p) => p.drawer && tw`absolute bottom-0 h-auto`}

  opacity: ${(p) => (p.transition === 'entered' ? 1 : 0)};
  transform: translateY(${(p) => (p.transition === 'entered' ? '0' : '100%')});
`;

const ContentCard = styled(Card)<{ fullheight?: boolean }>`
  ${tw`relative w-full h-full p-0 overflow-auto shadow-2xl bg-neutral-white`}

  ${(p) =>
    p.fullheight &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}

  box-shadow: 1px -3px 51px -18px rgba(0, 0, 0, 0.65);

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const Content = styled.div<{ excludePadding?: boolean; padContentAtSmallWidths?: boolean }>`
  ${tw`w-full`}

  ${(p) => !p.excludePadding && tw`px-8 py-8 mt-14`}
  ${(p) => p.padContentAtSmallWidths && tw`mt-24 xs:mt-14!`}
`;

export const Modal: FC<ModalProps> = ({
  open,
  header,
  forceOpen,
  onClose,
  fullHeight,
  fullWidth = true,
  excludePadding = false,
  padContentAtSmallWidths = false,
  showCloseButton = true,
  noBackdrop,
  drawerDialog,
  children,
  ...props
}) => {
  const container = useRef<HTMLDivElement>(null);
  const containerOverflow = useRef('');

  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(open ?? true);

    if (props.container) {
      const _container = document.getElementsByClassName(props.container)?.[0] as HTMLElement;
      if (_container) {
        containerOverflow.current = _container.style.overflow;
        _container.style.overflow = 'hidden';
      }
    }
  }, [open, props.container]);

  const close = useCallback(() => !forceOpen && setIsOpen(false), [forceOpen]);

  const content = useMemo(
    () => (typeof children === 'function' ? children({ close }) : children),
    [children, close]
  );

  useKey('Escape', close);

  let clickTarget: any = null;
  function onModalMouseDown(event: any) {
    clickTarget = event.target;
  }

  return (
    <Portal {...props} className="fixed top-0 left-0 z-50 w-full h-full">
      <Transition
        in={isOpen}
        timeout={300}
        nodeRef={container}
        onExit={() =>
          setTimeout(() => {
            if (props.container) {
              const _container = document.getElementsByClassName(
                props.container
              )?.[0] as HTMLElement;
              if (_container) {
                _container.style.overflow = containerOverflow.current;
              }
            }
            onClose?.();
          }, 300)
        }
        unmountOnExit
      >
        {(transitionStatus) => (
          <Container
            ref={container}
            onMouseDown={onModalMouseDown}
            fullWidth={fullWidth}
            fullHeight={fullHeight}
            onClick={(e) => {
              // preventing mouseUp outside of modal from closing if the click originated within the modal
              if (e.target === clickTarget) {
                close();
              }
            }}
          >
            {!noBackdrop && <Backdrop transition={transitionStatus} onClick={close} />}
            <Dialog
              transition={transitionStatus}
              fullWidth={fullWidth}
              fullHeight={fullHeight}
              drawer={drawerDialog}
            >
              <ContentCard onClick={(e) => e.stopPropagation()} fullheight={fullHeight}>
                {showCloseButton && (
                  <div className="fixed z-40 flex flex-wrap bg-neutral-white">
                    <div className="fixed flex w-full flex-wrap p-8 pb-4 bg-neutral-white rounded-t-3xl">
                      <div className="flex-1 text-2xl font-semibold">{header}</div>
                      {!forceOpen && <Close onClick={close} className="text-2xl cursor-pointer" />}
                    </div>
                  </div>
                )}
                <Content
                  padContentAtSmallWidths={padContentAtSmallWidths}
                  excludePadding={excludePadding}
                >
                  {content}
                </Content>
              </ContentCard>
            </Dialog>
          </Container>
        )}
      </Transition>
    </Portal>
  );
};

export default Modal;
