import * as React from 'react';

function SvgBreads(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        stroke="#070401"
        strokeWidth={1.54}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M14.672 4.66c1.577-.548 3.18-1.066 4.726-.191.816.462 1.545 1.314 1.6 2.305.052.97-.654 1.615-1.006 2.428 0-.07-.001-.12-.003-.152-.038-.65-.305-1.48-.875-1.782-1.62-.86-4.1.607-5.706.838h-.468c-1.605-.231-3.52-1.497-5.056-.838-.565.242-.844.77-.882 1.42-.045.753.882 1.567.882 2.27v1.019L7.664 20H19.79 5.742c-.9 0-1.629-.748-1.629-1.67h0v-7.37l.047-1.284c-.215-1.058-1.22-1.752-1.157-2.902.055-.991.783-1.843 1.599-2.305 1.546-.875 3.149-.357 4.726.191l.473.164c.628.216 1.251.415 1.864.509h.67c.613-.094 1.236-.293 1.865-.509z"
          fill="#DDDDDC"
        />
        <path
          d="M19.99 9.05c-.04-.65-.306-1.48-.876-1.782-1.62-.86-4.1.607-5.706.838h-.468c-1.605-.231-3.52-1.497-5.056-.838-.565.242-.844.77-.882 1.42-.045.753.882 1.567.882 2.27v1.019L7.664 20H19.79l.2-6.5c.014-2.716.014-4.199 0-4.45z"
          fill="#ECECEC"
        />
      </g>
    </svg>
  );
}

export default SvgBreads;
