import * as React from 'react';

function SvgMomentScene(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 526 381" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1)">
          <path
            d="M196.32 377.683a5.67 5.67 0 01-5.63-4.973l-4.199-35.262h19.657l-4.198 35.262a5.665 5.665 0 01-5.63 4.973zM416.847 377.683a5.66 5.66 0 01-5.625-4.973l-4.198-35.262h19.652l-4.198 35.262a5.665 5.665 0 01-5.63 4.973zM510.88 377.683a5.66 5.66 0 01-5.625-4.973l-4.198-35.262h19.651l-4.198 35.262a5.665 5.665 0 01-5.63 4.973z"
            fill="#000"
            fillRule="nonzero"
          />
          <path
            d="M80.985 191.718h88.242v146.198H40.75V231.937c.003-22.213 18.015-40.219 40.234-40.219h0z"
            stroke="#070401"
            strokeWidth={1.169}
            fill="#F5F5F6"
            fillRule="nonzero"
          />
          <path
            d="M179.742 118.385h304.024c22.22 0 40.234 18.01 40.234 40.225V337.9H139.518V158.605c0-22.216 18.013-40.225 40.234-40.225l-.01.005z"
            stroke="#070401"
            strokeWidth={1.169}
            fill="#F8F6F1"
            fillRule="nonzero"
          />
          <path
            stroke="#070401"
            strokeWidth={1.169}
            strokeLinecap="round"
            d="M139.508 213.26v124.641"
          />
          <path
            d="M179.742 250.192h304.024c22.22 0 40.234 18.01 40.234 40.225v47.489H139.518v-47.49c0-22.21 18.007-40.218 40.224-40.224h0z"
            stroke="#070401"
            fill="#F8F6F1"
            fillRule="nonzero"
          />
          <path
            d="M365.616 250.192H178.29c-21.426 0-38.796 17.366-38.796 38.787v48.942"
            stroke="#070401"
            strokeWidth={1.169}
            strokeLinecap="round"
          />
          <path
            stroke="#070401"
            strokeWidth={1.169}
            d="M394.559 250.804v87.097M524 190.758h-89.704"
          />
          <path
            d="M112.664 175.403c11.504 1.437 15.09-3.446 16.777-7.668 1.915-4.79.681-8.906-.811-14.257 0 0 3.8-2.069 3.208-4.44-.497-1.915-4.82-2.13-4.82-2.13-.169-.854-.32-1.741-.452-2.66-.96-6.713-2.398-10.547-2.398-10.547-3.357.96-7.67.498-9.107 0a28.678 28.678 0 01-10.067 13.427s-10.55-11.502-17.26-2.874c-5.47 7.036 2.398 15.813 7.67 16.772-3.133 6.61-11.564 10.443-15.951 12.044.269 2.079.522 4.008.746 5.69h32.828l-.363-3.357zM226.84 217.134a186.658 186.658 0 004.237-18.708l-11.504-31.154s4.794-12.929.497-14.858c-4.297-1.93-13.922 10.557-17.757 20.612-1.656 4.346-6.182 14.336-10.783 24.276l35.31 19.832z"
            stroke="#070401"
            strokeWidth={1.169}
            fill="#F8F6F1"
            fillRule="nonzero"
          />
          <path
            d="M179.299 223.35s-27.804-23.487-39.84-32.12c-12.038-8.632-24.559-6.877-24.559-6.877v-7.317H78.241a266.214 266.214 0 001.212 7.969l-22.88 87.236 72.863-5.753-1.915-26.853s39.294 32.82 61.845 29.717c20.338-2.814 33.785-34.327 39.36-54.367l-37.196-20.85-12.231 29.215z"
            stroke="#070401"
            strokeWidth={1.169}
            fill="#F8F6F1"
            fillRule="nonzero"
          />
          <path
            d="M95.404 161.041c-5.272-.96-13.146-9.736-7.67-16.773 6.71-8.627 17.26 2.875 17.26 2.875a28.678 28.678 0 0010.067-13.427c1.438.498 5.75.96 9.107 0 0 0 7.193-2.397 6.71-8.145-.641-7.718-12.464-4.316-12.464-4.316s-12.933-9.588-26.86-3.357c-14.842 6.678-16.284 20.14-15.339 29.245.547 5.226 2.044 16.907 3.223 25.942 4.402-1.606 12.833-5.435 15.966-12.044z"
            stroke="#070401"
            strokeWidth={1.169}
            fill="#000"
            fillRule="nonzero"
          />
          <path
            stroke="#070401"
            strokeWidth={1.169}
            strokeLinecap="round"
            d="M127.161 239.887l-1.776-20.731M124.72 158.39c-.79.498-3.765.876-4.973-1.406"
          />
          <circle fill="#000" fillRule="nonzero" cx={120.333} cy={146.183} r={1.437} />
          <path
            stroke="#070401"
            strokeWidth={1.169}
            strokeLinecap="round"
            d="M93.967 150.499l5.272 2.874"
          />
          <path
            d="M228.466 358.17l36.722-10.866c-2.8-9.677-8.157-28.02-11.604-38.707l-45.824 19.125a209.846 209.846 0 0020.706 30.448h0z"
            stroke="#070401"
            strokeWidth={1.169}
            fill="#FDFDFD"
            fillRule="nonzero"
          />
          <path d="M108.829 288.054l13.295 6.246-12.216-7.38c-.706.737-1.08 1.134-1.08 1.134zM160.533 312.336l11.579 5.435 2.412-2.207z" />
          <path
            d="M229.162 358.99a63.637 63.637 0 00-1.99 12.467h64.965a14.561 14.561 0 00-6.287-7.091l-18.816-10.652s-.717-2.486-1.846-6.415l-36.722 10.87c.448.538.696.821.696.821zM227.242 377.683h66.153l-.204-2.049a14.488 14.488 0 00-1.06-4.177h-64.963a38.634 38.634 0 00.074 6.226h0zM194.643 297.165c3.174 10.94 19.987 47.196 25.07 56.316l42.988-24.208c-.498-1.606-9.729-31.328-10.146-32.536-14.34-41.428-23.393-67.132-44.487-70.508-33.2-5.281-89.68 51.885-98.16 60.667l12.216 7.404 38.409 18.036 13.991 3.228 20.12-18.4z"
            stroke="#070401"
            strokeWidth={1.169}
            fill="#F8F6F1"
            fillRule="nonzero"
          />
          <path
            d="M194.326 297.052a78.054 78.054 0 01-1.591-16.112"
            stroke="#070401"
            strokeWidth={1.169}
            strokeLinecap="round"
          />
          <path
            d="M371.545 183.568s-45.944 12.118-66.153 23.486c-23.014 12.929-24.451 30.194-38.83 73.81-4.632 14.038-7.904 25.316-10.944 34.177l39.613 18.698.074-.15s19.398-2.849 21.095-26.852c1.278-17.902 1.89-31.184 8.147-44.575l106.909-78.604-59.91.01z"
            stroke="#070401"
            strokeWidth={1.169}
            fill="#F8F6F1"
            fillRule="nonzero"
          />
          <path
            d="M234.26 341.372c-13.852 2.098-19.631 4.794-21.885 11.074l26.168 12.178s14.031-3.978 21.587-2.033v.024a84.207 84.207 0 0114.757 4.938 220.35 220.35 0 0020.393-33.814l-39.612-18.698c-5.68 16.584-10.415 24.665-21.407 26.33z"
            stroke="#070401"
            strokeWidth={1.169}
            fill="#F8F6F1"
            fillRule="nonzero"
          />
          <path
            d="M253.828 371.745l12.763 5.938a113.528 113.528 0 008.267-10.13 84.207 84.207 0 00-14.758-4.938l-6.272 9.13z"
            fill="#000"
            fillRule="nonzero"
          />
          <path
            stroke="#070401"
            strokeWidth={1.169}
            strokeLinecap="round"
            d="M247.277 331.039l8.884 3.883M242.229 337.2l8.883 3.889"
          />
          <ellipse
            fill="#FFBE21"
            fillRule="nonzero"
            cx={418.046}
            cy={71.891}
            rx={5.034}
            ry={5.032}
          />
          <ellipse fill="#000" fillRule="nonzero" cx={435.788} cy={20.13} rx={20.134} ry={20.13} />
          <path
            d="M421.9 33.55c1.234.568-9.106-23.968-14.379-20.134-4.984 3.61 8.128 17.28 14.38 20.135z"
            fill="#EEAF7B"
            fillRule="nonzero"
          />
          <path
            d="M422.453 35.048c1.129 1.347 27.5-7.877 23.875-13.203-3.537-5.117-25 11.835-23.875 13.203z"
            fill="#EEAF7B"
            fillRule="nonzero"
          />
          <path
            d="M471.744 131.324l2.308-.358v-.676c-.746.394-1.517.74-2.308 1.034z"
            fill="#30242A"
            fillRule="nonzero"
          />
          <path
            d="M408.939 126.053c22.442.408 33.768-22.437 36.643-29.254a41.19 41.19 0 00-3.084-.463c-12.932-1.437-15.34-1.914-19.657-7.668-4.317-5.753-4.795-17.255-4.795-17.255s11.181-2.486 9.59-11.502c-1.438-8.15-11.027-8.15-16.3-2.397 0 0-8.152-1.919-15.822-10.547 0 0-5.61 1.318-9.45.677-.995 6.275-.935 13.297-2.07 18.975-1.92 9.588-.497 15.819 5.273 19.175 5.77 3.357 12.465.955 12.465.955l-1.438 8.628s-8.37.97-15.35 3.162c1.388 10.83 6.377 27.196 23.995 27.514z"
            stroke="#070401"
            strokeWidth={1.169}
            fill="#F8F6F1"
            fillRule="nonzero"
          />
          <path fill="#A15842" fillRule="nonzero" d="M440.1 183.568l2.612-1.77-4.527 1.77z" />
          <path
            d="M336.549 127.973s-25.133-34.312-31.162-41.224c-6.714-7.668-15.822-15.813-18.697-12.929-2.874 2.884 5.273 12.462 5.273 12.462l-1.438 31.154s16.514 44.52 34.52 50.329c9.002 2.904 26.102-4.754 38.184-11.239l-18.687-33.814c-4.75 3.113-7.993 5.26-7.993 5.26z"
            stroke="#070401"
            strokeWidth={1.169}
            fill="#F8F6F1"
            fillRule="nonzero"
          />
          <path
            d="M467.427 115.029c-3.586-8.613-10.76-15.628-23.1-17.945-2.875 6.818-13.176 26.59-35.619 26.182-17.617-.318-21.256-15.351-22.63-26.182-1.469.45-4.792 1.253-6.191 1.89-5.059 2.362-26.385 16.05-37.666 23.429l19.513 36.252c7.854-4.211 15.064-10.105 15.064-10.105l-5.273 34.988h66.65l4.527-1.77 31.335-21.254a197.37 197.37 0 00-2.293-29.19s-1.92-10.542-4.317-16.295z"
            stroke="#070401"
            strokeWidth={1.169}
            fill="#F8F6F1"
            fillRule="nonzero"
          />
          <path
            d="M485.646 106.884c-3.437-16.316-21.094-15.819-21.094-15.819a24.273 24.273 0 00-6.232-22.049c-9.59-10.064-23.015-5.753-23.015-5.753.498-5.749 1.438-20.607-11.027-29.712-12.464-9.105-28.281-4.316-34.036 2.874-2.343 2.93-3.482 6.897-4.163 11.219 3.825.641 9.45-.677 9.45-.677 7.67 8.628 15.822 10.547 15.822 10.547 5.273-5.753 14.862-5.753 16.3 2.397 1.591 9.016-9.59 11.502-9.59 11.502s.497 11.502 4.795 17.255c4.297 5.754 6.715 6.231 19.657 7.668a41.98 41.98 0 013.083.463c12.34 2.312 18.26 9.617 21.846 18.23 2.397 5.753 4.317 16.295 4.317 16.295a19.838 19.838 0 002.283-1.034c4.646-2.417 14.569-9.334 11.604-23.406z"
            stroke="#070401"
            strokeWidth={1.169}
            fill="#000"
            fillRule="nonzero"
          />
          <path
            stroke="#070401"
            strokeWidth={1.169}
            strokeLinecap="round"
            d="M417.091 63.263l3.835-2.392M392.555 74.516c.796.497 3.765.875 4.974-1.407"
          />
          <circle fill="#000" fillRule="nonzero" cx={400.374} cy={62.293} r={1.437} />
          <circle fill="#000" fillRule="nonzero" cx={389.282} cy={60.389} r={1.437} />
          <path
            d="M393.117 64.223s-1.308 3.277-.995 3.933c.227.422.573.767.995.995M378.439 132.757l-2.447 15.355"
            stroke="#070401"
            strokeWidth={1.169}
            strokeLinecap="round"
          />
          <path
            d="M407.024 184.05s-41.234 12.93-64.243 23.964c-22.88 10.94-20.134 36.425-15.822 81 1.527 15.759 3.313 28.096 4.218 37.867h43.77c2.248-1.253 15.519-9.77 7.605-33.073-5.78-17.012-10.768-28.28-10.768-43.059h36.608c21.652.001 39.376-17.219 39.995-38.857l.8-27.847-42.163.005zM322.83 358.399c-11.658 7.767-15.752 12.65-15.145 19.299l28.849-.04s11.027-9.543 18.697-10.98a81.81 81.81 0 0115.419-1.437c1.756-8.598 3.646-21.502 3.979-38.18l.318-.165h-43.77c1.527 16.4.557 25.56-8.346 31.503z"
            stroke="#070401"
            strokeWidth={1.169}
            fill="#F8F6F1"
            fillRule="nonzero"
          />
          <path
            d="M353.425 377.613h14.081s1.532-4.281 3.184-12.367a81.81 81.81 0 00-15.42 1.437l-1.845 10.93z"
            fill="#000"
            fillRule="nonzero"
          />
          <path
            stroke="#070401"
            strokeWidth={1.169}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M371.267 250.804h-11.301"
          />
          <path
            stroke="#070401"
            strokeWidth={1.169}
            strokeLinecap="round"
            d="M330.297 345.813h10.808M327.894 353.481h10.808"
          />
          <path
            d="M435.748 191.718H524v146.198H395.54V231.937c.002-22.203 18-40.205 40.208-40.219h0zM291.45 87.104s-10.544 0-11.982 4.311c-1.437 4.312 1.915 7.673 1.915 7.673s-5.75 2.874-1.915 8.145c0 0-2.88 4.794 1.915 7.668 0 0-2.875 3.357 1.92 6.714 4.795 3.356 11.505 3.834 12.932 2.396 1.696-1.695 1.353-4.177-1.96-6.882 0 0 6.278.652 7.233-2.705.955-3.357-2.393-5.271-2.393-5.271s4.313 0 4.313-3.834-3.835-4.794-3.835-4.794 2.029-1.467 1.437-3.834c-.706-2.82-5.292-4.082-7.675-4.555a16.577 16.577 0 00-1.915-.238l.01-4.794z"
            stroke="#070401"
            strokeWidth={1.169}
            fill="#F8F6F1"
            fillRule="nonzero"
          />
          <path
            d="M281.687 114.497a58.052 58.052 0 0013.32 2.75M279.915 106.77a68.556 68.556 0 0020.08 2.184M281.687 99.654a48.953 48.953 0 0018.307 1.283"
            stroke="#070401"
            strokeWidth={1.169}
            strokeLinecap="round"
          />
          <path
            d="M437.703 162.672l-.96 14.685s-38.831 10.07-52.256 20.134c-6.063 4.545-13.43 12.93-15.817 16.773-2.387 3.844-12.464 18.215-7.67 18.693 4.795.477 10.068-8.146 10.068-8.146s-2.885 10.543-2.388 13.9c.498 3.356 5.272 2.396 5.272 2.396 3.835 3.834 7.193-.96 7.193-.96 6.71 4.312 13.927-18.692 13.927-18.692a45.263 45.263 0 0010.788-7.43s42.905-.238 56.33-12.222c9.53-8.504 10.713-25.46 10.709-41.423"
            stroke="#070401"
            strokeWidth={1.169}
            fill="#F8F6F1"
            fillRule="nonzero"
          />
          <path
            d="M369.178 228.61c2.486-8.453 4.476-13.426 5.968-16.504M381.851 214.985c-4.317 9.3-6.963 20.518-8.197 26.057M388.576 218.337c-2.82 6.465-5.382 16.191-6.964 22.208"
            stroke="#070401"
            strokeWidth={1.169}
            strokeLinecap="round"
          />
          <path
            d="M377.375 194.135c-11.684 4.157-24.726 9.15-34.594 13.879-17.687 8.453-20.06 25.614-18.234 53.546"
            stroke="#070401"
            strokeWidth={1.169}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M96.394 331.69c-19.612 3.978-33.589 9.09-33.589 9.09l-.02-9.741-9.45 1.941-48.39 38.098-1.033 2.467c2.43 2.914 3.988 2.715 7.783 2.716l87.35 1.422-2.65-45.993z"
            stroke="#070401"
            strokeWidth={1.169}
            fill="#F8F6F1"
            fillRule="nonzero"
          />
          <path
            d="M62.785 340.78s-13.108 2.317 6.504-1.636l-7.764 38.867L160.533 380c17.03 0 33.444-14.252 36.617-30.98 2.114-11.164-1.188-23.118-13.056-31.249-19.09-13.078-59.926-30.194-70.47-33.55l-58.01 13.426c-11.028 11.984-8.63 31.631-8.63 31.631l15.801 11.502z"
            stroke="#070401"
            strokeWidth={1.169}
            fill="#F8F6F1"
            fillRule="nonzero"
          />
          <path
            d="M69.289 339.144c13.041-2.521 54.86-11.537 75.252-10.587M114.618 283.723c9.227 2.94 41.647 16.41 62.393 28.494"
            stroke="#070401"
            strokeWidth={1.169}
            strokeLinecap="round"
          />
          <path
            d="M53.335 332.356l-48.39 43.905a12.74 12.74 0 005.853 1.422h35.225l16.762-36.903a35.844 35.844 0 00-9.45-8.424h0zM46.983 329.273L0 371.934l.96 1.149a12.738 12.738 0 003.98 3.178l48.395-43.905a31.96 31.96 0 00-6.352-3.083z"
            stroke="#070401"
            strokeWidth={1.169}
            fill="#F8F6F1"
            fillRule="nonzero"
          />
          <path
            stroke="#070401"
            strokeWidth={1.169}
            strokeLinecap="round"
            d="M36.195 369.299v8.493M28.525 369.299v8.493"
          />
          <path
            d="M219.606 165.904s9.35-3.098 11.937.8c2.586 3.9.189 8.156.189 8.156s6.51 1.288 4.138 7.583c0 0 4.173 3.978.194 8.156 0 0 3.78 2.55-.074 7.2-3.855 4.65-10.396 6.962-12.221 5.927-2.149-1.223-2.487-3.784.064-7.384 0 0-6.063 2.362-7.933-.711s.94-5.903.94-5.903-4.292 1.184-5.342-2.635c-1.05-3.82 2.487-5.818 2.487-5.818s-2.417-.9-2.487-3.422c-.094-3.664 6.337-6.857 8.058-7.633l.05-4.316z"
            stroke="#070401"
            strokeWidth={1.169}
            fill="#F8F6F1"
            fillRule="nonzero"
          />
          <path
            d="M213.868 181.574c3.476-.647 12.307-2.646 18.154-6.962M216.976 189.49c6.82-.976 13.362-3.68 19.15-7.916M222.945 196.397c1.213-.467 9.126-3.575 13.927-6.907"
            stroke="#070401"
            strokeWidth={1.169}
            strokeLinecap="round"
          />
          <path
            d="M81.02 185.005s-22.05 2.397-32.117 13.924c-9.286 10.606-38.353 59.91-26.859 85.794 11.495 25.883 46.034 16.753 54.171 14.356 4.442-1.308 10.568-4.5 17.477-6.742l-6.173-30.885c-8.679 1.06-17.646 2.107-28.548 4.056"
            stroke="#070401"
            strokeWidth={1.169}
            fill="#F8F6F1"
            fillRule="nonzero"
          />
          <path
            stroke="#070401"
            strokeWidth={1.169}
            strokeLinecap="round"
            d="M58.729 265.571l10.56-36.246M437.703 163.916l-.239-24.68"
          />
          <path
            d="M94.3 292.49c5.79-1.84 11.649-3.172 16.444-2.516 10.55 1.437 22.532 6.708 24.451 0 0 0 6.467-.164 4.427-6.31 0 0 3.243-.403 3.72-2.8.96-4.789-10.544-9.105-10.544-9.105s9.107 1.437 11.027-.497c2.442-2.447.065-6.34-14.812-9.946-10.803-2.615-23.875-2.456-40.786-.388"
            stroke="#070401"
            strokeWidth={1.169}
            fill="#F8F6F1"
            fillRule="nonzero"
          />
          <path
            d="M134.653 272.241c-5.108-1.959-14.807-5.216-21.835-6.36"
            stroke="#070401"
            strokeWidth={1.169}
            strokeLinecap="round"
          />
          <path
            stroke="#070401"
            strokeWidth={1.169}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M474.037 160.38l-35.852 2.312"
          />
          <path
            d="M139.821 283.465c-6.665-2.984-19.537-8.514-26.441-9.548M134.653 290.471c-5.138-2.605-15.419-7.499-22.71-8.886M88.227 260.804c-4.685.607-19.672 2.675-42.06 6.997"
            stroke="#070401"
            strokeWidth={1.169}
            strokeLinecap="round"
          />
        </g>
        <g transform="translate(221 153)">
          <path
            d="M0 16.629V12.319c0-2.837 2.293-5.137 5.12-5.137h5.989c2.828 0 5.12 2.3 5.12 5.136v10.735c0 2.837-2.292 5.136-5.12 5.136H5.12L0 28.414"
            stroke="#070401"
            strokeWidth={1.462}
            strokeLinecap="round"
          />
          <path
            d="M7.578 2.274V30.22c.015 4.291 3.479 7.766 7.756 7.781H38.36c4.278-.015 7.742-3.49 7.757-7.78V2.273H7.577z"
            stroke="#070401"
            strokeWidth={1.169}
            fill="#F8F6F1"
            fillRule="nonzero"
          />
          <rect fill="#000" fillRule="nonzero" x={3.699} width={46.301} height={2.274} rx={0.666} />
        </g>
        <g transform="matrix(-1 0 0 1 292 74)">
          <path
            d="M0 16.629V12.319c0-2.837 2.293-5.137 5.12-5.137h5.989c2.828 0 5.12 2.3 5.12 5.136v10.735c0 2.837-2.292 5.136-5.12 5.136H5.12L0 28.414"
            stroke="#070401"
            strokeWidth={1.462}
            strokeLinecap="round"
          />
          <path
            d="M7.578 2.274V30.22c.015 4.291 3.479 7.766 7.756 7.781H38.36c4.278-.015 7.742-3.49 7.757-7.78V2.273H7.577z"
            stroke="#070401"
            strokeWidth={1.169}
            fill="#F8F6F1"
            fillRule="nonzero"
          />
          <rect fill="#000" fillRule="nonzero" x={3.699} width={46.301} height={2.274} rx={0.666} />
        </g>
      </g>
    </svg>
  );
}

export default SvgMomentScene;
