import { useMemo } from 'react';
import { Fetcher, Key } from 'swr';
import useSWRInfinite from 'swr/infinite';

import { Page } from '../models';

const keyGenerator =
  <ModelType>(key: Key, pageSize: number) =>
  (pageIndex: number, previousPage: Page<ModelType> | null) => {
    if (previousPage && !previousPage.data?.length) {
      return null;
    }

    if (pageIndex === 0 || !previousPage) {
      return `${key}?page-size=${pageSize}`;
    }

    return `${key}?page-cursor=${previousPage.nextCursor}&page-size=${pageSize}`;
  };

export function usePaged<ModelType>(
  pageSize: number,
  key: Key,
  fetcher?: Fetcher<Page<ModelType>>
) {
  const baseKey = useMemo(
    () => keyGenerator(typeof key === 'function' ? key() : key, pageSize),
    [key, pageSize]
  );
  const paging = useSWRInfinite<Page<ModelType>>(baseKey, fetcher || null);

  const total = paging?.data?.[0]?.total || 0;

  const data = paging?.data?.flatMap((p) => p.data).filter((d) => d);
  const hasMore = !paging.isValidating && paging.size < total / pageSize;

  return {
    ...paging,
    previous: () => paging.setSize(paging.size - 1),
    next: () => paging.setSize(paging.size + 1),
    data,
    total,
    hasMore
  };
}

export default usePaged;
