import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import tw from 'twin.macro';

import {
  ControlAnchor,
  FadeIn,
  HeartFilled,
  Loader,
  MessageInput,
  ProfilePicture
} from '../../components';
import { Event, Reaction } from '../../models';
import { usePotluck, useUser } from '../../providers';

interface ChatProps {
  event: Event;
}

const Container = styled.div`
  ${tw`w-full h-full overflow-auto mb-24`}

  &::-webkit-scrollbar-track {
    ${tw`bg-neutral-stone`}
  }

  &::-webkit-scrollbar-thumb {
    ${tw`bg-primary`}
  }
`;

const Form = styled.form`
  ${tw`relative h-full flex-1 px-6 pt-6 bg-neutral-white`}
`;

const Messages = styled.div`
  ${tw`flex min-h-full justify-items-end flex-col-reverse space-y-5 space-y-reverse`}
`;

const Message = styled(FadeIn)<{ self?: boolean }>`
  ${tw`relative inline-flex flex-col p-2 cursor-pointer bg-content-mist rounded-md`}

  max-width: 80%;

  ${(p) => p.self && tw`ml-auto bg-primary`}

  ${(p) => !p.self && tw`mb-2`}

  ${(p) =>
    !p.self &&
    css`
      border-bottom-left-radius: 0;

      :after {
        content: '';
        position: absolute;
        display: inline-block;
        bottom: -0.75rem;
        left: 0;
        border-top: 0.5rem solid #ececec;
        border-left: 0.5rem solid #ececec;
        border-bottom: 0.5rem solid transparent;
        border-right: 0.5rem solid transparent;
      }
    `}
`;

const ReactionBubble = styled(FadeIn)<{ userReaction?: boolean }>`
  ${tw`absolute flex items-center justify-center w-6 h-6 overflow-visible border rounded-full select-none -right-2 -top-4 border-neutral-ash bg-neutral-white`}
  padding: 0.15rem;

  ::before {
    content: ' ';
    ${tw`absolute rounded-full bg-neutral-ash`}
    width: 0.55rem;
    height: 0.55rem;
    left: -0.17rem;
    bottom: -0.145rem;
  }
`;

const ReactionBubbleTail = styled.div<{ userReaction?: boolean }>`
  ${tw`absolute w-2 h-2 rounded-full -left-1 -bottom-3 bg-neutral-white`}
`;

const AuthorPicture = styled((props: any) => <ProfilePicture {...props} />)`
  ${tw`self-end w-8 h-8 text-xs -bottom-3`}
`;

export const Chat = observer(({ event }: ChatProps) => {
  const { attending } = usePotluck();
  const { user } = useUser();
  const container = useRef<HTMLDivElement>(null);
  const messagesList = useRef<HTMLDivElement>();
  const lastMessage = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const messages = attending.messages(event.id);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty }
  } = useForm({ defaultValues: { message: '' } });

  const scrollToBottom = useCallback(
    () => container.current?.scrollTo(window.scrollX, messagesList.current?.scrollHeight || 0),
    []
  );

  const sendMessage = async (message: string) => {
    await attending.sendMessage(event.id, message);
    scrollToBottom();
  };

  if (!user) {
    return <Loader />;
  }

  return (
    <Container ref={container}>
      <Form
        onSubmit={handleSubmit(async ({ message }) => {
          await sendMessage(message);
          reset();
        })}
      >
        <Messages
          ref={(ref) => {
            if (!ref || messagesList.current) {
              return;
            }

            messagesList.current = ref;

            messages.register(ref, {
              afterNext: (cursorElement, previousCursor, nextCursor) => {
                if (!previousCursor) {
                  scrollToBottom();
                } else {
                  cursorElement.scrollIntoView();
                }
              }
            });
          }}
        >
          {messages.data?.map((m) => (
            <div key={m.id} className="relative flex space-x-2" ref={lastMessage}>
              {m.author.id !== user.id && <AuthorPicture user={m.author} />}
              <Message
                key={m.id}
                self={m.author.id === user.id}
                onClick={() =>
                  m.reactions.find((r) => r.user.id === user.id)
                    ? attending.unreact(
                        event.id,
                        m.id,
                        m.reactions.find((r) => r.user.id === user.id)!.id
                      )
                    : attending.reactToMessage(event.id, m.id, Reaction.Like)
                }
              >
                {m.author.id !== user.id && (
                  <div className="text-xs text-neutral-ash">
                    {m.author.firstName} (
                    {new Date(m.createdAt).toLocaleString('en-us', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit'
                    })}
                    )
                  </div>
                )}
                {m.message}
                {m.reactions.length > 0 && (
                  <ReactionBubble userReaction={!!m.reactions?.find((r) => r.user?.id === user.id)}>
                    <div className="relative bottom-0 left-0">
                      <ReactionBubbleTail
                        userReaction={!!m.reactions?.find((r) => r.user?.id === user.id)}
                      />
                    </div>
                    <HeartFilled />
                    {m.reactions.length}
                  </ReactionBubble>
                )}
              </Message>
            </div>
          ))}
          {messages?.loading && (
            <FadeIn className="flex items-center h-0 px-4 py-4 opacity-50 space-x-4">
              <div className="flex-1 border-t-2 border-neutral-stone"></div>
              <div>... loading messages ...</div>
              <div className="flex-1 border-t-2 border-neutral-stone"></div>
            </FadeIn>
          )}
        </Messages>
        <ControlAnchor>
          <div className="relative">
            <MessageInput
              {...register('message', { required: true })}
              disabled={isSubmitting || !isDirty}
            />
          </div>
        </ControlAnchor>
      </Form>
    </Container>
  );
});

export default Chat;
