import styled from '@emotion/styled';
import { useMemo } from 'react';
import { useModal } from 'react-modal-hook';
import tw from 'twin.macro';

import { Event, EventItem } from '../../models';
import { Button } from '../Button';
import { EditIcon, Trash } from '../icons';
import itemCategoryIconMap from '../ItemCategories/iconMap';
import { ItemCreator } from '../ItemCreator';
import { Modal } from '../Modal';

interface ItemsListItemProps {
  item: EventItem;
  event: Event;
  onUpdate: (item: EventItem) => void;
  onDelete: () => void;
}

const Container = styled.div`
  ${tw`flex flex-col border-b border-neutral-stone pb-7 mb-6`}
`;

const Header = styled.div`
  ${tw`flex`}
`;

const Name = styled.div`
  ${tw`text-2xl font-semibold flex-1`}
`;

const HeaderAction = styled.div`
  ${tw`mx-2 text-lg p-1 bg-content-mist rounded-xl self-center`}
`;

const Footer = styled.div`
  ${tw`flex`}
`;

const CategoryChip = styled.div`
  ${tw`flex items-center text-sm bg-content-mist rounded-3xl px-2 mt-5`}
`;

export const ItemsListItem = ({ item, event, onUpdate, onDelete }: ItemsListItemProps) => {
  const deleteDisabledForQuantity = useMemo(
    () => item.claims && item.claims.filter((c) => c.quantity > 0).length > 0,
    [item]
  );

  const deleteDisabledForComments = useMemo(
    () => item.comments && item.comments.length > 0,
    [item]
  );

  const [openConfirmDelete, closeConfirmDelete] = useModal(() => {
    if (!item) {
      return null;
    }
    return (
      <Modal onClose={closeConfirmDelete}>
        {({ close }) => (
          <div className="flex flex-col text-center space-y-4">
            {!deleteDisabledForQuantity && !deleteDisabledForComments && (
              <p>
                Are you sure you want to delete <strong>{item.name}</strong>? This cannot be undone.
              </p>
            )}
            {!deleteDisabledForQuantity && deleteDisabledForComments && (
              <p>
                A guest has left comments on <strong>{item.name}</strong>, so it cannot be deleted.
              </p>
            )}
            {deleteDisabledForQuantity && (
              <p>
                A guest has already claimed <strong>{item.name}</strong>, so it cannot be deleted.
              </p>
            )}
            <div className="flex flex-col justify-evenly sm:flex-row">
              {!deleteDisabledForQuantity && !deleteDisabledForComments && (
                <Button
                  onClick={() => {
                    onDelete();
                    close();
                  }}
                >
                  Delete Item
                </Button>
              )}
              <Button className="mt-2" outline onClick={close}>
                Cancel
              </Button>
            </div>
          </div>
        )}
      </Modal>
    );
  }, [item, deleteDisabledForComments, deleteDisabledForQuantity]);

  return (
    <>
      {item.name !== 'Lodging' && item.name !== 'Transportation' && (
        <Container>
          <Header>
            <Name>{item.name}</Name>
            <ItemCreator
              event={event}
              onSave={(i) => onUpdate(i)}
              item={item}
              openCreatorOption={
                <HeaderAction>
                  <EditIcon className="cursor-pointer" />
                </HeaderAction>
              }
            />
            <HeaderAction onClick={openConfirmDelete}>
              <Trash className="cursor-pointer" />
            </HeaderAction>
          </Header>
          {item.quantity && item.quantity > 0 && (
            <div className="pt-1">
              We'll need {item.quantity} {item.customMeasurement}
            </div>
          )}
          <div className="pt-1 text-sm text-neutral-char">{item.note}</div>
          <Footer>
            {item.category && (
              <CategoryChip>
                {itemCategoryIconMap[item.category.name]}
                <span className={itemCategoryIconMap[item.category.name] ? 'ml-1' : ''}>
                  {item.category.name}
                </span>
              </CategoryChip>
            )}
          </Footer>
        </Container>
      )}
    </>
  );
};
