import { Address } from '../models';

const getFormattedCityStateZipLines = (address: Address) => {
  const hasCity = address.city;
  const hasState = address.state;

  let addressString = ``;
  if (hasCity && hasState) {
    addressString = `${address.city}, ${address.state}`;
  } else if (hasCity && !hasState) {
    addressString = `${address.city}`;
  } else if (!hasCity && hasState) {
    addressString = `${address.state}`;
  }

  if (addressString.length > 0) {
    return addressString + ` ${address.zipCode}`;
  } else {
    return `${address.zipCode}`;
  }
};

export { getFormattedCityStateZipLines };
