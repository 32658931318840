import * as React from 'react';

function SvgTime2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        transform="translate(5 2)"
        stroke="#1D1D1F"
        strokeWidth={1.54}
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M2.843 4.198V.87C2.843.39 3.44 0 4.176 0h4.648c.736 0 1.333.39 1.333.87v3.328M10.157 14.802v3.327c0 .481-.597.871-1.333.871H4.176c-.736 0-1.333-.39-1.333-.87v-3.328"
          fill="#DDDDDC"
        />
        <ellipse fill="#ECECEC" fillRule="nonzero" cx={6.5} cy={9.5} rx={6.5} ry={6.665} />
        <path strokeLinecap="round" strokeLinejoin="round" d="M10.879 9.75H6.543l-2.98-3.056" />
      </g>
    </svg>
  );
}

export default SvgTime2;
