import styled from '@emotion/styled';
import { ComponentProps, FC, useRef } from 'react';
import { useClickAway } from 'react-use';
import tw from 'twin.macro';

import { FadeIn } from '../animations';

const Anchor = styled(FadeIn)`
  ${tw`relative z-30 w-0`}
`;

const Container = styled.div`
  ${tw`absolute w-max px-4 py-2 rounded-lg shadow-md bg-neutral-white`}
`;

interface PopupProps {
  onClose?: () => void;
}

export const Popup: FC<ComponentProps<'div'> & PopupProps> = ({ children, onClose, ...props }) => {
  const container = useRef<HTMLDivElement>(null);

  useClickAway(container, () => onClose?.());

  return (
    <Anchor>
      <Container {...props} ref={container}>
        {children}
      </Container>
    </Anchor>
  );
};

export default Popup;
