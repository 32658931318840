import styled from '@emotion/styled';
import { useMemo, useState } from 'react';
import tw from 'twin.macro';

import { Event, EventInvite, EventRole, User } from '../../models';
import { Button } from '../Button';
import { RoleListboxSelect } from '../Input';
import { UserSummary } from '../UserSummary';

const InvitationButton = styled(Button)`
  ${tw`flex-shrink-0`}

  :disabled {
    ${tw`bg-content-pearl border-neutral-white px-3`}
  }
`;

interface ConnectionListItemProps {
  connection: User;
  event: Event;
  roleEnabled?: boolean;
  isEdit?: boolean;
  displayRoleDescriptions: boolean;
  editInvite?: EventInvite;
  onAddInvite: (invite: EventInvite) => void;
  onUpdateInvite?: (invite: EventInvite) => void;
}

export const ConnectionListItem = ({
  connection,
  event,
  roleEnabled = true,
  isEdit,
  displayRoleDescriptions,
  onAddInvite,
  editInvite,
  onUpdateInvite
}: ConnectionListItemProps) => {
  const connectionHasInvite = useMemo(() => {
    return event.invites.find(
      (c) =>
        c.email === connection.email && (c.phoneNumber ?? '') === (connection.phoneNumber ?? '')
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, event.invites, connection]);

  const [selectedRole, setSelectedRole] = useState(() => {
    const invite = event.invites.find((i: EventInvite) => i.user?.id === connection.id);
    return invite?.role ? invite.role : EventRole.Guest;
  });

  return (
    <div className="mb-8">
      <div className="flex w-full px-2 space-between">
        <UserSummary user={connection} className="flex-1 py-3" />
        <InvitationButton
          outline={!connectionHasInvite}
          inline
          disabled={!!connectionHasInvite}
          onClick={() =>
            onAddInvite({
              firstName: connection.firstName,
              lastName: connection.lastName,
              email: connection.email,
              phoneNumber: connection.phoneNumber,
              user: { ...connection },
              role: selectedRole
            } as EventInvite)
          }
        >
          {!connectionHasInvite ? 'Invite' : 'Invited'}
        </InvitationButton>
      </div>
      {roleEnabled && (
        <RoleListboxSelect
          initialRole={selectedRole}
          displayRoleDescriptions={displayRoleDescriptions}
          onChange={(role) => {
            setSelectedRole(role);
            if (isEdit) {
              onUpdateInvite?.({
                ...editInvite,
                role: role
              } as EventInvite);
            }
          }}
        />
      )}
    </div>
  );
};

export default ConnectionListItem;
