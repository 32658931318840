import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useHistory } from 'react-router-dom';
import Toggle from 'react-toggle';
import tw from 'twin.macro';

import { Button, GuestList, GuestListCreator, Modal } from '../../components';
import SvgCohosts from '../../components/icons/Cohosts';
import SvgHyperlink from '../../components/icons/Hyperlink';
import { ProTipBanner } from '../../components/ProTipBanner';
import { Event } from '../../models';
import { useUser } from '../../providers';

interface GuestsProps {
  event: Event;
  onChange: (event: Event) => void;
  onSubmit: (event: Event) => void;
  onCancelEvent?: (eventId: number) => void;
  saveButtonName: string;
}

const Container = styled.div`
  ${tw`flex flex-col flex-1 justify-between`}
`;

const Cancel = styled.div`
  ${tw`cursor-pointer select-none text-indicating-error text-center`}
`;

const NextButton = styled(Button)`
  ${tw`min-w-0 px-14`}
`;

const AddGuestButton = styled.div`
  ${tw`cursor-pointer select-none text-interaction mt-5`}
`;

export const Guests = observer(
  ({ event, onChange, onSubmit, onCancelEvent, saveButtonName }: GuestsProps) => {
    const userProvider = useUser();
    const router = useHistory();
    const { user } = userProvider;
    const isOrganizer = event?.organizer?.id === user?.id;
    const [allowPlusOnes, setAllowPlusOnes] = useState(event.allowPlusOnes);
    const [lastUsedTab, setLastUsedTab] = useState('connection');

    const updatePlusOnes = (checked: boolean) => {
      if (!checked) {
        showPlusOnesWarningIfApplicable();
      } else {
        enablePlusOnes();
      }
    };

    const enablePlusOnes = () => {
      event.allowPlusOnes = true;
      setAllowPlusOnes(true);
    };

    const disablePlusOnes = () => {
      event.allowPlusOnes = false;
      setAllowPlusOnes(false);
    };

    const showPlusOnesWarningIfApplicable = () => {
      const eventHasPlusOnes = event.guests.some((g) => g.guestCount > 1);
      if (eventHasPlusOnes) {
        openPlusOnesWarning();
      } else {
        disablePlusOnes();
      }
    };

    const [openPlusOnesWarning, closePlusOnesWarning] = useModal(() => {
      return (
        <Modal fullWidth={false} onClose={closePlusOnesWarning}>
          {({ close }) => (
            <div className="flex flex-col space-y-4">
              <Container className="flex justify-center">
                <div>
                  <div className="text-lg text-left font-semibold">
                    Some guests have already RSVP'd with a group. Turning this off will remove their
                    +1s but leave them as a single attendee. Continue?
                  </div>
                </div>
              </Container>
              <Button
                onClick={() => {
                  disablePlusOnes();
                  closePlusOnesWarning();
                }}
              >
                Yes
              </Button>
              <Button
                outline
                onClick={() => {
                  closePlusOnesWarning();
                }}
              >
                Cancel
              </Button>
            </div>
          )}
        </Modal>
      );
    }, [allowPlusOnes]);

    if (!event.id) {
      router.push('/');
    }

    return (
      <Container>
        <div>
          <GuestList
            initialTab={lastUsedTab}
            onTabChange={(tab) => {
              setLastUsedTab(tab);
            }}
            event={event}
            onDelete={(i) => {
              const invites = [...event.invites];
              invites.splice(i, 1);
              onChange({ ...event, invites: [...invites] });
            }}
            onUpdateInvite={(invite, i) => {
              const invites = [...event.invites];
              if (invite.user) {
                const connectionIndex = invites.findIndex((i) => i.user?.id === invite.user?.id);
                invites[connectionIndex] = { ...invite };
              } else {
                invites[i] = { ...invite };
              }
              onChange({ ...event, invites: [...invites] });
            }}
          />

          <GuestListCreator
            initialTab={lastUsedTab}
            onTabChange={(tab) => {
              setLastUsedTab(tab);
            }}
            event={event}
            onAddInvite={(invite) => {
              onChange({
                ...event,
                invites: [...(event.invites || []), { ...invite }]
              });
            }}
            openCreatorOption={
              <AddGuestButton>
                {event.invites.length > 0 ? '+ Add another guest' : '+ Add a guest'}
              </AddGuestButton>
            }
          />
        </div>
        <div className="flex flex-col pt-10">
          {isOrganizer && {
            ...(event.invites.length > 0 ? (
              <ProTipBanner
                title={'PRO TIP: Add co-hosts!'}
                details={
                  "Switch any guest's role to “co-host” and they'll be able to help you manage the event"
                }
                icon={<SvgCohosts />}
              ></ProTipBanner>
            ) : (
              <ProTipBanner
                title={'PRO TIP: Just share your event link.'}
                details={
                  'You can send individual invitations or just simply share out your event ' +
                  'link and guests can RSVP directly on the event page.'
                }
                icon={<SvgHyperlink />}
              ></ProTipBanner>
            ))
          }}
          <div className="flex pt-8">
            <div className="pb-4 pr-4">Allow guests to bring other guests?</div>
            <div>
              <Toggle checked={allowPlusOnes} onChange={(e) => updatePlusOnes(e.target.checked)} />
            </div>
          </div>
          <div className={`flex w-full mt-10 ${onCancelEvent ? 'justify-between' : 'justify-end'}`}>
            {onCancelEvent && (
              <Cancel className="self-center" onClick={() => onCancelEvent(event.id)}>
                Cancel
              </Cancel>
            )}
            <NextButton onClick={() => onSubmit(event)}>{saveButtonName}</NextButton>
          </div>
        </div>
      </Container>
    );
  }
);

export default Guests;
