import { Event, EventItem, EventItemClaim, ItemCategory } from '../models';
import { fetcher } from '../providers';
import usePaged from './usePaged';

export function useEventItems(eventId: number) {
  const { data: items, ...pagination } = usePaged<EventItem>(10, `/api/event/${eventId}/items`);

  return { items, ...pagination };
}

export function useEventItemsForCategory(eventId: number, categoryId: number) {
  const { data: items, ...pagination } = usePaged<EventItem>(
    10,
    `/api/event/${eventId}/items/${categoryId}`
  );

  return { items, ...pagination };
}

export async function claimItems(
  event: Event,
  claims: Partial<EventItemClaim>[]
): Promise<EventItem[]> {
  return await fetcher(`/api/event/${event.id}/claims`, {
    method: 'POST',
    body: JSON.stringify(claims)
  });
}

export async function clearClaims(event: Event) {
  return await fetcher(`/api/event/${event.id}/claims`, { method: 'DELETE' });
}

export async function addCustomCategory(event: Event, categoryName: string): Promise<ItemCategory> {
  return await fetcher(`/api/event/${event.id}/add-custom-category`, {
    method: 'POST',
    body: JSON.stringify({ name: categoryName })
  });
}

export default useEventItems;
