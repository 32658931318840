import * as React from 'react';

function SvgClothing(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#1C1F21" strokeWidth={1.54} fill="none" fillRule="evenodd">
        <path
          d="M12.013 21H6.808v-9.621l-1.826 1.145L2 8.381l4.635-4.055c.24-.21.547-.326.866-.326h2.045c.282 0 .56.072.805.21.489.275 1.098.412 1.828.412.788 0 1.542-.16 2.263-.48.21-.094.438-.142.668-.142h1.69c.335 0 .656.127.9.355L22 8.381h0L19.375 12.5l-2.055-1.911V21h-5.307z"
          fill="#F8F6F1"
        />
        <path
          d="M9.634 4.26c.78.333 1.561.499 2.342.499.78 0 1.56-.166 2.341-.5a.329.329 0 01.458.303v1.253a1.77 1.77 0 01-1.77 1.77h-2.06a1.77 1.77 0 01-1.768-1.77V4.562a.329.329 0 01.457-.302z"
          fill="#F0EBE0"
        />
      </g>
    </svg>
  );
}

export default SvgClothing;
