import styled from '@emotion/styled';
import { ComponentProps, forwardRef } from 'react';
import tw from 'twin.macro';

import { CircleButton } from '../Button';
import { SendWhite } from '../icons';
import Input from './Input';

export const ControlAnchor = styled.div`
  ${tw`fixed bottom-0 w-full px-6 pt-4 pb-6 -mx-6 bg-neutral-white transform -translate-x-1/2`}
`;

export const Control = styled(Input)`
  ${tw`w-full h-12 px-4 rounded-full border-neutral-black pr-11`}
`;

const SendButton = styled(CircleButton)`
  ${tw`absolute z-10 flex items-center justify-center pt-1 text-4xl border-0 w-9 h-9 bg-interaction`}
  right: 0.375rem;
  top: 0.375rem;

  svg {
    height: 100%;
    width: 100%;
  }

  :disabled {
    ${tw`bg-secondary-100`}
  }
`;

export const MessageInput = forwardRef<HTMLInputElement, ComponentProps<'input'>>(
  ({ disabled, ...props }: ComponentProps<'input'>, ref) => {
    return (
      <div className="relative">
        <Control
          {...props}
          disableSubtlePlaceholder
          autoComplete="off"
          placeholder="Add your comment..."
          ref={ref}
        />
        <SendButton disabled={disabled}>
          <SendWhite />
        </SendButton>
      </div>
    );
  }
);

export default MessageInput;
