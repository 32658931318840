
import useSWR from 'swr';

import { Message, Reaction } from '../models';
import { fetcher } from '../providers';
import usePaged from './usePaged';

export function useMessages(eventId: number, size?: number) {
  const { data: messages, ...pagination } = usePaged<Message>(
    size || 15,
    `/api/event/${eventId}/message`
  );

  async function react(messageId: number, reaction: Reaction) {
    await fetcher(`/api/event/${eventId}/message/${messageId}/reaction/${reaction}`, {
      method: 'POST'
    });
    await pagination.mutate();
  }

  async function unreact(reactionId: number) {
    await fetcher(`/api/event/reaction/${reactionId}`, {
      method: 'DELETE'
    });

    pagination.mutate();
  }

  async function send(message: string) {
    await fetcher(`/api/event/${eventId}/message`, { method: 'POST', body: message });
    await pagination.mutate();
  }

  async function deleteComment(commentId: number) {
    await fetcher(`/api/event/items/comments/${commentId}`, {
      method: 'DELETE'
    });

    pagination.mutate();
  }

  return { messages, ...pagination, send, react, unreact, deleteComment };
}

export function useEventItemComments(eventId: number, eventItemId: number, size?: number) {
  const { data: comments } = useSWR<Message[]>(`/api/event/${eventId}/items/${eventItemId}/comment`);

  async function send(comment: string) {
    await fetcher(`/api/event/${eventId}/items/${eventItemId}/comment`, {
      method: 'POST',
      body: comment
    });
  }

  return { comments, send };
}

export function useMomentComments(eventId: number, momentId: number, size?: number) {
  const { data: comments, ...pagination } = usePaged<Message>(
    size || 15,
    `/api/event/${eventId}/moment/${momentId}/comment`
  );

  async function send(comment: string) {
    await fetcher(`/api/event/${eventId}/moment/${momentId}/comment`, {
      method: 'POST',
      body: comment
    });
    await pagination.mutate();
  }

  return { comments, ...pagination, send };
}

export default useMessages;
