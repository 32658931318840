import * as React from 'react';

function SvgEllipses(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fillRule="evenodd">
        <path d="M10.333 10.256C10.815 9.752 11.37 9.5 12 9.5c.63 0 1.185.252 1.667.756.481.504.722 1.085.722 1.744s-.24 1.24-.722 1.744S12.63 14.5 12 14.5c-.63 0-1.185-.252-1.667-.756-.481-.504-.722-1.085-.722-1.744s.24-1.24.722-1.744zM17.444 10.256c.482-.504 1.037-.756 1.667-.756.63 0 1.185.252 1.667.756.481.504.722 1.085.722 1.744s-.24 1.24-.722 1.744-1.037.756-1.667.756c-.63 0-1.185-.252-1.667-.756-.481-.504-.722-1.085-.722-1.744s.241-1.24.722-1.744zM3.222 10.256C3.704 9.752 4.26 9.5 4.89 9.5c.63 0 1.185.252 1.667.756.481.504.722 1.085.722 1.744s-.241 1.24-.722 1.744c-.482.504-1.037.756-1.667.756-.63 0-1.185-.252-1.667-.756C2.741 13.24 2.5 12.66 2.5 12s.24-1.24.722-1.744z" />
      </g>
    </svg>
  );
}

export default SvgEllipses;
