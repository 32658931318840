import { EventMoment, Message } from '../models';
import { LazyStore } from './Lazy';

export class MomentStore {
  private feeds: Record<number, LazyStore<EventMoment>> = {};
  private _comments: Record<number, LazyStore<Message>> = {};

  feed(eventId: number) {
    const feed = this.feeds[eventId] || new LazyStore<EventMoment>(`event/${eventId}/moment`);
    this.feeds[eventId] = feed;

    return {
      ...feed,
      register: feed.register
    };
  }

  comments(eventId: number, momentId: number): LazyStore<Message> {
    const comments =
      this._comments[momentId] ||
      new LazyStore<Message>(`event/${eventId}/moment/${momentId}/comment`, 2);

    this._comments[momentId] = comments;

    return comments;
  }
}

export default MomentStore;
