import { Event } from '../models';
import usePaged from './usePaged';

export function useOrganizingEvents() {
  const { data: events, ...pagination } = usePaged<Event>(10, '/api/event');

  return { events, ...pagination };
}

export function useAttendingEvents() {
  const { data: events, ...pagination } = usePaged<Event>(10, '/api/event/attending');

  return { events, ...pagination };
}

export function useEndedEvents() {
  const { data: events, ...pagination } = usePaged<Event>(10, '/api/event/ended');

  return { events, ...pagination };
}

export default useOrganizingEvents;
