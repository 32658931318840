import { createContext, FC, useContext, useState } from 'react';

import { PotluckStore } from '../stores';

const Potluck = new PotluckStore();
const PotluckContext = createContext<PotluckStore & { clear: () => void }>({
  ...Potluck,
  clear: () => void {}
});

export const PotluckProvider: FC = ({ children }) => {
  const [potluck, setPotluck] = useState(Potluck);

  return (
    <PotluckContext.Provider
      value={{ ...potluck, clear: () => void setPotluck(new PotluckStore()) }}
    >
      {children}
    </PotluckContext.Provider>
  );
};

export function usePotluck() {
  const context = useContext(PotluckContext);
  if (context === undefined) {
    throw new Error('usePotluck must be used within PotluckProvider');
  }

  return context;
}

export default PotluckProvider;
