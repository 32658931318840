import { useEffect, useState } from 'react';
import useSWR from 'swr';

import { EngagementMetrics } from '../models';

export function useMomentEngagementMetrics(eventId: number, momentId: number, activate?: boolean) {
  const [activator, setActivator] = useState('');

  useEffect(() => {
    if (activate && !activator) {
      setActivator(`/api/event/${eventId}/moment/${momentId}/engagement`);
    }
  }, [activate, activator, eventId, momentId]);

  const { data: metrics, ...query } = useSWR<EngagementMetrics>(() => activator);

  return {
    metrics,
    ...query
  };
}
