import * as React from 'react';

function SvgToiletries(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        transform="translate(3 3)"
        stroke="#070401"
        strokeWidth={1.54}
        fill="none"
        fillRule="evenodd"
      >
        <path fill="#D7D7DA" d="M14.716 12.619H1.723V1.87L14.716.063" />
        <ellipse fill="#F0EBE0" cx={14.679} cy={6.341} rx={3.321} ry={6.278} />
        <ellipse fill="#F0EBE0" cx={14.886} cy={6.117} rx={1.453} ry={2.915} />
        <path
          d="M2.595.308C3.734-.186 14.367.063 14.679.063c.205 0-.477-.04-1.138.357-.34.205-.77.665-.96.98-.58.957-.87 1.358-1.223 3.582-.237 1.482-.237 5.523 0 12.121L10.316 18l-1.311-.897-1.31.897-1.196-.897L5.193 18l-1.302-.897L2.648 18l-1.306-.897L.149 18C-.05 9.634-.05 4.738.149 3.311.446 1.171 1.456.803 2.595.308z"
          fill="#F8F6F1"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray={2.16}
          d="M.424 7.818h8.168"
        />
      </g>
    </svg>
  );
}

export default SvgToiletries;
