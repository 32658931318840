import { useModal } from 'react-modal-hook';

import { Button, Modal } from '../components';
import { fetcher } from '../providers';

export function useSendRsvpReminderModal(eventId: number | undefined, onSuccess: () => void) {
  const sendRsvpReminder = async () => {
    try {
      await fetcher(`/api/event/${eventId}/send-rsvp-reminders`, {
        method: 'POST'
      });
      onSuccess();
    } finally {
      closeRsvpReminderModal();
    }
  };

  const [openRsvpReminderModal, closeRsvpReminderModal] = useModal(() => {
    if (!eventId) {
      return null;
    }

    return (
      <Modal fullWidth={false} onClose={closeRsvpReminderModal}>
        {({ close }) => (
          <div className="flex flex-col text-center space-y-8">
            <div className="text-2xl">RSVP Reminder</div>
            <div>
              A reminder including RSVP links will be sent to all guests who have not yet responded.
            </div>
            <div className="flex flex-col justify-evenly sm:flex-row">
              <Button outline onClick={close}>
                Cancel
              </Button>
              <Button onClick={sendRsvpReminder} className="mt-2">
                Confirm
              </Button>
            </div>
          </div>
        )}
      </Modal>
    );
  });

  return { openRsvpReminderModal };
}
