import styled from '@emotion/styled';
import { ComponentProps } from 'react';
import tw from 'twin.macro';

import { Message } from '../../models';
import { useUser } from '../../providers';
import { EditIcon } from '../icons';
import { ProfilePicture as _ProfilePicture } from '../ProfilePicture';

type CommentsProps = ComponentProps<'div'> & {
  comments: Message[];
  onEdit: (comment: Message) => void;
};

const Comment = styled.div`
  ${tw`flex space-x-4 items-center`}
`;

const ProfilePicture = styled(_ProfilePicture)`
  ${tw`relative w-8 h-8 items-center justify-center text-sm`}
`;

const Container = styled.div`
  ${tw`flex flex-col space-y-4`}
`;

export function Comments({ comments, onEdit, ...props }: CommentsProps) {
  const { user } = useUser();

  return (
    <Container {...props}>
      {comments.map((c) => (
        <Comment key={c.id}>
          <ProfilePicture user={c.author} className="flex-shrink-0" />
          <div>{c.message}</div>
          {c.author?.id === user?.id && (
            <div className="text-interaction cursor-pointer">
              <div className="flex space-x-1 items-center" onClick={() => onEdit(c)}>
                <EditIcon /> <span>Edit</span>
              </div>
            </div>
          )}
        </Comment>
      ))}
    </Container>
  );
}

export default Comments;
