import Address from './Address';
import Message from './Message';
import User from './User';

export interface Event {
  id: number;
  name: string;
  organizer: User;
  adultGuestCount: number;
  category: EventCategory;
  childGuestCount: number;
  guestMessage?: string;
  eventPageMessage?: string;
  cohostMessage?: string;
  coverImageUrl?: string;
  coverImage?: File;
  startTime?: string;
  endTime?: string;
  recap: string;
  invites: EventInvite[];
  guests: EventGuest[];
  items: EventItem[];
  status: EventStatus;
  address?: Address;
  timezone?: string;
  hasCohosts?: boolean;
  paidSingleEvent?: boolean;
  code?: string;
  allowPlusOnes: boolean;
}

export enum EventCategory {
  Event = 'Event',
  Excursion = 'Excursion'
}

export enum EventStatus {
  Unplanned = 'Unplanned',
  Planning = 'Planning',
  Planned = 'Planned',
  StartingSoon = 'StartingSoon',
  Started = 'Started',
  Ended = 'Ended'
}

export interface EventInvite {
  id: number;
  firstName: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  role: EventRole;
  accepted?: boolean;
  user?: User;
  sentAt?: Date;
  respondedAt?: Date;
}

export interface ItemMeasurement {
  id: number;
  name: string;
}

export interface ItemCategory {
  id: number;
  name: string;
}

export interface EventItemClaim {
  id: number;
  eventItemId: number;
  user: User;
  quantity: number;
}

export interface EventItem {
  id: number;
  name: string;
  quantity: number | undefined;
  note?: string;
  category?: ItemCategory;
  measurement?: ItemMeasurement;
  customMeasurement?: string;
  comments: Message[];
  claims: EventItemClaim[];
}

export enum EventRole {
  Organizer = 'Organizer',
  Guest = 'Guest',
  Cohost = 'CoHost'
}

export interface EventGuest {
  guest: User;

  // The total number of guests in the EventGuest's party (including the guest). 
  // Defaults to 0 if no response has been collected from the guest.
  guestCount: number;

  role: EventRole;
}

export interface EventMoment {
  id: number;
  creator: User;
  imageUrl?: string;
  note: string;
}

export enum Reaction {
  Like = 'like'
}

export interface UserReaction {
  id: number;
  user: User;
  reaction: Reaction;
}

export interface EngagementMetrics {
  comments: number;
  reactions: Record<Reaction, number>;
}

export type EventInsights = {
  trends: number[];
  categories: Record<string, { quantity: number; claims: number }>;
};

export function getItemsByCategory(items: EventItem[]) {
  return items.reduce<Record<string, EventItem[]>>((itemsByCategory, item) => {
    const category = item.category?.name || 'uncategorized';
    itemsByCategory[category] = [...(itemsByCategory[category] || []), item];

    return itemsByCategory;
  }, {});
}

export default Event;
