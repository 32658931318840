import { useModal } from 'react-modal-hook';

import { Button, Question } from '..';
import Modal from '../Modal/Modal';

interface ItemsHelpLinkProps {
  howToButton?: boolean;
}

export const ItemsHelpLink = ({howToButton = false}: ItemsHelpLinkProps) => {
  const [showItemsHelp, closeItemsHelp] = useModal(() => (
    <Modal onClose={closeItemsHelp} fullHeight>
      {({ close }) => (
        <>
          <div className="flex flex-col text-left space-y-4">
            <p className="font-bold text-center mb-4 text-lg">Pro Tips</p>
            <p>
              You can{' '}
              <b>
                be <i>general</i>
              </b>{' '}
              with items like "Appetizers" and your guests will be able to write in specific dishes.
            </p>
            <p>
              You can{' '}
              <b>
                be <i>specific</i>
              </b>{' '}
              with items like "deviled eggs" and setting a quantity.
            </p>
            <p>
              You can use the{' '}
              <b>
                <i>Category Tags</i>
              </b>{' '}
              to bucket like items together. E.g., you can tag multiple items as "Sides" and those
              items will become their own menu.
            </p>
            <p>
              <b>
                <i>Custom Category Tags</i>
              </b>{' '}
              can be used to create multi-stage or multi-day events. E.g., you can tag items as
              "Friday" and "Saturday" or "First Course" and "Second Course".
            </p>
            <p>
              You can <b>ask for volunteers or assign tasks</b>. E.g., "Help with setup" for 3
              people. In the notes, you can offer directions like "Please arrive one hour early".
            </p>
            <div className="text-center pt-14">
              <Button onClick={close}>Got it</Button>
            </div>
          </div>
        </>
      )}
    </Modal>
  ));

  return howToButton ?
      <Button className="self-center ml-4" outline inline borderInfo onClick={showItemsHelp}>
        <div>How To</div>
      </Button> 
    :
    <Question onClick={showItemsHelp} />;
};
