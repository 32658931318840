import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import tw from 'twin.macro';

import Input from './Input';

const Container = styled.div`
  ${tw`flex items-end space-x-2`}
`;

const DigitInput = styled(Input)`
  ${tw`text-2xl text-center w-14`}
`;

const Meridiem = styled.div`
  ${tw`flex flex-col overflow-hidden border rounded-md`}
`;

const MeridiemButton = styled.button<{ active?: boolean }>`
  ${tw`px-2`}

  ${(p) => p.active && tw`bg-secondary`}

  :hover {
    ${(p) => !p.active && tw`bg-content-spring-wood`}
  }
`;

interface TimeRangeProps {
  value: string;
  onChange?: (time: string) => void;
}

const digitize = (num: number) => `${num}`.padStart(2, '0');

const format = (input: string) => {
  return input.slice(Math.max(input.length - 2, 0), input.length);
};

export function TimePicker({ value, onChange }: TimeRangeProps) {
  const [_hour, _minute] = [...value.split(':').map((v) => Number(v)), 0, 0];
  const [hour, setHour] = useState<number>(_hour);
  const [minute, setMinute] = useState<number>(_minute);

  const displayHour = digitize(hour > 12 ? hour - 12 : hour);
  const displayMinute = digitize(minute);

  useEffect(() => {
    setHour(_hour);
    setMinute(_minute);
  }, [_hour, _minute]);

  const ensureNumeric = (input: string) => {
    const numeric = Number(input);

    if (isNaN(numeric)) {
      return 0;
    }

    return numeric;
  };

  return (
    <Container>
      <div>
        <div className="text-xs">hr</div>
        <DigitInput
          autoComplete="off"
          inputMode="numeric"
          disableSubtlePlaceholder
          value={displayHour}
          onFocus={(e) => {
            if (hour === 0) e.target.value = '';
          }}
          onBlur={(e) => {
            if (hour === 0) e.target.value = '00';
          }}
          onChange={(e) => {
            const hour = Math.min(23, ensureNumeric(format(e.target.value)));
            setHour(hour);

            if (!minute) {
              setMinute(0);
            }

            onChange?.(`${digitize(hour)}:${digitize(minute)}`);
          }}
          placeholder="00"
        />
      </div>
      <div>
        <div className="text-xs">min</div>
        <DigitInput
          autoComplete="off"
          inputMode="numeric"
          disableSubtlePlaceholder
          value={displayMinute}
          onFocus={(e) => {
            if (minute === 0) e.target.value = '';
          }}
          onBlur={(e) => {
            if (minute === 0) e.target.value = '00';
          }}
          onChange={(e) => {
            const minute = Math.min(59, ensureNumeric(format(e.target.value)));
            setMinute(minute);

            onChange?.(`${digitize(hour)}:${digitize(minute)}`);
          }}
          placeholder="00"
        />
      </div>
      <Meridiem>
        <MeridiemButton
          onClick={(e) => {
            e.preventDefault();
            const _hour = hour > 12 ? hour - 12 : hour;
            setHour(_hour);
            onChange?.(`${digitize(_hour)}:${digitize(minute)}`);
          }}
          active={hour < 12}
        >
          AM
        </MeridiemButton>
        <MeridiemButton
          onClick={(e) => {
            e.preventDefault();
            const _hour = hour < 12 ? hour + 12 : hour;
            setHour(_hour);
            onChange?.(`${digitize(_hour)}:${digitize(minute)}`);
          }}
          active={hour >= 12}
        >
          PM
        </MeridiemButton>
      </Meridiem>
    </Container>
  );
}

export default TimePicker;
