import useSWR from 'swr';

import { Reaction, UserReaction } from '../models';
import { fetcher } from '../providers';

export function useMomentReactions(eventId: number, momentId: number) {
  const { data: reaction, ...query } = useSWR<UserReaction>(
    `/api/event/${eventId}/moment/${momentId}/reaction`
  );

  async function react(reaction: Reaction) {
    const userReaction = await fetcher(
      `/api/event/${eventId}/moment/${momentId}/reaction/${reaction}`,
      {
        method: 'POST'
      }
    );

    if (userReaction?.id) {
      query.mutate(userReaction);
    }

    return userReaction;
  }

  async function unreact(reactionId: number) {
    await fetcher(`/api/event/reaction/${reactionId}`, {
      method: 'DELETE'
    });

    query.mutate(undefined);
  }

  return {
    reaction: reaction?.id ? reaction : undefined,
    react,
    unreact,
    ...query
  };
}
