import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { ComponentProps, useEffect, useRef, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { Link } from 'react-router-dom';
import { useIntersection } from 'react-use';
import tw from 'twin.macro';

import { EventLinkCopy, Loader, Notification, Popup, Portal } from '../../components';
import { useDuplicateEventConfirmationModal, useEventMetrics } from '../../hooks';
import { useSendGuestMessageModal } from '../../hooks/useSendGuestMessageModal';
import { useSendRsvpReminderModal } from '../../hooks/useSendRsvpReminderModal';
import { Event, EventGuest, EventRole } from '../../models';
import { usePotluck, useUser } from '../../providers';
import AddressDisplay from '../AddressDisplay/AddressDisplay';
import { fadeInAnimation } from '../animations';
import { Button } from '../Button';
import { Card } from '../Card';
import { EventCodeCopy } from '../EventCodeCopy';
import { EventEditMenu } from '../EventEditMenu';
import EventImportantActionButton from '../EventImportantActionButton/EventImportantActionButton';
import { CircleEdit, CircleShare, Ellipses } from '../icons';
import { Modal } from '../Modal';
import { Timestamp } from '../Timestamp/Timestamp';

const HeaderContainer = styled.div`
  ${tw`overflow-hidden bg-neutral-stone bg-opacity-50 rounded-t-3xl rounded-br-3xl`}
  height: 9.5rem;
`;

const Header = styled.div<{ background?: string }>`
  ${tw`flex items-end mb-4 justify-between p-4 bg-center bg-no-repeat bg-cover bg-secondary-600`}
  height: 9.5rem;

  background-image: ${(p) => p.background && `url(${p.background})`};
  transition: background 0.1s ease-in;

  ${(p) => p.background && fadeInAnimation(0.3)}
`;

const Body = styled.div`
  ${tw`flex flex-col flex-1 flex-grow p-6 pt-6`}
`;

const Headline = styled.div`
  ${tw`flex justify-between`}
`;

const Name = styled.div`
  ${tw`overflow-hidden text-xl font-bold overflow-ellipsis`}
  max-width: 85%;
`;

const EventCard = styled(Card)`
  ${tw`flex flex-col w-full p-0 my-4 overflow-visible md:mr-8`}
  height: 20rem;
  max-width: calc(100vw - 3rem);

  @media (min-width: 768px) {
    max-width: 21.25rem;
  }

  ${fadeInAnimation(0.3)}
`;

const BasicInsight = styled.div`
  ${tw`flex justify-between space-x-12`}

  a {
    ${tw`text-neutral-soil`}
  }
`;

const MenuItem = styled.div`
  ${tw`text-interaction cursor-pointer select-none text-left self-center px-2 pb-2`}
`;

export const EventSummary = observer(
  ({
    event,
    isOrganizer,
    userId,
    ...props
  }: { event: Event; isOrganizer?: boolean; userId?: number } & ComponentProps<'div'>) => {
    const { organizing } = usePotluck();
    const userProvider = useUser();
    const [currentCoverImageUrl, setCurrentCoverImageUrl] = useState(event.coverImageUrl);
    const { user } = userProvider;
    const element = useRef<HTMLDivElement>(null);
    const intersection = useIntersection(element, { threshold: 0.1 });
    const eventRole = event.guests.find((g: EventGuest) => g.guest.id === userId)?.role;
    const isCoHost = eventRole === EventRole.Cohost;
    const { openDuplicateEventConfirmation } = useDuplicateEventConfirmationModal(event);
    const [showMessageSent, setShowMessageSent] = useState(false);

    const handleMessageSentDisplay = () => {
      setShowMessageSent(true);
      setTimeout(() => setShowMessageSent(false), 3000);
    };

    const { openRsvpReminderModal } = useSendRsvpReminderModal(event.id, handleMessageSentDisplay);
    const { openGuestMessageModal } = useSendGuestMessageModal(event.id, handleMessageSentDisplay);

    const [showEditMenu, setShowEditMenu] = useState(false);
    const [showShareMenu, setShowShareMenu] = useState(false);

    useEffect(() => {
      setCurrentCoverImageUrl(event.coverImageUrl);
    }, [event]);

    if (!user) {
      return <Loader />;
    }

    const toggleEditMenu = () => {
      setShowEditMenu(!showEditMenu);
      setShowShareMenu(false);
    };

    const toggleShareMenu = () => {
      setShowShareMenu(!showShareMenu);
      setShowEditMenu(false);
    };

    const eventCancelText = `Are you sure you want to cancel <b>${event.name}</b>?<br>All attendees will be notified.<br>This cannot be undone.`;

    const [openConfirmCancelEvent, closeConfirmCancelEvent] = useModal(
      () => (
        <Modal onClose={closeConfirmCancelEvent}>
          {({ close }) => (
            <div className="flex flex-col text-center space-y-4">
              <p dangerouslySetInnerHTML={{ __html: eventCancelText }}></p>
              <div className="flex flex-col justify-evenly sm:flex-row">
                <Button
                  onClick={async () => {
                    close();
                    await organizing.cancel(event.id);
                  }}
                >
                  Cancel Event
                </Button>
                <Button className="mt-2" outline onClick={close}>
                  I've changed my mind
                </Button>
              </div>
            </div>
          )}
        </Modal>
      ),
      [event]
    );

    const { metrics } = useEventMetrics(event.id, intersection?.isIntersecting);

    if (!metrics) {
      return (
        <EventCard className="flex-initial flex-grow-0" {...props} ref={element}>
          <HeaderContainer className="animate-pulse">
            <Header className="bg-opacity-50"></Header>
          </HeaderContainer>
          <Body className={`${isOrganizer ? 'justify-between' : 'justify-start'}`}>
            <Headline className="animate-pulse space-x-8">
              <Name className="flex-1 mr-12 bg-neutral-stone bg-opacity-50"></Name>
              <Ellipses className="flex-shrink-0 text-2xl fill-current text-neutral-stone bg-opacity-50" />
            </Headline>
            <BasicInsight>
              <div className="flex flex-col flex-1 space-y-2">
                <div className="w-4/5 p-1 bg-neutral-stone bg-opacity-50"></div>
                <div className="w-full p-1 bg-neutral-stone bg-opacity-50"></div>
              </div>

              <div className="flex flex-col flex-1 space-y-2">
                <div className="w-4/5 p-1 bg-neutral-stone bg-opacity-50"></div>
                <div className="w-full p-1 bg-neutral-stone bg-opacity-50"></div>
              </div>
            </BasicInsight>
          </Body>
        </EventCard>
      );
    }

    return (
      <>
        <Portal container="notifications">
          {showMessageSent && <Notification>Message sent!</Notification>}
        </Portal>
        <EventCard className="flex-initial flex-grow-0" {...props} ref={element}>
          <Link
            to={`/event/${event.id}`}
            className="text-neutral-soil rounded-t-3xl rounded-br-3xl"
          >
            <HeaderContainer>
              <Header background={currentCoverImageUrl}>
                <EventImportantActionButton event={event} user={user} />
                <Timestamp event={event} />
              </Header>
            </HeaderContainer>
          </Link>
          <Body className={`${isOrganizer ? 'justify-between' : 'justify-start'}`}>
            <Headline>
              <Name>
                <Link to={`/event/${event.id}`} className="text-neutral-soil">
                  {event.name}
                </Link>
              </Name>
              <div className="flex flex-col justify-start">
                <div className="flex pl-4">
                  {(isOrganizer || isCoHost) && (
                    <>
                      <CircleEdit
                        className="flex-shrink-0 text-3xl cursor-pointer select-none text-interaction ml-4"
                        onClick={toggleEditMenu}
                      />
                      <div className="pl-4">
                        <CircleShare
                          className="flex-shrink-0 text-3xl cursor-pointer select-none text-interaction"
                          onClick={toggleShareMenu}
                        />
                      </div>
                    </>
                  )}
                </div>

                {showEditMenu && (
                  <EventEditMenu
                    userId={user.id}
                    isOrganizer={isOrganizer ?? false}
                    onClose={() => setShowEditMenu(false)}
                    visible={showEditMenu}
                    event={event}
                    onCancelEvent={openConfirmCancelEvent}
                    onDuplicateEvent={openDuplicateEventConfirmation}
                  />
                )}
                {showShareMenu && (
                  <Popup className="-left-32" onClose={() => setShowShareMenu(false)}>
                    <EventLinkCopy
                      customLabel="Copy Share Link"
                      copyButtonOnly
                      defaultValue={`${window.location.origin}/api/event/${event.id}/link`}
                    />
                    {(isOrganizer || isCoHost) && (
                      <>
                        <EventCodeCopy
                          copyButtonOnly
                          customLabel="Copy Event Code"
                          eventId={event.id}
                        />
                        <MenuItem onClick={openRsvpReminderModal}>Send RSVP Reminder</MenuItem>
                        {event.guests.length > 0 && (
                          <MenuItem onClick={openGuestMessageModal}>Send Guest Message</MenuItem>
                        )}
                      </>
                    )}
                  </Popup>
                )}
              </div>
            </Headline>
            {event.address && !isOrganizer && <AddressDisplay address={event.address} />}
            {isOrganizer && (
              <BasicInsight>
                <Link to={`/event/${event.id}`} className="flex-1">
                  <div className="flex flex-col flex-1 space-y-2">
                    <div>Items Claimed: {metrics?.claims}</div>
                  </div>
                </Link>

                <Link to={`/event/${event.id}/insights`} className="flex-1">
                  <div className="flex flex-col flex-1 space-y-2 whitespace-nowrap">
                    <div>Total Attending: {metrics?.guests}</div>
                  </div>
                </Link>
              </BasicInsight>
            )}
          </Body>
        </EventCard>
      </>
    );
  }
);

export default EventSummary;
