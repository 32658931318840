import { useState } from 'react';
import { useModal } from 'react-modal-hook';

import { Button, Modal } from '../components';
import { fetcher } from '../providers';

export function useSendGuestMessageModal(eventId: number | undefined, onSuccess: () => void) {
  const [guestMessage, setGuestMessage] = useState('');

  const sendGuestMessage = async () => {
    try {
      await fetcher(`/api/event/${eventId}/guests/send-custom-message`, {
        method: 'POST',
        body: guestMessage
      });
      onSuccess();
    } finally {
      closeGuestMessageModal();
    }
  };

  const [openGuestMessageModal, closeGuestMessageModal] = useModal(() => {
    if (!eventId) {
      return null;
    }

    return (
      <Modal fullWidth={false} onClose={closeGuestMessageModal}>
        {({ close }) => (
          <div className="flex flex-col text-center space-y-8">
            <div className="text-2xl">Guest Message</div>
            <textarea
              onChange={(e) => {
                setGuestMessage(e.target.value);
              }}
              className="h-64 p-4 border rounded-lg border-neutral-ash"
              placeholder={'This will be sent to everyone in attendance.'}
              maxLength={1200}
            />
            <div className="flex flex-col justify-evenly sm:flex-row">
              <Button outline onClick={close}>
                Cancel
              </Button>
              <Button onClick={sendGuestMessage} className="mt-2">
                Confirm
              </Button>
            </div>
          </div>
        )}
      </Modal>
    );
  }, [guestMessage]);

  return { openGuestMessageModal };
}
