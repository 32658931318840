import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { ComponentProps, useEffect, useState } from 'react';
import { useModal } from 'react-modal-hook';
import tw from 'twin.macro';

import { usePotluck } from '../../providers';
import { FadeIn, fadeInAnimation } from '../animations';
import { Divider } from '../Divider';
import { CheckmarkWhite, Photo } from '../icons';
import { Loader } from '../Loader';
import { Modal } from '../Modal';
import ImageUpload from './CoverImageUpload';

interface CoverImagePickerProps {
  imageUrl?: string;
  container?: string;
  onPick: (image?: File, url?: string) => void;
}

const Container = styled.div<{ background?: string }>`
  ${tw`flex p-4 border border-dashed cursor-pointer border-neutral-stone rounded-md backdrop-filter hover:backdrop-blur-xl`}

  background-image: ${(p) => p.background && `url(${p.background})`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  ${(p) => p.background && fadeInAnimation()}

  ${(p) => p.background && tw`bg-neutral-ash`}
  ${(p) =>
    p.background &&
    css`
      height: 14.50rem;
    `}
`;

const Image = styled.img`
  ${tw`w-full border border-neutral-stone rounded-md`}
`;

export const SelectedOverlay = () => (
  <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full opacity-50 bg-neutral-black text-9xl rounded-md">
    <CheckmarkWhite />
  </div>
);

export const CoverImagePicker = ({
  imageUrl,
  onPick,
  container,
  ...props
}: ComponentProps<'div'> & CoverImagePickerProps) => {
  const [localUrl, setLocalUrl] = useState(imageUrl);

  useEffect(() => {
    setLocalUrl(imageUrl);
  }, [imageUrl]);

  const [openPicker, closePicker] = useModal(
    () => (
      <Modal
        onClose={closePicker}
        header={<div className="text-2xl font-semibold">Cover Image</div>}
        fullHeight
        noBackdrop
        container={container}
      >
        {({ close }) => (
          <CoverImageBrowser
            imageUrl={localUrl}
            onPick={(image, url) => {
              setLocalUrl(url);
              onPick(image, url);
              close();
            }}
          />
        )}
      </Modal>
    ),
    [localUrl, onPick]
  );

  return (
    <Container {...props} background={localUrl} onClick={openPicker}>
      {!localUrl && (
        <>
          <Photo className="text-2xl" />
          Add cover image
        </>
      )}
    </Container>
  );
};

export const CoverImageBrowser = observer(({ imageUrl, onPick }: CoverImagePickerProps) => {
  const [file, setFile] = useState<File>();
  const [localUrl, setLocalUrl] = useState(imageUrl);

  const { coverImages } = usePotluck();

  return (
    <>
      <ImageUpload
        imageUrl={file && localUrl}
        onUpload={(file, url) => {
          setFile(file);
          setLocalUrl(url);
          setTimeout(() => onPick(file, url), 300);
        }}
      />
      <div className="text-sm mt-2 -mb-4">
        For best results please use a landscape photo (wider than it is tall)
      </div>
      <Divider />
      <div className="my-4 text-lg text-center">Gallery</div>
      <div className="min-h-full pb-8 -mb-8 grid md:grid-cols-2 gap-4" ref={coverImages.register}>
        {coverImages.data?.map(({ url }) => (
          <FadeIn
            key={url}
            className="relative cursor-pointer rounded-md"
            onClick={() => {
              localUrl !== url ? setLocalUrl(url) : setLocalUrl(undefined);
              setTimeout(() => onPick(undefined, url), 300);
            }}
          >
            {localUrl === url && <SelectedOverlay />}
            <Image key={url} src={url} alt="cover" />
          </FadeIn>
        ))}
        {coverImages.loading && <Loader />}
      </div>
    </>
  );
});

export default CoverImagePicker;
