import styled from '@emotion/styled';
import { ComponentProps, useRef } from 'react';
import tw from 'twin.macro';

import { CheckmarkWhite } from '../icons';

const Container = styled.div<{ checked?: boolean }>`
  ${tw`flex items-center justify-center border relative cursor-pointer bg-neutral-white
  transition-all`}

  ${(p) => p.checked && tw`bg-neutral-black`}
`;

const Checkmark = styled(CheckmarkWhite)<{ checked?: boolean }>`
  ${tw`text-lg`}
  ${(p) => !p.checked && tw`invisible`}
`;

export function Checkbox({ checked, onChange, ...props }: ComponentProps<'input'>) {
  const checkboxInput = useRef<HTMLInputElement>(null);

  return (
    <Container onClick={() => checkboxInput.current?.click()} checked={checked}>
      <Checkmark checked={checked} />
      <input
        {...props}
        hidden
        ref={checkboxInput}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
    </Container>
  );
}

export default Checkbox;
