import styled from '@emotion/styled';
import { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useAsync } from 'react-use';
import tw from 'twin.macro';

import { Event, EventInsights, EventRole } from '../../models';
import { usePotluck } from '../../providers';
import { Loader } from '../Loader';
import { ProgressBar } from '../ProgressBar';
import { CircularProgressBar } from '../ProgressBar/CircularProgressBar';

interface InsightsProps {
  event: Event;
  role?: EventRole;
}

const Container = styled.div`
  ${tw`flex flex-col space-y-12`}
`;

const Trends = styled.div`
  ${tw`flex w-full space-x-2 md:space-x-3`}
  height: 10rem;
`;

const Trend = styled.div`
  ${tw`self-end flex-1 md:p-1 rounded-full bg-secondary`}
  min-height: 0.75rem;
`;

const Categories = styled.div`
  ${tw`flex items-start justify-between flex-1 space-x-8`}
`;

export function Insights({ event, role }: InsightsProps) {
  const { url } = useRouteMatch();
  const { organizing } = usePotluck();
  const canEditItems = () => role === EventRole.Organizer || role === EventRole.Cohost;

  const [insights, setInsights] = useState<EventInsights>();

  useAsync(async () => {
    setInsights(await organizing.insights(event.id));
  }, []);

  if (!insights) {
    return <Loader />;
  }

  const { trends, categories } = insights;

  const maxTrend = Math.max(...trends);

  const totalQuantity = event.items.reduce((sum, i) => sum + (i.quantity ?? 0), 0);
  const totalClaimed = Object.values(categories).reduce((sum, c) => sum + c.claims, 0);

  return (
    <Container>
      <div className="space-y-4">
        <div className="text-center">
          {canEditItems() && (
            <div>
              <Link to={`${url}/items`}>Edit Needs</Link>
            </div>
          )}
          <div className="font-semibold text-lg">Need Details</div>
          <div>
            {totalClaimed} Claimed of {totalQuantity}
          </div>
        </div>

        <Trends>
          {trends.map((t, i) => (
            <Trend key={i} style={{ height: `${maxTrend ? (t / maxTrend) * 100 : 0}%` }} />
          ))}
        </Trends>
        <div className="flex justify-between">
          <div>Invite</div>
          <div className="text-lg font-semibold">Claim Rate</div>
          <div>Event</div>
        </div>
      </div>
      <Categories>
        <div className="flex-1">
          {Object.entries(categories).map(([category, { claims, quantity }]) => (
            <div key={category} className="flex flex-col space-y-2">
              <div className="flex flex-grow space-justify-between mt-6">
                <div className="font-semibold">{category}</div>
                <div className="ml-auto">{claims}</div>
              </div>
              <ProgressBar value={claims} total={quantity} animated />
            </div>
          ))}
        </div>
        <div>
          <CircularProgressBar value={totalClaimed} total={totalQuantity} />
          <div>Items Claimed</div>
        </div>
      </Categories>
    </Container>
  );
}

export default Insights;
