import * as React from 'react';

function SvgCircleShare(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#f4f2ee" />
      <path d="M12 7.5V13.2614" stroke="#4C69BF" strokeLinecap="round" />
      <path d="M10 9L11.9916 7L14 9" stroke="#4C69BF" strokeLinecap="round" />
      <path
        d="M10.4 10.5H9C8.44771 10.5 8 10.9477 8 11.5V16C8 16.5523 8.44772 17 9 17H15C15.5523 17 16 16.5523 16 16V11.5C16 10.9477 15.5523 10.5 15 10.5H13.6"
        stroke="#4C69BF"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SvgCircleShare;
