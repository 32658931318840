import * as React from 'react';

function SvgSendWhite(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.211 4.371l15.792 1.004a.648.648 0 01.471 1.044l-9.751 12.552a1.296 1.296 0 01-2.208-.268l-1.594-3.581a1.296 1.296 0 01.414-1.57l4.098-3.026a.648.648 0 00-.476-1.163l-5.236.748a1.296 1.296 0 01-1.367-.756L2.945 6.192a1.296 1.296 0 011.266-1.82z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgSendWhite;
