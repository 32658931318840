import useSWR from 'swr';

import { Event, EventCategory, ItemCategory } from '../models';

export function useItemCategories(eventCategory?: EventCategory) {
  const { data: categories } = useSWR<ItemCategory[]>(
    `/api/event/item-categories/${eventCategory}`
  );

  return categories;
}

export function useAllItemCategories() {
  const { data: categories } = useSWR<ItemCategory[]>(`/api/event/item-categories`);

  return categories;
}

export function useAllItemCategoriesIncludingCustom(event: Event) {
  const { data: categories } = useSWR<ItemCategory[]>(
    event ? `/api/event/${event.id}/available-item-categories` : null
  );

  return categories;
}

// TODO: Delete this method?
export function useEventItemCategories(event: Event) {
  const { data: categories } = useSWR<ItemCategory[]>(`/api/event/${event.id}/item-categories`);

  return categories;
}

export function getEventItemCategories(event: Event): ItemCategory[] {
  let categories = [] as ItemCategory[];

  if (event && event.items) {
    event.items.forEach((i) => {
      if (i.category && categories.findIndex((c) => c.id === i.category?.id) === -1) {
        categories.push(i.category);
      }
    });
  }

  return categories;
}
