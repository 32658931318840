import { useForm } from 'react-hook-form';

import { Button } from '../../components';

interface AddRecapProps {
  onSubmit: (recap: string) => void;
  recap?: string;
}

export function AddRecap({ onSubmit, recap }: AddRecapProps) {
  const {
    register,
    formState: { isDirty, isValid },
    handleSubmit
  } = useForm({ defaultValues: { recap: recap || '' }, mode: 'all' });

  return (
    <form
      onSubmit={handleSubmit(({ recap }) => onSubmit(recap))}
      className="flex flex-col space-y-4 mt-4"
    >
      <div>Organizer recap</div>
      <textarea
        placeholder="Use this area to summarize all the fun everyone had."
        className="h-40 p-4 border rounded-lg"
        {...register('recap', { required: true })}
      />
      <Button className="self-center mt-24" disabled={!isDirty || !isValid}>
        {recap ? 'Save' : 'Create'}
      </Button>
    </form>
  );
}

export default AddRecap;
