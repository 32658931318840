import * as React from 'react';

function SvgMoments(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#070401" strokeWidth={1.54} fill="none" fillRule="evenodd">
        <path
          d="M7.92 21H5.066C3.925 21 3 20.189 3 19.188V4.812C3 3.812 3.925 3 5.065 3h2.997L7.92 21z"
          fill="#F0EBE0"
        />
        <path
          d="M7.839 3H19.56c.795 0 1.44.645 1.44 1.44v15.12A1.44 1.44 0 0119.56 21H7.839h0V3z"
          fill="#F8F6F1"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.204 13.777h5.689M11.204 16.988h5.689M14.354 6.564a1.24 1.24 0 011.715 0 1.15 1.15 0 010 1.662h0l-.601.668-1.346 1.363-.077-.074-2.02-1.957a1.15 1.15 0 010-1.662 1.24 1.24 0 011.716 0l.31.33V6.86z"
        />
      </g>
    </svg>
  );
}

export default SvgMoments;
