import * as React from 'react';

function SvgEssentials(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        fill="#DDDDDC"
        stroke="#070401"
        strokeWidth={1.54}
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M10.906 4.165c-.543 0-.983.377-.983.841v3.149c0 .465-.335.84-.748.84s-.748-.375-.748-.84V5.006c0-.464-.44-.84-.982-.84-.543 0-.983.376-.983.84v3.255c-.047.414-.358.735-.739.735-.413 0-.747-.376-.747-.841V5.006c0-.464-.44-.84-.984-.84-.542 0-.982.376-.982.84V8.38a.332.332 0 00-.01.078c0 .054.007.107.01.16v.027l.003.027c.12 1.615.696 2.915 2.56 3.087V19.1c0 .465.14 1.832 1.871 1.832 1.731 0 1.872-1.367 1.872-1.832V11.758c1.803-.166 2.345-1.387 2.547-2.928a.714.714 0 00.026-.186V5.006c0-.464-.44-.84-.983-.84zM18.014 21h0c.03 0 .059-.007.089-.01.01.001.021.004.033.004.47 0 .851-.398.851-.888v-.001c.006-.045.013-.088.013-.133v-7.48c0-.046-.007-.09-.013-.133V4.888c0-.49-.381-.888-.851-.888s-.852.398-.852.888v6.576h-.004c.007.572-.913 1.15-1.62 1.15-.034 0-.066-.002-.1-.005v5.833c0 1.413 1.1 2.558 2.454 2.558z" />
      </g>
    </svg>
  );
}

export default SvgEssentials;
