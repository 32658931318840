import * as React from 'react';

function SvgHyperlink(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1"
        y="1"
        width="48"
        height="48"
        rx="24"
        fill="#CDD2E3"
        stroke="#CDD2E3"
        strokeWidth="1.782"
      />
      <mask id="mask0_1_371" maskUnits="userSpaceOnUse" x="0" y="0" width="50" height="50">
        <rect
          x="1"
          y="1"
          width="48"
          height="48"
          rx="24"
          fill="white"
          stroke="white"
          strokeWidth="1.782"
        />
      </mask>
      <g mask="url(#mask0_1_371)">
        <path
          d="M32.0247 27.0481L35.0025 23.8569C37.5228 21.156 37.3859 16.9145 34.6966 14.3833C32.0074 11.852 27.7842 11.9896 25.2639 14.6905L22.2861 17.8816"
          stroke="#070401"
          strokeWidth="2.54"
          strokeLinecap="round"
        />
        <path
          d="M17.3962 23.1219L14.4184 26.3131C11.8981 29.014 12.035 33.2555 14.7243 35.7867C17.4135 38.318 21.6367 38.1804 24.157 35.4795L27.1348 32.2884"
          stroke="#070401"
          strokeWidth="2.54"
          strokeLinecap="round"
        />
        <path
          d="M30.7607 19.0602L19.738 30.8728"
          stroke="black"
          strokeWidth="2.54"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}

export default SvgHyperlink;
