import styled from '@emotion/styled';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import tw from 'twin.macro';

import { Button, ThumbsUp } from '../../components';
import { logPaymentAnalytic } from '../../utils/AnalyticsUtils';

const Container = styled.div`
  ${tw`flex flex-col text-center space-y-4`}

  & + & {
    ${tw`py-8 mt-2 border-t border-neutral-stone`}
  }
`;

export function PaymentConfirmation() {
  const router = useHistory();

  useEffect(() => {
    logPaymentAnalytic();
  }, []);

  return (
    <>
      <Container>
        <div className="self-center">
          <ThumbsUp />
        </div>
        <div className="text-2xl font-semibold text-center">All Set!</div>
        <div className="text-sm text-neutral-char">Now let's create your first event</div>
        <Button onClick={() => router.push('/create/details')}>Plan Something</Button>
        <div
          onClick={() => router.push('/')}
          className="text-sm text-interaction cursor-pointer mt-1"
        >
          Go to My Dashboard
        </div>
      </Container>
    </>
  );
}

export default PaymentConfirmation;
