import styled from '@emotion/styled';
import {
  ComponentProps,
  FC,
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import tw from 'twin.macro';

import { Add, Subtract } from '../icons';

const Container = styled.div`
  ${tw`flex flex-col`}

  & + & {
    ${tw`pt-2 mt-2 border-t border-neutral-stone`}
  }
`;

const Header = styled.div`
  ${tw`flex items-center flex-1 px-1 py-4 cursor-pointer select-none space-x-2`}

  svg {
    ${tw`text-2xl`}
  }
`;

const Content = styled.div<{ open?: boolean }>`
  ${tw`flex`}
  ${(p) => !p.open && tw`overflow-hidden max-h-0`}
`;

const InnerContent = styled.div`
  ${tw`flex flex-col flex-1`}
`;

type AccordionProps = ComponentProps<'div'> & {
  header: string | ReactNode;
  isOpen?: boolean;
  children: ReactNode | FC<{ close: () => void }>;
  scrollIntoView?: boolean;
};

export const Accordion = forwardRef(
  ({ header, isOpen: _isOpen, children, scrollIntoView, ...props }: AccordionProps, ref) => {
    const [isOpen, setIsOpen] = useState(!!_isOpen);
    const content = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => content);

    useEffect(() => {
      if (!(isOpen && scrollIntoView)) {
        return;
      }

      if (!content.current) {
        return;
      }

      const { bottom } = content.current.getBoundingClientRect();

      if (bottom > window.innerHeight) {
        content.current?.parentElement?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        });
      }
    }, [isOpen, scrollIntoView]);

    const _children = useMemo(
      () =>
        typeof children === 'function'
          ? children({ close: () => void setIsOpen(false) })
          : children,
      [children]
    );

    return (
      <Container {...props}>
        <Header onClick={() => setIsOpen(!isOpen)}>
          <div className="flex flex-1 space-x-2">{header}</div>
          {isOpen ? <Subtract /> : <Add />}
        </Header>
        {isOpen && (
          <Content open={isOpen} ref={content} className="accordion-content flex mb-4">
            <InnerContent className="flex-reverse">{_children}</InnerContent>
          </Content>
        )}
      </Container>
    );
  }
);

export default Accordion;
