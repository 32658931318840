import styled from '@emotion/styled';
import { ComponentProps, ReactNode } from 'react';
import tw from 'twin.macro';

import { EventInvite, EventRole } from '../../models';
import { ProfilePicture as _ProfilePicture } from '../ProfilePicture';

type InviteUserSummaryProps = ComponentProps<'div'> & {
  invite: EventInvite;
  userOptions?: ReactNode;
};

const Container = styled.div`
  ${tw`flex justify-between`}
  
  flex-basis: 100%;
  
  & + & {
    ${tw`border-t-2 border-neutral-stone`}
  }
`;

const Content = styled.div`
  ${tw`flex flex-col flex-1 text-left`}

  max-width: 12.3rem;

  @media (max-width: 640px) {
    max-width: 9.5rem;
  }

  &,
  & > * {
    ${tw`overflow-hidden whitespace-nowrap overflow-ellipsis`}
  }
`;

const Children = styled.div`
  ${tw`mt-2`}
`;

const ProfilePicture = styled(_ProfilePicture)`
  ${tw`w-12 h-12 text-xl`}
`;

const RolePill = styled.div`
  ${tw`inline-flex text-sm bg-content-pearl px-2 rounded-2xl`}
`;

export function InviteUserSummary({ invite, children, userOptions, ...props }: InviteUserSummaryProps) {
  return (
    <Container {...props}>
      <div className="flex space-x-4">
        <ProfilePicture user={invite.user || invite} />
        <Content>
          <b>
            {invite.firstName} {invite.lastName && `${invite.lastName[0]}.`}
          </b>
          <div>{invite.email ? invite.email : ''}</div>
          <div>{invite.phoneNumber ? invite.phoneNumber : ''}</div>
          {invite.role === EventRole.Cohost && <div><RolePill>Co-host</RolePill></div>}
          <Children>{children}</Children>
        </Content>
      </div>
      {userOptions}
    </Container>
  );
}

export default InviteUserSummary;
