import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { FC } from 'react';
import tw from 'twin.macro';

import { ProgressBarProps } from './ProgressBar';

const progressCircularAnimation = keyframes`
  0% {
    stroke-dasharray: 0 100;
  }
`;

const ProgressCircular = styled.div`
  ${tw`relative inline-flex items-center justify-center w-full`}
  max-width: 6rem;

  svg {
    ${tw`w-full h-full`}

    fill: none;
    strokewidth: 2;

    .percent {
      ${tw`text-secondary stroke-current`}
      strokeLinecap: round;

      animation: ${progressCircularAnimation} 0.3s ease-out forwards;
    }
  }
`;

export const CircularProgressBar: FC<ProgressBarProps> = ({ value, total, ...props }) => {
  return (
    <ProgressCircular {...props}>
      <svg viewBox="0 0 36 36" className="progress-circular">
        <path
          className="around"
          strokeDasharray="100, 100"
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        {value && total && (
          <path
            className="percent"
            style={{ strokeDasharray: `${((value / total) * 100).toFixed(2)}, 100` }}
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          />
        )}
      </svg>
      <div className="absolute text-xl font-bold">
        {value && total ? `${((value / total) * 100).toFixed(2)}%` : '0%'}
      </div>
    </ProgressCircular>
  );
};
