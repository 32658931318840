import styled from '@emotion/styled';
import { Listbox } from '@headlessui/react';
import { useState } from 'react';
import tw from 'twin.macro';

import { EventRole } from '../../models';
import { ChevronDownBlack, ChevronUpBlack } from '../icons';

const Container = styled.div`
  ${tw`relative z-10 w-full border border-neutral-ash text-neutral-soil bg-neutral-white`}
`;

const Options = styled.div`
  ${tw`absolute -left-px -right-px border border-t-0 rounded-b-lg border-neutral-ash`}
`;

const RoleDescription = styled.div`
  ${tw`text-sm mt-2`}
`;

interface ListboxSelectProps {
  initialRole?: EventRole;
  displayRoleDescriptions: boolean;
  onChange: (value: any) => void;
}

export const RoleListboxSelect = ({ initialRole, displayRoleDescriptions, onChange }: ListboxSelectProps) => {
  const roles = [
    EventRole.Guest,
    EventRole.Cohost
  ]

  const [selectedRole, setSelectedRole] = useState(initialRole ? initialRole : roles[0])

  return (
    <>
      <Listbox as="div" value={selectedRole} onChange={(role)=> {
        setSelectedRole(role);
        onChange(role)
      }}>
        {({open}) => (
          <>
            <Container className={`${open ? 'rounded-t-lg' : 'rounded-lg'}`}>
              <span className="inline-block w-full">
                <Listbox.Button className="px-3 py-2 w-full">
                  <span className="text-left text-neutral-soil truncate float-left">
                    Role: {selectedRole === EventRole.Cohost ? 'Co-host' : selectedRole}
                  </span>
                  <span className="float-right">
                    {!open && <ChevronDownBlack className="w-6 h-6" />}
                    {open && <ChevronUpBlack className="w-6 h-6" />}
                  </span>
                </Listbox.Button>
              </span>
              <Listbox.Options>
                <Options>
                {roles.map((role, index) => (
                  <Listbox.Option key={role} value={role}>
                    {({ selected, active }) =>(
                      <div className={
                        `${active ? 'text-white bg-content-mist rounded-b' : 'text-gray-900'}
                         ${index === 0 ? 'border-t' : ''}
                         ${index === roles.length-1 ? 'rounded-b-lg' : ''}
                         pl-3 py-2 bg-neutral-white`
                      }>
                        <span>{role === EventRole.Cohost ? 'Co-host' : role}</span>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Options>
              </Listbox.Options>
            </Container>
            {displayRoleDescriptions &&
              <>
                <RoleDescription><strong>Guest</strong> is anyone that will be attending and bringing something.</RoleDescription>
                <RoleDescription><strong>Co-Host</strong> can edit the event details, items, and guest list.</RoleDescription>
              </>
            }
          </>
        )}
      </Listbox>
    </>
  )
}

export default RoleListboxSelect;
