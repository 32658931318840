import * as React from 'react';

function SvgSides(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M15.122 10.057c0 4.45-1.208 8.057-2.699 8.057-1.49 0-2.699-3.607-2.699-8.057S10.933 2 12.424 2c1.49 0 2.698 3.607 2.698 8.057"
          fill="#F5F5F6"
        />
        <path
          d="M15.724 10.057c0 4.45-1.343 8.057-3 8.057-1.656 0-3-3.607-3-8.057S11.068 2 12.724 2c1.657 0 3 3.607 3 8.057z"
          stroke="#070401"
          strokeWidth={1.54}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 9.107c.54-.032 1.289.185 2.006.62 1.12.678 1.357 1.532 1.383 2.127v3.638c0 1.922.85 3.595 2.845 3.595h3.098c1.703 0 2.8-1.566 3.29-4.697.195-1.403.31-2.436.346-3.098.054-.993-.095-2.434 1.026-3.112.717-.434 1.467-.65 2.006-.62"
          stroke="#070401"
          strokeWidth={1.54}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          stroke="#070401"
          strokeWidth={1.54}
          fill="#DDDDDC"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M14.888 19.882V22h-4v-2.118"
        />
        <path
          stroke="#070401"
          strokeWidth={1.54}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12.423 18.114l.069-15.66M12.423 7.908H9.822M15.024 10.057h-2.489M12.424 12.297H9.822"
        />
      </g>
    </svg>
  );
}

export default SvgSides;
