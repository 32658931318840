import styled from '@emotion/styled';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useModal } from 'react-modal-hook';
import tw from 'twin.macro';

import { addCustomCategory, useAllItemCategoriesIncludingCustom } from '../../hooks';
import { Event, EventItem } from '../../models';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { Popup } from '../Popup';
import { BAKE_SALE, BLANK_SIGNUP, GENERAL_POTLUCK, TAILGATE, THANKSGIVING } from './itemTemplates';

const MenuItem = styled.div`
  ${tw`text-interaction cursor-pointer`}
`;

const MenuItemRed = styled.div`
  ${tw`cursor-pointer mt-6 text-center text-indicating-error`}
`;

export const ItemTemplatesButton = ({
  event: _event,
  onChange
}: {
  event: Event;
  onChange: (event: Event) => void;
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [event, setEvent] = useState(_event);
  const categories = useAllItemCategoriesIncludingCustom(_event);
  const [isClearing, setIsClearing] = useState(false);

  const [populateTemplateCallback, setPopulateTemplateCallback] = useState(() => () => {});

  const itemsHaveClaims = useMemo(() => {
    return event.items.some((i) => i.claims && i.claims.length > 0);
  }, [event]);

  const itemsHaveComments = useMemo(
    () => event.items.some((i) => i.comments && i.comments.length > 0),
    [event]
  );

  useEffect(() => {
    setEvent(_event);
  }, [_event]);

  const getOrCreateCustomCategory = useCallback(
    async (name: string) => {
      const existing = categories?.find((c) => c.name === name);
      if (existing) {
        return existing;
      } else {
        return await addCustomCategory(event, name);
      }
    },
    [categories, event]
  );

  const getMultiDayEvent = async () => {
    const fridayCategory = await getOrCreateCustomCategory('Friday');
    const saturdayCategory = await getOrCreateCustomCategory('Saturday');
    const sundayCategory = await getOrCreateCustomCategory('Sunday');

    return [
      {
        name: 'Brunch',
        quantity: 8,
        customMeasurement: 'Dishes',
        note: "Please leave a note with what specific dish you'll bring for Brunch",
        category: fridayCategory
      },
      {
        name: 'Dinner',
        quantity: 8,
        customMeasurement: 'Dishes',
        category: fridayCategory
      },
      {
        name: 'Brunch',
        quantity: 8,
        customMeasurement: 'Dishes',
        note: "Please leave a note with what specific dish you'll bring for Brunch",
        category: saturdayCategory
      },
      {
        name: 'Dinner',
        quantity: 8,
        customMeasurement: 'Dishes',
        category: saturdayCategory
      },
      {
        name: 'Brunch',
        quantity: 8,
        customMeasurement: 'Dishes',
        note: "Please leave a note with what specific dish you'll bring for Brunch",
        category: sundayCategory
      },
      {
        name: 'Dinner',
        quantity: 8,
        customMeasurement: 'Dishes',
        category: sundayCategory
      }
    ];
  };

  const [openConfirmDeleteModal, closeConfirmDeleteModal] = useModal(() => {
    return (
      <Modal onClose={closeConfirmDeleteModal}>
        {({ close }) => (
          <div className="flex flex-col text-center space-y-8">
            {!itemsHaveClaims && !itemsHaveComments && (
              <>
                <div className="font-bold text-lg">Are you sure?</div>
                <div>This will {isClearing ? 'clear' : 'replace'} your current list of needs.</div>
              </>
            )}
            {(itemsHaveClaims || itemsHaveComments) && (
              <p>
                Cannot {isClearing ? 'clear' : 'replace'} needs because claims and/or comments have
                been made. Please ask guests to remove their claims/comments to continue.
              </p>
            )}
            <div className="flex flex-col space-y-4 justify-evenly sm:flex-row">
              {!itemsHaveClaims && !itemsHaveComments && (
                <Button
                  onClick={() => {
                    populateTemplateCallback();
                    close();
                  }}
                >
                  Confirm
                </Button>
              )}
              <Button className="mt-2" outline onClick={close}>
                {itemsHaveClaims || itemsHaveComments ? 'Got it' : 'Cancel'}
              </Button>
            </div>
          </div>
        )}
      </Modal>
    );
  }, [event, itemsHaveClaims, itemsHaveComments, categories, populateTemplateCallback, isClearing]);

  const preventLossOfCommentsOrClaims = (onDeleteConfirmation: () => void) => {
    if (!event.items || event.items.length <= 0) {
      onDeleteConfirmation();
      return;
    }
    setPopulateTemplateCallback(() => onDeleteConfirmation);
    openConfirmDeleteModal();
  };

  const setupMultiDayEvent = async () => {
    setIsClearing(false);
    preventLossOfCommentsOrClaims(async () => {
      const multiDayEvent = await getMultiDayEvent();
      event.items = multiDayEvent as EventItem[];
      onChange(event);
    });
  };

  const populateWithItems = (items: EventItem[]) => {
    setIsClearing(false);
    preventLossOfCommentsOrClaims(() => {
      event.items = items;
      onChange(event);
    });
  };

  const clearAll = () => {
    setIsClearing(true);
    preventLossOfCommentsOrClaims(() => {
      event.items = [];
      setIsClearing(false);
      onChange(event);
    });
  };

  return (
    <>
      <Button
        className="mt-5 bg-neutral-white"
        outline
        inline
        onClick={() => setShowMenu(!showMenu)}
      >
        + Sign Up Templates
        {showMenu && (
          <Popup className="mt-2 text-left -top-4 left-2" onClose={() => setShowMenu(false)}>
            <MenuItem onClick={() => populateWithItems(GENERAL_POTLUCK as EventItem[])}>
              General Potluck
            </MenuItem>
            <MenuItem onClick={() => populateWithItems(TAILGATE as EventItem[])}>
              Tailgate/Watch Party
            </MenuItem>
            <MenuItem onClick={() => populateWithItems(THANKSGIVING as EventItem[])}>
              Thanksgiving
            </MenuItem>
            <MenuItem onClick={() => populateWithItems(BLANK_SIGNUP as EventItem[])}>
              Blank Signup Sheet
            </MenuItem>
            <MenuItem onClick={setupMultiDayEvent}>Multi-Day Event</MenuItem>
            <MenuItem onClick={() => populateWithItems(BAKE_SALE as EventItem[])}>
              Bake Sale/Raffle
            </MenuItem>
          </Popup>
        )}
      </Button>
      {event.items && event.items.length > 0 && (
        <MenuItemRed onClick={clearAll}>Clear All</MenuItemRed>
      )}
    </>
  );
};
