import { ComponentProps } from 'react';
import { Link } from 'react-router-dom';

import { Event, EventGuest, EventRole } from '../../models';
import { EventCodeCopy } from '../EventCodeCopy';
import { Popup } from '../Popup';

interface EventEditMenuProps {
  userId: number;
  showViewOption?: boolean;
  isOrganizer: boolean;
  onClose: () => void;
  visible: boolean;
  event: Event;
  onCancelEvent: () => void;
  onDuplicateEvent: () => void;
}
export function EventEditMenu({
  isOrganizer,
  event,
  userId,
  visible,
  onClose,
  showViewOption = true,
  onCancelEvent,
  onDuplicateEvent,
  ...props
}: ComponentProps<'div'> & EventEditMenuProps) {
  const eventRole = event.guests.find((g: EventGuest) => g.guest.id === userId)?.role;
  const isViewingEvent = window.location.pathname.includes('/event');
  const isCoHost = eventRole === EventRole.Cohost;

  return (
    <>
      {visible && (
        <Popup
          className={!isOrganizer && !isCoHost ? '' : '-left-32'}
          {...props}
          onClose={() => onClose()}
        >
          {showViewOption && (
            <Link
              to={`/event/${event.id}`}
              onClick={() => onClose()}
              className="select-none text-interaction block pb-2"
            >
              View
            </Link>
          )}
          {(isOrganizer || isCoHost) && (
            <Link
              to={isViewingEvent ? `/event/${event.id}/details` : `/edit/${event.id}/details`}
              onClick={() => onClose()}
              className="select-none text-interaction block pb-2"
            >
              Edit Date/Time/Location
            </Link>
          )}
          {(isOrganizer || isCoHost) && (
            <Link
              to={isViewingEvent ? `/event/${event.id}/items` : `/edit/${event.id}/items`}
              onClick={() => onClose()}
              className="select-none text-interaction block pb-2"
            >
              Edit Event Page
            </Link>
          )}
          {(isOrganizer || isCoHost) && (
            <Link
              to={isViewingEvent ? `/event/${event.id}/guests` : `/edit/${event.id}/guests`}
              onClick={() => onClose()}
              className="select-none text-interaction block pb-2"
            >
              Edit Guest List
            </Link>
          )}
          {isOrganizer && (
            <>
              <div
                className="cursor-pointer select-none text-interaction block pb-2"
                onClick={onDuplicateEvent}
              >
                Duplicate Event
              </div>
              <EventCodeCopy key={event.code} createOrRemoveButtonOnly eventId={event.id} />
              <div
                onClick={() => {
                  onClose();
                  onCancelEvent();
                }}
                className="cursor-pointer select-none block text-indicating-error"
              >
                Cancel Event
              </div>
            </>
          )}
        </Popup>
      )}
    </>
  );
}

export default EventEditMenu;
