import * as React from 'react';

function SvgAlcohol(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M18 8.522c0 3.178-2.405 5.753-5.372 5.753h-1.256C8.405 14.275 6 11.7 6 8.522V7.206l.06-1.61c.944-.586 2.622-.6 3.576-.035.497.294.913.727 1.41 1.02 1.062.63 2.35.558 3.562.47.97-.071 2.409-.149 3.299-.567L18 8.522z"
          fill="#DDDDDC"
        />
        <path
          d="M18 8.522c0 3.178-2.405 5.753-5.372 5.753h-1.256C8.405 14.275 6 11.7 6 8.522V7.206l.06-1.61c.944-.586 2.622-.6 3.576-.035.497.294.913.727 1.41 1.02 1.062.63 2.35.558 3.562.47.97-.071 2.409-.149 3.299-.567L18 8.522z"
          stroke="#070401"
          strokeWidth={1.54}
        />
        <path
          d="M12.628 14.461h-1.256C8.405 14.461 6 11.886 6 8.71V3h12v5.709c0 3.177-2.405 5.752-5.372 5.752z"
          stroke="#070401"
          strokeWidth={1.54}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path stroke="#070401" strokeWidth={1.54} strokeLinejoin="round" d="M12 14.461v6.111" />
        <path
          stroke="#070401"
          strokeWidth={1.54}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8.912 20.741h6.176"
        />
      </g>
    </svg>
  );
}

export default SvgAlcohol;
