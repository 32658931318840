import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useMount } from 'react-use';
import tw from 'twin.macro';

import { ActionBar, EventSummary, Loader, UnlockMembership } from '../../components';
import { SubscriptionType } from '../../models';
import { usePotluck, useUser } from '../../providers';

const Container = styled.div`
  ${tw`flex flex-col flex-grow pb-20 space-y-4`}
`;

const Summaries = styled.div`
  ${tw`flex flex-wrap`}
`;

export function PlanSomethingCallToAction({ hasEvents = false, showGreeting = true }) {
  return (
    <div className="flex flex-col items-center pb-12 lg:items-start lg:inline-flex space-y-8">
      {showGreeting && (
        <div className="flex items-end">
          <div className="text-3xl">Provide your guests the guidance they need.</div>
        </div>
      )}
    </div>
  );
}

export const Events = observer(() => {
  const { organizing, attending } = usePotluck();
  const userProvider = useUser();
  const { user, checkout } = userProvider;
  const router = useHistory();

  useMount(() => {
    organizing.next();
    attending.next();
  });

  if (!organizing.events || !attending.events) {
    return <Loader>Loading your events...</Loader>;
  }

  const attendingEvents = attending.events.filter((e) => e.organizer.id !== user?.id);

  function MembershipContent({ displaySingleOptionOnly = false, displaySingleOption = false }) {
    return (
      <>
        {!user?.subscriptionType && (
          <div className="flex flex-start">
            <UnlockMembership
              displaySingleOptionOnly={displaySingleOptionOnly}
              displaySingleOption={displaySingleOption}
              inModal={false}
              onSubmit={async (subscriptionType) => {
                if (subscriptionType === SubscriptionType.Single) {
                  organizing.setCreatingSingleEvent(true);
                  router.push('/create/details');
                } else {
                  await checkout(subscriptionType, `${window.location.origin}/confirmation`);
                }
              }}
              showShareLinkHintText={false}
              userProvider={userProvider}
            />
          </div>
        )}
      </>
    );
  }

  if (organizing.events.length === 0 && attendingEvents.length === 0) {
    return (
      <>
        <PlanSomethingCallToAction />
        <Container>
          <MembershipContent displaySingleOptionOnly />
        </Container>
        <MembershipContent />
        <ActionBar className="fixed bottom-0 z-10 lg:hidden" />
      </>
    );
  }

  if (organizing.events.length === 0 && attendingEvents.length > 0) {
    return (
      <>
        <PlanSomethingCallToAction />
        <Container>
          <MembershipContent displaySingleOptionOnly />
        </Container>
        <div className="mb-2 text-lg font-semibold">Attending</div>
        {attendingEvents.length === 0 && <div>You are not currently attending any events.</div>}
        <Summaries ref={attending.register}>
          {attendingEvents.map((event) => (
            <EventSummary key={event.id} event={event} userId={user?.id} />
          ))}
          {attending.loading && <Loader />}
        </Summaries>
        <MembershipContent />
        <ActionBar className="fixed bottom-0 z-10 lg:hidden" />
      </>
    );
  }

  return (
    <>
      <Container>
        <div className="text-lg font-semibold">Organizing</div>
        {organizing.events.length === 0 && <div>You are not currently organizing any events.</div>}
        <Summaries ref={organizing.register}>
          {organizing.events.map((event) => (
            <EventSummary key={event.id} isOrganizer event={event} />
          ))}
          {organizing.loading && <Loader />}
        </Summaries>

        <PlanSomethingCallToAction hasEvents={organizing.events.length > 0} showGreeting={false} />

        {attendingEvents.length > 0 && <div className="mb-2 text-lg font-semibold">Attending</div>}
        <Summaries ref={attending.register}>
          {attendingEvents.map((event) => (
            <EventSummary key={event.id} event={event} userId={user?.id} />
          ))}
          {attending.loading && <Loader />}
        </Summaries>

        <MembershipContent />
      </Container>
      <ActionBar className="fixed bottom-0 z-10 lg:hidden" />
    </>
  );
});

export default Events;
