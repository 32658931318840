import * as React from 'react';

function SvgDesserts(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        stroke="#070401"
        strokeWidth={1.54}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M18.95 17.56L18.383 20H5.904l-.609-2.44v-.362a5.755 5.755 0 013.234-.979c1.75 0 2.027.98 3.593.98 1.567 0 1.885-.75 2.966-.98.72-.153 2.007.294 3.861 1.34z"
          fill="#DDDDDC"
        />
        <path
          d="M19.451 14.424c2.805.26 1.225 2.8-.244 2.7-1.627-.11-3.172-1.492-4.869-.706l-.622.315c-.888.629-2.204.604-3.111.025l-.698-.34c-1.697-.786-3.242.597-4.87.707-1.468.1-3.47-2.44-.512-2.776l.565-.865c1.33-1.68 3.639-4.358 7.032-4.358 3.747 0 5.924 2.394 7.014 4.358l.315.94z"
          fill="#ECECEC"
        />
        <path d="M12.122 11.49v1.605M14.511 11.593l1.153 1.399M9.682 11.593l-1.153 1.399M13.91 6.668c.087-.276-.363-.442-.41-.728-.027-.162.085-.312.19-.44l.833-1.006c.22-.267.453-.574.412-.916-.026-.215-.16-.402-.289-.578M11.04 6.668c.087-.276-.363-.442-.41-.728-.027-.162.085-.312.19-.44l.833-1.006c.22-.267.453-.574.412-.916-.026-.215-.16-.402-.288-.578" />
      </g>
    </svg>
  );
}

export default SvgDesserts;
