import { Loader } from '../../components';
import { useUser } from '../../providers';
import Page from '../Page';

export function SignOut() {
  const { signOut } = useUser();

  signOut();

  return (
    <Page>
      <Loader />
    </Page>
  );
}

export default SignOut;
