import styled from '@emotion/styled';
import copy from 'copy-to-clipboard';
import { ComponentProps, useState } from 'react';
import tw from 'twin.macro';

import { Notification } from '../Notification';
import { Portal } from '../Portal';

const Container = styled.div`
  ${tw`flex w-full border rounded-lg border-neutral-ash text-neutral-soil bg-neutral-white`}
`;

const Element = styled.input`
  ${tw`flex-1 rounded-l-lg pl-2 focus:outline-none`}
  height: 2.625rem;

  &:disabled {
    ${tw`border-neutral-stone bg-neutral-white text-neutral-stone`}
  }
`;

const Copy = styled.div`
  ${tw`cursor-pointer select-none text-left self-center px-2`}
`;

type InputProps = ComponentProps<'input'> & {
  defaultValue: string;
  disabled?: boolean;
  customLabel?: string;
  copyButtonOnly?: boolean;
};

export const EventLinkCopy = ({
  defaultValue,
  disabled,
  customLabel,
  copyButtonOnly
}: InputProps) => {
  const [showCopyNotification, setShowCopyNotification] = useState(false);
  const label = customLabel ? customLabel : 'Copy';

  const copyButton = (
    <Copy
      className={`${disabled ? 'text-neutral-stone' : 'text-interaction'} ${
        copyButtonOnly && 'pb-2'
      }`}
      onClick={() => {
        if (!disabled) {
          setShowCopyNotification(true);
          copy(defaultValue, { format: "text/plain"});
          setTimeout(() => setShowCopyNotification(false), 3000);
        }
      }}
    >
      {label}
    </Copy>
  );

  return (
    <>
      <Portal container="notifications">
        {showCopyNotification && <Notification>Share link copied!</Notification>}
      </Portal>
      {copyButtonOnly ? (
        <>{copyButton}</>
      ) : (
        <Container>
          <Element disabled={disabled} defaultValue={defaultValue} readOnly={true} />
          {copyButton}
        </Container>
      )}
    </>
  );
};

export default EventLinkCopy;
