import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import { Link, Redirect, useLocation } from 'react-router-dom';
import tw from 'twin.macro';

import { Button, Input } from '../../components';
import { useUser } from '../../providers';
import { PublicPage } from '../Page';

const Form = styled.form`
  ${tw`flex flex-col items-center max-w-sm mx-auto space-y-14`}
`;

const FormBody = styled.div`
  ${tw`flex flex-col space-y-10`}
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function ResetPassword() {
  const { resetPassword, user } = useUser();
  const code = useQuery().get('code');

  const {
    handleSubmit,
    register,
    formState: { isValid, isDirty, errors },
    watch
  } = useForm({ defaultValues: { password: '', confirmPassword: '' }, mode: 'all' });
  const password = watch('password');

  if (user) {
    const redirectUrl = new URLSearchParams(window.location.search).get('redirect-url') || '/';
    return <Redirect to={{ pathname: redirectUrl }} />;
  }

  if (!code) {
    return (
      <PublicPage footer={<Link to="/sign-in">Sign in</Link>}>
        <Form>
          <FormBody>
            Oops! Looks like you ended up here by mistake. You need to request a password reset.
            Click the button below to do that if you haven't already, and then follow the steps sent
            to you in an email.
          </FormBody>
          <Link to="/send-reset-password-code">
            <Button>Request password reset</Button>
          </Link>
        </Form>
      </PublicPage>
    );
  }

  return (
    <PublicPage footer={<Link to="/sign-in">Sign in</Link>}>
      <Form onSubmit={handleSubmit(async (f) => resetPassword(f.password, code))}>
        <FormBody>
          <p>Enter your password and confirm it below and submit it.</p>
          <Input
            type="password"
            placeholder="New password"
            {...register('password', {
              required: 'A password is required.',
              minLength: { value: 8, message: 'Must be a least 8 characters.' }
            })}
            errors={errors.password}
          />
          <Input
            type="password"
            placeholder="Confirm password"
            {...register('confirmPassword', {
              required: 'You must re-type your password.',
              validate: (value) => value === password || 'Passwords do not match.'
            })}
            errors={errors.confirmPassword}
          />
        </FormBody>
        <Button disabled={!isValid || !isDirty}>Reset password</Button>
      </Form>
    </PublicPage>
  );
}

export default ResetPassword;
