import { useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useHistory } from 'react-router-dom';

import { useEvent } from '../../hooks';
import { Event, EventGuest, EventRole, SubscriptionType, User } from '../../models';
import { usePotluck, useUser } from '../../providers';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { UnlockMembership } from '../UnlockMembership';

export const EventImportantActionButton = ({
  user,
  event,
  containerClassNames = ''
}: {
  user: User | undefined | null;
  event: Event | undefined;
  containerClassNames?: string;
}) => {
  const isOrganizer = event?.organizer.id === user?.id;
  const { organizing } = usePotluck();
  const { save } = useEvent();
  const eventRole = event?.guests.find((g: EventGuest) => g.guest.id === user?.id)?.role;
  const isCoHost = eventRole === EventRole.Cohost;
  const eventNeedsPayment = !user?.subscriptionType && !event?.paidSingleEvent && isOrganizer;
  const hasUnsentInvites = event?.invites.some((i) => !i.sentAt) && (isOrganizer || isCoHost);
  const userProvider = useUser();
  const router = useHistory();
  const [failedNotificationMessage, setFailedNotificationMessage] = useState('');
  const { checkout } = userProvider;

  const [openUnlockMembership, closeUnlockMembership] = useModal(
    () => (
      <Modal onClose={closeUnlockMembership} fullHeight>
        {() => (
          <UnlockMembership
            displaySingleOption={true}
            onSubmit={async (subscriptionType) => {
              const redirectBackHere = `${window.location.origin}/edit/${event?.id}/confirmation`;
              subscriptionType === SubscriptionType.Single
                ? await checkout(subscriptionType, redirectBackHere, event?.id)
                : await checkout(subscriptionType, redirectBackHere);
            }}
            userProvider={userProvider}
          />
        )}
      </Modal>
    ),
    []
  );

  const [openFailedNotificationModal, closeFailedNotificationModal] = useModal(
    () => (
      <Modal onClose={closeFailedNotificationModal} fullHeight>
        {() => {
          const lines = failedNotificationMessage.split('\n');
          return (
            <div className="space-y-8 flex flex-col">
              <div className="text-center mb-2">
                The following invites were not sent due to invalid or missing phone numbers.
              </div>
              <div className="text-center">
                {lines.map((text) => (
                  <div>{text}</div>
                ))}
              </div>
              <div className="flex flex-col justify-evenly sm:flex-row">
                <Button
                  onClick={() => {
                    closeFailedNotificationModal();
                    router.push(`/edit/${event?.id}/guests`);
                  }}
                >
                  Edit Event Guests
                </Button>
              </div>
            </div>
          );
        }}
      </Modal>
    ),
    [failedNotificationMessage, event]
  );

  if (!user || !event) {
    return <></>;
  }

  const hasImportantAction = eventNeedsPayment || hasUnsentInvites;
  if (!hasImportantAction) {
    return <></>;
  }

  return (
    <div className={`self-start flex ${containerClassNames}`}>
      <Button
        onClick={async (e) => {
          e.preventDefault();
          if (eventNeedsPayment) {
            openUnlockMembership();
            organizing.setCreatingSingleEvent(false);
          } else {
            try {
              await save(event, true);
            } catch (e) {
              //@ts-ignore
              const result = await e.json();
              setFailedNotificationMessage(result.error);
              openFailedNotificationModal();
              return;
            }
            router.push(`/edit/${event.id}/confirmation`);
          }
        }}
      >
        {eventNeedsPayment ? 'Purchase & Send' : 'Send Unsent Invites'}
      </Button>
    </div>
  );
};

export default EventImportantActionButton;
