import styled from '@emotion/styled';
import { ComponentProps, FC } from 'react';
import tw from 'twin.macro';

import { FadeIn } from '../animations';

const Container = styled(FadeIn)`
  ${tw`flex flex-col flex-grow h-full w-full items-center justify-center space-y-6`}
`;

const Element = styled.div`
  ${tw`relative inline-block w-32 h-32`}

  div {
    ${tw`absolute`}
    width: 6.4rem;
    height: 6.4rem;
    margin: 0.8rem;
    border: 0.8rem solid #ced3e4;
    border-radius: 50%;
    border-color: #ced3e4 transparent transparent transparent;
  }

  div:nth-of-type(1) {
    border: 0.8rem solid #eceded;
  }
  div:nth-of-type(2) {
    animation-delay: -0.3s;
  }
`;

export const Loader: FC<ComponentProps<'div'>> = ({ children, ...props }) => (
  <Container {...props}>
    <Element className="animate-spin">
      <div></div>
      <div></div>
    </Element>
    {children}
  </Container>
);

export default Loader;
