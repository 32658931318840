import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useModal } from 'react-modal-hook';
import { useAsync } from 'react-use';
import tw from 'twin.macro';

import { useEventItemComments, useMessages } from '../../hooks';
import { Event, EventItem, EventItemClaim, Message } from '../../models';
import { fetcher, usePotluck, useUser } from '../../providers';
import { Button } from '../Button';
import { ChatBerry } from '../icons';
import { Input } from '../Input';
import { Modal } from '../Modal';
import { ProfilePicture } from '../ProfilePicture';

enum ClaimType {
  Bring,
  Transport,
  House
}

const getClaimTypeFromItem = (item: EventItem) => {
  switch (item.name) {
    case 'Lodging':
      return ClaimType.House;
    case 'Transportation':
      return ClaimType.Transport;
    default:
      return ClaimType.Bring;
  }
};

const getVerbFromClaimType = (type: ClaimType) => {
  switch (type) {
    case ClaimType.House:
      return 'is housing';
    case ClaimType.Transport:
      return 'is transporting';
    default:
      return 'has claimed';
  }
};

interface ClaimsProps {
  event: Event;
  item: EventItem;
  onClaim: (quantity: number) => void;
  isPreviewMode?: boolean;
}

interface ClaimProps {
  event: Event;
  claim: EventItemClaim;
  item: EventItem;
  type: ClaimType;
  onEdit: (claim: EventItemClaim) => void;
  onRemove?: (claim: EventItemClaim) => void;
}

interface AddEventItemCommentProps {
  eventId: number;
  comment: Message | undefined;
  onSubmit: (comment: string) => void;
  onDelete: (comment: Message) => void;
}

const AddEventItemComment = ({
  eventId,
  comment,
  onSubmit,
  onDelete
}: AddEventItemCommentProps) => {
  const {
    register,
    formState: { isDirty, isValid, isSubmitting },
    handleSubmit
  } = useForm({ defaultValues: { comment: comment?.message || '' }, mode: 'all' });
  const { deleteComment } = useMessages(eventId);

  return (
    <form
      onSubmit={handleSubmit(({ comment }) => onSubmit(comment))}
      className="flex flex-col space-y-4 mt-4"
    >
      <textarea
        placeholder="Include any details the host and/or guests should know. For example: what specific dish you will be bringing."
        className="h-40 p-4 border rounded-lg"
        {...register('comment', { required: true })}
      />
      <Button className="self-center mt-24" disabled={!isDirty || !isValid || isSubmitting}>
        Save
      </Button>

      {comment && (
        <Button
          outline
          onClick={async () => {
            await deleteComment(comment.id);
            onDelete(comment);
          }}
        >
          Delete
        </Button>
      )}
    </form>
  );
};

const Card = styled.div`
  ${tw`bg-content-spring-wood p-4 rounded-2xl space-y-4 mb-4`}
`;

const ClaimDetail = styled.div`
  ${tw`flex flex-wrap items-center space-x-2 text-neutral-black`}
`;

const QuantityInput = styled(Input)`
  ${tw`w-12`}
`;

const Comment = styled.div`
  ${tw`flex space-x-2 text-neutral-ash text-sm`}
`;

const InteractionLink = styled.div<{ useDefaultPointer?: boolean }>`
  ${tw`text-interaction`}
  ${(p) => (p.useDefaultPointer ? tw`cursor-default` : tw`cursor-pointer`)}
`;

const Claim = observer(({ event, claim, item, type, onEdit, onRemove }: ClaimProps) => {
  const { user } = useUser();
  const { attending } = usePotluck();
  const [comments, setComments] = useState<Message[]>([]);

  useAsync(
    async () =>
      setComments(await attending.getItemUserClaimComments(event.id, item.id, claim.user.id)),
    []
  );

  return (
    <Card>
      <ClaimDetail>
        <div className="flex space-x-2 flex-1">
          <div className="h-6 w-6">
            <ProfilePicture user={claim.user} />
          </div>
          <div>
            {claim.user.firstName} {getVerbFromClaimType(type)} {claim.quantity}{' '}
          </div>
        </div>
        {user?.id === claim.user.id && (
          <div className="flex space-x-2 pr-2">
            <InteractionLink>
              <div
                className="flex items-center"
                onClick={() => {
                  onEdit(claim);
                }}
              >
                Edit
              </div>
            </InteractionLink>
            <InteractionLink>
              <div
                className="flex items-center"
                onClick={() => {
                  onRemove?.(claim);
                }}
              >
                Remove
              </div>
            </InteractionLink>
          </div>
        )}
      </ClaimDetail>
      {comments.map((c) => (
        <Comment key={c.id}>
          <div>
            {c.author.firstName}: {c.message}
          </div>
        </Comment>
      ))}
    </Card>
  );
});

export const Claims = observer(({ event, item, onClaim, isPreviewMode = false }: ClaimsProps) => {
  const { user } = useUser();
  const { attending } = usePotluck();

  const { comments } = useEventItemComments(event.id, item.id);

  const [userClaims, setUserClaims] = useState(() =>
    item.claims.filter((c) => c.user.id === user?.id)
  );
  const { deleteComment } = useMessages(event.id);
  const [isClaiming, setIsClaiming] = useState(!item.quantity);
  const [userComments, setUserComments] = useState<Message[]>();

  const [targetComment, setTargetComment] = useState<Message>();

  const quantityInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setUserClaims(item.claims.filter((c) => c.user.id === user?.id));
    setIsClaiming(!item.quantity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const remaining = !item.quantity
    ? 0
    : item.quantity -
      item.claims
        .filter((c) => c.user.id !== user?.id)
        .reduce((remaining, c) => (remaining += c.quantity), 0);

  const [claimed, setClaimed] = useState(
    () => item.claims.find((c) => c.user.id === user?.id)?.quantity
  );

  useEffect(() => {
    const input = quantityInput.current;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const onFocusOut = () => {
      if (!isMobile || !claimed) {
        return;
      }

      handleClaimUpdate();
    };
    input?.addEventListener('focusout', onFocusOut);

    return () => input?.removeEventListener('focusout', onFocusOut);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimed]);

  useAsync(
    async () =>
      setUserComments(await attending.getItemUserClaimComments(event.id, item.id, user!.id)),
    []
  );

  const handleClaimUpdate = async () => {
    const currentItem = item.claims.find((c) => c.user.id === user?.id);
    const currentQuantity = currentItem?.quantity;
    if (claimed === undefined || (claimed === 0 && currentQuantity === undefined)) {
      return;
    }

    onClaim(claimed);
    if (claimed === 0) {
      await removeCommentsForClaim();
    }
  };

  const [openCommentEditor, closeCommentEditor] = useModal(
    () => (
      <Modal
        onClose={closeCommentEditor}
        header={<div className="text-xl font-semibold">Details</div>}
        fullHeight
      >
        {({ close }) => (
          <AddEventItemComment
            eventId={event.id}
            comment={targetComment}
            onDelete={(comment) => {
              if (userComments) {
                userComments.splice(userComments.indexOf(comment), 1);
                setTargetComment(undefined);
              }
            }}
            onSubmit={async (comment) => {
              if (targetComment?.id) {
                await fetcher(`/api/event/${event.id}/message/${targetComment.id}`, {
                  method: 'POST',
                  body: comment
                });

                setUserComments(
                  userComments?.map((c) =>
                    c.id !== targetComment.id
                      ? c
                      : {
                          ...targetComment,
                          message: comment
                        }
                  )
                );
              } else {
                setUserComments([
                  ...(userComments || []),
                  await fetcher(`/api/event/${event.id}/items/${item.id}/comment`, {
                    method: 'POST',
                    body: comment
                  })
                ]);
              }

              close();
            }}
          />
        )}
      </Modal>
    ),
    [targetComment, userComments]
  );

  const removeCommentsForClaim = async () => {
    const userCommentToRemove = item.comments.find((c) => c.author.id === user?.id);
    if (userCommentToRemove) {
      await deleteComment(userCommentToRemove.id);
      if (userComments) {
        userComments.splice(userComments.indexOf(userCommentToRemove), 1);
        setTargetComment(undefined);
      }
    }
  };

  return (
    <>
      {isClaiming && (
        <Card>
          {item.quantity && item.quantity > 0 && (
            <ClaimDetail>
              <div className="flex space-x-2 flex-1 flex-wrap items-baseline items-center">
                <div className="flex-shrink-0">
                  I'll{' '}
                  {item.name === 'Lodging'
                    ? 'house'
                    : item.name === 'Transportation'
                    ? 'transport'
                    : 'bring'}{' '}
                </div>
                <div>
                  <QuantityInput
                    ref={quantityInput}
                    type="number"
                    placeholder="0"
                    pattern="[0-9]*"
                    disableSubtlePlaceholder
                    value={claimed}
                    onFocus={(e) => e.target.select()}
                    onChange={(e) => {
                      const quantity = Number(e.target.value);
                      if (quantity > remaining) {
                        return;
                      }

                      setClaimed(quantity);
                    }}
                    min={1}
                    max={remaining}
                  />
                </div>
                <div className="flex-1 lowercase">
                  {item.customMeasurement || item.measurement?.name}
                </div>
              </div>
            </ClaimDetail>
          )}
          {!userComments?.length ? (
            <>
              <div className="flex flex-row flex-wrap">
                <InteractionLink
                  useDefaultPointer={isPreviewMode}
                  className="flex items-center select-none space-x-1 w-26 mr-2"
                  onClick={isPreviewMode ? () => {} : openCommentEditor}
                >
                  <ChatBerry className="fill-current" /> <div>Add Details</div>
                </InteractionLink>
                <div className="text-sm self-center basis-full">
                  (e.g., specifics of what you'll bring or how you'll help)
                </div>
              </div>
            </>
          ) : (
            userComments.map((c) => (
              <Comment key={c.id}>
                <div>
                  {c.author.firstName}: {c.message}
                </div>
                <InteractionLink
                  onClick={() => {
                    setTargetComment(c);
                    openCommentEditor();
                  }}
                >
                  Edit
                </InteractionLink>
              </Comment>
            ))
          )}
          {item.quantity && item.quantity > 0 && (
            <div className="flex flex-1 space-x-2 pr-2 justify-end">
              <InteractionLink
                onClick={() => {
                  handleClaimUpdate();
                }}
              >
                <div className="flex items-center">Save</div>
              </InteractionLink>
              <InteractionLink
                onClick={() => {
                  setIsClaiming(false);
                }}
              >
                <div className="flex items-center">Cancel</div>
              </InteractionLink>
            </div>
          )}
        </Card>
      )}

      {!isClaiming &&
        userClaims?.map((c) => (
          <Claim
            key={c.id}
            event={event}
            item={item}
            claim={c}
            type={getClaimTypeFromItem(item)}
            onEdit={() => setIsClaiming(true)}
            onRemove={async () => {
              removeCommentsForClaim();
              onClaim(0);
            }}
          />
        ))}

      {item.claims
        .filter((c) => c.user.id !== user?.id)
        .map((c) => (
          <Claim
            key={c.id}
            event={event}
            item={item}
            claim={c}
            type={getClaimTypeFromItem(item)}
            onEdit={() => setIsClaiming(true)}
          />
        ))}

      {!userClaims.length &&
        !item.quantity &&
        comments &&
        comments
          .filter((c) => (user ? c.author.id !== user.id : true))
          .map((c) => (
            <Card key={c.id}>
              <Comment key={c.id}>
                <div>
                  {c.author.firstName}: {c.message}
                </div>
              </Comment>
            </Card>
          ))}

      {!userClaims.length && item.quantity && item.quantity > item.claims.length && !isClaiming && (
        <InteractionLink
          useDefaultPointer={isPreviewMode}
          onClick={() => {
            if (isPreviewMode) {
              return;
            }
            setClaimed(1);
            setIsClaiming(true);
          }}
          className="flex items-center select-none"
        >
          Claim Item
        </InteractionLink>
      )}
    </>
  );
});

export default Claims;
