import styled from '@emotion/styled';
import { ErrorMessage } from '@hookform/error-message';
import Moment from 'moment';
import { useMemo, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useRouteMatch } from 'react-router-dom';
import tw from 'twin.macro';

import {
  Address,
  Button,
  Calendar,
  DatePicker,
  FadeIn,
  Location,
  Time2,
  TimePicker
} from '../../components';
import { Accordion } from '../../components/Accordion';
// TODO investigate why direct import of this component works, but importing it above from '../../components' creates a UI breaking styled component error
import Input from '../../components/Input/Input';
import { Event, EventCategory } from '../../models';

interface DetailsProps {
  saveButtonName?: string;
  event: Event;
  onChange: (event: Event) => void;
  onSubmit: (event: Event) => void;
  onCancelEvent?: (eventId: number) => void;
}

const SectionHeaders = {
  [EventCategory.Event]: {
    date: 'Pick a date',
    time: 'What time?',
    location: 'Choose a location'
  },
  [EventCategory.Excursion]: {
    date: 'When are we going?',
    time: 'Arrive By',
    location: 'Where is it?'
  }
};

const Cancel = styled.div`
  ${tw`cursor-pointer select-none text-indicating-error text-center`}
`;

const NextButton = styled(Button)`
  ${tw`min-w-0 px-14`}
`;

const NameInput = styled(Input)`
  ${tw`w-full text-xl font-semibold rounded-none border-neutral-black`}

  border: 0;
  border-bottom: 0.125rem solid black;

  ::placeholder {
    ${tw`text-neutral-black`}
  }
`;

export function Details({ event, onSubmit, onCancelEvent, saveButtonName = 'Next' }: DetailsProps) {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    trigger,
    reset,
    formState: { errors }
  } = useForm<Event>({
    defaultValues: useMemo(() => event, [event]),
    mode: 'all',
    shouldUnregister: false
  });

  const startTime = useWatch({ control, name: 'startTime', defaultValue: '' });
  const endTime = useWatch({ control, name: 'endTime', defaultValue: '' });
  const { path } = useRouteMatch();
  const isEditing = path.includes('edit') || path.includes('event');
  const expandDateTimeFields = event?.name?.includes('(Copy)');

  const [isPickingEndTime, setIsPickingEndTime] = useState(!!event?.endTime);

  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate'>('startDate');

  const initialVisibleMonth = useMemo(() => {
    if (event && event.startTime) {
      return () => (event.startTime ? Moment(event.startTime) : Moment());
    } else {
      return () => Moment();
    }
  }, [event]);

  if (!event) {
    return null;
  }

  const headers = SectionHeaders[event.category];

  return (
    <FadeIn className="details flex-1 flex flex-col">
      <form
        onSubmit={handleSubmit((data) => {
          onSubmit?.(data);
          reset(data);
        })}
        className="flex flex-col flex-1 justify-between"
      >
        <div>
          <div className={'pl-2 pb-1'}>Event Title</div>
          <Input
            className={!errors.name ? 'mb-6' : ''}
            disableSubtlePlaceholder
            autoComplete="off"
            placeholder="(required)"
            errors={errors.name}
            {...register('name', { required: 'Event title is required' })}
          />
          <Accordion
            isOpen={!isEditing || expandDateTimeFields}
            header={
              <>
                <Calendar /> <div>{headers['date']}</div>
              </>
            }
          >
            <Controller
              control={control}
              name="startTime"
              rules={{
                validate: {
                  time1: (start) => (endTime && start ? start < endTime : true)
                }
              }}
              render={({ field: { ref, value, onChange } }) => (
                <DatePicker
                  ref={ref}
                  noBorder
                  hideKeyboardShortcutsPanel
                  enableOutsideDays
                  minimumNights={0}
                  numberOfMonths={1}
                  minDate={Moment()}
                  initialVisibleMonth={initialVisibleMonth}
                  startDate={startTime ? Moment(startTime) : null}
                  endDate={endTime ? Moment(endTime) : null}
                  focusedInput={focusedInput}
                  onFocusChange={(input) => setFocusedInput(input || 'startDate')}
                  onDatesChange={({ startDate: s, endDate: e }) => {
                    if (focusedInput === 'startDate' || (s && s < Moment(value))) {
                      const startDate = s?.toISOString().slice(0, 10);
                      const _startTime = startTime?.slice(11);

                      setValue('startTime', `${startDate} ${_startTime || '00:00'}`);
                      setValue('endTime', undefined);

                      onChange(`${startDate} ${_startTime || '00:00'}`);
                      setIsPickingEndTime(false);

                      return;
                    }

                    const endDate = e?.toISOString().slice(0, 10);
                    const _endTime = endTime?.slice(11);

                    setValue('endTime', `${endDate} ${_endTime || '23:59'}`);

                    // required to pick up value of endTime on second render
                    trigger();

                    setIsPickingEndTime(true);
                  }}
                />
              )}
            />
          </Accordion>
          <Accordion
            isOpen={!isEditing || expandDateTimeFields}
            header={
              <>
                <Time2 />
                <div>{headers['time']}</div>
              </>
            }
          >
            <div className="flex flex-col space-y-4">
              <Controller
                control={control}
                name="startTime"
                rules={{
                  validate: {
                    time2: (start) => (endTime && start ? start < endTime : true)
                  }
                }}
                render={({ field: { ref, value, onChange, ...field } }) => (
                  <TimePicker
                    {...field}
                    value={value ? value.slice(11) : ''}
                    onChange={(time) => {
                      const currentStartTime = value || new Date().toISOString();
                      const startTime = `${currentStartTime.slice(0, 10)} ${time}`;

                      setValue('startTime', startTime);
                      trigger();

                      onChange(startTime);
                    }}
                  />
                )}
              />
              {!isPickingEndTime && (
                <div
                  className="cursor-pointer select-none text-interaction"
                  onClick={() => setIsPickingEndTime(true)}
                >
                  + add end time
                </div>
              )}
              {isPickingEndTime && (
                <>
                  <div className="text-neutral-ash">
                    {event.category === EventCategory.Event ? 'until' : 'Leave by'}
                  </div>
                  <Controller
                    control={control}
                    name={'endTime'}
                    rules={{
                      validate: {
                        time: (end) =>
                          startTime && end
                            ? end > startTime || 'End time cannot be before start time.'
                            : true
                      }
                    }}
                    render={({ field: { ref, value, onChange, ...field } }) => (
                      <TimePicker
                        {...field}
                        value={value ? value.slice(11) : ''}
                        onChange={(time) => {
                          const endTime = `${(value || startTime!).slice(0, 10)} ${time}`;

                          setValue('endTime', endTime);
                          trigger();

                          onChange(endTime);
                        }}
                      />
                    )}
                  />
                  <div
                    className="cursor-pointer select-none text-interaction"
                    onClick={() => {
                      setIsPickingEndTime(false);
                      setValue('endTime', undefined);
                    }}
                  >
                    - remove end time
                  </div>
                  <ErrorMessage
                    errors={errors}
                    name="endTime"
                    render={({ message }) => (
                      <FadeIn className="p-2 bg-indicating-error bg-opacity-50 rounded-md">
                        {message}
                      </FadeIn>
                    )}
                  />
                </>
              )}
            </div>
          </Accordion>

          <Accordion
            isOpen={!isEditing}
            header={
              <>
                <Location />
                <div>{headers['location']}</div>
              </>
            }
          >
            <Controller
              control={control}
              name="address"
              render={({ field: { ref, ...field } }) => <Address {...field} />}
            />
          </Accordion>
          <div className="border-t border-neutral-stone mt-2" />
        </div>
        <div>
          <div className="flex flex-row flex-wrap mt-10">
            <div className={`flex w-full mt-10 ${onCancelEvent ? 'justify-between' : 'justify-end'}`}>
              {onCancelEvent && (
                <Cancel className="self-center" onClick={() => onCancelEvent(event.id)}>
                  Cancel
                </Cancel>
              )}
              <NextButton>{saveButtonName}</NextButton>
            </div>
          </div>
          <div className="pt-4 space-y-2">
            <ErrorMessage
              errors={errors}
              name="startTime"
              render={({ message, messages }) =>
                message ? (
                  <p className="text-indicating-error">{message}</p>
                ) : messages ? (
                  Object.entries(messages).map(([type, message]) => (
                    <p className="text-indicating-error" key={type}>
                      {message}
                    </p>
                  ))
                ) : null
              }
            />
          </div>
        </div>
      </form>
    </FadeIn>
  );
}

export default Details;
