import * as React from 'react';

function SvgLodging(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        stroke="#070401"
        strokeWidth={1.24}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M19.6 11.586v7.346c0 .59-.478 1.068-1.068 1.068H5.468c-.59 0-1.068-.478-1.068-1.068v-7.346h0l7.643-7.218 7.557 7.218z"
          fill="#F8F6F1"
        />
        <path d="M8.99 15.506H7.412a.5.5 0 01-.512-.485v-1.766a.5.5 0 01.512-.485H8.99a.5.5 0 01.512.485v1.766a.5.5 0 01-.512.485z" />
        <path fill="#F0EBE0" d="M12.177 18.455v-5.667h4v5.667z" />
        <path
          fill="#F0EBE0"
          d="M10.838 19.907v-1.563h6.6v1.563zM21.34 11.323L12 3h0l-.014.015-9.326 8.31a.989.989 0 00-.15 1.31.79.79 0 001.171.133l8.32-7.414h0l8.317 7.412a.79.79 0 001.171-.134.989.989 0 00-.149-1.31z"
        />
        <path fill="#ECECEC" d="M15.8 6.443V4.368h2.6v4.299z" />
      </g>
    </svg>
  );
}

export default SvgLodging;
