import * as React from 'react';

function SvgHeartFilled(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M17.505 10.34l1.498-1.56a5.166 5.166 0 000-7.273 5.09 5.09 0 00-7.23 0L10.25 3.039 8.727 1.507a5.09 5.09 0 00-7.23 0 5.166 5.166 0 000 7.274l1.524 1.532L10.25 17.5l7.255-7.16z"
          id="heart-filled_svg__a"
        />
      </defs>
      <use
        stroke="#070401"
        strokeWidth={1.54}
        fill="#070401"
        xlinkHref="#heart-filled_svg__a"
        transform="translate(2 4)"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgHeartFilled;
