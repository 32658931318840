import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';

export const growIn = (height: string) => keyframes`
  0% {
    height: 0;
    opacity: 0;
  }
  
  100% {
    height: ${height};
    opacity: 1;
  }
`;

export const growInAnimation = (height?: string, time?: number) => css`
  animation: ${growIn(height || '100%')} ${time || 0.3}s ease-in-out;
`;

export const GrowIn = styled.div`
  ${growInAnimation('100%', 0.3)}
`;
