const logPaymentAnalytic = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';
  const LENGTH = 64;

  for (let i = 0; i < LENGTH; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  //@ts-ignore
  window.gtag_report_conversion(randomString);
};

export { logPaymentAnalytic };
