import styled from '@emotion/styled';
import tw from 'twin.macro';

import { Event } from '../../models';
import { Card } from '../Card';

const Container = styled(Card)`
  ${tw`px-3 py-2 text-center overflow-visible -mt-4 mr-4`}
`;

const EventMonth = styled.div``;

const EventDate = styled.div`
  ${tw`text-3xl font-semibold`}
`;

type TimestampProps = {
  event: Event;
};

export function Timestamp({ event }: TimestampProps) {
  const startTime = event.startTime ? new Date(event.startTime) : undefined;
  const month = startTime?.toLocaleString('default', { month: 'short' });

  const hours = startTime?.getHours();
  const minutes = startTime?.getMinutes();

  return (
    <Container>
      {startTime && hours !== undefined && minutes !== undefined ? (
        <>
          <EventMonth>{month}</EventMonth>
          <EventDate>{startTime?.getDate()}</EventDate>
          <div>
            {hours > 12 ? hours - 12 : hours}:{`${minutes}`.padStart(2, '0')}
            {hours < 12 ? 'am' : 'pm'}
          </div>
        </>
      ) : (
        <EventDate>TBD</EventDate>
      )}
    </Container>
  );
}
