import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import { useModal } from 'react-modal-hook';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import tw from 'twin.macro';

import {
  BrandLogo,
  Button,
  Card,
  Input,
  Modal,
  PollCreateScene,
  SignupScene
} from '../../components';
import Page from '../Page';

const FormCard = styled(Card)`
  ${tw`z-10 overflow-visible`}
`;

export function Access() {
  const router = useHistory();
  const { id } = useParams<{ id: string }>();
  const code = new URLSearchParams(useLocation().search).get('code');

  const {
    register,
    reset,
    formState: { isValid, isDirty, errors },
    handleSubmit
  } = useForm<{ identifier: string }>({
    mode: 'onChange',
    defaultValues: { identifier: '' }
  });

  const [openCredentialsWereInvalid, closeCredentialsWereInvalid] = useModal(
    () => (
      <Modal onClose={closeCredentialsWereInvalid}>
        {({ close }) => (
          <div className="flex flex-col items-center space-y-10">
            <div>
              Sorry! That email or phone number was not valid. Please try again. If you are still
              having trouble, please contact the event organizer.
            </div>
            <Button onClick={close}>Try again</Button>
          </div>
        )}
      </Modal>
    ),
    []
  );

  return (
    <Page className="flex flex-col items-center self-center h-full max-w-xl pt-8 mx-auto space-y-20">
      <FormCard>
        <form
          onSubmit={handleSubmit(async ({ identifier }) => {
            const { ok } = await fetch(`/api/event/${id}/access/${code}`, {
              method: 'POST',
              body: identifier
            });

            if (!ok) {
              openCredentialsWereInvalid();
              reset();

              return;
            }

            router.push(`/event/${id}`);
          })}
        >
          <PollCreateScene />
          <div className="flex flex-col space-y-8">
            <div className="flex flex-col space-y-4">
              <div className="text-xl font-semibold">
                Just a sec! We need to make sure we know who you are.
              </div>
              <div>
                Please enter the email address or phone number you received your event invitation
                at.
              </div>
            </div>
            <div className="flex flex-col space-y-6">
              <Input
                placeholder="Invitation Email / Phone Number"
                {...register('identifier', {
                  required: 'An email or phone number is required.',
                  validate: {
                    pattern: (value) =>
                      !!(
                        value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) ||
                        value.match(/^(\d{10}|\d{12})$/)
                      ) || 'Not a valid email or phone number.'
                  }
                })}
                errors={errors.identifier}
              />

              <Button className="self-center" disabled={!isValid || !isDirty}>
                Confirm
              </Button>
            </div>
          </div>
        </form>
      </FormCard>
      <BrandLogo className="z-10" />
      <SignupScene className="absolute bottom-0 z-0" />
    </Page>
  );
}
