import * as React from 'react';

function SvgCalendar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        transform="translate(3 3)"
        stroke="#070401"
        strokeWidth={1.54}
        fill="none"
        fillRule="evenodd"
      >
        <rect fill="#ECECEC" y={1.667} width={17.754} height={16} rx={3} />
        <path
          d="M0 6.167V3.75C0 2.507 1.051 1.5 2.348 1.5h13.058c1.297 0 2.348 1.007 2.348 2.25v2.417H0z"
          fill="#DDDDDC"
        />
        <rect fill="#ECECEC" x={2.968} y={8.417} width={2.968} height={2.667} rx={0.96} />
        <rect fill="#DDDDDC" x={4.452} width={2.226} height={4} rx={0.96} />
        <rect fill="#DDDDDC" x={11.13} width={2.226} height={4} rx={0.96} />
      </g>
    </svg>
  );
}

export default SvgCalendar;
