import { useEffect } from 'react';
import { useModal } from 'react-modal-hook';
import { Link } from 'react-router-dom';

import { Button, MemberCreateScene, Modal } from '../../components';
import { fetcher, useUser } from '../../providers';
import { PublicPage } from '../Page';

export function VerifyEmail() {
  const { user, getUser, setUser } = useUser();

  useEffect(() => {
    const onRefocus = async () => {
      if (document.visibilityState === 'visible') {
        setUser(await getUser());
      }
    };
    window.addEventListener('visibilitychange', onRefocus);

    return () => void window.removeEventListener('visibilitychange', onRefocus);
  }, [getUser, setUser]);

  const [openVerificationEmailResentConfirmation, closeVerificationEmailResentConfirmation] =
    useModal(() => {
      if (!user) {
        return null;
      }

      return (
        <Modal onClose={closeVerificationEmailResentConfirmation}>
          {({ close }) => (
            <div className="flex flex-col items-center space-y-4">
              <MemberCreateScene className="h-28" />
              <div>
                Your verification email has been resent to <b>{user?.email}</b>
              </div>
              <Button onClick={close}>Close</Button>
            </div>
          )}
        </Modal>
      );
    }, [user]);

  // bring back when we decide to use verification on email/phone
  // if (user?.verifiedAt) {
  //   const redirectUrl = new URLSearchParams(window.location.search).get('redirect-url') || '/';
  //   return <Redirect to={{ pathname: redirectUrl }} />;
  // }

  return (
    <PublicPage
      footer={
        <>
          <Link to="/sign-out">Sign out</Link>
          <p>
            By signing up and verifiying you agree to our{' '}
            <a href="https://potluck.us/privacy">Privacy Policy</a> and{' '}
            <a href="https://potluck.us/terms">Terms of Service</a>
          </p>
        </>
      }
    >
      <div className="max-w-xs mx-auto space-y-8">
        <MemberCreateScene />
        <div>
          You need to verify your email before you can start to create events. You should have
          received an email with a link to verify with. If you need to resend this, do so using the
          button below.
        </div>
        <div className="text-center">
          <Button
            onClick={async () => {
              await fetcher('/api/user/send-verification-email');
              openVerificationEmailResentConfirmation();
            }}
          >
            Resend verification email
          </Button>
        </div>
      </div>
    </PublicPage>
  );
}

export default VerifyEmail;
