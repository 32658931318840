import { Link } from 'react-router-dom';

import { Button, Card, PlanSomething } from '../../components';
import { PublicPage } from '../Page';

export function Forbidden() {
  return (
    <PublicPage>
      <Card className="flex flex-col items-center max-w-xl mx-auto space-y-8">
        <PlanSomething />
        <div className="text-4xl font-semibold">NO ACCESS</div>
        <p>It looks like you don't have access to that event. Check with the host to make sure.</p>
        <Link to="/">
          <Button>Home</Button>
        </Link>
      </Card>
    </PublicPage>
  );
}

export default Forbidden;
