import { useEffect, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { Button, Card, Modal } from '../../components';
import { useEvent } from '../../hooks';
import { Event } from '../../models';
import { usePotluck } from '../../providers';
import { Details } from './Details';
import FlowConfirmation from './FlowConfirmation';
import { Guests } from './Guests';
import { Invites } from './Invites';
import Items from './Items';

interface EditorProps {
  event: Event;
  onChange: (event: Event, publish?: boolean) => void | Promise<void>;
  onDismiss?: () => void;
  showCancelDialog?: boolean;
}

const orderedSteps = ['details', 'items', 'guests', 'invites', 'confirmation'] as const;
type EditStep = (typeof orderedSteps)[number];

export function Editor({ event: _event, onChange, showCancelDialog = true }: EditorProps) {
  const router = useHistory();
  const { organizing } = usePotluck();
  const { url } = useRouteMatch();
  const [event, setEvent] = useState(_event);
  useEffect(() => setEvent(_event), [_event]);
  const { save: saveEvent } = useEvent(_event.id);

  const handleEventChange = (updatedEvent: Event) => {
    setEvent(updatedEvent);
    saveEvent(updatedEvent);
  };

  const saveAndProgress = (step: EditStep, publish?: boolean) => async (event: Event) => {
    if (publish) {
      await onChange(event, publish);
    } else {
      await onChange(event);
    }

    if (orderedSteps.indexOf(step) + 1 < orderedSteps.length) {
      router.push(`${url}/${orderedSteps[orderedSteps.indexOf(step) + 1]}`);
    }
  };

  const eventCancelText = `Are you sure you want to cancel <b>${event.name}</b>?<br>All attendees will be notified.<br>This cannot be undone.`;

  const [openConfirmCancelEvent, closeConfirmCancelEvent] = useModal(
    () => (
      <Modal onClose={closeConfirmCancelEvent}>
        {({ close }) => (
          <>
            {event && (
              <div className="flex flex-col text-center space-y-4">
                <p dangerouslySetInnerHTML={{ __html: eventCancelText }}></p>
                <div className="flex flex-col justify-evenly sm:flex-row">
                  <Button
                    onClick={async () => {
                      close();
                      if (event.id) await organizing.cancel(event.id);
                      router.push('/');
                    }}
                  >
                    Cancel Event
                  </Button>
                  <Button className="mt-2" outline onClick={close}>
                    I've changed my mind
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </Modal>
    ),
    [event]
  );

  const handleEventCancel = () => {
    if (showCancelDialog) {
      openConfirmCancelEvent();
    } else {
      router.push('/');
    }
  };

  return (
    <Card className="flex flex-col flex-1 w-full space-y-6">
      <Switch>
        <Route path={`${url}/details`}>
          <Details
            event={{ ...event }}
            onChange={handleEventChange}
            onSubmit={(event) => saveAndProgress('details')({ ...event })}
            onCancelEvent={handleEventCancel}
          />
        </Route>

        <Route path={`${url}/items`}>
          <Items
            event={event}
            onChange={handleEventChange}
            onSubmit={(event) => saveAndProgress('items')({ ...event })}
            onCancelEvent={handleEventCancel}
          />
        </Route>

        <Route path={`${url}/guests`}>
          <Guests
            event={event}
            onChange={handleEventChange}
            onSubmit={saveAndProgress('guests')}
            onCancelEvent={handleEventCancel}
            saveButtonName="Next"
          />
        </Route>

        <Route path={`${url}/invites`}>
          <Invites
            onChange={(e) => {
              event.guestMessage = e.guestMessage;
              event.cohostMessage = e.cohostMessage;
              handleEventChange(e);
            }}
            event={event}
            onSubmit={saveAndProgress('invites', true)}
          />
        </Route>

        <Route path={`${url}/confirmation`}>
          <FlowConfirmation eventParam={event} />
        </Route>
      </Switch>
    </Card>
  );
}

export default Editor;
