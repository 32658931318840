import { runInAction } from 'mobx';
import { useEffect, useState } from 'react';
import useSWR from 'swr';

import { EngagementMetrics, Event, EventGuest, EventMoment, EventRole } from '../models';
import { fetcher, usePotluck } from '../providers';

const API = `/api/event`;

export function useEvent(_id?: number) {
  const [id, setId] = useState(_id);
  const { organizing, moments, attending } = usePotluck();
  const { data: event, error, mutate, ...rest } = useSWR<Event>(() => (id ? `${API}/${id}` : null));

  async function role(id: number): Promise<EventRole> {
    return await fetcher(`${API}/${id}/role`);
  }

  async function addCoverImage(id: number, coverImage: File) {
    const envelope = new FormData();
    envelope.set('cover-image', coverImage);

    return await fetcher(`${API}/${id}/cover-image`, { method: 'POST', body: envelope }, true);
  }

  async function save(updates: Event, publish?: boolean) {
    if (updates.startTime) {
      updates.startTime = updates.startTime.replace(/\//g, '-');
    }

    if (updates.endTime) {
      updates.endTime = updates.endTime.replace(/\//g, '-');
    }

    if (!updates.address?.zipCode) {
      updates.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    if (!updates.id) {
      const { coverImage, ...rest } = updates;
      updates = rest;
      updates = await fetcher(`${API}${publish ? '?publish=True' : ''}`, {
        method: 'POST',
        body: JSON.stringify(updates)
      });

      if (coverImage) {
        updates = await addCoverImage(updates.id, coverImage);
      }

      setId(updates.id);
      mutate(updates);

      runInAction(() => {
        organizing.data?.unshift(updates);
        attending.data?.unshift(updates);
      });

      return updates;
    }

    const { id, guests, status, coverImage, ...editable } = updates;

    updates = await fetcher(`${API}/${id}${publish ? '?publish=True' : ''}`, {
      method: 'PUT',
      body: JSON.stringify(editable, (k, v) => (v === undefined ? null : v))
    });

    if (coverImage) {
      updates = await addCoverImage(updates.id, coverImage);
    }

    mutate(updates);

    runInAction(() => {
      if (organizing.data) {
        const matchedEvent = organizing.data.find((e) => e.id === updates.id);
        if (matchedEvent) {
          // forcing update of affected event -- keeps event summary cover images in sync
          const matchedEventIndex = organizing.data.indexOf(matchedEvent);
          organizing.data[matchedEventIndex] = updates;
        } else {
          organizing.data.unshift(updates);
        }
        organizing.data[organizing.lookup[updates.id]] = updates;
      }

      organizing.items(updates.id).data = updates.items;

      if (attending.data) {
        attending.data[attending.lookup[updates.id]] = updates;
      } else {
        attending.items(updates.id).data = updates.items;
      }
    });

    return updates;
  }

  async function cancel() {
    await fetcher(`${API}/${id}`, { method: 'DELETE' });
    mutate();
  }

  async function addMoment(event: Event, moment: Partial<EventMoment>, momentImage?: File) {
    moment = await fetcher(`${API}/${event.id}/moment`, {
      method: 'POST',
      body: JSON.stringify(moment)
    });

    try {
      if (momentImage) {
        const envelope = new FormData();
        envelope.set('moment-image', momentImage);

        moment = await fetcher(
          `${API}/${event.id}/moment/${moment.id}/image`,
          { method: 'POST', body: envelope },
          true
        );
      }
    } catch (e){
      console.log("Error uploading media");
      return true;
    }

    await mutate(event, true);

    moments.feed(event.id).data?.unshift(moment as EventMoment);

    return moment;
  }

  async function guests(event: Event): Promise<EventGuest[]> {
    return await fetcher(`${API}/${event.id}/guests`);
  }

  async function leave(event: Event) {
    return await fetcher(`${API}/${event.id}/leave`);
  }

  async function copy(event: Event) {
    return await fetcher(`${API}/${event.id}/copy`, { method: 'POST' });
  }

  return {
    event,
    error,
    save,
    addMoment,
    guests,
    cancel,
    mutate,
    role,
    leave,
    copy,
    ...rest
  };
}

export function useEventMetrics(eventId: number, activate?: boolean) {
  const [activator, setActivator] = useState('');
  useEffect(() => {
    if (activate && !activator) {
      setActivator(`/api/event/${eventId}/metrics`);
    }
  }, [activate, activator, eventId]);

  const { data: metrics, ...query } = useSWR<{
    claims: number;
    guests: number;
    invites: number;
    quantity: number;
  }>(() => activator);

  return {
    metrics,
    ...query
  };
}

export function useEventEnagementMetrics(eventId: number, activate?: boolean) {
  const [activator, setActivator] = useState('');

  useEffect(() => {
    if (activate && !activator) {
      setActivator(`/api/event/${eventId}/engagement`);
    }
  }, [activate, activator, eventId]);

  const { data: metrics, ...query } = useSWR<EngagementMetrics>(() => activator);

  return {
    metrics,
    ...query
  };
}

export default useEvent;
