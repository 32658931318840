import * as React from 'react';

function SvgAppetizers(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        stroke="#070401"
        strokeWidth={1.54}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path fill="#ECECEC" d="M9.34 8.293L6.107 4l-1.855 7h4.607L7.056 9.063z" />
        <path
          d="M14.866 19H9.068C5.717 19 3 16.428 3 13.256v-1.813h17.934v1.813c0 3.172-2.716 5.744-6.068 5.744z"
          fill="#DDDDDC"
        />
        <path fill="#ECECEC" d="M13.557 10.997l.5-3.871-7 1.97L9.032 11z" />
        <path fill="#ECECEC" d="M17.656 11L21 9.297l-6.407-4.241L13.506 11z" />
      </g>
    </svg>
  );
}

export default SvgAppetizers;
