import { action, makeObservable, observable, runInAction } from 'mobx';

import { Page } from '../models';
import { fetcher } from '../providers';

const paged = (url: string, pageSize?: number, pageCursor?: string) => {
  const searchParams = new URLSearchParams();
  if (pageSize) {
    searchParams.append('page-size', `${pageSize}`);
  }

  if (pageCursor) {
    searchParams.append('page-cursor', pageCursor);
  }

  return `${url}?${searchParams}`;
};

interface WindowOptions {
  bounds?: Element | null | (() => Element | null);
  margin?: number | [number, number] | [number, number, number, number];
  afterNext?: (cursorElement: Element, previousCursor?: string, nextCursor?: string) => void;
}

const hasId = (data: Object): data is { id: number } => data.hasOwnProperty('id');

export class LazyStore<DataType extends Object> {
  data?: DataType[];
  cursor?: string = '';
  previousCursor?: string;

  loading: boolean = false;
  lookup: Record<number, number> = {};

  constructor(public name: string = '', public pageSize = 15) {
    makeObservable(this, {
      data: observable,
      cursor: observable,
      loading: observable,
      next: action.bound,
      register: action.bound,
      load: action.bound
    });
  }

  register(_window: HTMLElement | null, { bounds, margin, afterNext }: WindowOptions = {}) {
    if (!_window) {
      return;
    }

    if (bounds === undefined) {
      bounds = () => _window?.lastElementChild || null;
    }

    const resolve = () => {
      return typeof bounds === 'function' ? bounds() : bounds;
    };

    const intersector = new IntersectionObserver(
      async (elements) => {
        if (!elements.some((e) => e.isIntersecting)) {
          return;
        }

        const boundsPreExecution = resolve() || _window;
        if (!boundsPreExecution) {
          return;
        }

        const yielded = await this.next();
        intersector.unobserve(boundsPreExecution);

        afterNext?.(boundsPreExecution, this.previousCursor, this.cursor);

        if (!yielded) {
          return;
        }

        const nextBounds = resolve();
        if (nextBounds) {
          intersector.observe(nextBounds);
        }
      },
      {
        threshold: 0.1,
        rootMargin: `${margin || 0}px`
      }
    );

    const _bounds = resolve() || _window;
    if (!_bounds) {
      return;
    }

    intersector.observe(_bounds);
  }

  async next(size?: number): Promise<boolean> {
    if (this.loading) {
      return false;
    }

    if (!this.cursor && this.data) {
      return false;
    }

    runInAction(() => (this.loading = true));

    const response = (await fetcher(
      paged(`/api/${this.name}`, size || this.pageSize, this.cursor)
    )) as Page<DataType> | undefined;

    runInAction(() => (this.loading = false));

    if (!response) {
      this.cursor = undefined;
      return false;
    }

    const { data, previousCursor, nextCursor } = response;

    runInAction(() => {
      if (!this.data) {
        this.data = [];
      }

      data?.forEach((datum) => {
        if (hasId(datum)) {
          this.lookup[datum.id] = this.data!.length;
        }

        // This was put in place to address a race condition where a store could be populated before an
        // http request finishes and appends duplicate records to the store.
        if (!this.data?.some(d => hasId(d) && hasId(datum) ? d.id === datum.id : false)) {
          this.data?.push(datum);
        }

      });
      this.previousCursor = previousCursor;
      this.cursor = nextCursor;
    });

    return !!this.cursor;
  }

  async load(): Promise<void> {
    if (!this.cursor) {
      this.cursor = this.previousCursor;
    }

    runInAction(() => (this.loading = true));

    do {
      const response = (await fetcher(paged(`/api/${this.name}`, 50, this.cursor))) as
        | Page<DataType>
        | undefined;

      if (!response) {
        this.cursor = undefined;
        return;
      }

      const { data, previousCursor, nextCursor } = response;

      runInAction(() => {
        if (!this.data) {
          this.data = [];
        }

        data?.forEach((datum) => {
          if (hasId(datum)) {
            if (this.lookup[datum.id]) {
              return;
            }

            this.lookup[datum.id] = this.data!.length - 1;
          }

          this.data?.push(datum);
        });
        this.previousCursor = previousCursor;
        this.cursor = nextCursor;
      });
    } while (this.cursor);

    runInAction(() => (this.loading = false));
  }

  get hasMore() {
    return !!this.cursor;
  }
}

export default LazyStore;
