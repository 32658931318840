import { css } from '@emotion/react';
import styled from '@emotion/styled';
import tw from 'twin.macro';

interface ButtonProps {
  outline?: boolean;
  inline?: boolean;
  allWhite?: boolean;
  width?: number;
  borderInfo?: boolean;
  allBlue?: boolean;
  inlineWide?: boolean;
}

export const Button = styled.button<ButtonProps>`
  ${tw`self-center border rounded-3xl text-neutral-soil cursor-pointer`}
  transition: 0.1s ease-in-out;

  ${(p) => {
    if (p.outline) {
      if (p.borderInfo) {
        return tw`border-interaction text-interaction`;
      }
      return tw`border-neutral-soil`;
    }
    return tw`bg-primary border-primary`;
  }}
  
  ${(p) => {
    if (p.inline) {
      if (p.inlineWide) {
        return tw`px-9 py-2`;
      } 
      return tw`px-4 py-2`;
    } else {
      return tw`px-7 py-3 `;
    }
  }}

  ${(p) => p.allWhite && tw`text-neutral-white border-neutral-white`}

  
  ${(p) =>
    p.width &&
    css`
      width: ${p.width}px;
    `}

  ${(p) =>
    p.inline
      ? css`
          min-width: 0;
        `
      : css`
          min-width: 11.5rem;
        `}

  :hover:not(:disabled) {
    ${tw`shadow-md`}
    transform: scale(1.02);
  }

  :disabled {
    ${tw`cursor-not-allowed`}
    ${(p) =>
      p.outline
        ? tw`border-neutral-stone text-neutral-stone`
        : tw`border-primary-disabled bg-primary-disabled text-neutral-ash`}
  }
`;

export const CircleButton = styled(Button)`
  ${tw`flex items-center justify-center p-0 rounded-full w-14 h-14`}

  min-width: 0;
  font-size: 2rem;
`;

export default Button;
