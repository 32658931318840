import styled from '@emotion/styled';
import { ComponentProps, forwardRef, Ref } from 'react';
import { FieldError } from 'react-hook-form';
import tw from 'twin.macro';

import { FadeIn } from '../animations';

export const Container = styled.div`
  ${tw`relative w-full`}

  input + div {
    ${tw`absolute z-10 left-2 text-neutral-ash`}
    font-size: 0.6rem;
    top: 0.1rem;
  }

  input:placeholder-shown + div {
    display: none;
  }

  input::-webkit-date-and-time-value {
    text-align: left;
  }
`;

const Element = styled.input<{ invalid?: boolean }>`
  ${tw`relative z-10 w-full border rounded-lg border-neutral-ash text-neutral-soil bg-neutral-white p-2`}

  ${(p) => p.invalid && tw`border-2 border-indicating-error focus:outline-indicating-error`}

  height: 3rem;

  ::placeholder {
    ${tw`text-neutral-ash`}
  }

  &:disabled {
    ${tw`border-neutral-stone bg-content-pearl text-neutral-ash`}
  }

  &[type='date'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    position: relative;

    &:focus:before,
    &:not([data-value='']):before,
    &[data-value=''] + div {
      display: none;
    }

    &:focus + div {
      display: inline-flex;
    }

    &:not(:focus):before {
      content: attr(placeholder);
      pointer-events: none;
      ${tw`absolute top-0 bottom-0 left-0 right-0 p-2 text-neutral-ash bg-neutral-white`}
      width: 80%;
      margin-right: 0.5rem;
    }
  }
`;

export const Error = styled(FadeIn)<{ relativePosition?: boolean }>`
  ${tw`z-0 w-full px-2 py-1 pt-3 -mt-2 text-sm bg-indicating-error bg-opacity-50 rounded-b-md`}
  ${(p) => (p.relativePosition ? tw`relative` : tw`absolute`)}
`;

type InputProps = ComponentProps<'input'> & {
  errors?: FieldError;
  disableSubtlePlaceholder?: boolean;
};

export const Input = forwardRef(
  ({ errors, disableSubtlePlaceholder, ...props }: InputProps, ref: Ref<HTMLInputElement>) => (
    <Container placeholder={props.placeholder}>
      <Element invalid={!!errors} {...props} ref={ref} />
      <div>{!disableSubtlePlaceholder && props.placeholder}</div>
      {errors && <Error relativePosition>{errors.message}</Error>}
    </Container>
  )
);

export default Input;
