import * as React from 'react';

function SvgEdit(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`stroke-current text-neutral-soil ${props.className}`}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M16.425 5.416l1.038-1.038a2 2 0 012.829 0l.015.016a2 2 0 010 2.828L19.27 8.26h0L9.062 18.3l-3.812.843 1.194-3.553 9.981-10.173zM16 6l2.5 2.5" />
      </g>
    </svg>
  );
}

export default SvgEdit;
