import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import truncate from 'lodash/truncate';
import { useState } from 'react';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import tw from 'twin.macro';

import { FadeIn, Loader, Menu } from '../../components';
import { useEvent } from '../../hooks';
import Editor from './Editor';
import Step from './Step';

const Header = styled.div`
  ${tw`text-base font-semibold text-center flex-1 self-center`}
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 50%;
  }
`;

export const FadedLoader = styled.div`
  ${tw`fixed bottom-0 right-0 z-40 flex items-center justify-center w-full h-full bg-opacity-50 bg-neutral-soil opacity-50`};
  max-width: 30rem;

  animation: ${fadeIn} 0.5s ease-in;
`;

const Pill = styled.div`
  ${tw`flex flex-1 text-sm bg-content-pearl px-2 rounded-2xl w-16 justify-center self-center`}
`;

export function Edit() {
  const router = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { event, save } = useEvent(Number(id));
  const { url } = useRouteMatch();
  const { pathname } = useLocation();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>();

  const [isSaving, setIsSaving] = useState(false);

  const getPageName = () => {
    const urlSplit = pathname.split('/');
    return urlSplit[urlSplit.length - 1];
  };

  const orderedSteps = ['details', 'items', 'guests', 'invites', 'confirmation'] as const;
  type EditStep = typeof orderedSteps[number];

  const goBackFrom = (step: EditStep) => {
    if (orderedSteps.indexOf(step) - 1 >= 0) {
      router.push(`${url}/${orderedSteps[orderedSteps.indexOf(step) - 1]}`);
    }
  };

  if (!event) {
    return null;
  }

  const maxTitleLength = 57;
  const title = truncate(`${event.name}`, { length: maxTitleLength, omission: '...' });
  const isOnConfirmationPage = getPageName() === 'confirmation';

  return (
    <>
      <Menu
        open={isMenuOpen}
        header={
          <>
            <div className="flex flex-1">
              {!isOnConfirmationPage && (
                <div className="flex flex-col flex-1">
                  <Pill>Editing</Pill>
                  <Header>{title}</Header>
                </div>
              )}
              <Step />
            </div>
          </>
        }
        displayBack={!isOnConfirmationPage}
        displayBackToDashboard={isOnConfirmationPage}
        displayClose={false}
        className="w-full"
        onGoBack={() => {
          switch (getPageName()) {
            case 'details':
              router.push('/');
              break;
            case 'items':
              goBackFrom('items');
              break;
            case 'guests':
              goBackFrom('guests');
              break;
            case 'invites':
              goBackFrom('invites');
              break;
          }
        }}
      >
        <FadeIn className="relative w-full flex flex-col flex-1">
          <Editor
            showCancelDialog={false}
            event={event}
            onChange={async (event, publish) => {
              setIsSaving(true);
              await save(event, publish);
              setIsSaving(false);
            }}
            onDismiss={() => {
              setIsMenuOpen(false);
              router.push('/');
            }}
          />
        </FadeIn>
      </Menu>
      {isSaving && (
        <FadedLoader>
          <Loader />
        </FadedLoader>
      )}
    </>
  );
}
