import * as React from 'react';

function SvgTrashBlue(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#4C69BF" strokeWidth={1.54} fill="none" fillRule="evenodd">
        <path d="M6 9h13l-1.13 11.101a1 1 0 01-.995.899h-8.75a1 1 0 01-.994-.899L6 9h0zM10 6.166V4h5v2.166" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 6.5h16" />
      </g>
    </svg>
  );
}

export default SvgTrashBlue;
