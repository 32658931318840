import styled from '@emotion/styled';
import { ComponentProps, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useModal } from 'react-modal-hook';
import tw from 'twin.macro';

import { Photo } from '../icons';
import { ImageDialog } from '../ImageEditor/ImageDialog';
import { Modal } from '../Modal';

const Container = styled.div<{ background?: string }>`
  ${tw`flex items-center justify-center w-full p-16 border border-dashed cursor-pointer border-neutral-stone rounded-md`}

  ${(p) => p.background && tw`bg-neutral-ash`}
  background-image: ${(p) => p.background && `url(${p.background})`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  height: 11.875rem;
`;

type CoverImageUploadProps = ComponentProps<'div'> & {
  imageUrl?: string;
  onUpload: (file: File, localUrl: string) => void | Promise<void>;
  clearOnUpload?: boolean;
  useCropper?: boolean;
};

export const ImageUpload = forwardRef(
  (
    { imageUrl, onUpload, clearOnUpload, useCropper = true, ...props }: CoverImageUploadProps,
    forwardRef
  ) => {
    const [localUrl, setLocalUrl] = useState(imageUrl);
    const fileInput = useRef<HTMLInputElement>(null);

    const [openImageEditorModal, closeImageEditorModal] = useModal(
      () => (
        <Modal
          onClose={() => {
            closeImageEditorModal();
          }}
          fullHeight
        >
          {() => <ImageDialog onSave={doTheUpload} image={localUrl!} />}
        </Modal>
      ),
      [localUrl]
    );

    useImperativeHandle(forwardRef, () => fileInput.current);

    const prepareImageEditor = async (file?: File) => {
      if (!file) {
        return;
      }

      const imageUrl = URL.createObjectURL(file);
      setLocalUrl(imageUrl);
      if (useCropper) {
        openImageEditorModal();
      } else {
        doTheUpload(file);
      }
    };

    const doTheUpload = async (image: File) => {
      closeImageEditorModal();
      const imageUrl = URL.createObjectURL(image);
      setLocalUrl(imageUrl);
      await onUpload(image, imageUrl);
    };

    return (
      <>
        <Container {...props} onClick={() => fileInput.current?.click()} background={localUrl}>
          <input
            hidden
            type="file"
            value=""
            ref={fileInput}
            onChange={(e) => prepareImageEditor(e.target.files?.[0])}
          />
          {!localUrl && (
            <>
              <Photo className="text-2xl" />
              Upload an Image
            </>
          )}
        </Container>
      </>
    );
  }
);

export default ImageUpload;
