import Address from '../../models/Address';
import { getFormattedCityStateZipLines } from '../../utils/StringUtils';

export const AddressDisplay = ({ address }: { address: Address }) => {
  if (!address) {
    return <></>;
  }
  return (
    <div className="text-sm">
      {address.address && <p>{address.address}</p>}
      {address.address2 && <p>{address.address2}</p>}
      {(address.city || address.state || address.zipCode) && (
        <p>{getFormattedCityStateZipLines(address)}</p>
      )}
    </div>
  );
};

export default AddressDisplay;
