import styled from '@emotion/styled';
import { Observer } from 'mobx-react';
import { FC } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import tw from 'twin.macro';

import { SubscriptionType, UserRole } from '../../models';
import { usePotluck, useUser } from '../../providers';
import { Button, Moments, Planning } from '..';
import { FadeIn } from '../animations';
import { growInAnimation } from '../animations/growIn';
import { ProfilePicture as _ProfilePicture } from '../ProfilePicture';

const Container = styled.div`
  ${tw`flex flex-col flex-grow space-y-10`}
`;

export const Section = styled.div`
  ${tw`flex flex-col items-start py-4 pl-12 lg:pl-16`}
`;

const ProfilePicture = styled(_ProfilePicture)``;

const NameTag = styled.div`
  ${tw`items-center hidden lg:flex space-x-4 cursor-pointer`}
`;

export const Link = styled(NavLink)`
  ${tw`relative flex w-full`}

  &.active::after {
    ${tw`absolute right-0 flex bg-secondary`}
    width: 3%;
    height: 150%;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    content: ' ';
    top: -25%;

    ${growInAnimation('150%', 0.1)}
  }
`;

const MegaLink = styled(Link)`
  ${tw`flex items-center text-2xl font-semibold text-neutral-soil`}

  svg {
    ${tw`mr-3 text-4xl`}
  }
`;

export const Items: FC = ({ children }) => {
  const { user } = useUser();
  const { organizing } = usePotluck();

  const router = useHistory();

  return (
    <Observer>
      {() => {
        return (
          <Container>
            <Section>
              <NameTag onClick={() => router.push('/')}>
                <ProfilePicture user={user} />
                {user && <FadeIn className="text-xl font-semibold">Hi {user.firstName}</FadeIn>}
              </NameTag>
            </Section>
            <Section className="space-y-6">
              <MegaLink exact to="/">
                <Planning /> Planning
              </MegaLink>
              <MegaLink to="/moments">
                <Moments /> Moments
              </MegaLink>
              {(user?.subscriptionType || (organizing.events && organizing.events.length > 0)) && (
                <Link to="/create/details" className="pt-10">
                  <Button className="w-3/4" inline>
                    Plan new event
                  </Button>
                </Link>
              )}
            </Section>
            <Section className="pr-0 space-y-6">
              {children}
              <Link to="/account">Your Account</Link>
              {user?.subscriptionType && user?.subscriptionType !== SubscriptionType.Lifetime && (
                <Link to="/billing">Manage Membership</Link>
              )}
              <Link to="/connections">Connections</Link>
              <Link to="/privacy">Privacy Settings</Link>
              <Link to="/support">Ask for Help</Link>
              {user?.role?.name === UserRole.Admin && (
                <Link to="/admin" className="font-semibold">
                  Admin
                </Link>
              )}
              <Link to="/sign-out">Sign Out</Link>
            </Section>
          </Container>
        );
      }}
    </Observer>
  );
};

export default Items;
