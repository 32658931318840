import * as React from 'react';

function SvgCheckmarkWhite(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        stroke="#FFF"
        strokeWidth={1.54}
        d="M19 7l-2.608 2.887L10.573 16 6 11.022"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgCheckmarkWhite;
