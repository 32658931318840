import * as React from 'react';

function SvgSignupScene(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 391 108" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M318.428 105.76c.065-.582.106-1.166.106-1.76 0-28.056-71.186-50.8-158.998-50.8C71.724 53.2.534 75.949.534 104c0 .589.042 1.173.106 1.76h317.788z"
          fill="#F6CFAE"
          fillRule="nonzero"
          opacity={0.229}
        />
        <path
          d="M313.622 105.76c.036-.411.063-.824.063-1.24 0-8.666 3.616-12.49-9.854-18.69-17.333-7.98-55.278-17.19-84.646-17.19-30.928 0-31.745 12.68-60.267 23.337-19.609 7.328-73.666 13.855-73.65 14.023l228.354-.24z"
          fill="#EEAF7B"
          fillRule="nonzero"
          opacity={0.229}
        />
        <path fill="#EEAF7B" fillRule="nonzero" d="M43.756 5.76l-27.196 100h54.392z" />
        <path fill="#F0BA8C" fillRule="nonzero" d="M75.52 37.108L57.48 105.76h36.08z" />
        <path
          stroke="#000"
          strokeWidth={0.64}
          strokeLinecap="round"
          d="M75.652 58.828v46.932M43.887 34.348v71.412M75.52 76.363l4.786-4.41M75.52 95.407l-7.444-6.86M43.756 54.747l-5.444-5.015M43.756 83.618L54.6 73.624"
        />
        <path
          d="M93.36 10.776c0-2.331 5.789-2.826 8.075-2.826 4.64 0 4.949-2.656 8.8-2.656 3.386 0 5.109 2.741 8.264 2.741 6.771 0 8.279-7.793 14.863-7.793 6.697 0 6.356 8.136 12.59 8.136 3.17 0 22.211.6 22.211 2.4l-74.803-.002zM185.6 20.634c0-1.474 5.018-3.78 7.762-3.78 5.412 0 3.723 1.89 7.128 1.89 3.88 0 4.766-8.414 10.67-8.414 6.453 0 5.44 8.228 9.4 8.228 1.706 0 2.56-.8 4.986-.8 1.404 0 2.944 1.405 2.944 2.88l-42.89-.004z"
          fill="#F6CFAE"
          fillRule="nonzero"
        />
        <g>
          <g opacity={0.561} fill="#F6CFAE" fillRule="nonzero">
            <path d="M379 108h-28V60h28z" />
            <path d="M391 108h-18V72h18z" />
          </g>
          <path fill="#F6CFAE" fillRule="nonzero" d="M267.25 105.564h47.203v-69H267.25z" />
          <path
            fill="#FCECDE"
            fillRule="nonzero"
            d="M272.773 52.46h6.13V42.262h-6.13zM272.773 63.61h6.13V53.412h-6.13zM287.566 52.46h6.13V42.262h-6.13zM287.566 63.61h6.13V53.412h-6.13zM302.358 52.46h6.13V42.262h-6.13zM302.358 63.61h6.13V53.412h-6.13zM272.773 89.515h6.13v-10.2h-6.13zM272.773 100.664h6.13V90.467h-6.13zM287.566 89.515h6.13v-10.2h-6.13zM287.566 100.664h6.13V90.467h-6.13zM302.358 89.515h6.13v-10.2h-6.13zM302.358 100.664h6.13V90.467h-6.13z"
          />
          <path
            fill="#EEAF7B"
            fillRule="nonzero"
            d="M267.084 36.564H262l6.554-12.84h45.954v12.84h-47.424"
          />
          <path fill="#F0BA8C" fillRule="nonzero" d="M300.556 15.65h9.768V4.618h-9.768z" />
          <path
            fill="#484645"
            fillRule="nonzero"
            d="M305.44 0l-6.17 4.618h12.339L305.439 0M298.645 15.65v1.539h1.911v6.415h.9v-6.415h7.968v6.415h.9v-6.415h1.558V15.65h-13.237"
          />
          <path fill="#F0BA8C" fillRule="nonzero" d="M306.508 108h59.701V23.724h-59.701z" />
          <g stroke="#E78F4A" strokeWidth={0.5}>
            <path d="M351 64.653h11M351 61.352h11M354.595 64.65v3.302M359.589 61.35v3.3M354.595 58.049v3.3" />
          </g>
          <g stroke="#E78F4A" strokeWidth={0.5}>
            <path d="M310 81h8M310 77h8M313 81.043v2.914M316 77.043v3.914M313 74.043v2.914" />
          </g>
          <path
            d="M329.738 53.3h-8.483V37.367c0-2.338 1.9-4.234 4.242-4.234a4.238 4.238 0 014.241 4.234v15.935"
            fill="#FCECDE"
            fillRule="nonzero"
          />
          <path fill="#F6CFAE" fillRule="nonzero" d="M318.873 55.662h13.347V53.3h-13.347z" />
          <path
            d="M351.362 53.3h-8.482V37.367a4.237 4.237 0 014.24-4.234 4.238 4.238 0 014.242 4.234v15.935"
            fill="#FCECDE"
            fillRule="nonzero"
          />
          <path fill="#F6CFAE" fillRule="nonzero" d="M340.498 55.662h13.346V53.3h-13.346z" />
          <path
            d="M329.738 88.514h-8.483V72.579c0-2.338 1.9-4.234 4.242-4.234a4.239 4.239 0 014.241 4.234v15.935M321.255 107.724v-2.267c0-2.337 1.9-4.234 4.242-4.234a4.239 4.239 0 014.241 4.234v2.267h-8.483z"
            fill="#FCECDE"
            fillRule="nonzero"
          />
          <path fill="#F6CFAE" fillRule="nonzero" d="M318.873 90.875h13.347v-2.361h-13.347z" />
          <path
            d="M351.362 88.514h-8.482V72.579a4.238 4.238 0 014.24-4.234 4.239 4.239 0 014.242 4.234v15.935M342.88 107.724v-2.267a4.238 4.238 0 014.24-4.234 4.239 4.239 0 014.242 4.234v2.267h-8.482z"
            fill="#FCECDE"
            fillRule="nonzero"
          />
          <path fill="#F6CFAE" fillRule="nonzero" d="M340.498 90.875h13.346v-2.361h-13.346z" />
          <path fill="#484645" fillRule="nonzero" d="M319.97 18.711h11.227V15.55h-11.228z" />
          <path
            fill="#484645"
            fillRule="nonzero"
            d="M337.667 18.478h-35.155l2.466 3.386h62.556L370 18.478h-32.333"
          />
          <path fill="#706D6B" fillRule="nonzero" d="M304.978 24.899h62.304v-3.035h-62.304z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgSignupScene;
