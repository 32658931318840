import * as React from 'react';

function SvgEntrees(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        stroke="#070401"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M21.496 15.474c.59.757.59 1.716-.242 2.493-.859.802-2.214 1.158-3.439 1.35-1.409.22-3.731.55-5.585.55-2.792 0-4.914-.243-6.958-.957-.78-.273-1.589-.59-2.196-1.218-.613-.635-.648-1.648.087-2.285.873-.757 1.295-1.136 1.837-.946.542.19.276.492.678 1.013.403.522.513.806.904 1.035.39.23.53.206 1.407.325.585.079 1.245.119 1.978.119h4.573c.639 0 1.341-.04 2.105-.12 1.147-.118 1.17-.22 1.77-.655.401-.29.832-.956 1.293-2 .752.3 1.348.733 1.788 1.296z"
          strokeWidth={1.54}
          fill="#ECECEC"
        />
        <path
          d="M13.812 8.517c2.09.185 3.737 1.01 4.768 2.677.655 1.06.841 2.218.664 3.4-.174 1.157-1.076 1.807-2.277 2.012-1.22.207-2.478.29-3.723.328a42.274 42.274 0 01-3.99-.073 9.748 9.748 0 01-2.344-.424c-1.15-.371-1.582-1.926-1.64-2.374-.057-.448-.116-.779-.046-1.143.046-.244.153-.533.318-.868L6.917 13.3c2.85 3.232 7.552-1.051 4.54-3.532v-.006l-.25-1.047a7.987 7.987 0 012.605-.198z"
          strokeWidth={1.54}
          fill="#DDDDDC"
        />
        <path
          d="M6.542 8.284L5.307 9.485C7.437 20.9 19.4 9.235 6.542 8.284z"
          strokeWidth={1.54}
          fill="#DDDDDC"
        />
        <path
          d="M6.542 8.004l-1.36 1.481-1.68-1.557C1.686 9.023.602 6.265 2.663 5.69c.416-2.241 3.308-.994 2.239.828l1.641 1.487z"
          strokeWidth={1.44}
          fill="#ECECEC"
        />
      </g>
    </svg>
  );
}

export default SvgEntrees;
