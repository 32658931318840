import styled from '@emotion/styled';
import tw from 'twin.macro';

import { Card, Loader, Switch, UserSummary } from '../../components';
import { useUser } from '../../providers';

const Container = styled.div`
  ${tw`space-y-8 pb-16`}

  @media (min-width: 1024px) {
    max-width: 21.375rem;
  }
`;

const Header = styled.div`
  ${tw`font-semibold`}

  font-size: 1.375rem;
`;

const Divider = styled.div`
  ${tw`opacity-50 bg-neutral-stone`}

  height: 0.125rem;
`;

interface PrivacyRuleProps {
  label?: string;
  isPrivate?: boolean;
  onToggle?: () => void;
}

const PrivacyRule = ({ label, isPrivate, onToggle }: PrivacyRuleProps) => {
  return (
    <div className="flex justify-between space-x-16">
      <div>
        <div className="font-semibold">{label}</div>
        Choose to show or hide your <span className="lowercase">{label}</span>.
      </div>
      <Switch checked={!isPrivate} onChange={onToggle} />
    </div>
  );
};

const PrivacyProperties = {
  email: 'Email address',
  last_name: 'Last name'
};

export function PrivacySettings() {
  const { user, update } = useUser();

  if (!user) {
    return <Loader>Fetching your privacy settings...</Loader>;
  }

  const togglePrivacyRule = async (property: string) => {
    await update({
      ...user,
      privacyRules: user.privacyRules.some((r) => r === property)
        ? user.privacyRules.filter((r) => r !== property)
        : [...user.privacyRules, property]
    });
  };

  return (
    <Container>
      <Header className="mb-4 text-center">Privacy</Header>
      <div className="mb-8">
        You decide what information is shared with your connections and community.
      </div>
      <Container>
        <Card className="flex flex-col flex-shrink-0 space-y-6" inline>
          <Header className="text-center">What you're Sharing</Header>
          <Divider />
          <UserSummary user={user} className="mx-auto" />
        </Card>
        <Card inline>
          <Header className="mb-4">Privacy Settings</Header>
          <div className="flex flex-col space-y-6">
            {Object.entries(PrivacyProperties).map(([property, label]) => (
              <PrivacyRule
                key={property}
                label={label}
                isPrivate={user.privacyRules.some((p) => p === property)}
                onToggle={() => togglePrivacyRule(property)}
              />
            ))}
          </div>
        </Card>
      </Container>
    </Container>
  );
}

export default PrivacySettings;
