import styled from '@emotion/styled';
import { ModalProvider } from 'react-modal-hook';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import tw from 'twin.macro';

import { MainMenu } from './components';
import { EditEventDetails } from './components/EditEventDetails';
import { EditEventGuests } from './components/EditEventGuests';
import { EditEventItems } from './components/EditEventItems';
import {
  Access,
  Account,
  Admin,
  Billing,
  Connections,
  Create,
  Events,
  FlowConfirmation,
  Forbidden,
  InviteDeclined,
  Moment,
  Moments,
  NotFound,
  Page,
  PaymentConfirmation,
  PrivacySettings,
  ResetPassword,
  SendResetPasswordCode,
  SignIn,
  SignOut,
  SignUp,
  VerifyEmail
} from './pages';
import EventPage from './pages/Events/EventPage';
import { ApiConnectionProvider, PotluckProvider, UserProvider } from './providers';

const Container = styled.div`
  ${tw`flex flex-col flex-grow lg:flex-row`}
`;

const NotificationsContainer = styled.div`
  ${tw`z-50`}
`;

function App() {
  return (
    <ApiConnectionProvider>
      <ModalProvider>
        <NotificationsContainer id="notifications" />
        <Router>
          <Switch>
            <Route
              path="/support"
              component={() => {
                window.location.replace('https://www.potluck.us/support');
                return null;
              }}
            />
            <Route path="/event/:id/access">
              <Access />
            </Route>
            <Route path="/invite-decline">
              <InviteDeclined message="So sorry you can't make it! We've let the host know on your behalf." />
            </Route>
            <Route path="/decline">
              <InviteDeclined message="So sorry you can't make it!" />
            </Route>
            <Route path="/not-found">
              <NotFound />
            </Route>
            <Route path="/no-access">
              <Forbidden />
            </Route>
            <PotluckProvider>
              <UserProvider>
                <Switch>
                  <Route path="/verify-email">
                    <VerifyEmail />
                  </Route>
                  <Route path="/sign-in">
                    <SignIn />
                  </Route>
                  <Route path="/sign-up">
                    <SignUp />
                  </Route>
                  <Route path="/register">
                    <Redirect to="/sign-up" />
                  </Route>
                  <Route path="/sign-out">
                    <SignOut />
                  </Route>
                  <Route path="/send-reset-password-code">
                    <SendResetPasswordCode />
                  </Route>
                  <Route path="/reset-password">
                    <ResetPassword />
                  </Route>
                  <Route path="/event/:id">
                    <EventPage />
                    <Route path="/event/:id/details">
                      <EditEventDetails />
                    </Route>
                    <Route path="/event/:id/items">
                      <EditEventItems />
                    </Route>
                    <Route path="/event/:id/guests">
                      <EditEventGuests />
                    </Route>
                  </Route>
                  <Route path="/moments/:id">
                    <Moment />
                  </Route>
                  <Route path="/">
                    <Container>
                      <MainMenu />
                      <Page>
                        <Switch>
                          <Route path="/admin">
                            <Admin />
                          </Route>
                          <Route path="/moments">
                            <Moments />
                          </Route>
                          <Route path="/account">
                            <Account />
                          </Route>
                          <Route path="/billing">
                            <Billing />
                          </Route>
                          <Route path="/connections">
                            <Connections />
                          </Route>
                          <Route path="/privacy">
                            <PrivacySettings />
                          </Route>
                          <Route path="/confirmation">
                            <PaymentConfirmation />
                          </Route>
                          <Route path="/">
                            <Events />
                            <Route path="/edit/:id/confirmation">
                              <FlowConfirmation wrapInMenu />
                            </Route>
                            <Route path={'/create'}>
                              <Create />
                            </Route>
                            <Route path="/edit/:id/details">
                              <EditEventDetails />
                            </Route>
                            <Route path="/edit/:id/items">
                              <EditEventItems />
                            </Route>
                            <Route path="/edit/:id/guests">
                              <EditEventGuests />
                            </Route>
                          </Route>
                        </Switch>
                      </Page>
                    </Container>
                  </Route>
                </Switch>
              </UserProvider>
            </PotluckProvider>
            <Route>
              <Redirect to="/not-found" />
            </Route>
          </Switch>
        </Router>
      </ModalProvider>
    </ApiConnectionProvider>
  );
}

export default App;
