import * as React from 'react';

function SvgData(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd" stroke="#070401">
        <path
          d="M15.201 3.264h3a1 1 0 011 1v13h0-5v-13a1 1 0 011-1zM10.201 7.264h3a1 1 0 011 1v9h0-5v-9a1 1 0 011-1zM5.201 11.264h3a1 1 0 011 1v5h0-5v-5a1 1 0 011-1z"
          strokeWidth={1.54}
        />
        <path strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" d="M21.5 20.5h-19" />
      </g>
    </svg>
  );
}

export default SvgData;
