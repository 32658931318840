import * as React from 'react';

function SvgCloseWhite(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="#FFF" strokeWidth={1.54} fill="none" fillRule="evenodd" strokeLinecap="round">
        <path d="M4.42 4.42l15.12 15.12M19.54 4.42L4.42 19.54" />
      </g>
    </svg>
  );
}

export default SvgCloseWhite;
