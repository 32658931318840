import usePaged from './usePaged';

export function useStockCoverImages() {
  const { data: coverImages, ...pagination } = usePaged<{ url: string }>(
    20,
    '/api/event/cover-images'
  );

  return { coverImages, ...pagination };
}

export default useStockCoverImages;
