import styled from '@emotion/styled';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { Link, Redirect, useLocation } from 'react-router-dom';
import tw from 'twin.macro';

import {
  Button,
  Container as InputContainer,
  Error,
  fadeInAnimation,
  FormattedPhoneInput,
  Input
} from '../../components';
import { verifyPhoneWithTwilio } from '../../hooks';
import { User } from '../../models';
import { useUser } from '../../providers';
import { PublicPage } from '../Page';

const Form = styled.form`
  ${tw`max-w-lg mx-auto space-y-8`}
  ${fadeInAnimation()}
`;

const FormBody = styled.div`
  ${tw`space-y-10`}
`;

const NameInputContainer = styled(InputContainer)`
  margin-top: 1rem !important;
  margin-bottom: 2rem;

  .css-mhducs,
  .css-1ixuyck {
    position: relative;
    margin-bottom: 1rem;
  }
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function SignUp() {
  const { signUp, user } = useUser();

  const code = useQuery().get('code');

  const eventId = new URLSearchParams(window.location.search).get('event-id');
  const rsvpResponse = new URLSearchParams(window.location.search).get('response');

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
    watch
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: ''
    },
    mode: 'onSubmit'
  });

  // separate phone number state to be used by the react-phone-number-input
  const [phoneNumHolder, setPhoneNumHolder] = useState<string | undefined>(undefined);
  const [invalidNumberError, setInvalidNumberError] = useState(false);
  const redirectUrl = new URLSearchParams(window.location.search).get('redirect-url') || '/';
  const email = watch('email');

  if (user?.id) {
    return <Redirect to={{ pathname: redirectUrl }} />;
  }

  return (
    <PublicPage
      footer={
        <div className="max-w-sm mx-auto text-xs">
          By clicking "Submit," you agree to Potluck{' '}
          <a href="https://www.potluck.us/terms">Terms of Service</a> and{' '}
          <a href="https://www.potluck.us/privacy">Privacy Policy</a>. You consent to receive
          automated SMS messages and/or emails from Potluck that provide updates on the events you
          are hosting and attending. You may opt out of promotional emails at any time, and you will
          never receive promotional SMS messages.
        </div>
      }
    >
      <Form
        onSubmit={handleSubmit(async (user) => {
          if (user.phoneNumber) {
            try {
              await verifyPhoneWithTwilio(user.phoneNumber);
              setInvalidNumberError(false);
            } catch (e) {
              setInvalidNumberError(true);
              return;
            }
          }

          try {
            await signUp(
              user as unknown as User,
              code || undefined,
              redirectUrl,
              eventId || undefined,
              rsvpResponse || undefined
            );
          } catch (e) {
            //@ts-ignore
            const result = await e.json();

            if (result.error.includes('number')) {
              setError('phoneNumber', { message: result.error });
            } else {
              setError('email', { message: result.error });
            }
          }
        })}
      >
        <div className="text-xl font-semibold text-center">Create Login</div>
        <div className="text-sm text-center">
          No download required, and guests do not need to be members.
        </div>
        <FormBody>
          <NameInputContainer>
            <div className="flex flex-col md:flex-row md:space-x-6 space-y-0 space-x-0">
              <Input
                className={!errors.firstName ? 'mb-2' : ''}
                {...register('firstName', { required: 'Your first name is required.' })}
                placeholder="First Name (required)"
                errors={errors.firstName}
              />
              <Input
                className={!errors.lastName ? 'mb-2' : ''}
                {...register('lastName')}
                placeholder="Last Name"
                errors={errors.lastName}
              />
            </div>
            <div className="mt-4">
              <Input
                className={!errors.email ? 'mb-2' : ''}
                {...register('email', {
                  required:
                    !phoneNumHolder && !getValues('email')
                      ? 'Email required if no phone number given.'
                      : undefined,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Must be a valid email address.'
                  }
                })}
                placeholder="Email"
                errors={errors.email}
              />
            </div>
            <p className="font-bold text-left ml-2.5">and/or</p>
            <InputContainer className={!errors.phoneNumber ? 'mt-2 mb-2' : 'mt-2'}>
              <FormattedPhoneInput
                invalid={errors.phoneNumber || invalidNumberError}
                {...register('phoneNumber', {
                  validate: () => {
                    return phoneNumHolder
                      ? isPossiblePhoneNumber(phoneNumHolder)
                        ? undefined
                        : 'Must be a valid phone number,'
                      : undefined;
                  },
                  required:
                    !email && !phoneNumHolder
                      ? 'Phone number required if no email given.'
                      : undefined
                })}
                country="US"
                placeholder="Phone"
                value={phoneNumHolder}
                onChange={setPhoneNumHolder}
              />
              <div>Phone</div>
              {errors.phoneNumber && <Error relativePosition>{errors.phoneNumber.message}</Error>}
              {!errors.phoneNumber && invalidNumberError && (
                <Error relativePosition>This is not a registered mobile phone number.</Error>
              )}
            </InputContainer>
          </NameInputContainer>
          <div className="flex flex-col items-center space-y-4">
            <Button>Submit</Button>
            <div>
              Already have an account? <Link to="/sign-in">Sign In</Link>
            </div>
          </div>
        </FormBody>
      </Form>
    </PublicPage>
  );
}

export default SignUp;
