import { useEffect } from 'react';

import { FadeIn, MemberCreateScene } from '../../components';
import { fetcher } from '../../providers';

export function Billing() {
  useEffect(() => {
    const aborter = new AbortController();

    fetcher('/api/user/billing-portal', { signal: aborter.signal }).then(
      (url) => (window.location.href = url)
    );
    return () => aborter.abort();
  }, []);

  return (
    <FadeIn className="flex flex-col flex-grow justify-center items-center text-center space-y-10 px-8">
      <MemberCreateScene className="self-center animate-pulse" width="10rem" height="10rem" />
      <b className="text-xl animate-pulse">Redirecting you to view your billing information...</b>
    </FadeIn>
  );
}

export default Billing;
