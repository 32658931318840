import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import truncate from 'lodash/truncate';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import tw from 'twin.macro';

import useEvent from '../../hooks/useEvent';
import { Event } from '../../models';
import Details from '../../pages/Events/Details';
import { Card } from '../Card';
import { Loader } from '../Loader';
import { Menu } from '../Menu';

export function EditEventDetails() {
  const [isSaving, setIsSaving] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { event: _event } = useEvent(Number(id));
  const [event, setEvent] = useState(_event);
  const { save } = useEvent(Number(id));
  const router = useHistory();

  const Header = styled.div`
    ${tw`text-base font-semibold text-center flex-1 self-center`}
  `;

  const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 50%;
  }
`;

  const Pill = styled.div`
    ${tw`flex flex-1 text-sm bg-content-pearl px-2 rounded-2xl w-16 justify-center self-center`}
  `;

  const FadedLoader = styled.div`
    ${tw`fixed bottom-0 right-0 z-40 flex items-center justify-center w-full h-full bg-opacity-50 bg-neutral-soil opacity-50`};
    max-width: 30rem;

    animation: ${fadeIn} 0.5s ease-in;
  `;

  const maxTitleLength = 57;
  const title = truncate(`Details for ${event?.name}`, {
    length: maxTitleLength,
    omission: '...'
  });

  const handleEventChange = (updatedEvent: Event) => {
    setEvent(updatedEvent);
    setIsSaving(true);
    save(updatedEvent);
    setIsSaving(false);
  };

  useEffect(() => {
    setEvent(_event);
  }, [_event]);

  if (!event) {
    return (
      <FadedLoader>
        <Loader />
      </FadedLoader>
    );
  }

  return (
    <>
      <Menu
        header={
          <>
            <div className="flex flex-1 flex-col">
              <Pill>Editing</Pill>
              <Header>{title}</Header>
            </div>
          </>
        }
        goBackOnClose
      >
        <Card className="flex flex-col flex-1 w-full space-y-6">
          <Details
            event={event}
            onChange={handleEventChange}
            onSubmit={async (e) => {
              setIsSaving(true);
              save(e, false);
              setIsSaving(false);
              router.push(`/event/${event.id}`);
            }}
            saveButtonName="Save Details"
          />
        </Card>
      </Menu>
      {isSaving && (
        <FadedLoader>
          <Loader />
        </FadedLoader>
      )}
    </>
  );
}

export default EditEventDetails;
