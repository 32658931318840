import styled from '@emotion/styled';
import { ComponentProps } from 'react';
import tw from 'twin.macro';

import { Input as _Input } from './Input';

const Input = styled(_Input)`
  ${tw`w-10 border-0 text-2xl`}
`;

const Container = styled.div`
  ${tw`flex items-center py-2 pl-2 pr-4 border rounded-lg border-neutral-soil space-x-2`}
`;

const Label = styled.label`
  ${tw`text-xs`}
`;

type GuestInputProps = Omit<ComponentProps<'input'>, 'ref'> & {
  label: string;
  value: number;
  onChange: (value: number) => void;
};

export function GuestInput({ label, value, onChange, ...props }: GuestInputProps) {
  const ensureNumeric = (input: string) => {
    const numeric = Number(input);

    if (isNaN(numeric)) {
      return 0;
    }

    return numeric;
  };

  return (
    <Container>
      <Input
        {...props}
        autoComplete="off"
        disableSubtlePlaceholder
        value={value ? `${value}`.padStart(2, '0') : ''}
        onChange={(e) => onChange(ensureNumeric(e.target.value))}
        placeholder="00"
      />
      <Label>{label}</Label>
    </Container>
  );
}

export default GuestInput;
