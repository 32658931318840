import * as React from 'react';

function SvgReservations(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        stroke="#070401"
        strokeWidth={1.54}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M17.177 3.394l.783.79a2.57 2.57 0 00-.535 1.573 2.559 2.559 0 002.549 2.568c.587 0 1.127-.2 1.558-.535l1.078 1.09c.52.524.52 1.374 0 1.9l-9.734 9.827a1.322 1.322 0 01-1.881 0l-.645-.652 4.3-15.91.645-.651a1.322 1.322 0 011.882 0z"
          fill="#F0EBE0"
        />
        <path d="M19.736 10.32L21.401 12" />
        <path
          d="M14.005 3.394l1.025 1.035a2.584 2.584 0 00.385 3.135c.843.85 2.13.98 3.108.39l.916.925c.52.525.52 1.375 0 1.9l-9.734 9.827a1.322 1.322 0 01-1.882 0l-.902-.91a2.735 2.735 0 00-.306-3.48 2.685 2.685 0 00-3.448-.31l-.777-.785a1.353 1.353 0 010-1.9l9.734-9.827a1.321 1.321 0 011.881 0z"
          fill="#F8F6F1"
        />
        <path strokeDasharray="1.296000051498413,2.160000085830688" d="M10.015 5.521l7.315 7.386" />
      </g>
    </svg>
  );
}

export default SvgReservations;
