import styled from '@emotion/styled';
import { useModal } from 'react-modal-hook';
import tw from 'twin.macro';

import { Event } from '../../models';
import EventPageBody from '../../pages/Events/EventPageBody';
import { UserProvider } from '../../providers';
import { Modal } from '..';

interface PreviewButtonProps {
  large?: boolean;
}

const Preview = styled.div<PreviewButtonProps>`
  ${tw`text-neutral-white rounded-3xl cursor-pointer text-center bg-secondary-600`}
  transition: 0.1s ease-in-out;
  
  ${(p) => p.large ? tw`px-5 py-2` : tw`px-4 py-1 w-max h-8` };
`;

export const PreviewEventLink = ({
  event,
  onClick,
  preventDisplay = false,
  buttonText = "Preview Event",
  large = false
}: {
  event: Event;
  onClick?: () => void;
  preventDisplay?: boolean;
  buttonText?: string;
  large?: boolean;
}) => {
  const [show, close] = useModal(
    () => (
      <Modal onClose={close} showCloseButton={false} excludePadding fullHeight>
        <UserProvider>
          <EventPageBody
            onCloseEventPreview={close}
            isPreviewMode
            event={event}
            path={''}
            router={undefined}
          />
        </UserProvider>
      </Modal>
    ),
    [event]
  );

  return (
    <Preview
      large={large}
      onClick={() => {
        onClick?.();
        if (!preventDisplay) {
          show();
        }
      }}
    >{buttonText}</Preview> 
  );
};
