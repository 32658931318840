import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
`;

export const fadeInAnimation = (time?: number) => css`
  animation: ${fadeIn} ${time || 0.3}s ease-in;
`;

export const FadeIn = styled.div`
  ${fadeInAnimation(0.2)}
`;
