import { observer } from 'mobx-react';
import { ComponentProps, useState } from 'react';

import { Event, EventItem, ItemCategory } from '../../models';
import { usePotluck, useUser } from '../../providers';
import { FadeIn } from '../animations';
import { ClaimItem } from '../ClaimItem';
import { Loader } from '../Loader';

type CategorizedClaimableItemsProps = ComponentProps<'div'> & {
  event: Event;
  onClaims: (claims: Record<number, number>) => void;
  filter?: (items: EventItem[]) => EventItem[];
  itemCategory: ItemCategory;
  isPreviewMode?: boolean;
};

export const CategorizedClaimableItems = observer(
  ({
    event,
    onClaims,
    filter,
    itemCategory,
    isPreviewMode = false,
    ...props
  }: CategorizedClaimableItemsProps) => {
    const { user } = useUser();
    const { attending, organizing } = usePotluck();
    const isOrganizer = event?.organizer.id === user?.id;

    const [claims, setClaims] = useState<Record<number, number>>({});

    if (!user) {
      return <Loader>Fetching items...</Loader>;
    }

    const observableItemsList = isOrganizer
      ? organizing.items(event.id)
      : attending.items(event.id);

    let items = observableItemsList.data || [];

    items = items.filter((i) => {
      //@ts-ignore
      if (itemCategory.id === 'uncategorized') {
        return !i.category;
      } else {
        return i.category?.id === itemCategory.id;
      }
    });

    const filteredItems = filter ? filter(items) : items;

    return (
      <FadeIn
        {...props}
        className={`flex flex-col space-y-8 ${filteredItems.length ? props.className || '' : ''}`}
        ref={observableItemsList.register}
      >
        {filteredItems.map((item, i) => (
          <div
            key={item.id}
            className={`flex flex-col my-2 border-neutral-stone ${
              i < filteredItems.length - 1 ? 'border-b' : ''
            } ${`${itemCategory.id}` === 'uncategorized' ? '' : 'border-dashed'}`}
          >
            <ClaimItem
              key={item.id}
              isPreviewMode={isPreviewMode}
              event={event}
              item={item}
              onClaim={(quantity) => {
                const updates = { ...claims, [item.id]: quantity };
                setClaims(updates);
                onClaims(updates);
              }}
            />
          </div>
        ))}
        {observableItemsList.loading && <Loader />}
      </FadeIn>
    );
  }
);

export default CategorizedClaimableItems;
