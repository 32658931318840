import { runInAction } from 'mobx';

import { Event, EventGuest, EventInsights, EventItem, Message, Reaction } from '../models';
import { fetcher } from '../providers';
import { LazyStore } from './Lazy';

const API = '/api/event';

export class EventStore extends LazyStore<Event> {
  private _guests: Record<number, LazyStore<EventGuest>> = {};
  private _items: Record<number, LazyStore<EventItem>> = {};
  private _itemsInCategory: Record<string, LazyStore<EventItem>> = {};
  private _itemComments: Record<number, LazyStore<Message>> = {};
  private _messages: Record<number, LazyStore<Message>> = {};
  creatingSingleEvent: boolean = false;

  constructor(filter: string = '') {
    super(`event${filter ? `/${filter}` : ''}`);
  }

  setCreatingSingleEvent(value: boolean) {
    this.creatingSingleEvent = value;
  }

  get events(): Event[] | undefined {
    return this.data;
  }

  async event(id: number) {
    if (this.events?.[this.lookup[id]]) {
      return this.events[this.lookup[id]];
    }

    const event = (await fetcher(`${API}/${id}`)) as Event;

    if (!event) {
      return undefined;
    }

    if (this.data) {
      this.data.push(event);
      this.lookup[event.id] = this.data.length;
    }

    return event;
  }

  items(id: number): LazyStore<EventItem> {
    const items = this._items[id] || new LazyStore<EventItem>(`event/${id}/items`);

    this._items[id] = items;

    return items;
  }

  itemsInCategory(eventId: number, categoryId: number | string) {
    const itemsInCategory =
      this._itemsInCategory[`${eventId}-${categoryId}`] ||
      new LazyStore<EventItem>(`event/${eventId}/items/${categoryId}`);

    this._itemsInCategory[`${eventId}-${categoryId}`] = itemsInCategory;
    return itemsInCategory;
  }

  async updateItemsInCategories(eventId: number) {
    this._itemsInCategory = {};
  }

  guests(id: number): LazyStore<EventGuest> {
    const guests = this._guests[id] || new LazyStore<EventGuest>(`event/${id}/guests`);

    this._guests[id] = guests;

    return guests;
  }

  itemComments(eventId: number, itemId: number) {
    const comments =
      this._itemComments[itemId] ||
      new LazyStore<Message>(`event/${eventId}/items/${itemId}/comment`);

    this._itemComments[itemId] = comments;

    return comments;
  }

  async getItemUserClaimComments(eventId: number, eventItemId: number, userId: number) {
    return await fetcher(`/api/event/${eventId}/items/${eventItemId}/comment/${userId}`);
  }

  async sendComment(eventId: number, eventItemId: number, comment: string) {
    const saved = await fetcher(`/api/event/${eventId}/items/${eventItemId}/comment`, {
      method: 'POST',
      body: comment
    });

    runInAction(() => this._itemComments[eventItemId].data?.push(saved));
  }

  messages(eventId: number) {
    const messages = this._messages[eventId] || new LazyStore<Message>(`event/${eventId}/message`);

    this._messages[eventId] = messages;

    return messages;
  }

  async sendMessage(eventId: number, message: string) {
    const saved = await fetcher(`/api/event/${eventId}/message`, {
      method: 'POST',
      body: message
    });

    runInAction(() => this._messages[eventId].data?.unshift(saved));
  }

  async updateMessage(eventId: number, message: Message) {
    await fetcher(`/api/event/${eventId}/message/${message.id}`, {
      method: 'POST',
      body: message.message
    });

    runInAction(() => {
      if (this._messages[eventId]?.data) {
        this._messages[eventId].data = this._messages[eventId].data?.map((m) =>
          m.id === message.id ? message : m
        );
      }
    });
  }

  async reactToMessage(eventId: number, messageId: number, reaction: Reaction) {
    const saved = await fetcher(`/api/event/${eventId}/message/${messageId}/reaction/${reaction}`, {
      method: 'POST'
    });

    runInAction(() => {
      const message = this._messages[eventId].data?.find((m) => m.id === messageId);
      message?.reactions.push(saved);
    });
  }

  async unreact(eventId: number, messageId: number, reactionId: number) {
    await fetcher(`/api/event/reaction/${reactionId}`, {
      method: 'DELETE'
    });

    runInAction(() => {
      const message = this._messages[eventId].data?.find((m) => m.id === messageId);
      if (message) {
        message.reactions = message?.reactions.filter((r) => r.id !== reactionId);
      }
    });
  }

  async insights(id: number): Promise<EventInsights> {
    return await fetcher(`${API}/${id}/insights`);
  }

  async cancel(id: number) {
    await fetcher(`${API}/${id}`, { method: 'DELETE' });
    runInAction(() => (this.data = this.data?.filter((d) => d.id !== id)));
  }
}

export default EventStore;
