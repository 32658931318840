import styled from '@emotion/styled';
import { capitalize } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import tw from 'twin.macro';

import {
  Button,
  Card,
  EventCreateScene,
  ExcursionCreateScene,
  FadeIn,
  Loader,
  Menu, Modal
} from '../../components';
import { useEvent } from '../../hooks';
import { Event, EventCategory, EventStatus } from '../../models';
import { useUser } from '../../providers';
import { FadedLoader } from './Edit';
import Editor from './Editor';
import Step from './Step';

const Option = styled.div`
  ${tw`flex justify-between space-x-6`}
`;

const Description = styled.div`
  ${tw`flex flex-col`}
`;

const CreateButton = styled(Button)`
  ${tw`min-w-0`}
`;

const CallToAction = styled.div`
  ${tw`flex flex-col items-center space-y-8`}
`;

const Header = styled.div`
  ${tw`text-2xl font-semibold text-center flex-1`}
`;

const Cancel = styled.div`
  ${tw`cursor-pointer select-none text-indicating-error text-center`}
`;

const Container = styled.div`
  scroll-margin-top: 50px;

  ${tw`flex flex-1 flex-row`};
`;

interface SelectTypeProps {
  onSelect: (category: EventCategory) => void;
}

export function SelectEventType({ onSelect }: SelectTypeProps) {
  const router = useHistory();

  return (
    <Card className="space-y-14">
      <Option>
        <Description>
          <div className="font-semibold">Event</div>
          <div>Small gathering or massive shindig, make sure everyone knows what to bring.</div>
        </Description>
        <CallToAction>
          <EventCreateScene className="w-40 h-28" />
          <CreateButton outline inline onClick={() => onSelect(EventCategory.Event)}>
            Create
          </CreateButton>
        </CallToAction>
      </Option>
      <Option>
        <Description>
          <div className="font-semibold">Excursion</div>
          <div>Going out of town or around the corner, make sure you have everything you need.</div>
        </Description>
        <CallToAction>
          <ExcursionCreateScene className="w-40 h-28" />
          <CreateButton outline inline onClick={() => onSelect(EventCategory.Excursion)}>
            Create
          </CreateButton>
        </CallToAction>
      </Option>
      <Cancel onClick={() => router.push('/')}>Cancel</Cancel>
    </Card>
  );
}

export function Create() {
  const router = useHistory();
  const { url } = useRouteMatch();
  const user = useUser();
  const { save } = useEvent();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>();

  const initialEvent = {
    name: '',
    description: '',
    category: EventCategory.Event,
    adultGuestCount: 0,
    childGuestCount: 0,
    coverImageUrl: '',
    cohostMessage: '',
    guestMessage: '',
    eventPageMessage: '',
    invites: [],
    organizer: user.user,
    guests: [],
    startTime: '',
    endTime: '',
    recap: '',
    address: {
      address: '',
      city: '',
      state: '',
      zipCode: ''
    },
    items: [],
    status: EventStatus.Unplanned
  } as unknown as Event;

  const [event, setEvent] = useState(initialEvent);

  const [isSaving, setIsSaving] = useState(false);
  const { pathname } = useLocation();
  const { path } = useRouteMatch();
  const headerRef = useRef<HTMLDivElement>(null);

  const orderedSteps = ['details', 'items', 'guests', 'invites'] as const;
  type EditStep = (typeof orderedSteps)[number];
  const isEditing = path.includes('edit') || path.includes('event');
  const [displayInitialItemTemplateMessage, setDisplayInitialItemTemplateMessage] = useState<boolean>(!isEditing);

  const [openTemplateNotification, closeTemplateNotification] = useModal(() => {
    return (
      <Modal onClose={closeTemplateNotification}>
        {({ close }) => (
          <div className="flex flex-col text-left space-y-4">
            <p>
              Your event page is customizable with a cover image, message, and sign-up sheet.<br/><br/>
              You can use one of our sign-up templates or build from scratch! 
            </p>
            <div className="flex flex-col justify-evenly sm:flex-row">
              <Button className="mt-2" outline onClick={close}>
                Start Editing
              </Button>
            </div>
          </div>
        )}
      </Modal>
    );
  }, []);

  useEffect(() => {
    headerRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    const keyFromPath = pathname.split('/')[2];
    if (keyFromPath === 'items' && displayInitialItemTemplateMessage) {
      openTemplateNotification();
      setDisplayInitialItemTemplateMessage(false);
    }
    }, [
      pathname,
      openTemplateNotification,
      displayInitialItemTemplateMessage,
      setDisplayInitialItemTemplateMessage
    ]);

  const getPageName = () => {
    const urlSplit = pathname.split('/');
    return urlSplit[2];
  };

  const goBackFrom = (step: EditStep) => {
    if (orderedSteps.indexOf(step) - 1 >= 0) {
      router.push(
        `${url}/${orderedSteps[orderedSteps.indexOf(step) - 1]}${event.id ? '/' + event.id : ''}`
      );
    }
  };

  const isOnConfirmationPage = getPageName() === 'confirmation';
  const isOnItemsPage = getPageName() === 'items';

  const headerText = useMemo(() => {
    const keyFromPath = pathname.split('/')[2];
    if (keyFromPath === 'items') {
      return 'Event Page';
    } else if (keyFromPath === 'invites') {
      return 'Messaging';
    } else {
      return capitalize(keyFromPath.toUpperCase());
    }
  }, [pathname]);

  const action = (
    <div className="flex flex-col flex-1">
      <Editor
        event={event}
        onChange={async (event, publish) => {
          setIsSaving(true);
          setEvent(await save(event, publish));
          setIsSaving(false);
        }}
        onDismiss={() => setIsMenuOpen(false)}
      />
    </div>
  );

  if (!event) {
    return (
      <FadedLoader>
        <Loader />
      </FadedLoader>
    );
  }

  return (
    <>
      <Menu
        id="editor"
        header={
          <>
            <Container ref={headerRef}>
              {headerText && !isOnConfirmationPage && (
                <Header className={isOnItemsPage ? 'self-center -mr-6' : 'self-center'}>
                  {headerText}
                </Header>
              )}
            </Container>
            <Step />
          </>
        }
        open={isMenuOpen}
        displayBack={!!headerText && headerText !== 'Details' && !isOnConfirmationPage}
        displayBackToDashboard={isOnConfirmationPage}
        displayClose={false}
        className="w-full"
        onGoBack={() => {
          switch (getPageName()) {
            case 'details':
              setEvent(initialEvent);
              router.push('/create');
              break;
            case 'items':
              goBackFrom('items');
              break;
            case 'guests':
              goBackFrom('guests');
              break;
            case 'invites':
              goBackFrom('invites');
              break;
          }
        }}
      >
        <FadeIn className="relative w-full flex flex-col flex-1">{action}</FadeIn>
      </Menu>
      {isSaving && (
        <FadedLoader>
          <Loader />
        </FadedLoader>
      )}
    </>
  );
}

export default Create;
